import styled from 'styled-components';

export const DayLabelStyled = styled.div`
    font-size: ${({ theme }) => theme.typography.size.p16.fontSize};
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    text-transform: capitalize;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 30px;
    padding: 2px 16px;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        font-size: ${({ theme }) => theme.typography.size.p18.fontSize};
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
        font-size: ${({ theme }) => theme.typography.size.h6.fontSize};
    }

    ${({ theme }) => theme.breakpoints.between(1780, 1800)} {
        font-size: ${({ theme }) => theme.typography.size.h5.fontSize};
        padding-right: 26px;
        padding-left: 26px;
    }

    ${({ theme }) => theme.breakpoints.up('xl')} {
        font-size: ${({ theme }) => theme.typography.size.h3.fontSize};
        padding-right: 26px;
        padding-left: 26px;
    }
`;
