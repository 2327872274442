import { createSlice } from '@reduxjs/toolkit';
import { initialNotificationState } from './initialState';

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: initialNotificationState(),
    reducers: {
        setData(state, action) {
            for (const element in action?.payload) {
                state[element] = action.payload[element];
            }
        },
        changeStatus(state, action) {
            const { type, id } = action.payload;
            const notification = state[type].find((element) => {
                return element.Config.Id === id;
            });

            if (notification) notification.Status = 1;
            if (type === 'notifications') --state.unreadCount;
        },
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
    },
});
