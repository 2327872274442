// import { UserGuidesRepository } from '../../infra/old-mock-load/user-guides/UserGuidesRepository';
import { PostsRepository as UserGuidesRepository } from '../../infra/dice-admin/posts/PostsRepository';

import { UserGuideGetter } from './UserGuideGetter';
import { UserGuidesGetter } from './UserGuidesGetter';

const userGuidesRepository = new UserGuidesRepository();

export const userGuideGetter = new UserGuideGetter(userGuidesRepository);
export const userGuidesGetter = new UserGuidesGetter(userGuidesRepository);
