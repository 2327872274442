import React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { globalSel } from '../../../store/global';

const OfferCardLogOutSkeleton = () => {
    const theme = useSelector(globalSel.themeSelector);

    return (
        <Box
            borderRadius={[14, 20, 20, 24, 30]}
            overflow="hidden"
            bgcolor={theme.palette.orange.A50}
            boxShadow={theme.elevation[100]}
        >
            <Box height={[126, 138, 133, 163, 250]}>
                <Skeleton variant="rect" animation="wave" height="100%" width="100%" />
            </Box>

            <Box
                className="WP-card-logout-figcaption-skeleton"
                pt={[4, 3, 4, 5, 6]}
                px={[2, 3, 3, 4, 5]}
                pb={[2, 3, 3, 4, 5]}
            >
                <Box mb={[4, 5, 6, 8, 10]}>
                    <Box height={[64, 40, 40, 44, 56]} width="100%">
                        <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                    </Box>

                    <Box height={[48, 24, 24, 28, 32]} width="100%">
                        <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                    </Box>
                </Box>

                <Box height={[32, 38, 38, 48, 56]}>
                    <Skeleton variant="rect" animation="wave" height="100%" width="100%" />
                </Box>
            </Box>
        </Box>
    );
};

export default OfferCardLogOutSkeleton;
