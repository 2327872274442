import styled from 'styled-components';
import LoyaltyPointGiftBg from '../../../../assets/images/Modal/daily_gift_bg.png';

export const OnboardingContainerStyle = styled.div`
    .WP-close-button {
        background-color: #de3535;
        border: 3px solid ${({ theme }) => theme.palette.orange.A50};
    }

    .WP-steps {
        background-color: rgba(0, 0, 0, 0.6);

        ${({ theme }) => theme.breakpoints.up('xl')} {
            font-size: ${({ theme }) => theme.typography.size.h6.fontSize};
        }

        span {
            color: rgba(255, 255, 255, 0.6);
        }
    }

    .WP-header {
        background-image: url(${LoyaltyPointGiftBg});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .WP-image-bg {
        position: relative;
        z-index: 1;
        padding: 10px;

        background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(255, 134, 107, 0.6) 0%,
            rgba(255, 208, 118, 0.6) 100%
        );

        border: 8px solid #f5ba78;
        border-radius: 50%;

        &:before,
        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            border-radius: 50%;
        }

        &:before {
            width: 108px;
            height: 108px;
            border: 6px solid #faa74a;

            ${({ theme }) => theme.breakpoints.down('xs')} {
                width: 82px;
                height: 82px;
            }
        }

        &:after {
            width: 120px;
            height: 120px;
            border: 6px solid #ff9544;

            ${({ theme }) => theme.breakpoints.down('xs')} {
                width: 94px;
                height: 94px;
            }
        }
    }
`;

export const OnboardingButtonStyled = styled.button`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    font-family: ${({ theme }) => theme.typography.font.inter}, sans-serif;
    font-size: ${({ theme }) => theme.typography.size.p16.fontSize};
    font-weight: ${({ theme }) => theme.typography.weight.semiBold};
    line-height: 24px;
    padding: 2px 28px;
    border-radius: 6px;

    border: 2px solid ${({ theme }) => theme.palette.green.A800};
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    outline: none;

    background-color: ${({ theme }) => theme.palette.green.A800};
    color: ${({ theme }) => theme.palette.common.white};

    &:hover {
        background-color: ${({ theme }) => theme.palette.green.A700};
        border-color: ${({ theme }) => theme.palette.green.A700};
    }

    &:active {
        background-color: ${({ theme }) => theme.palette.green.A800};
    }

    ${({ theme }) => theme.breakpoints.up('sm')} {
        font-size: ${({ theme }) => theme.typography.size.p16.fontSize};
        line-height: 24px;
        padding: 5px 28px;
        border-radius: 8px;
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
        font-size: ${({ theme }) => theme.typography.size.h6.fontSize};
        line-height: 28px;
        white-space: nowrap;
        padding: 8px 28px;
        border-radius: 10px;
    }

    ${({ theme }) => theme.breakpoints.up('xl')} {
        font-size: ${({ theme }) => theme.typography.size.h5.fontSize};
        line-height: 32px;
        padding: 10px 44px;
        border-radius: 10px;
    }
`;
