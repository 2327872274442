import { createSlice } from '@reduxjs/toolkit';
import { initialLeadersState } from './initialState';

export const leaderboardSlice = createSlice({
    name: 'leaders',
    initialState: initialLeadersState(),
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setBoards(state, action) {
            state.boards = action.payload;
        },
        setGamesModes(state, action) {
            state.gamesModes = action.payload;
        },
        setSelected(state, action) {
            state.selected = action.payload;
        },
    },
});
