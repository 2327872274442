import styled from 'styled-components';
import DiceBg from '../../../assets/images/Global/dicePattern.png';
import Shine from '../../../assets/images/Maintenance/shine.png';

export const MaintenanceStyled = styled.div`
    background: linear-gradient(180deg, #ff8128 0%, #ffc453 100%), #ffffff;
    background-blend-mode: multiply, overlay, normal, normal;
    position: relative;

    &:before {
        ${({ theme }) => theme.breakpoints.down('xs')} {
            width: 100%;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;
            content: '';
            z-index: 1;

            mix-blend-mode: multiply;
            background-image: url(${DiceBg});
            background-position: center;
        }
    }

    .WP-maintenance-block-inner {
        width: fit-content;
        position: relative;

        &:after {
            content: '';
            width: calc(100% + 100px);
            height: calc(100% + 100px);

            position: absolute;
            top: -50px;
            left: -50px;
            z-index: -1;

            background: url(${Shine}) no-repeat 82%;
            background-blend-mode: overlay;
            mix-blend-mode: overlay;
            background-size: cover;
            display: inline-block;

            ${({ theme }) => theme.breakpoints.up('sm')} {
                width: calc(100% + 200px);
                height: calc(100% + 200px);

                top: -100px;
                left: -100px;
            }

            ${({ theme }) => theme.breakpoints.up('xl')} {
                width: calc(100% + 300px);
                height: calc(100% + 300px);

                top: -150px;
                left: -150px;
            }
        }
    }

    .WP-maintenance-body {
        height: calc(100vh - 110px);

        ${({ theme }) => theme.breakpoints.up('sm')} {
            height: calc(100vh - 150px);
        }

        ${({ theme }) => theme.breakpoints.up('md')} {
            height: calc(100vh - 170px);
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            height: calc(100vh - 235px);
        }

        .WP-maintenance-block {
            ${({ theme }) => theme.breakpoints.down('sm')} {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    .WP-maintenance-something,
    .WP-maintenance-big,
    .WP-maintenance-coming {
        font-family: Rubik;
        font-weight: 800;
        color: #fffdef;
        text-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);

        &.WP-maintenance-highlighted {
            -webkit-text-stroke: 8px rgba(169, 64, 30, 0.8);

            ${({ theme }) => theme.breakpoints.up('sm')} {
                -webkit-text-stroke-width: 12px;
            }
        }
    }

    .WP-maintenance-something {
        font-size: 35px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            font-size: 50px;
        }

        ${({ theme }) => theme.breakpoints.up('md')} {
            font-size: 80px;
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            font-size: 106px;
        }

        ${({ theme }) => theme.breakpoints.down(375)} {
            font-size: 30px;
        }
    }

    .WP-maintenance-big {
        font-size: 125px;
        line-height: 120px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            font-size: 180px;
            line-height: 150px;
        }

        ${({ theme }) => theme.breakpoints.up('md')} {
            font-size: 300px;
            line-height: 250px;
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            font-size: 400px;
            line-height: 300px;
        }

        ${({ theme }) => theme.breakpoints.down(375)} {
            font-size: 110px;
            line-height: 95px;
        }
    }

    .WP-maintenance-coming {
        font-size: 39px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            font-size: 55px;
        }

        ${({ theme }) => theme.breakpoints.up('md')} {
            font-size: 90px;
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            font-size: 115px;
        }

        ${({ theme }) => theme.breakpoints.down(375)} {
            font-size: 34px;
        }
    }

    .WP-maintenance-footer {
        background-color: rgba(225, 102, 0, 0.2);

        .WP-maintenance-footer-text {
            text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

            ${({ theme }) => theme.breakpoints.down('xs')} {
                font-size: ${({ theme }) => theme.typography.size.p14.fontSize};
            }
        }
    }
`;
