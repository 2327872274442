import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Box, Icon } from 'portal-modules/core-ui';
import { UserImageStyled } from './UserImage.styled';
import { globalSel, globalOp } from '../../../../../../store/global';
import { getStorageItem } from '../../../../../../helpers/localStorage';
import { scopelyId } from '../../../../../../configs/scopelyId.config';
import { setGAEvent } from '../../../../../../helpers/GA4Helper';

const UserImage = ({ imageSrc, mainBorderColor, secondaryBorderColor }) => {
    const dispatch = useDispatch();
    const theme = useSelector(globalSel.themeSelector);
    const user = useSelector(globalSel.currentUserSelector);
    const pathname = window.location.pathname;

    const handleLogout = () => {
        setGAEvent('clickEvent', {
            screen_name: `${pathname === '/' ? '/home' : pathname}`,
            screen_type: 'screen',
            screen_section: 'right_menu',
            interaction_object: 'log_out',
            interaction_object_category: 'close',
        });

        if (getStorageItem('loginProcessing') === 'withScopely')
            return scopelyId.logout('scopely-logout');
        dispatch(globalOp.logout());
    };

    return (
        <Box component={UserImageStyled}>
            <Box
                width={100}
                height={100}
                position="relative"
                className="flex-center"
                p={2}
                border={2}
                borderRadius="50%"
                bordercolor={mainBorderColor}
            >
                {user && imageSrc ? (
                    <Box
                        width={80}
                        height={80}
                        borderRadius={50}
                        overflow="hidden"
                        className="WP-user-image"
                    >
                        <Box
                            component="img"
                            width={1}
                            height={1}
                            src={imageSrc}
                            alt="Player"
                            className="cursor-pointer"
                        />
                    </Box>
                ) : (
                    <Box
                        width={100}
                        height={100}
                        borderRadius={50}
                        className="WP-user-default-image flex-center cursor-pointer"
                    >
                        <Box
                            component={Icon}
                            className="WP-user-icon icon-default-user"
                            size={32}
                            mr={2}
                        />
                    </Box>
                )}

                <Box
                    width={36}
                    height={36}
                    position="absolute"
                    bottom={1}
                    right={-3}
                    zIndex={9}
                    bgcolor={mainBorderColor}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    p={1}
                    border={3}
                    bordercolor={secondaryBorderColor}
                    borderRadius="50%"
                    onClick={handleLogout}
                    className="cursor-pointer"
                >
                    <Icon className="icon-logout" size={20} color={theme.palette.common.white} />
                </Box>
            </Box>
        </Box>
    );
};

UserImage.propTypes = {
    imageSrc: PropTypes.string,
    mainBorderColor: PropTypes.string,
    secondaryBorderColor: PropTypes.string,
};

export default UserImage;
