import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core';
import { globalSel, globalOp } from './store/global';
import ConnectionLostComponent from './views/components/ConnectionLostComponent/ConnectionLostComponent';
import Modal from './views/components/Modal/Modal';

import { getStorageItem, removeStorageItem, setStorageItem } from './helpers/localStorage';

import { GlobalStyles } from './theme/global.styled';
import { TITAN_API_KEY } from './constants/api.constants';

import './theme/styles.scss';
import { useXsollaLoadHook } from './hooks/useXsollaLoad.hook';
import { titanAnalyticsEvents } from './helpers/titanAnalytics';
import Maintenance from './views/pages/Maintenance/Maintenance';
import { getSessionToken, removeSessionToken } from './bridge';
import PageLoader from './views/components/PageLoader/PageLoader';
import { PAYMENT_SUCCESS_MODAL, PAYMENT_SUCCESS_MODAL_DATA } from './constants/modals.constants';
import { translationsOp, translationsSel } from './store/translations';
import { getCookie } from './bridge/cookieStorage';
import PageRoutes from './PageRoutes';

function App() {
    const dispatch = useDispatch();
    const [online, setOnline] = useState(true);
    const providerTheme = useSelector(globalSel.themeSelector);
    const maintenanceMode = useSelector(globalSel.maintenanceModeSelector);
    const appLoaded = useSelector(globalSel.appLoaded);
    const processingOrder = getStorageItem('processingOrder');
    const internalPurchase = getStorageItem('internalPurchase');
    const isLoyaltyPointsSection = processingOrder?.item?.section === 'loyalty_points';
    const translation = useSelector(translationsSel.translationsSelector);

    const handleConnectionChange = () => {
        setOnline(navigator.onLine);
    };
    useXsollaLoadHook();
    useEffect(() => {
        if (getSessionToken() && !getStorageItem('accessToken')) removeSessionToken();
        removeStorageItem('app_version');
        dispatch(globalOp.getConfigs());
        dispatch(globalOp.getFeatures());
        setStorageItem('titanKey', TITAN_API_KEY);
        titanAnalyticsEvents.appOpen();
        titanAnalyticsEvents.startupComplete();
    }, [dispatch]);

    useEffect(() => {
        if (appLoaded) {
            dispatch(translationsOp.getTranslations());

            const interval = setInterval(() => {
                dispatch(globalOp.getConfigs());
            }, 60000);

            if (!getCookie('interstitials')) {
                removeStorageItem('interstitials');
                removeStorageItem('pages');
            }

            return () => {
                clearInterval(interval);
            };
        }
    }, [appLoaded, dispatch]);

    useEffect(() => {
        window.addEventListener('online', handleConnectionChange);
        window.addEventListener('offline', handleConnectionChange);

        return () => {
            window.removeEventListener('online', handleConnectionChange);
            window.removeEventListener('offline', handleConnectionChange);
        };
    }, [dispatch]);

    useEffect(() => {
        if (appLoaded) {
            if (
                (processingOrder && window.location.search.includes('status=done')) ||
                (processingOrder && internalPurchase)
            ) {
                dispatch(
                    globalOp.handleOpenModal({
                        open: true,
                        variant: PAYMENT_SUCCESS_MODAL,
                        data: PAYMENT_SUCCESS_MODAL_DATA,
                        message: `${isLoyaltyPointsSection ? '.loyalty.points' : ''}`,
                    }),
                );
            }
            removeStorageItem('orderInProgress');
            removeStorageItem('internalPurchase');
            removeStorageItem('processingOrder');
        }
    }, [appLoaded, dispatch, processingOrder, isLoyaltyPointsSection]);

    if (!translation)
        return (
            <ThemeProvider theme={{ ...providerTheme }}>
                <GlobalStyles />
                <PageLoader />
            </ThemeProvider>
        );

    return (
        <MuiThemeProvider theme={{ ...providerTheme }}>
            <ThemeProvider theme={{ ...providerTheme }}>
                <Router>
                    {!appLoaded ? (
                        <Router>
                            <PageLoader />
                        </Router>
                    ) : !online ? (
                        <Router>
                            <ConnectionLostComponent />
                        </Router>
                    ) : maintenanceMode ? (
                        <Switch>
                            <Route path="/">
                                <Maintenance />
                            </Route>
                        </Switch>
                    ) : (
                        <PageRoutes />
                    )}
                    <Modal />
                </Router>
                <GlobalStyles />
            </ThemeProvider>
        </MuiThemeProvider>
    );
}

export default App;
