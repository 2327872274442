import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { TooltipStyled } from './Tooltip.styled';
import { Typography } from 'portal-modules/core-ui';

const TooltipWrapper = ({ header, text, position, children }) => {
    return (
        <Box component={TooltipStyled} className="WP-tooltip-container">
            {children}
            <Box className={`WP-tooltip ${position}`}>
                <Box
                    component={Typography}
                    className="WP-tooltip-header"
                    variant="p12"
                    fWeight="bold"
                    mb={1}
                >
                    {header}
                </Box>

                <span className="WP-tooltip-body">{text}</span>
            </Box>
        </Box>
    );
};

TooltipWrapper.propTypes = {
    header: PropTypes.string,
    text: PropTypes.string,
    position: PropTypes.string,
    section: PropTypes.string,
    children: PropTypes.object,
};

export default TooltipWrapper;
