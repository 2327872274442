import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ButtonStyled } from './Button.styled';
import { Icon } from 'portal-modules/core-ui';
import LoadAnimateButton from '../../LoadAnimateButton/LoadAnimateButton';
import IconCustom from '../../IconCustom/IconCustom';

export const Button = ({
    size,
    variant,
    completed = false,
    fullWidth,
    className,
    icon,
    customIcon,
    children,
    disabled = false,
    hasDiscount = false,
    onClick,
    isLoading,
    preventLoading,
    unAvailable,
    propogate,
    shine = false,
    ...rest
}) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isLoading) setLoading(isLoading);
        return () => {
            setLoading(false);
        };
    }, [setLoading, isLoading]);

    const handleClick = (event) => {
        !propogate && event.stopPropagation();
        if (!onClick) return false;
        if (!preventLoading) setLoading(true);

        onClick(setLoading);
    };

    return (
        <ButtonStyled
            fullWidth={fullWidth}
            className={`${shine && 'shine'} ${
                completed || unAvailable ? 'completed' : variant
            } ${size} ${className} 'cursor-pointer'`}
            disabled={disabled || unAvailable}
            onClick={!loading && !completed && !unAvailable ? handleClick : null}
        >
            {loading ? (
                <LoadAnimateButton hasText bodySize="small" />
            ) : (
                <>
                    {icon && !loading && <Icon className={`${icon} button-icon`} />}
                    {customIcon && (
                        <IconCustom
                            icon={customIcon}
                            className="WP-mr-8 button-icon custom-button-icon"
                        />
                    )}

                    <span className={`button-text ${hasDiscount ? 'discount-text' : ''}`} {...rest}>
                        {children}
                    </span>
                </>
            )}
        </ButtonStyled>
    );
};

Button.defaultProps = {
    size: 'medium', // 'large' | 'medium' | 'small' | 'extra-small'
    hasDiscount: false, // is discount button with line through
    variant: 'primary', // 'primary' | 'secondary' | 'solid' | 'grey-1' |
    //'grey-2' | 'grey-3' | 'grey-4' | facebook-btn | 'outlined' | 'brown' | 'outlined-800'
    fullWidth: true, // 'fullWidth'
};

Button.propTypes = {
    size: PropTypes.string,
    variant: PropTypes.string,
    completed: PropTypes.bool,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    customIcon: PropTypes.string,
    disabled: PropTypes.bool,
    hasDiscount: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node,
    isLoading: PropTypes.bool,
    preventLoading: PropTypes.bool,
    unAvailable: PropTypes.bool,
    propogate: PropTypes.bool,
    shine: PropTypes.bool,
};
