import { Box } from '@material-ui/core';
import HighlightedText from '../HighlightedText/HighlightedText';
import React from 'react';
import { useSelector } from 'react-redux';
import { shopSel } from '../../../store/shop';
import PropTypes from 'prop-types';

const CommodityIcon = ({ commodity, className }) => {
    const commodities = useSelector(shopSel.commoditiesSelector);
    if (!commodities) return false;
    return (
        <>
            {commodities?.[commodity.CommodityKey]?.image && (
                <Box className={className}>
                    <Box
                        className="WP-commodity-inner"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Box
                            component="img"
                            src={commodities?.[commodity.CommodityKey]?.image}
                            alt="commodity"
                            className="WP-commodity-img"
                        />

                        <Box className="WP-commodity-quantity-wrapper flex-center">
                            <HighlightedText
                                text={commodity.Quantity}
                                className="WP-commodity-quantity"
                            />
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

CommodityIcon.propTypes = {
    commodity: PropTypes.object,
    className: PropTypes.string,
};

export default CommodityIcon;
