import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, useMediaQuery } from '@material-ui/core';
import { Icon, Typography } from 'portal-modules/core-ui';

import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from '../../../../hooks/useTranslation';

import { OnboardingButtonStyled, OnboardingContainerStyle } from './LP_Onboarding.styled';
import { useSelector } from 'react-redux';
import { globalSel } from '../../../../store/global';
import { deviceType } from '../../../../helpers/deviceType';

const LP_Container = ({
    continuous,
    index,
    step,
    closeProps,
    primaryProps,
    isLastStep,
    tooltipProps,
    size,
}) => {
    const t = useTranslation();
    const theme = useTheme();

    const checkMobileOrTablet = deviceType();
    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const landscapeOrientation = window.matchMedia('(orientation: landscape)').matches;

    const ftueInformation = useSelector(globalSel.ftueSelector);
    const handleCloseTour = () => {
        document.body.classList.remove('disable-scroll');
    };

    useEffect(() => {
        if (index + 1 === 3) {
            const elem = document.querySelector('.WP-section-title');
            const elemLandscape = document.querySelector('#WP-daily-deals-ftue .daily-deals');

            if (checkMobileOrTablet && landscapeOrientation) {
                elemLandscape.scrollIntoView();
            } else {
                elem.scrollIntoView({
                    block: breakpointsSM ? 'start' : 'end',
                });
            }
        }
    }, [index]);

    return (
        <Box
            {...tooltipProps}
            component={OnboardingContainerStyle}
            className="react-joyride__tooltip"
            borderRadius={[14, 20, 20, 24, 30]}
            overflow="hidden"
            position="relative"
            bgcolor={theme.palette.orange.A50}
            boxShadow={theme.elevation[100]}
        >
            {isLastStep && (
                <Box
                    className="WP-close-button flex-center cursor-pointer"
                    onClick={handleCloseTour}
                    width={30}
                    height={30}
                    borderRadius="50%"
                    position="absolute"
                    top={12}
                    right={12}
                    color={theme.palette.orange.A50}
                >
                    <Icon {...closeProps} className="icon-close" size={18} />
                </Box>
            )}
            <div
                {...tooltipProps}
                className={`${
                    checkMobileOrTablet && landscapeOrientation ? 'WP-ftue-tooltip' : ''
                } react-joyride__tooltip`}
            >
                <div className="tour-content">{step.content}</div>

                <Box mt={[5, 8]} p={4}>
                    <Box
                        className="WP-steps flex-center"
                        height={[26, 30]}
                        width="fit-content"
                        borderRadius={20}
                        color={theme.palette.common.white}
                        mx="auto"
                        px={[5, 5, 5, 5, 7]}
                    >
                        <Box component={Typography} variant="p16" fWeight="bold">
                            {index + 1}/<span>{size}</span>
                        </Box>
                    </Box>

                    {continuous && !isLastStep && (
                        <Box mt={[3, 3, 3, 3, 4]}>
                            <OnboardingButtonStyled {...primaryProps}>
                                {ftueInformation?.Popups?.[index].CTAText || t(`continue`)}
                            </OnboardingButtonStyled>
                        </Box>
                    )}
                </Box>
            </div>
        </Box>
    );
};
LP_Container.propTypes = {
    size: PropTypes.number,
    continuous: PropTypes.bool,
    index: PropTypes.number,
    step: PropTypes.object,
    isLastStep: PropTypes.bool,
    backProps: PropTypes.object,
    closeProps: PropTypes.object,
    primaryProps: PropTypes.object,
    tooltipProps: PropTypes.object,
};
export default LP_Container;
