import styled from 'styled-components';

export const NotificationStyled = styled.div`
    position: relative;

    &.WP-more-notification-container {
        .WP-notification-content {
            height: 88px;
            overflow: hidden;

            ${({ theme }) => theme.breakpoints.down(500)} {
                height: 90px;
            }
        }

        .WP-more-notification {
            height: 124px;

            position: relative;
            overflow: hidden;
        }

        .WP-notification-show-more {
            position: absolute;
            bottom: 0;
        }
    }

    .WP-notification-show-more-txt {
        cursor: pointer;
    }

    .WP-checked-icon {
        color: ${({ theme }) => theme.palette.brown.main};
    }

    .WP-notification-close {
        top: 5px;
        right: 5px;
        color: ${({ theme }) => theme.palette.common.white};

        ${({ theme }) => theme.breakpoints.up('sm')} {
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
        }
    }
`;
