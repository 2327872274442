import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import PageHeader from '../../components/PageHeader/PageHeader';
import UserGuidesCard from '../../components/UserGuidesCard/UserGuidesCard';
import UserSinglePageSkeleton from './UserGuideSinglePageSkeleton';
import { useTranslation } from '../../../hooks/useTranslation';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import { userGuidesGetter } from '../../../core/application/user-guides';

const UserGuidesPage = ({ itemCount, isHomePage }) => {
    const t = useTranslation();
    const theme = useTheme();

    const [userGuidList, setUserGuidList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

    const fetchUserGuides = async () => {
        setIsLoading(true);
        userGuidesGetter
            .execute()
            .then((res) => {
                setUserGuidList(res);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchUserGuides();
    }, []);

    // const calculatePagination = (allItems) => {
    //     return Math.floor(allItems / 10) + (allItems % 10 > 0 ? 1 : 0);
    // };

    // const handleRedirect = () => {
    //     history.push('/user-guides');
    // };
    return (
        <Box component="section" pb={[10, 12, 12, 16, 20]}>
            {isLoading ? (
                <UserSinglePageSkeleton isPageNews itemCount={itemCount} />
            ) : !userGuidList.length ? null : (
                <>
                    {!isHomePage ? (
                        <PageHeader
                            title={t('user.guides')}
                            subtitle={t('user.guides.subtitle')}
                            icon="icon-userInfo"
                            isLoading={isLoading}
                        />
                    ) : (
                        <SectionTitle
                            title={t('user.guides')}
                            subtitle={t('user.guides.subtitle')}
                            icon="icon-userInfo"
                        />
                    )}

                    <Box pt={[5, 7, 7, 0, 0, 0]}>
                        <Grid container spacing={breakpointsXL ? 10 : breakpointsLG ? 5 : 4}>
                            {userGuidList.map((item) => (
                                <Grid item key={item?.id} xs={breakpointsSM ? 4 : 12} md={4}>
                                    <UserGuidesCard item={item} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </>
            )}
        </Box>
    );
};

UserGuidesPage.propTypes = {
    itemCount: PropTypes.number,
    isHomePage: PropTypes.bool,
};

export default UserGuidesPage;
