import { getStorageItem, removeStorageItem, setStorageItem } from '../helpers/localStorage';

export const saveSessionToken = (token) => {
    setStorageItem('sessionToken', token);
};

export const getSessionToken = () => {
    return getStorageItem('sessionToken') ?? '';
};

export const removeSessionToken = () => {
    removeStorageItem('sessionToken');
};
