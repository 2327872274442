import { fetch } from '../FetchService';

export class ConfigRepository {
    cache = null;

    async getConfig() {
        if (!this.cache) {
            const {
                data: {
                    Data: { Settings },
                    IsSuccess,
                },
            } = await fetch({
                endPoint: `config`,
                method: 'GET',
            });

            if (!IsSuccess || !Settings) {
                this.cache = null;
            }

            this.cache = Settings;
        }

        return this.cache ?? [];
    }

    clearCache() {
        this.cache = null;
    }
}
