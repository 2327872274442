import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';

import { useTranslation } from '../../../hooks/useTranslation';
import PageHeader from '../../components/PageHeader/PageHeader';
import Banner from './Item/Banner';
import List from './Item/List';

import { mostVisitedSupportsGetter } from '../../../core/application/supports';

const helpCenterBanner = {
    id: 1,
    title: 'Digging for some help?',
    description: 'Find everything you want to know about Yahtzee with Buddies, all in one place!',
    buttonText: 'Go to our help center!',
    sectionUrl: 'https://scopely.helpshift.com/hc/en/32-yahtzee-with-buddies/',
};

const Support = () => {
    const t = useTranslation();

    const [mostVisitedSupports, setMostVisitedSupports] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchSupports = async () => {
        setIsLoading(true);

        mostVisitedSupportsGetter
            .execute()
            .then((supports) => {
                setMostVisitedSupports(supports);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchSupports();
    }, []);

    return (
        <Box className="WP-helpshift-page" pt={[5, 5, 5, 7]}>
            {mostVisitedSupports && helpCenterBanner && (
                <>
                    <PageHeader
                        title={t('support')}
                        subtitle={t('all.answers.in.one.place')}
                        icon="icon-support"
                        isLoading={isLoading}
                    />

                    <Banner data={helpCenterBanner} isLoading={isLoading} />

                    <List data={mostVisitedSupports} isLoading={isLoading} />
                </>
            )}
        </Box>
    );
};

export default Support;
