import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const UserGuideSinglePageSkeleton = ({ itemCount }) => {
    const theme = useTheme();
    const customXS = useMediaQuery(theme.breakpoints.down(768), { noSsr: true });
    const breakpointsXS = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
    const breakpointsSM = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
    const breakpointsLG = useMediaQuery(theme.breakpoints.down('lg'), { noSsr: true });

    const items = itemCount ? [0, 1, 2] : [0, 1, 2, 3, 4, 5, 6, 7, 8];

    return (
        <Box component="section" mb={8}>
            <Box height={28} width={115} mb={4} borderRadius={4}>
                <Skeleton variant="text" animation="wave" height="100%" width="100%" />
            </Box>
            <Grid container spacing={breakpointsXS ? 3 : breakpointsSM ? 4 : breakpointsLG ? 5 : 7}>
                {items.map((item) => (
                    <Grid item key={item} xs={customXS ? 12 : 4}>
                        <Box bgcolor={theme.palette.orange.A50} borderRadius={16} p={4}>
                            <Box mr={3} width="100%" height={[110, 136, 136, 136, 206]}>
                                <Skeleton variant="rect" animation="wave" height="100%" />
                            </Box>

                            <Box width="70%" mt={5} height={180}>
                                <Skeleton variant="text" animation="wave" height={24} />
                                <Skeleton variant="text" animation="wave" height={24} />
                            </Box>

                            <Box width="80%" mt={5} ml={8}>
                                <Skeleton variant="text" animation="wave" height={24} />
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

UserGuideSinglePageSkeleton.propTypes = {
    itemCount: PropTypes.number,
};

export default UserGuideSinglePageSkeleton;
