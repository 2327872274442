import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../hooks/useTranslation';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Icon } from '../ui-components/Icon/Icon';
import { Button } from '../ui-components/Button/Button';

import { getStorageItem, removeStorageItem, setStorageItem } from '../../../helpers/localStorage';
import { LOGINBUTTONTYPE } from '../../../enums/enums';
import { APP_REDIRECT_URL } from '../../../constants/api.constants';
import { LoginWithFacebookStyled } from './LoginWithFacebook.styled';
import { globalOp, globalSel } from '../../../store/global';
import { globalSlice } from '../../../store/global/globalSlice';
import { setGAEvent } from '../../../helpers/GA4Helper';
import {
    TRACKING_ERROR_CODES,
    TRACKING_ACTIONS,
    TRACKING_TYPES,
    loginAnalyticsTracking,
} from '../../../helpers/loginAnalyticsTracking';
import { FACEBOOK_OPEN_TAB_REMINDER } from '../../../constants/modals.constants';

const LoginWithFacebook = ({
    appId,
    disableMobileRedirect,
    type,
    text,
    rightSideBtn,
    loginCallback = () => null,
}) => {
    const FBLogin = globalSlice.actions.setFBLogin;
    const FBLoginStatus = useSelector(globalSel.FBLoginStatus);
    const t = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const pathname = window.location.pathname;
    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });

    const loginProcessing = getStorageItem('loginProcessing');

    const handleFacebookResponse = (response) => {
        if (response.error) {
            loginAnalyticsTracking({
                type: TRACKING_TYPES.FACEBOOK,
                action: TRACKING_ACTIONS.LOGIN_FAILED,
                errorCode: `${TRACKING_ERROR_CODES.FACEBOOK_RESPONSE_ERROR}${
                    response?.error?.code || TRACKING_ERROR_CODES.UNKNOWN_ERROR
                }`,
            });
        }

        if (response?.userID && response?.accessToken && !response.error) {
            dispatch(globalOp.handleFbData(response));
            setStorageItem('fbData', response);
            setStorageItem('accessToken', response.accessToken);
            dispatch(globalOp.loginAsync()).then(() => {
                dispatch(FBLogin(false));
            });
        }
        rightSideBtn &&
            setGAEvent('clickEvent', {
                screen_name: `${pathname === '/' ? '/home' : pathname}`,
                screen_type: 'screen',
                screen_section: 'right_menu',
                interaction_object: 'fb_login',
                interaction_object_category: 'login',
            });
    };

    const handleFacebookOnFailure = () => {
        loginAnalyticsTracking({
            type: TRACKING_TYPES.FACEBOOK,
            action: TRACKING_ACTIONS.LOGIN_FAILED,
            errorCode: `${TRACKING_ERROR_CODES.FACEBOOK_COMPONENT_ON_FAILURE}${TRACKING_ERROR_CODES.UNKNOWN_ERROR}`,
        });
        removeStorageItem('authorizing');
        dispatch(FBLogin(false));
        dispatch(globalOp.handleCloseModal());
    };

    const handleOnClickFacebookButton = (renderProps) => {
        if (getStorageItem('authorizing')) {
            dispatch(
                globalOp.handleOpenModal({
                    open: true,
                    variant: FACEBOOK_OPEN_TAB_REMINDER,
                    data: FACEBOOK_OPEN_TAB_REMINDER,
                }),
            );
        } else {
            setStorageItem('authorizing', true);
            setStorageItem('loginProcessing', 'withFacebook');

            renderProps.onClick();
            dispatch(FBLogin(true));
            loginCallback();
        }
    };

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            if (getStorageItem('authorizing')) {
                dispatch(FBLogin(false));
            }
        }
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    return (
        <LoginWithFacebookStyled>
            <FacebookLogin
                appId={appId}
                disableMobileRedirect={disableMobileRedirect}
                callback={handleFacebookResponse}
                onFailure={handleFacebookOnFailure}
                redirectUri={loginProcessing === 'withFacebook' ? APP_REDIRECT_URL : null}
                render={(renderProps) => {
                    return type === LOGINBUTTONTYPE.FB ? (
                        <Button
                            variant="facebook-btn"
                            data-testid="facebook-btn"
                            onClick={() => handleOnClickFacebookButton(renderProps)}
                            isLoading={FBLoginStatus}
                            preventLoading={true}
                        >
                            <>
                                <div className="logo-container">
                                    <Icon className="icon-login-icon icon-style" size={25} />
                                </div>
                                <p className="WP-fb-text text-truncate">
                                    {t('login.with.facebook')}
                                </p>
                            </>
                        </Button>
                    ) : (
                        <Button
                            size={
                                breakpointsXL
                                    ? 'large'
                                    : breakpointsLG
                                    ? 'medium'
                                    : breakpointsSM
                                    ? 'small'
                                    : 'extra-small'
                            }
                            variant="secondary"
                            data-testid="facebook-btn"
                            onClick={() => {
                                if (getStorageItem('authorizing', true)) return false;
                                setStorageItem('c');
                                dispatch(FBLogin(true));
                                renderProps.onClick();
                                loginCallback();
                            }}
                            isLoading={FBLoginStatus}
                        >
                            {text}
                        </Button>
                    );
                }}
            />
        </LoginWithFacebookStyled>
    );
};

LoginWithFacebook.defaultProps = {
    rightSideBtn: false,
};

LoginWithFacebook.propTypes = {
    appId: PropTypes.string,
    disableMobileRedirect: PropTypes.bool,
    type: PropTypes.number,
    text: PropTypes.string,
    rightSideBtn: PropTypes.bool,
    loginCallback: PropTypes.func,
};

export default LoginWithFacebook;
