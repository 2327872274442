import React from 'react';
import { useSelector } from 'react-redux';

import { Box, useMediaQuery } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { globalSel } from '../../../store/global';

const FeaturedSkeleton = () => {
    const theme = useSelector(globalSel.themeSelector);
    const breakpointsMac = useMediaQuery(theme.breakpoints.between(1780, 1800), {
        noSsr: true,
    });

    return (
        <Box component="section">
            <Box mb={[4, 5, 5, 5, 6]}>
                <Box display="flex" alignItems="center">
                    <Box height={32} width={32} flexShrink={0} mr={2}>
                        <Skeleton variant="circle" animation="wave" height="100%" width="100%" />
                    </Box>

                    <Box height={[32, 32, 32, 32, 40]} width={180}>
                        <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                    </Box>
                </Box>

                <Box height={24} width={270} mt={1}>
                    <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                </Box>
            </Box>

            <Box height={[400, 244, 244, breakpointsMac ? 385 : 288, 450]} width={1}>
                <Skeleton
                    variant="rect"
                    animation="wave"
                    height="100%"
                    width="100%"
                    bgcolor={theme.palette.common.white}
                />
            </Box>
        </Box>
    );
};

export default FeaturedSkeleton;
