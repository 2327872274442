import styled from 'styled-components';

export const TooltipStyled = styled.div`
    z-index: 1;
    position: relative;
    cursor: pointer;

    &:hover {
        .WP-tooltip {
            visibility: visible;
            opacity: 1;
        }
    }

    .WP-tooltip {
        width: 200px;

        color: ${({ theme }) => theme.palette.brown.main};
        font-size: ${({ theme }) => theme.typography.size.p12.fontSize};
        font-weight: 500;

        position: absolute;
        bottom: 26px;
        z-index: 11;

        visibility: hidden;
        opacity: 0;

        background-color: ${({ theme }) => theme.palette.common.white};
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        padding: 12px 8px;
        margin-left: -60px;

        transition: opacity 0.6s;

        &:after {
            position: absolute;
            top: 100%;
            content: '';

            border-width: 5px;
            border-style: solid;
            border-color: ${({ theme }) => theme.palette.common.white} transparent transparent
                transparent;
        }
    }

    .top {
        left: 100%;

        &:after {
            left: 46px;
        }
    }

    .right {
        right: -50px;

        &:after {
            right: 50px;
        }
    }
`;
