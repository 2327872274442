import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';

import SectionTitle from '../SectionTitle/SectionTitle';
import TopLeadersSkeleton from '../Leaderboard/TopLeaders/TopLeadersSkeleton';
import { useTranslation } from '../../../hooks/useTranslation';

import NewsSlider from './NewsSlider';
import { getSlicedArray } from '../../../helpers/utils';
import { getStorageItem } from '../../../helpers/localStorage';
import { LANGUAGES } from '../../../constants/languages.constantants';
import { newsGetter } from '../../../core/application/news';

const HomeNews = () => {
    const t = useTranslation();
    const [news, setNews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchNews = async () => {
        setIsLoading(true);
        newsGetter
            .execute(getStorageItem('language')?.locale || LANGUAGES[0].locale)
            .then((res) => {
                setNews(getSlicedArray(res, 0, 4));
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchNews();
    }, []);

    return (
        <Box component="section" pb={[10, 12, 12, 16, 20]}>
            {isLoading ? (
                <TopLeadersSkeleton />
            ) : !news?.length ? null : (
                <>
                    <SectionTitle
                        title={t('news')}
                        subtitle={t('news.subtitle')}
                        icon="icon-news"
                    />

                    <NewsSlider isHomePage data={news} />
                </>
            )}
        </Box>
    );
};

export default HomeNews;
