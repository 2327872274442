import moment from 'moment';
import { APP_ROUTES } from '../AppRoutes';
import { getStorageItem } from './localStorage';
import { LANGUAGES } from '../constants/languages.constantants';

/**
 * Calculate Progress bar percents
 *  * @param startDate
 * @param endDate

 */
export const generateProgressDateInPercents = (startDate, endDate) => {
    const dateNow = moment().valueOf();
    const sDate = moment.parseZone(startDate).valueOf();
    const eDate = moment.parseZone(endDate).valueOf();
    const fullProgress = eDate - sDate;
    const currentProgress = dateNow - sDate;

    return (currentProgress * 100) / fullProgress;
};

/**
 * Generate Progress bar Date and time
 * @param endDate
 */
export const generateProgressDate = (endDate) => {
    /**
     * Calculate duration between date now/startDate and event endDate
     * Format: X Days Y Hours Remaining
     */
    const duration = moment.duration(moment.parseZone(endDate).diff(moment()));

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (days && hours) {
        return `${days} days ${hours} hours remaining`;
    } else if (hours && !days) {
        return `${hours} hours remaining`;
    } else if (!hours && days) {
        return `${days} days remaining`;
    }

    return `${minutes} minutes remaining`;
};

export const ordinalSuffixOf = (i) => {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + 'st';
    }
    if (j === 2 && k !== 12) {
        return i + 'nd';
    }
    if (j === 3 && k !== 13) {
        return i + 'rd';
    }
    return i + 'th';
};

export const thousandsFormatterWithDots = (string) => {
    if (string || string === 0) {
        return string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
};

export const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const oneDayDiff = (date, endDate) => {
    if (endDate && new Date() > endDate) {
        return false;
    } else if (date && new Date().getTime() - date >= 24 * 60 * 60 * 1000) {
        return false;
    }
    return true;
};

export const formatPriceWithZeroes = (string) => {
    if (string) {
        if (Number.isInteger(Number(string))) {
            return string.toString() + '.00';
        } else {
            return string.toString();
        }
    } else return 0;
};

export const USNumberFormat = (number) => {
    return Number.isInteger(number)
        ? number.toLocaleString('en-US')
        : parseFloat(number).toFixed(2);

    //return trauncateFractionAndFormat(formatter.formatToParts(number), 4);
};

export const calculateDiscount = (original, discounted) => {
    if (!original || original === discounted) return false;
    if (original > discounted) return Math.round(100 - (discounted / original) * 100);
};
/*
    commented due to ios lower version depreciation
const trauncateFractionAndFormat = (parts, digits) => {
    return parts
        .map(({ type, value }) => {
            if (type !== 'fraction' || !value || value.length < digits) {
                return value;
            }

            let retVal = '';
            for (let idx = 0, counter = 0; idx < value.length && counter < digits; idx++) {
                if (value[idx] !== '0') {
                    counter++;
                }
                retVal += value[idx];
            }
            return retVal;
        })
        .reduce((string, part) => string + part);
};

const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 20,
});
*/

export const getSlicedArray = (data, startIndex, endIndex) => {
    return data.slice(startIndex, endIndex);
};

export const validPath = (path) => {
    return APP_ROUTES.some((item) => item.path === path);
};

export const getUrlParam = (param) => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param);
};

export const findByTestAttr = (component, attr) => {
    return component.find(`[data-test='${attr}']`);
};

/**
 * Returns formatted popups data
 * @return screens object formatted to fit UI.
 * @param data
 */
export const formatPopups = (data) => {
    if (!data) return data;
    const sortedData = filterAndSortData(data);
    let screens = {
        home: [],
        store: [],
        'loyalty-point-shop': [],
    };

    for (let i = 0; i < sortedData.length; i++) {
        screens[data[i].TriggerScreen] = screens[data[i].TriggerScreen]
            ? screens[data[i].TriggerScreen]
            : [];
        screens[data[i].TriggerScreen].push(data[i]);
    }
    return screens;
};

/**
 * Sort data by param
 * @param data
 * @param param
 */
export const filterAndSortData = (data, param) => {
    const seenPopups = getStorageItem('interstitials');
    let filtered = data;
    if (seenPopups?.length) {
        filtered = data.filter(function (e) {
            return this.indexOf(e) < 0;
        }, seenPopups);
    }
    return filtered.sort((a, b) => {
        const A = a[param] ? +a[param] : 2;
        const B = b[param] ? +b[param] : 2;
        return B - A;
    });
};

/**
 * return page language
 */
export const getLanguage = () => {
    return getStorageItem('language') || LANGUAGES[0];
};

export const firstLatterOfTextToUppercase = (str) => {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
};

export const IncludeTheWordInArray = (arr, word) => arr.includes(word);

/**
 * Change object keys to lowercase
 * @param obj
 * return object with lowercase keys
 */
export const toLowerKeys = (obj) => {
    return Object.keys(obj).reduce((accumulator, key) => {
        accumulator[key.toLowerCase()] = obj[key];
        return accumulator;
    }, {});
};
