import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Box, useMediaQuery } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';
import { useTheme } from '@material-ui/core/styles';

import { useTranslation } from '../../../../hooks/useTranslation';
import CountdownUTC from '../../CountdownUTC/CountdownUTC';
import { DAILY_LOGIN_CALENDAR_MODAL } from '../../../../constants/modals.constants';
import GiftImage from '../../../../assets/images/DailyRewards/gift.png';
import { dailyRewardsOp } from '../../../../store/dailyReward';
import { DailyRewardBannerStyled } from '../DailyRewardBanner.styled';
import { Button } from '../../ui-components/Button/Button';
import { setGAEvent } from '../../../../helpers/GA4Helper';

const Claimed = ({ callback, time }) => {
    const theme = useTheme();
    const t = useTranslation();
    const dispatch = useDispatch();
    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

    const showDailyLoginCalendar = () => {
        setGAEvent('clickEvent', {
            screen_id: 'web_store',
            screen_name: 'web_store',
            screen_type: 'screen',
            screen_category: 'daily_reward',
            interaction_type: 'tap',
            interaction_object: 'view_calendar',
            interaction_object_category: 'display',
            flow_order: '0',
        });
        dispatch(
            dailyRewardsOp.handleOpenModal({
                open: true,
                variant: DAILY_LOGIN_CALENDAR_MODAL,
            }),
        );
    };

    return (
        <Box component={DailyRewardBannerStyled} width="100%" className="WP-claim-container">
            <Box
                className="WP-daily-reward-img-box WP-daily-reward-claim flex-center"
                width={[90]}
                flexDirection="column"
                flexShrink={0}
                position="relative"
                bgcolor={theme.palette.brown.main}
                data-testid="daily-reward-claimed-banner"
            >
                <Box
                    component="img"
                    src={GiftImage}
                    alt="gift-img"
                    height={80}
                    position="relative"
                    zIndex={1}
                    data-testid="daily-reward-banner-claimed-image"
                />
            </Box>

            <Box
                width={1}
                display="flex"
                p={3}
                alignItems={['baseline', 'center']}
                flexDirection={['column', 'row']}
            >
                <Box flexGrow={1}>
                    <Box className="WP-daily-reward-countdown" mr={3} mb={2}>
                        <CountdownUTC
                            text={`${t('daily.reward.timer')}:`}
                            start={time}
                            interval={60 * 1000}
                            h
                            m
                            callback={callback}
                        />
                    </Box>

                    <Box
                        component={Typography}
                        className="WP-daily-reward-banner-description"
                        variant={['p12', 'p16']}
                        fWeight="medium"
                        color={theme.palette.brown.A700}
                        mb={[2, 0]}
                    >
                        {t('daily.reward.banner.description')}
                    </Box>
                </Box>

                <Box className="WP-claim-button-container" mr={[0, 6]} ml={[0, 2]}>
                    <Button
                        onClick={showDailyLoginCalendar}
                        variant="outlined-800"
                        preventLoading={true}
                        className="WP-claim-button flex-center cursor-pointer"
                        size={breakpointsSM ? 'small' : 'extra-small'}
                    >
                        {t('daily.reward.banner.view')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Claimed;

Claimed.propTypes = {
    time: PropTypes.string,
    callback: PropTypes.string,
};
