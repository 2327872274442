import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { globalOp, globalSel } from '../../../store/global';

import Notification from '../../components/Notifications/Notification';
import HomeLeaderboard from '../../components/Leaderboard/HomeLeaderboard';
// import FeaturedSliderWrapper from '../../components/FeaturedBanner/FeaturedSliderWrapper';
import Landing from '../HomeLogout/Landing/Landing';
import Rewards from '../HomeLogout/Rewards/Rewards';
import Leaderboard from '../HomeLogout/Leaderboard/Leaderboard';
import TipsAndTricks from '../HomeLogout/TipsAndTricks/TipsAndTricks';
import CTA from '../HomeLogout/CTA/CTA';
import Accordion from '../../components/Accordion/Accordion';

import { HomeLogoutStyled } from '../HomeLogout/HomeLogout.styled';
import HomeLatestUpdates from '../../components/LatestUpdates/HomeLatestUpdates';
import HomeNews from '../../components/News/HomeNews';
import { LOGIN_MODAL } from '../../../constants/modals.constants';

const Home = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const flagedPages = useSelector(globalSel.pageFlags);
    const currentUser = useSelector(globalSel.isLoggedIn);
    const authenticated = useSelector(globalSel.isAuthSelector);

    useEffect(() => {
        const isAuthBlocked = location.state?.accesDenied || false;
        if (isAuthBlocked) {
            dispatch(
                globalOp.handleOpenModal({
                    open: true,
                    variant: LOGIN_MODAL,
                    data: {
                        image: 'login',
                        title: 'login.title',
                        description: 'login.description',
                        buttonText: 'login',
                        loginWithFb: 'true',
                    },
                }),
            );
        }
    }, [location]);

    return (
        <Box className="WP-home-page">
            {currentUser ? (
                <>
                    <Box mb={[4, 4, 6, 7]}>{authenticated && <Notification />}</Box>

                    {/*<FeaturedSliderWrapper selector="WebFeatured" />*/}

                    {/*<OffersSection />*/}

                    <HomeLatestUpdates />

                    {flagedPages?.['leaderboards']?.Enabled !== false && (
                        <HomeLeaderboard selector="WebLeaderboards" />
                    )}

                    <HomeNews />

                    {/* <UserGuidesPage itemCount={3} isHomePage /> */}

                    {/* <Box pb={[10, 12, 12, 16, 20]}>
                        <NewsWrapper newsCountToShow={4} isHomePage />
                    </Box> */}

                    <Accordion />
                </>
            ) : (
                <Box component={HomeLogoutStyled} className="WP-minus-margin">
                    <Landing />

                    <Rewards />

                    <Leaderboard />

                    <TipsAndTricks />

                    <CTA />

                    <Box mt={[5, 5, 5, 6]}>
                        <Accordion />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default Home;
