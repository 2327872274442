import styled from 'styled-components';
import Shine from '../../../assets/images/Global/shine.png';

export const GiftsStyled = styled.div`
    .WP-gift-countdown-wrapper {
        &:empty {
            margin-bottom: 0;
        }
    }

    .WP-gift-img-container {
        position: relative;
        background: linear-gradient(181.62deg, #ffbd12 -14.78%, #fff465 56.66%, #fff96c 117.09%);

        &:before {
            width: 100%;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;
            content: '';

            display: inline-block;
            background-image: url(${Shine});
            background-position: center;
            background-size: cover;
            background-blend-mode: lighten;
            mix-blend-mode: overlay;
        }
    }

    .WP-gift-img {
        object-fit: cover;
    }
`;
