import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { useTheme } from '@material-ui/core/styles';
import { Box, Icon, Typography } from 'portal-modules/core-ui';

import { LANGUAGES } from '../../../../constants/languages.constantants';
import { globalOp } from '../../../../store/global';

import { getStorageItem } from '../../../../helpers/localStorage';
import { translationsOp } from '../../../../store/translations';
import { LangSwitcherStyled } from './LangSwitcher.styled';

import US from '../../../../assets/images/Flags/united-states.svg';
import FR from '../../../../assets/images/Flags/france.svg';
import DE from '../../../../assets/images/Flags/germany.svg';
import IT from '../../../../assets/images/Flags/italy.svg';
import ES from '../../../../assets/images/Flags/spain.svg';

const flags = {
    en: US,
    fr: FR,
    de: DE,
    it: IT,
    es: ES,
};

const LangSwitcher = () => {
    const dispatch = useDispatch();

    const theme = useTheme();
    const [isOpen, setOpen] = useState(false);
    const language = getStorageItem('language') || LANGUAGES[0];
    const [lang, setLang] = useState(language);
    const wrapperRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    const handleItemClick = (lng) => {
        setLang(lng);

        setOpen(false);
        moment.locale(lng.code);
        window.helpshiftConfig.language = lng.code;
        window.Helpshift('updateHelpshiftConfig');

        if (!language || language.code !== lng.code) {
            dispatch(globalOp.getPageData(lng));
            dispatch(translationsOp.getTranslations());
        }
    };

    return (
        <div ref={wrapperRef}>
            <Box component={LangSwitcherStyled} mb={10} display="flex dropdown">
                <Box display="none">
                    <img src={US} width="20px" height="20px" alt="flag" />
                    <img src={FR} width="20px" height="20px" alt="flag" />
                    <img src={DE} width="20px" height="20px" alt="flag" />
                    <img src={IT} width="20px" height="20px" alt="flag" />
                    <img src={ES} width="20px" height="20px" alt="flag" />
                </Box>
                <Box
                    className="dropdown-header"
                    bgcolor={theme.palette.common.white}
                    color={theme.palette.brown.A600}
                    borderRadius={8}
                    onClick={() => setOpen(!isOpen)}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box className="flex-center">
                        <Box mr={2} className="flex-center">
                            <img
                                src={flags[lang.code]}
                                width="20px"
                                height="20px"
                                id="test"
                                alt="flag"
                            />
                        </Box>

                        <Box component={Typography} variant="p12" color={theme.palette.brown.main}>
                            {lang.label}
                        </Box>
                    </Box>

                    <Icon
                        className={`${isOpen ? 'open-menu' : 'close-menu'} icon-arrow`}
                        size={16}
                        color={theme.palette.brown.A600}
                    />
                </Box>
                <Box
                    className={`dropdown-body ${isOpen && 'open'}`}
                    borderRadius={8}
                    mt={1}
                    bgcolor={theme.palette.common.white}
                >
                    {LANGUAGES.map((item) => (
                        <div
                            className={`${
                                item.id === language.id && 'active-menu'
                            } dropdown-item text-truncate`}
                            onClick={() => handleItemClick(item)}
                            id={item.id}
                            key={item.id}
                        >
                            <Box
                                component={Typography}
                                variant="p12"
                                color={theme.palette.brown.main}
                                className=" text-truncate"
                            >
                                {item.label}
                            </Box>
                        </div>
                    ))}
                </Box>
            </Box>
        </div>
    );
};

export default React.memo(LangSwitcher, () => true);
