import styled from 'styled-components';

export const MessageBannerStyled = styled.div`
    width: calc(100vw - 110px);
    min-height: 48px;

    position: fixed;
    left: 30px;
    bottom: 1.2rem;

    z-index: 999;

    border: 3px solid;
    border-radius: 43px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    &.banner-offer {
        background-color: #c35ed0;
        border-color: #dc7fe8;
        color: #fff;
    }

    &.banner-generic {
        background-color: #fff49d;
        border-color: #ffffff;
        color: #844733;
    }

    &.banner-announcement {
        background-color: #b86348;
        border-color: #c27a62;
        color: #fff;
    }

    &.banner-error {
        background-color: #f57070;
        border-color: #fbb6b6;
        color: #fff;
    }

    ${({ theme }) => theme.breakpoints.up('sm')} {
        left: 40px;
        width: calc(100vw - 130px);
    }

    ${({ theme }) => theme.breakpoints.up('md')} {
        width: 697px;

        left: 50%;
        transform: translateX(-50%);
    }

    img {
        position: absolute;
        left: -20px;
        bottom: -8px;
        height: 80px;

        ${({ theme }) => theme.breakpoints.up('md')} {
            left: -40px;
            top: -90px;
            height: 151px;
        }
    }

    .messageBannerText {
        margin: auto;
        text-align: center;
        font-family: ${({ theme }) => theme.typography.font.inter}, sans-serif;
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        width: 70%;

        ${({ theme }) => theme.breakpoints.up('md')} {
            width: 90%;
            font-size: 24px;
            line-height: 32px;
        }

        .smallText {
            font-size: 12px;
            line-height: 15px;

            ${({ theme }) => theme.breakpoints.up('md')} {
                font-size: 14px;
                line-height: 17px;
            }
        }
    }

    .closeBanner {
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-40%);
    }

    &:hover {
        cursor: pointer;
    }
`;
