import styled from 'styled-components';

import badgeXs from '../../../assets/images/Global/badgeXs.svg';
import badge from '../../../assets/images/Global/badge.svg';

export const BadgeStyled = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    text-transform: uppercase;
    border-top-left-radius: 14px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), inset 3px 3px 0 rgba(255, 154, 122, 0.4);

    ${({ theme }) => theme.breakpoints.up('sm')} {
        border-top-left-radius: 20px;
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
        border-top-left-radius: 24px;
    }

    ${({ theme }) => theme.breakpoints.up('xl')} {
        border-top-left-radius: 30px;
    }

    &.large-mobile {
        ${({ theme }) => theme.breakpoints.down('500')} {
            height: 36px;
            &:after {
                width: 13px;
            }
        }
    }

    &:after {
        content: '';
        display: block;
        background: url(${badgeXs}) no-repeat center;
        background-size: cover;
        width: 7px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 100%;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            width: 12px;
            background-image: url(${badge});
        }

        ${({ theme }) => theme.breakpoints.up('md')} {
            width: 13px;
        }

        ${({ theme }) => theme.breakpoints.up('lg')} {
            width: 14px;
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            width: 15px;
        }

        // &.large-mobile {
        //     ${({ theme }) => theme.breakpoints.down('500')} {
        //         width: 13px;
        //     }
        // }
    }

    .WP-badge-text {
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

        ${({ theme }) => theme.breakpoints.up('lg')} {
            font-size: ${({ theme }) => theme.typography.size.h6.fontSize};
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            font-size: ${({ theme }) => theme.typography.size.h5.fontSize};
        }
    }
`;

export const ButtonBadgeStyled = styled.div`
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    border-radius: 12px 0 12px 0;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), inset 3px 3px 0 rgba(255, 154, 122, 0.4);
    background-color: #ff3c25;
    color: ${({ theme }) => theme.palette.common.white};
`;
