import styled from 'styled-components';

import ribbon from '../../../assets/images/Home/Logout/ribbon.svg';
import button from '../../../assets/images/Home/Logout/buttonBg.svg';
import shine from '../../../assets/images/Home/Logout/shine.png';

export const HomeLogoutStyled = styled.div`
    .WP-logout-landing,
    .WP-logout-rewards,
    .WP-logout-leaderboard,
    .WP-logout-tipsAndTricks,
    .WP-logout-CTA {
        display: flex;
        align-items: center;

        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        position: relative;
        overflow: hidden;

        box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.33),
            0px -6px 0px 0px rgba(255, 255, 255, 0.4) inset;
        border-radius: 0 0 20px 20px;
    }

    .WP-logout-rewards,
    .WP-logout-leaderboard,
    .WP-logout-tipsAndTricks,
    .WP-logout-CTA {
        margin-top: -20px;
    }

    .WP-logout-rewards,
    .WP-logout-leaderboard,
    .WP-logout-tipsAndTricks {
        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 6px;
            z-index: -1;
            background-repeat: no-repeat;
        }

        &:before {
            border-bottom-left-radius: 20px;
            background-position: left bottom;
            left: 0;
        }

        &:after {
            border-bottom-right-radius: 20px;
            background-position: right bottom;
            right: 0;
        }
    }

    .WP-logout-landing,
    .WP-logout-tipsAndTricks {
        .WP-content-wrapper {
            ${({ theme }) => theme.breakpoints.up('lg')} {
                width: 50%;
            }

            .WP-content-inner {
                position: relative;

                display: flex;
                flex-direction: column;
                align-items: center;

                ${({ theme }) => theme.breakpoints.up('lg')} {
                    width: 260px;
                }

                &:before {
                    width: 100%;
                    height: 100%;

                    position: absolute;
                    left: -130%;
                    top: -70px;
                    content: none;
                    z-index: -1;

                    display: inline-block;
                    mix-blend-mode: color-dodge;

                    ${({ theme }) => theme.breakpoints.up('lg')} {
                        content: url(${shine});
                    }
                }
            }

            .WP-landing-highlighted-title {
                font-size: ${({ theme }) => theme.typography.size.h6.fontSize};
                margin-bottom: 30px;

                > span {
                    font-size: 36px;
                    display: block;
                }
            }

            .WP-landing-highlighted-text {
                font-size: ${({ theme }) => theme.typography.size.p16.fontSize};
                margin-bottom: 16px;

                > span {
                    display: block;
                }
            }

            .WP-button {
                border: none;
                margin: 0;

                width: 230px;
                min-height: 71px;

                background: url(${button}) no-repeat center;
                background-size: cover;
                font-size: 36px;
                font-weight: ${({ theme }) => theme.typography.weight.bold};

                text-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.4);
                color: rgba(253, 255, 251, 1);
                text-transform: uppercase;

                position: relative;
                z-index: 1;

                padding-bottom: 8px;
            }

            .WP-bonus {
                width: 200px;
                min-height: 90px;
                background: url(${ribbon}) no-repeat center;
                background-size: cover;
                margin-top: -22px;

                .WP-bonus-plus {
                    font-size: ${({ theme }) => theme.typography.size.h5.fontSize};
                    font-weight: ${({ theme }) => theme.typography.weight.bold};
                    color: #fffce1;
                }

                .WP-bonus-img {
                    width: 36px;
                    height: 36px;
                    margin: 0 12px;
                }

                .WP-bonus-text {
                    font-size: ${({ theme }) => theme.typography.size.p16.fontSize};
                    font-weight: ${({ theme }) => theme.typography.weight.bold};
                    color: #fffce1;

                    > span {
                        display: block;
                    }
                }
            }
        }
    }

    .WP-reward-highlighted,
    .WP-leaderboard-highlighted {
        font-size: ${({ theme }) => theme.typography.size.h6.fontSize};

        ${({ theme }) => theme.breakpoints.up('lg')} {
            font-size: 36px;
        }

        > span {
            display: block;
        }
    }
`;
