import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from '../../../hooks/useTranslation';

import { AnchorsMenuStyled } from './AnchorsMenu.styled.js';

import SwiperSlider from '../SwiperSlider/SwiperSlider';

import { Button } from '../ui-components/Button/Button.jsx';
import { setGAEvent } from '../../../helpers/GA4Helper.js';

const AnchorsMenu = ({ anchors }) => {
    const theme = useTheme();
    const breakpointsXS = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
    const breakpointsSM = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
    const anchorsMenuRef = useRef(null);

    const t = useTranslation();

    const history = useHistory();
    const location = useLocation();

    const [isFixed, setIsFixed] = useState(false);

    const [elementFocused, setElementFocused] = useState(anchors[0]);

    useEffect(() => {
        setGAEvent('impression', {
            screen_id:
                location.pathname === '/loyalty-point-shop' ? 'loyalty_point_store' : 'web_store',
            screen_name:
                location.pathname === '/loyalty-point-shop' ? 'loyalty_point_store' : 'web_store',
            screen_type: 'screen',
            screen_section: 'web',
            interaction_type: 'load',
            interaction_object_category: 'display',
        });

        let timeoutId;

        const handleScroll = () => {
            // fix and unfix the menu
            if (window.scrollY >= anchorsMenuRef.current.offsetTop + (breakpointsSM ? 4 : 61)) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }

            // send scroll event
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setGAEvent('clickEvent', {
                    screen_id:
                        location.pathname === '/loyalty-point-shop'
                            ? 'loyalty_point_store'
                            : 'web_store',
                    screen_name:
                        location.pathname === '/loyalty-point-shop'
                            ? 'loyalty_point_store'
                            : 'web_store',
                    screen_type: 'screen',
                    screen_section: 'web',
                    interaction_type: 'scroll',
                    interaction_object_category: 'display',
                    screen_content: elementFocused ? elementFocused.screenCategory : null,
                });
            }, 200);

            //verify position
            const windowHeight = window.innerHeight;

            anchors.forEach((anchor, index) => {
                const anchorDiv = document.querySelector(`.${anchor.anchor}`);

                if (anchorDiv) {
                    const element = anchorDiv.getBoundingClientRect();

                    if (element.top <= windowHeight / 2 && element.bottom >= windowHeight / 2) {
                        setElementFocused(anchor);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const onClick = (swiper) => {
        const clicked = swiper.clickedIndex;

        if (clicked) {
            swiper.slideTo(clicked > 1 ? clicked - 1 : 0, 500);

            setGAEvent('clickEvent', {
                screen_id:
                    location.pathname === '/loyalty-point-shop'
                        ? 'loyalty_point_store'
                        : 'web_store',
                screen_name:
                    location.pathname === '/loyalty-point-shop'
                        ? 'loyalty_point_store'
                        : 'web_store',
                screen_type: 'screen',
                screen_category: anchors[clicked].screenCategory,
                screen_section: 'web',
                interaction_type: 'tap',
                interaction_object_category: 'display',
                screen_content: elementFocused ? elementFocused.screenCategory : null,
            });
        }
    };

    const onSliderFirstMove = (swiper) => {
        setGAEvent('clickEvent', {
            screen_id:
                location.pathname === '/loyalty-point-shop' ? 'loyalty_point_store' : 'web_store',
            screen_name:
                location.pathname === '/loyalty-point-shop' ? 'loyalty_point_store' : 'web_store',
            screen_type: 'screen',
            screen_section: 'web',
            interaction_type: 'swap',
            interaction_object_category: 'display',
            screen_content: elementFocused ? elementFocused.screenCategory : null,
        });
    };

    return (
        <Box component={AnchorsMenuStyled} ref={anchorsMenuRef}>
            {isFixed && <Box className="WP-anchors-menu-placeholder" />}

            <Box className={`WP-anchors-menu ${isFixed ? 'fixed' : ''}`}>
                <SwiperSlider
                    className="WP-anchors-menu-slider"
                    navigation={breakpointsXS}
                    slidesPerView="auto"
                    spaceBetween={10}
                    allowTouchMove
                    onClick={onClick}
                    onSliderFirstMove={onSliderFirstMove}
                >
                    {anchors.map((anchor, index) => (
                        <Button
                            key={index}
                            variant="solid"
                            size="small"
                            preventLoading
                            className="WP-anchors-menu-box"
                            customIcon={anchor.customIcon}
                            icon={anchor.icon}
                            onClick={() =>
                                history.push(history.location.pathname, `${anchor.anchor}`)
                            }
                        >
                            {t(anchor.text)}
                        </Button>
                    ))}
                </SwiperSlider>
            </Box>
        </Box>
    );
};

AnchorsMenu.propTypes = {
    anchors: PropTypes.array,
    scrollYXS: PropTypes.number,
    scrollY: PropTypes.number,
};

export default AnchorsMenu;
