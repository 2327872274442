import React, { useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../hooks/useTranslation';

import { Icon, Typography } from 'portal-modules/core-ui';
import { Box } from '@material-ui/core';

import { NotificationStyled } from './Notification.styled';
import { notificationsSel, notificationsOp } from '../../../store/notifications';
import { globalSel } from '../../../store/global';

const Notification = () => {
    const dispatch = useDispatch();
    const t = useTranslation();
    const notificationRef = useRef(null);

    const theme = useSelector(globalSel.themeSelector);
    const message = useSelector(notificationsSel.messagesSelector)[0];
    const authenticated = useSelector(globalSel.isAuthSelector);

    const [toggleShowMoreText, setToggleShowMoreText] = useState(false);
    const [notificationHeight, setNotificationHeight] = useState(false);
    const maxHeight = 140;

    useLayoutEffect(() => {
        if (message)
            notificationRef.current && setNotificationHeight(notificationRef.current?.clientHeight);
    }, [message]);

    const toggleShowMore = () => {
        setToggleShowMoreText(!toggleShowMoreText);

        const notificationClassList = notificationRef.current.classList;

        if (notificationClassList?.contains('WP-more-notification-container')) {
            notificationClassList?.remove('WP-more-notification-container');
        } else {
            notificationClassList?.add('WP-more-notification-container');
        }
    };

    const handleNotificationShow = () => {
        dispatch(notificationsOp.readNotification('messages', message.Config.Id));
    };

    return (
        <>
            {message?.Status === 0 && authenticated && (
                <Box
                    component={NotificationStyled}
                    ref={notificationRef}
                    className={`${
                        notificationHeight >= maxHeight ? 'WP-more-notification-container' : ''
                    } WP-notification-container`}
                    display="flex"
                    alignItems="flex-start"
                    bgcolor={theme.palette.common.white}
                    borderRadius={12}
                    py={2}
                    pl={2}
                    pr={5}
                    mt={[4, 5, 0]}
                >
                    <Box
                        component={Icon}
                        className="WP-notification-close icon-close flex-center cursor-pointer"
                        onClick={handleNotificationShow}
                        size={12}
                        width={20}
                        height={20}
                        bgcolor={theme.palette.red.A800}
                        borderRadius="50%"
                        position="absolute"
                    />

                    <Box className="WP-more-notification" display="flex" alignItems="flex-start">
                        <Box
                            component="img"
                            src={message.Config.BannerUrl}
                            alt={message.Config.Title}
                            width={[50, 70]}
                            mr={3}
                        />

                        <Box display="flex" flexDirection="column" ml={[0, 5]}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                className="WP-notification-content"
                            >
                                <Box
                                    component={Icon}
                                    className="icon-checked-star WP-checked-icon"
                                    size={20}
                                    mr={1}
                                    pt={1}
                                />
                                <Box>
                                    <Box
                                        component={Typography}
                                        variant="p14"
                                        fWeight="bold"
                                        color={theme.palette.brown.main}
                                        className="text-uppercase"
                                        display="flex"
                                        alignItems="flex-start"
                                    >
                                        {message.Config.Title}
                                    </Box>
                                    <Box
                                        component={Typography}
                                        variant="p12"
                                        color={theme.palette.brown.main}
                                    >
                                        {message.Config.Description}
                                    </Box>

                                    <Box display="flex" alignItems="center" mt={4}>
                                        <Box
                                            component={Typography}
                                            variant="p10"
                                            color={theme.hexToRgba(theme.palette.brown.main, 0.7)}
                                        >
                                            {message.Config.SubTitle}
                                        </Box>
                                        <Box
                                            component="img"
                                            src={message.Config?.copyrightImg}
                                            alt={message.Config?.copyright}
                                            height={14}
                                            ml={3}
                                        />
                                    </Box>
                                </Box>
                            </Box>

                            {notificationHeight >= maxHeight ? (
                                <Box
                                    className="WP-notification-show-more"
                                    onClick={toggleShowMore}
                                    ml={6}
                                >
                                    <Box
                                        component={Typography}
                                        color={theme.palette.green.main}
                                        fontSize="p12"
                                        fontWeight="medium"
                                        className="WP-notification-show-more-txt"
                                        mt={2}
                                    >
                                        {toggleShowMoreText ? t('show.less') : t('show.more')}
                                    </Box>
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default Notification;
