import React, { useEffect, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import Header from './Header/Header';
import Main from './Main/Main';
import LeftSidebar from './LeftSidebar/LeftSidebar';
import RightSidebar from './RightSidebar/RightSidebar';
import MessageBanner from './MessageBanner/MessageBanner';
import BackToGame from './BackToGame/BackToGame';
import { Backdrop } from '../components/ui-components/Backdrop/Backdrop';
import { GA_ID } from '../../constants/analytic.constants';
import globalSlice, { globalSel } from '../../store/global';
import 'moment/min/locales.min';
import { getStorageItem } from '../../helpers/localStorage';
import moment from 'moment';
import { LANGUAGES } from '../../constants/languages.constantants';

export default function Layout({ children }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const currentUser = useSelector(globalSel.currentUserSelector);
    const openRightSidebar = useSelector(globalSel.rightSidebar);
    const openLeftSidebar = useSelector(globalSel.leftSidebar);

    const closeRightSidebar = () => {
        dispatch(globalSlice.actions.setSidebar({ side: 'rightSidebar', open: false }));
    };

    const closeLeftSidebar = () => {
        dispatch(globalSlice.actions.setSidebar({ side: 'leftSidebar', open: false }));
    };

    useLayoutEffect(() => {
        const html = document.getElementsByTagName('html')[0].classList;

        if (openLeftSidebar || openRightSidebar) {
            html.add('disable-scroll');
        } else {
            html.remove('disable-scroll');
        }
    }, [openLeftSidebar, openRightSidebar]);

    const checkPageLoadDuration = () => {
        const entriesValues = performance.getEntriesByType('navigation')[0];
        if (!entriesValues) {
            return;
        }
        const pageLoadDuration = Math.floor(entriesValues.duration / 1000);
        const url =
            window.location.pathname === '/'
                ? 'home'
                : `${window.location.pathname.replace('/', '')}`;
        pageLoadDuration >= 10 &&
            window?.DD_RUM?.addError(`page-load-${url}`, {
                host: process.env.REACT_APP_REDIRECT_URI,
                path: window.location.pathname,
            });
    };

    useEffect(() => {
        if (!window.gtag) return;

        window.gtag('config', GA_ID, {
            page_title: history.location.pathname,
            page_path: history.location.pathname,
            user_id: currentUser?.UserId,
        });
    }, [currentUser?.UserId, history.location.pathname]);

    useEffect(() => {
        checkPageLoadDuration();
        moment.locale(getStorageItem('language')?.code || LANGUAGES[0].code);
    }, []);
    return (
        <>
            <Header />
            <Box display="flex" className="WP-layout">
                <Backdrop open={openLeftSidebar} zIndex={1150} onClick={closeLeftSidebar} />
                <LeftSidebar />
                <Main>{children}</Main>
                <Backdrop open={openRightSidebar} zIndex={1150} onClick={closeRightSidebar} />
                <RightSidebar />
                <MessageBanner />
                <BackToGame />
            </Box>
        </>
    );
}

Layout.propTypes = {
    children: PropTypes.node,
};
