import styled from 'styled-components';
import ShineImage from '../../../assets/images/Home/Featured/shine.png';

export const FeaturedItemStyled = styled.div`
    position: relative;

    background-image: url(${({ backgroundImage }) => backgroundImage});
    background-color: #653ea1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    border-radius: 14px;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        border-radius: 20px;
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
        border-radius: 24px;
    }

    ${({ theme }) => theme.breakpoints.up('xl')} {
        border-radius: 30px;
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
        background-size: cover;
    }

    .WP-featured-shadow {
        position: relative;
        padding-top: 8px;

        &:before {
            width: 100%;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;
            content: '';
            z-index: -1;

            display: inline-block;
            background: linear-gradient(90.01deg, #000000 -1.68%, rgba(0, 0, 0, 0) 99.99%);
            opacity: 0.6;

            ${({ theme }) => theme.breakpoints.down('xs')} {
                background: rgba(14, 14, 14, 0.5);
            }
        }
    }

    .WP-featured-shine-effect {
        position: relative;

        &:before {
            width: 100%;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;
            content: '';
            display: inline-block;
            z-index: -1;

            background-image: url(${ShineImage});
            background-position: center;
            background-size: cover;
        }

        img {
            object-fit: contain;
        }
    }

    .WP-featured-discount {
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    }
`;
