import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { AddBalanceStyled } from './AddBalance.styled';
import { ADDBALLANCETYPE } from '../../../../../../enums/enums';
import PLUS from './../../../../../../assets/images/UserProfile/infoPlus.png';
import { setGAEvent } from '../../../../../../helpers/GA4Helper';
import globalSlice from '../../../../../../store/global';

const AddBalance = ({ type, dataTestId }) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const pathname = window.location.pathname;

    const handleRightSideClose = () => {
        dispatch(globalSlice.actions.setSidebar({ side: 'rightSidebar', open: false }));
    };

    const handleScroll = () => {
        switch (type) {
            case ADDBALLANCETYPE.LP:
                history.push('/store', 'store-loyalty');
                setGAEvent('clickEvent', {
                    screen_name: `${pathname === '/' ? '/home' : pathname}`,
                    screen_type: 'screen',
                    screen_section: 'right_menu',
                    interaction_object: 'loyalty_point_get_more',
                    interaction_object_category: 'redirect',
                });
                break;
            case ADDBALLANCETYPE.BR:
                history.push(
                    location.pathname === '/loyalty-point-shop' ? 'loyalty-point-shop' : '/store',
                    'WP-bonus-rolls',
                );

                setGAEvent('clickEvent', {
                    screen_name: `${pathname === '/' ? '/home' : pathname}`,
                    screen_type: 'screen',
                    screen_section: 'right_menu',
                    interaction_object: 'bonus_rolls',
                    interaction_object_category: 'redirect',
                });
                break;

            case ADDBALLANCETYPE.EN:
                history.push(
                    location.pathname === '/loyalty-point-shop' ? 'loyalty-point-shop' : '/store',
                    'WP-energy',
                );

                setGAEvent('clickEvent', {
                    screen_name: `${pathname === '/' ? '/home' : pathname}`,
                    screen_type: 'screen',
                    screen_section: 'right_menu',
                    interaction_object: 'energy_get_more',
                    interaction_object_category: 'redirect',
                });
                break;

            default:
        }

        handleRightSideClose();
    };

    return (
        <AddBalanceStyled>
            <Box
                className="WP-ActionButton-button cursor-pointer flex-center"
                height={27}
                width={27}
                onClick={handleScroll}
            >
                <Box component="img" src={PLUS} alt="plus" data-testid={dataTestId} />
            </Box>
        </AddBalanceStyled>
    );
};

AddBalance.propTypes = {
    type: PropTypes.number,
    dataTestId: PropTypes.string,
};

export default AddBalance;
