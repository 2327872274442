import React from 'react';

import { Box, Grid, useMediaQuery } from '@material-ui/core';

import SectionTitle from '../SectionTitle/SectionTitle';
import TopLeaders from './TopLeaders/TopLeaders';
import TopLeadersItemSkeleton from './TopLeaders/TopLeadersItemSkeleton';
import { useTranslation } from '../../../hooks/useTranslation';

import { useSelector } from 'react-redux';
import { leadersSel } from '../../../store/leaderboard';
import { TopLeadersStyled } from './TopLeaders/TopLeaders.styled';
import { globalSel } from '../../../store/global';

const TopFamilies = () => {
    const theme = useSelector(globalSel.themeSelector);
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });

    const t = useTranslation();
    const familiesBoards = useSelector(leadersSel.familiesBoardsSelector);

    return (
        <Box component="section">
            {!familiesBoards ? (
                <TopLeadersItemSkeleton />
            ) : !familiesBoards?.length ? null : (
                <>
                    <SectionTitle
                        title={t('top.families')}
                        subtitle={t('top.families.subtitle')}
                        icon="icon-families"
                    />

                    <Grid container spacing={breakpointsXL ? 10 : breakpointsLG ? 5 : 4}>
                        {familiesBoards.map((item, index) => {
                            return (
                                <Grid key={index} item xs={12} sm={6}>
                                    <Box
                                        key={index}
                                        className="WP-top-families"
                                        component={TopLeadersStyled}
                                    >
                                        <TopLeaders type={item.Category} item={item} changed />
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                </>
            )}
        </Box>
    );
};

export default TopFamilies;
