import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { fetch } from '../../../core/infra/dice-admin/FetchService';
import globalSlice, { globalOp } from '../../../store/global';
import { useDispatch } from 'react-redux';
import { removeStorageItem, setStorageItem } from '../../../helpers/localStorage';
import { paymentsBackendDelegate } from '../../../configs/payments.config';
import { titanAnalyticsEvents } from '../../../helpers/titanAnalytics';
import { loginEvent } from '../../../helpers/GA4Helper';
import { saveSessionToken } from '../../../bridge';
import {
    TRACKING_ERROR_CODES,
    TRACKING_ACTIONS,
    TRACKING_TYPES,
    loginAnalyticsTracking,
} from '../../../helpers/loginAnalyticsTracking';

const useFastLogin = () => {
    const GUID_LENGTH = 36;
    const guidRegEx = new RegExp(
        /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
    );

    const location = useLocation();
    const userId = new URLSearchParams(location.search).get('userid');
    const token = new URLSearchParams(location.search).get('token');
    const source = new URLSearchParams(location.search).get('source');

    const { setAuthenticated } = globalSlice.actions;
    const scopelyLoginStatus = globalSlice.actions.setScopelyLogin;
    const dispatch = useDispatch();

    const checkParams = () => {
        if (!userId || !token) {
            return false;
        }
        if (isNaN(Number(userId))) {
            return false;
        }
        if (token.length !== GUID_LENGTH) {
            return false;
        }

        return guidRegEx.test(token);
    };

    const fastLogin = async (userId, token) => {
        dispatch(scopelyLoginStatus(true));
        setStorageItem('loggingIn', true);
        setStorageItem('authorizing', true);

        try {
            // const { data } = await __mockFastLogin(userId); //for testing

            const { data } = await fetch({
                endPoint: 'me/fast-login',
                method: 'POST',
                params: {
                    BypassSignature: true,
                    c_platform: 'web',
                    c_store: 'xsolla',
                    c_bundle: 'yahtzeenux',
                },
                body: {
                    UserId: userId,
                    Token: token,
                },
            });

            if (data.IsSuccess) {
                paymentsBackendDelegate.setCurrentUserId(data.Data.Id);
                titanAnalyticsEvents.updateAnalyticsUser(data.Data.Id);
                titanAnalyticsEvents.login('ScopelyId', data.Data.Id, data.Data.Email);

                loginEvent('ScopelyId', data.Data.Id);

                setStorageItem('accessToken', token);
                saveSessionToken(data?.Data?.SecretKey);

                dispatch(globalOp.getFeatures());
                dispatch(globalOp.handleCloseModal());
                dispatch(globalSlice.actions.setSidebar({ side: 'rightSidebar', open: false }));

                dispatch(setAuthenticated(true));

                window?.DD_RUM?.addAction('logins-count', {});
            } else {
                loginAnalyticsTracking({
                    type: TRACKING_TYPES.FAST_LOGIN,
                    action: TRACKING_ACTIONS.LOGIN_FAILED,
                    errorCode: `${TRACKING_ERROR_CODES.FAST_LOGIN_DATA_IS_NOT_SUCCESS}${TRACKING_ERROR_CODES.UNKNOWN_ERROR}`,
                });
                removeStorageItem('loggingIn');
                removeStorageItem('authorizing');
                dispatch(scopelyLoginStatus(false));
            }
        } catch (error) {
            loginAnalyticsTracking({
                type: TRACKING_TYPES.FAST_LOGIN,
                action: TRACKING_ACTIONS.LOGIN_FAILED,
                errorCode: `${TRACKING_ERROR_CODES.FAST_LOGIN_FETCH_CATCH}${TRACKING_ERROR_CODES.UNKNOWN_ERROR}`,
            });
            removeStorageItem('loggingIn');
            removeStorageItem('authorizing');
            dispatch(scopelyLoginStatus(false));
        }
    };

    useEffect(() => {
        if (source) {
            setStorageItem('fromSource', source);
        }

        if (checkParams()) {
            dispatch(globalOp.logout());
            fastLogin(Number(userId), token).then(() => {
                removeStorageItem('loggingIn');
                removeStorageItem('authorizing');
                dispatch(scopelyLoginStatus(false));
            });
        }
    }, [userId, token, source]);

    return;
};

export default useFastLogin;
