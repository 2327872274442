import { combineReducers } from '@reduxjs/toolkit';
import globalSlice from './global';
import notificationsSlice from './notifications';
import shopSlice from './shop';
import dailyDealsSlice from './dailyDeals';
import translationsSlice from './translations';
import dailyRewardsSlice from './dailyReward';
import dailyOffersSlice from './dailyOffers';
import leaderboardSlice from './leaderboard';

export const reducers = () =>
    combineReducers({
        global: globalSlice.reducer,
        notifications: notificationsSlice.reducer,
        shop: shopSlice.reducer,
        dailyDeals: dailyDealsSlice.reducer,
        translations: translationsSlice.reducer,
        dailyRewards: dailyRewardsSlice.reducer,
        dailyOffers: dailyOffersSlice.reducer,
        leaders: leaderboardSlice.reducer,
    });
