import GOOGLE_PLAY_EN from '../../../../assets/images/SocialMedia/googlePlayEn.svg';
import GOOGLE_PLAY_FR from '../../../../assets/images/SocialMedia/googlePlayFr.svg';
import GOOGLE_PLAY_DE from '../../../../assets/images/SocialMedia/googlePlayDe.svg';
import GOOGLE_PLAY_IT from '../../../../assets/images/SocialMedia/googlePlayIt.svg';
import GOOGLE_PLAY_ES from '../../../../assets/images/SocialMedia/googlePlayEs.svg';
import APPLE_STORE_EN from '../../../../assets/images/SocialMedia/appleStoreEn.svg';
import APPLE_STORE_FR from '../../../../assets/images/SocialMedia/appleStoreFr.svg';
import APPLE_STORE_DE from '../../../../assets/images/SocialMedia/appleStoreDe.svg';
import APPLE_STORE_IT from '../../../../assets/images/SocialMedia/appleStoreIt.svg';
import APPLE_STORE_ES from '../../../../assets/images/SocialMedia/appleStoreEs.svg';

const socialMediaIcons = {
    appStore: {
        en: APPLE_STORE_EN,
        fr: APPLE_STORE_FR,
        de: APPLE_STORE_DE,
        es: APPLE_STORE_ES,
        it: APPLE_STORE_IT,
    },
    googlePlay: {
        en: GOOGLE_PLAY_EN,
        fr: GOOGLE_PLAY_FR,
        de: GOOGLE_PLAY_DE,
        es: GOOGLE_PLAY_ES,
        it: GOOGLE_PLAY_IT,
    },
};

export default socialMediaIcons;
