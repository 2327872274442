import React from 'react';
import { Box, Link } from '@material-ui/core';

import { useSelector } from 'react-redux';

import { globalSel } from '../../../../../../store/global';
import socialMediaIcons from '../../../../../layout/LeftSidebar/LeftSidebarFooter/SocialIcons';
import { DOWNLOAD_LINKS } from '../../../../../../constants/scopely.constants';

import { ModalStoreLinksStyled } from './ModalStoreLinks.styled';

const ModalStoreLinks = () => {
    const { code: languageCode } = useSelector(globalSel.languageSelector);

    return (
        <Box component={ModalStoreLinksStyled}>
            <Link
                href={DOWNLOAD_LINKS.APP_STORE}
                className="WP-modal-store-link"
                underline="none"
                target="_blank"
            >
                <img src={socialMediaIcons.appStore[languageCode]} alt="AppStore" />
            </Link>

            <Link
                href={DOWNLOAD_LINKS.PLAY_STORE}
                className="WP-modal-store-link"
                underline="none"
                target="_blank"
            >
                <img src={socialMediaIcons.googlePlay[languageCode]} alt="PlayMarket" />
            </Link>
        </Box>
    );
};

export default ModalStoreLinks;
