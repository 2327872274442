import { Titan, tracker } from '../configs/titan.config';

const warm_start = localStorage.getItem('warm_start');
export const titanAnalyticsEvents = {
    appOpen: () => {
        Titan.SendEvent({
            name: 'sys.app_open',
            parameters: {
                is_warm_start: +warm_start ? false : true,
            },
        });
        localStorage.setItem('warm_start', +warm_start + 1);
    },

    updateAnalyticsUser: (id) => {
        // console.warn(`updating analytics user => tracker.getContext().setTenant('${id}')`);
        tracker.getContext().setTenant(String(id || -7));
    },

    login: (connection, userId, email) => {
        Titan.SendEvent({
            name: 'sys.connect',
            parameters: {
                connection: connection ? connection : '',
                first_name: '',
                last_name: '',
                gender: '',
                id: String(userId || ''),
                email: email ? email : '',
                action: 'login',
                context: 'web',
                n_friends: '',
            },
        });
    },

    logout: (connection, userId, email) => {
        Titan.SendEvent({
            name: 'sys.connect',
            parameters: {
                connection: connection ? connection : '',
                first_name: '',
                last_name: '',
                gender: '',
                id: String(userId || ''),
                email: email ? email : '',
                action: 'logout',
                context: 'web',
                n_friends: '',
            },
        });
    },
    startupComplete: () => {
        const perfObserver = new PerformanceObserver((observedEntries) => {
            const entries = observedEntries.getEntries();
            const pageLoadDuration = entries[0].duration / 1000;
            Titan.SendEvent({
                name: 'sys.startup_complete',
                parameters: {
                    time_since_startup: parseFloat(pageLoadDuration.toFixed(2)),
                },
            });
        });
        perfObserver.observe({ type: 'navigation' });
    },
    xsolla_payment: (parameters) => {
        Titan.SendEvent({
            name: 'sys_payment',
            parameters,
        });
    },
    loginFlow: (parameters) => {
        Titan.SendEvent({
            name: 'login_flow',
            parameters,
        });
    },
    sysConnect: (parameters) => {
        Titan.SendEvent({
            name: 'sys.connect',
            parameters,
        });
    },
};
