import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../hooks/useTranslation';
import PropTypes from 'prop-types';

import { Box, useMediaQuery } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';

import OrderLabel from '../OrderLabel/OrderLabel';
import Badge from '../Badge/Badge';
import { Button } from '../ui-components/Button/Button';
import { OfferCardStyled } from './OfferCard.styled';
import { globalOp, globalSel } from '../../../store/global';
import { shopSel } from '../../../store/shop';
import { OFFER_CARD_MODAL } from '../../../constants/modals.constants';
import { calculateDiscount, USNumberFormat } from '../../../helpers/utils';
import { withPurchase } from '../../../hoc/withPurchase';
import LoyaltyPointIcon from '../../../assets/images/Global/loyaltyPoints.png';
import PlusIcon from '../../../assets/images/Global/plus.svg';
import { setGAEvent } from '../../../helpers/GA4Helper';
import { globalSlice } from '../../../store/global/globalSlice';

const OfferCard = ({
    data,
    handlePurchase,
    section,
    imageSizeHeight,
    isEarnExist,
    isBonusRollsDetails,
    isDiceAndMoreDetails,
}) => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const commodities = useSelector(shopSel.commoditiesSelector);
    const pathname = window.location.pathname;
    const showTour = globalSlice.actions.setShowTour;
    const theme = useSelector(globalSel.themeSelector);
    const customXS = useMediaQuery(theme.breakpoints.down(375), { noSsr: true });
    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
    const breakpointsMac = useMediaQuery(theme.breakpoints.between(1780, 1800), {
        noSsr: true,
    });

    const handleModalOpen = () => {
        setGAEvent('clickEvent', {
            screen_name: `${pathname === '/' ? '/home' : pathname}`,
            screen_type: 'screen',
            screen_section: section,
            interaction_object: 'offer_card',
            interaction_object_category: 'display',
            offer_id: data?.Name,
        });

        return dispatch(
            globalOp.handleOpenModal({
                open: true,
                variant: OFFER_CARD_MODAL,
                section,
                data,
                isEarnExist,
                isBonusRollsDetails,
                isDiceAndMoreDetails,
            }),
        );
    };
    if (!data) return false;
    const { DisplayDetails } = data;
    if (!DisplayDetails) return false;

    const loyaltyPoints = data?.Credit?.Adjustments.find((item) => {
        return item.CommodityKey === 'LoyaltyPoint';
    });
    const handleRemoveTour = () => {
        dispatch(showTour(false));
    };
    const discountedCommodity = data?.Debit?.Adjustments[0];
    const originalCommodity = data?.OriginalListing?.Debit?.Adjustments[0];
    const divider = discountedCommodity.CommodityKey === 'USP' ? 100 : 1;
    const originalQuantity = originalCommodity ? originalCommodity?.Quantity / divider : null;
    const discountedQuantity = discountedCommodity ? discountedCommodity?.Quantity / divider : null;
    const Credits = data?.Credit?.Adjustments;
    const MainImage = data?.DisplayDetails?.IconImage
        ? data?.DisplayDetails?.IconImage
        : commodities?.[Credits[0]?.CommodityKey]?.image
        ? commodities?.[Credits[0]?.CommodityKey]?.image
        : null;

    const discount = calculateDiscount(originalQuantity, discountedQuantity);
    const completed =
        (data?.availableCount !== null && data?.availableCount <= 0) ||
        (data?.DisplayDetails.IsFtue === 'true' && data?.DisplayDetails.IsCompleted === 'true');
    return (
        <Box
            component={OfferCardStyled}
            className="WP-card"
            bgImageUrl={data?.DisplayDetails?.BackgroundUrl}
            borderRadius={[14, 20, 20, 24, 30]}
            overflow="hidden"
            height={1}
            bgcolor={theme.palette.orange.A50}
            boxShadow={theme.elevation[100]}
            onClick={handleRemoveTour}
        >
            <Box
                onClick={handleModalOpen}
                className="WP-card-bgImg flex-center cursor-pointer"
                height={[126, 138, 133, breakpointsMac ? 206 : 163, 250]}
                flexShrink={0}
                position="relative"
            >
                {discount ? (
                    <Badge text={`${discount}% ${t('off')}`} />
                ) : data?.DisplayDetails?.BannerText ? (
                    <Badge text={data?.DisplayDetails?.BannerText} />
                ) : null}

                {MainImage && (
                    <Box
                        component="img"
                        src={MainImage}
                        alt="bonus"
                        className="WP-card-img"
                        height={imageSizeHeight}
                    />
                )}

                {data?.Credit?.Adjustments.length > 2 && (
                    <Box mx={[2, 2, 3, 3, 4]}>
                        <Box
                            component="img"
                            src={PlusIcon}
                            alt="plus"
                            width={[16, 18, 18, 18, 30]}
                            height={[16, 18, 18, 18, 30]}
                        />
                    </Box>
                )}

                {data?.Credit?.Adjustments.map((item, index) => {
                    return (
                        index > 0 &&
                        item.CommodityKey !== 'LoyaltyPoint' &&
                        commodities?.[item?.CommodityKey]?.image && (
                            <Fragment key={item.CommodityKey}>
                                <Box
                                    component="img"
                                    src={commodities?.[item.CommodityKey]?.image}
                                    alt="bonus"
                                    height={[
                                        customXS ? 60 : 40,
                                        60,
                                        60,
                                        breakpointsMac ? 90 : 60,
                                        120,
                                    ]}
                                />
                            </Fragment>
                        )
                    );
                })}
            </Box>

            <Box
                component="figcaption"
                className="WP-card-figcaption"
                height={[160, 210, 200, 240, 270]}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                pt={2}
                px={[2, 3, 3, 4, 5]}
                pb={[2, 3, 3, 4, 5]}
            >
                <Box>
                    {data?.availableCount > 0 ? (
                        <OrderLabel text={`${data?.availableCount} ${t('left')}`} />
                    ) : null}

                    <Box onClick={handleModalOpen}>
                        <Typography
                            className="WP-card-title cursor-pointer"
                            variant={['p12', 'p16']}
                            fWeight="semiBold"
                            color={theme.palette.brown.A600}
                        >
                            {isBonusRollsDetails || isDiceAndMoreDetails
                                ? data?.DisplayDetails?.DisplayName
                                : commodities?.[data?.Credit?.Adjustments[0]?.CommodityKey]?.name}
                        </Typography>

                        <Typography
                            className="WP-card-subtitle text-truncate-second-line cursor-pointer"
                            variant={['h6', 'h6', 'h6', 'h5', 'h4']}
                            fWeight="bold"
                            color={theme.palette.brown.main}
                        >
                            {isBonusRollsDetails
                                ? `${Credits[0]?.Quantity}
                                  ${
                                      commodities?.[data?.Credit?.Adjustments[0]?.CommodityKey]
                                          ?.name
                                  }`
                                : isDiceAndMoreDetails
                                ? commodities?.[data?.Credit?.Adjustments[0]?.CommodityKey]?.name
                                : data?.DisplayDetails?.DisplayName}
                        </Typography>
                    </Box>
                </Box>

                <Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={[2, 2, 2, 2, 3]}
                    >
                        {originalQuantity && discount && (
                            <Box display="flex" alignItems="center">
                                <Box
                                    component={Typography}
                                    className="WP-card-discount"
                                    display="flex"
                                    alignItems="center"
                                    variant={['p12', 'p16', 'p16']}
                                    fWeight="bold"
                                    color={theme.palette.red.A700}
                                    mr={1}
                                >
                                    {t('retail.price')}
                                </Box>

                                <Box
                                    component={Typography}
                                    className="WP-card-discount text-decoration-line-through"
                                    display="flex"
                                    alignItems="center"
                                    variant={['p12', 'p16', 'p16']}
                                    fWeight="bold"
                                    color={theme.palette.red.A700}
                                >
                                    {originalCommodity?.CommodityKey === 'USP' ? (
                                        <span>$</span>
                                    ) : (
                                        <Box
                                            component="img"
                                            src={LoyaltyPointIcon}
                                            alt="loyalty points"
                                            width={[16, 20, 20, 24, 30]}
                                            height={[16, 20, 20, 24, 30]}
                                            mr={1}
                                        />
                                    )}
                                    {USNumberFormat(originalQuantity)}
                                </Box>
                            </Box>
                        )}

                        {loyaltyPoints && !isEarnExist && (
                            <Box display="flex" alignItems="center" ml="auto">
                                <Box
                                    component={Typography}
                                    className="WP-card-earn"
                                    variant={['p12', 'p16', 'p16']}
                                    fWeight="semiBold"
                                    color={theme.palette.brown.A600}
                                    px={1}
                                >
                                    {`${t('earn')} ${loyaltyPoints?.Quantity}`}
                                </Box>

                                <Box
                                    component="img"
                                    src={LoyaltyPointIcon}
                                    alt="loyalty points"
                                    width={[16, 20, 20, 24, 30]}
                                    height={[16, 20, 20, 24, 30]}
                                />
                            </Box>
                        )}
                    </Box>

                    <Button
                        completed={completed}
                        variant="secondary"
                        size={
                            breakpointsXL
                                ? 'large'
                                : breakpointsLG
                                ? 'medium'
                                : breakpointsSM
                                ? 'small'
                                : 'extra-small'
                        }
                        onClick={handlePurchase({
                            commodity: discountedCommodity?.CommodityKey,
                            iapSku: data?.IapSku,
                            storeListingName: data?.Name,
                            promoId: data?.promoId,
                            item: {
                                openType: 'screen',
                                name: data?.DisplayDetails?.DisplayName,
                                price: discountedQuantity,
                                section,
                            },
                        })}
                        preventLoading
                    >
                        {completed ? (
                            t('already.owned')
                        ) : (
                            <>
                                {discountedCommodity?.CommodityKey === 'USP' ? (
                                    <span>$</span>
                                ) : (
                                    <Box
                                        component="img"
                                        src={LoyaltyPointIcon}
                                        alt="loyalty points"
                                        width={[16, 20, 20, 24, 30]}
                                        height={[16, 20, 20, 24, 30]}
                                        mr={1}
                                    />
                                )}

                                {discountedQuantity && USNumberFormat(discountedQuantity)}
                            </>
                        )}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

OfferCard.defaultProps = {
    imageSizeHeight: [90, 90, 90, 90, 130],
};

OfferCard.propTypes = {
    data: PropTypes.object,
    handlePurchase: PropTypes.func,
    commodity: PropTypes.string,
    bonusRollsImage: PropTypes.bool,
    section: PropTypes.string,
    isEarnExist: PropTypes.bool,
    imageSizeHeight: PropTypes.array,
    isBonusRollsDetails: PropTypes.bool,
    isDiceAndMoreDetails: PropTypes.bool,
};

export default withPurchase(OfferCard);
