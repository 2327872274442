import { titanAnalyticsEvents } from './titanAnalytics';

export const TRACKING_ERROR_CODES = {
    UNKNOWN_ERROR: '000',
    SCOPELY_AUTH_RESULT_CATCH: '6000',
    SCOPELY_IS_NOT_SUCCESSFUL: '6001',
    FACEBOOK_RESPONSE_ERROR: '7000',
    FACEBOOK_COMPONENT_ON_FAILURE: '7001',
    FAST_LOGIN_DATA_IS_NOT_SUCCESS: '8000',
    FAST_LOGIN_FETCH_CATCH: '8001',
    ME_SESSIONS_NOT_DATA: '9000',
    ME_SESSIONS_FETCH_CATCH: '9001',
};

export const TRACKING_ACTIONS = {
    LOGIN_SUCCESSFULL: 0,
    LOGIN_FAILED: 1,
    LOGIN_ABANDONED: 2,
    LOG_OUT: 3,
};

const loginFlowActions = ['login_sucessfull', 'login_failed', 'login_abandoned', 'log_out'];
const sysConnectActions = ['login_sucessfull', 'login_failed', 'login_abandoned', 'logout'];

export const TRACKING_TYPES = {
    FAST_LOGIN: 0,
    FACEBOOK: 1,
    SCOPELY_ID: 2,
};

const loginFlowTypes = ['fail_fast_url', 'Facebook', 'ScopelyId'];
const sysConnectTypes = ['fast_login_url', 'Facebook', 'ScopelyId'];

export const loginAnalyticsTracking = ({ type, action, errorCode }) => {
    const loginFlowParams = {
        login_type: loginFlowTypes[type],
        action: loginFlowActions[action],
        ...(errorCode && { error_code: Number(errorCode) }),
    };

    titanAnalyticsEvents.loginFlow(loginFlowParams);

    const sysConnectParams = {
        context: 'web',
        connection_type: sysConnectTypes[type],
        action: sysConnectActions[action],
    };

    titanAnalyticsEvents.sysConnect(sysConnectParams);
};
