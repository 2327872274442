export function initialShopState() {
    return {
        offers: [],
        offerData: [],
        isLoading: true,
        xsollaToken: '',
        sections: ['WebFeatured', 'WebDailyDeals'],
        version: '',
    };
}
