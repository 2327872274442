import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';

import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import globalSlice, { globalOp } from '../../../../store/global';

function LpDailyDeals({ data }) {
    const theme = useTheme();
    const dispatch = useDispatch();

    const getDailyDealsSection = document.querySelector('#WP-daily-deals-ftue .daily-deals');

    useEffect(() => {
        getDailyDealsSection.classList.add('WP-daily-deals-ftue-position');
        dispatch(globalOp.getFtue(true));
        return () => {
            dispatch(globalSlice.actions.setFtue());
            getDailyDealsSection.classList.remove('WP-daily-deals-ftue-position');
        };
    }, []);

    return (
        <Box className="WP-daily-deals-lp" pt={4} px={4} maxWidth={350}>
            <Box
                component={Typography}
                variant={['h5', 'h5', 'h5', 'h5', 'h4']}
                fWeight="bold"
                color={theme.palette.brown.main}
                mb={3}
            >
                {data?.TitleText}
            </Box>

            <Box
                component={Typography}
                fWeight="semiBold"
                variant="p16"
                color={theme.palette.brown.A600}
            >
                {data?.BodyText}
            </Box>
        </Box>
    );
}
LpDailyDeals.propTypes = {
    data: PropTypes.object,
};
export default LpDailyDeals;
