export const postParser = (newItem) => {
    if (newItem.Config.Category.Name === 'Support') {
        return {
            id: newItem.Config.Id,
            label: 1,
            image: newItem.Config.BannerUrl,
            createdAt: newItem.Config.StartDate,
            title: newItem.Config.Title,
            buttonText: 'recurring.events',
            sectionUrl: newItem.Config.Content.Ctas[0]?.Link,
        };
    }

    if (newItem.Config.Category.Name === 'Tips & Tricks') {
        return {
            id: newItem.Config.Id,
            title: newItem.Config.Title,
            url: newItem.Config.Id,
            description: bodyParser(newItem),
            content: 'HTML',
            image: newItem.Config.BannerUrl,
            createdAt: newItem.Config.StartDate,
            label: 3,
        };
    }

    if (
        newItem.Config.Category.Name === 'Web Floating Banner Offer' ||
        newItem.Config.Category.Name === 'Web Floating Banner Generic' ||
        newItem.Config.Category.Name === 'Web Floating Banner Announcement' ||
        newItem.Config.Category.Name === 'Web Floating Banner Error/Change'
    ) {
        return {
            id: newItem.Config.Id,
            title: newItem.Config.Title,
            subtitle: newItem.Config.SubTitle,
            link: newItem.Config.Content.Ctas[0]?.Link,
            textLink: newItem.Config.Ctas[0]?.Text,
            categoryName: newItem.Config.Category.Name,
        };
    }

    return {
        id: newItem.Config.Id,
        url: newItem.Config.Id,
        image: newItem.Config.BannerUrl,
        createdAt: newItem.Config.StartDate,
        label: 3,
        labelCategory: newItem.Config.Category.Name,
        title: newItem.Config.Title,
        description:
            newItem.Config.SubTitle ||
            newItem.Config.Content.Blocks[0]?.Heading ||
            newItem.Config.Content.Blocks[0]?.Body,
        content: bodyParser(newItem),
    };
};

export const postsParser = (news) => {
    return news.map((newItem) => postParser(newItem));
};

const bodyParser = (newItem) => {
    let body = '';

    newItem.Config.Content.Blocks.forEach((block) => {
        if (block.Heading) {
            block.Heading = block.Heading?.replace(/\n/g, '<br />');
            body += `<p class="WP-mb-16"><strong style="color: #844733">${block.Heading}</strong></p>`;
        }
        if (block.Body) {
            block.Body = block.Body?.replace(/\n/g, '<br />');
            body += `<p class="WP-mb-16">${block.Body}</p>`;
        }
        if (block.ImageUrl) {
            body += `<img class="MuiBox-root-402 object-cover" src="${block.ImageUrl}" style="display: flex; margin: 50px auto; text-align: center; height: 100%; max-height: 70vh; max-width: 100%" />`;
        }
        if (block.VideoUrl) {
            body += `<video class="MuiBox-root-402 object-cover" src="${block.VideoUrl}" style="display: flex;  margin: 50px auto; text-align: center; height: 100%; max-height: 70vh; max-width: 100%" controls></video>`;
        }
    });

    return body;
};
