import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box, useMediaQuery } from '@material-ui/core';

import { useTranslation } from '../../../../../../hooks/useTranslation';
import DayIso from '../../../../DayIso/DayIso';

import { backgrounds } from '../backgrounds';
import { globalSel } from '../../../../../../store/global';
import { DailyOfferItemStyled } from '../DailyOffer.styled';

import giftOpen from '../../../../../../assets/images/DailyOffer/gift-open.png';
import giftClose from '../../../../../../assets/images/DailyOffer/gift-close.png';

const DailyOfferItem = ({ offer, index }) => {
    const theme = useSelector(globalSel.themeSelector);
    const breakpointsMac = useMediaQuery(theme.breakpoints.between(1440, 1780), {
        noSsr: true,
    });
    const activeOffer = useSelector(globalSel.modalSelector);
    const activeIndex = activeOffer?.data ? activeOffer?.data.offerIndex : 1;
    const item = offer?.ListingsSku;
    const t = useTranslation();

    if (!item) return false;

    const purchased = item.availableCount != null && item.availableCount <= 0;
    const currentOffer = activeIndex === offer.offerIndex;

    return (
        <Box
            component={DailyOfferItemStyled}
            bgImageUrl={currentOffer ? backgrounds[index].active : backgrounds[index].passive}
            className={currentOffer ? 'active' : ''}
            width={[90, 90, 90, breakpointsMac ? 96 : 150, 150]}
            height={[100, 100, 100, breakpointsMac ? 106 : 168, 168]}
        >
            {purchased && (
                <Box
                    className="WP-list-item-purchased flex-center"
                    height={[26, 26, 26, breakpointsMac ? 26 : 36, 36]}
                    px={1}
                    position="absolute"
                    top={26}
                    left={0}
                    right={0}
                    color={theme.palette.common.white}
                >
                    {t('purchased')}
                </Box>
            )}

            <Box component="img" src={purchased ? giftOpen : giftClose} alt="gift" width={1} />

            <Box
                component="span"
                className="WP-list-item-day flex-center"
                height={[36, 36, 36, breakpointsMac ? 36 : 58, 58]}
                position="absolute"
                right={0}
                bottom={0}
                left={0}
                fontWeight={700}
                color={theme.palette.common.white}
            >
                <DayIso date={offer?.StartDate} sameDay={true} format="ddd" />
            </Box>
        </Box>
    );
};

export default DailyOfferItem;

DailyOfferItem.propTypes = {
    offer: PropTypes.object,
    index: PropTypes.number,
};
