import React from 'react';

import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const PageHeaderSkeleton = () => {
    return (
        <Box display="flex" flexDirection="column" mb={[2, 0]}>
            <Box display="flex" alignItems="center">
                <Box height={28} width={28} mr={2}>
                    <Skeleton variant="circle" animation="wave" height="100%" width="100%" />
                </Box>

                <Box height={32} width={[100, 160]}>
                    <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                </Box>
            </Box>

            <Box height={[24, 24, 24, 24, 24]} width={[180, 260]}>
                <Skeleton variant="text" animation="wave" height="100%" width="100%" />
            </Box>
        </Box>
    );
};

export default PageHeaderSkeleton;
