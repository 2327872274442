import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Box, Hidden } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';

import { globalSel } from '../../../../store/global';
import { thousandsFormatterWithDots } from '../../../../helpers/utils';
import LoyaltyPointIcon from '../../../../assets/images/Global/loyaltyPoints.png';

const UserCoins = () => {
    const theme = useSelector(globalSel.themeSelector);
    const location = useLocation();
    const currentUser = useSelector(globalSel.currentUserSelector);

    useEffect(() => {}, [location]);

    if (!currentUser) return false;

    return location.pathname === '/' ? null : (
        <Hidden mdUp>
            <Box
                className="WP-loyalty-points-amount"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius={20}
                py={1}
                pl={1}
                pr={2}
                mr={2}
            >
                <Box
                    component="img"
                    src={LoyaltyPointIcon}
                    alt="loyalty points"
                    width={[16, 18]}
                    height={[16, 18]}
                    mr={1}
                />

                <Box
                    component={Typography}
                    className=""
                    variant={['p12', 'p14']}
                    fWeight="medium"
                    color={theme.palette.common.white}
                >
                    {thousandsFormatterWithDots(currentUser?.LoyaltyPoints)}
                </Box>
            </Box>
        </Hidden>
    );
};

export default UserCoins;
