import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';

import DateFormat from '../../components/DateFormat/DateFormat';
import { NewsPageStyled } from './UsersGuidePage.styled';
import NotFoundPage from '../NotFound/NotFound';

const UserGuidesSelectedItem = ({ userGuideItem, theme }) => {
    return (
        <Box component={NewsPageStyled} mt={[4, 15]} mb={[6, 40]}>
            {!userGuideItem ? (
                <NotFoundPage />
            ) : (
                <Box
                    className="WP-news-page-wrapper"
                    width={['100%', '80%']}
                    borderRadius={[14, 20, 20, 24, 30]}
                    display="flex"
                    flexDirection="column"
                >
                    <Box
                        component="img"
                        className="object-cover"
                        src={userGuideItem?.image}
                        alt={userGuideItem?.title}
                        width={1}
                        maxHeight={[146, 340]}
                    />
                    <Box bgcolor={theme.palette.orange.A50} px={7} py={7}>
                        <Box
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            marginBottom="10px"
                        >
                            <Box
                                component={Typography}
                                variant={['p12', 'p12', 'p12', 'p14', 'p16']}
                                fWeight="semiBold"
                                color={theme.palette.brown.A600}
                                mb={2}
                                className="WP-NewsCard-Date"
                            >
                                <DateFormat date={userGuideItem?.createdAt} format="MMMM D, YYYY" />
                            </Box>
                        </Box>

                        <Box
                            component={Typography}
                            variant={['h6', 'h6', 'h6', 'h5', 'h4']}
                            fWeight="bold"
                            color={theme.palette.brown.main}
                            className="WP-NewsCard-Title text-truncate-second-line"
                            mb={[4, 7]}
                        >
                            {userGuideItem?.title}
                        </Box>

                        <Box
                            component={Typography}
                            variant={['p14', 'p16']}
                            fWeight="medium"
                            color="#424242"
                            mt={2}
                        >
                            <p
                                className="WP-NewsCard-description"
                                dangerouslySetInnerHTML={{ __html: userGuideItem.description }}
                            />
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

UserGuidesSelectedItem.propTypes = {
    theme: PropTypes.object,
    userGuideItem: PropTypes.object,
};

export default UserGuidesSelectedItem;
