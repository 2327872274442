import styled from 'styled-components';
import titleBg from '../../../../../assets/images/Modal/Iterstitial/header_bg.png';
import Shine from '../../../../../assets/images/Modal/Iterstitial/shine.png';
import Bg from '../../../../../assets/images/Modal/Iterstitial/bg.jpg';

export const InterstitialLogoutStyled = styled.div`
    position: relative;
    &:before {
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
        content: '';
        display: inline-block;
        background: url(${Bg}) no-repeat bottom;
        background-size: cover;
    }

    .popup-header {
        position: relative;

        &:before {
            width: 100%;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;
            content: '';
            opacity: 0.5;
            display: inline-block;

            background: url(${titleBg}), #e14523;
            background-blend-mode: darken;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .popup-header-welcome {
            position: absolute;
            left: -40px;
            top: 0px;
            z-index: 1;
            ${({ theme }) => theme.breakpoints.down('xs')} {
                left: -10px;
            }
        }
    }

    .popup-content {
        position: relative;
        z-index: 1;

        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: center;
        text-align: center;

        .content-title-container {
            width: 100%;

            .content-title {
                font-family: 'Rubik';
                font-weight: bold;
                background: linear-gradient(
                    90deg,
                    rgba(178, 88, 33, 0) 0%,
                    rgba(179, 88, 33, 0.8) 50%,
                    rgba(178, 88, 33, 0) 100%
                );
                text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                mix-blend-mode: luminosity;
                font-size: 16px;

                > span {
                    display: block;
                }

                ${({ theme }) => theme.breakpoints.up('sm')} {
                    font-size: 20px;
                }

                ${({ theme }) => theme.breakpoints.up('lg')} {
                    font-size: 24px;
                }

                ${({ theme }) => theme.breakpoints.up('xl')} {
                    font-size: 28px;
                }
            }
        }

        .image-title {
            position: relative;
            justify-content: center;
        }

        .image-container {
            position: relative;
            z-index: 1;

            &:before {
                width: 100%;
                height: 100%;

                position: absolute;
                top: 10px;
                left: 0;
                content: '';
                z-index: -1;

                display: block;
                background-image: url(${Shine});
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
        }
    }

    .interstitial-body {
        @media screen and (max-width: 767px) and (orientation: landscape) {
            height: 80px;
            overflow: auto;
        }
    }

    .WP-bonus-container {
        div {
            position: absolute;
            left: 50%;
            bottom: 18px;
            transform: translateX(-50%);

            ${({ theme }) => theme.breakpoints.up('sm')} {
                bottom: 30px;
            }

            ${({ theme }) => theme.breakpoints.up('lg')} {
                bottom: 38px;
            }

            ${({ theme }) => theme.breakpoints.up('xl')} {
                bottom: 25px;
            }

            p {
                font-size: 65px;
                color: #fff;
                -webkit-text-stroke: 5px #000;
                text-shadow: 0 5px 5px #805517;

                font-weight: 800;

                ${({ theme }) => theme.breakpoints.up('sm')} {
                    font-size: 80px;
                }

                ${({ theme }) => theme.breakpoints.up('lg')} {
                    font-size: 90px;
                }

                ${({ theme }) => theme.breakpoints.up('xl')} {
                    font-size: 110px;
                }
            }
        }
    }

    .claim-bonus {
        width: 100%;
        background: rgba(129, 38, 18, 0.2);

        p {
            ${({ theme }) => theme.breakpoints.down(600)} {
                width: 70%;
            }
        }

        i {
            font-style: normal;
            color: ${({ theme }) => theme.palette.yellow.A800};
        }

        .claim-bonus-title {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            text-align: center;
            color: ${({ theme }) => theme.palette.yellow.A200};
            text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);

            ${({ theme }) => theme.breakpoints.up('lg')} {
                font-size: 22px;
            }

            i {
                &:last-child {
                    display: block;
                }
            }
        }

        .claim-bonus-img {
            display: flex;

            ${({ theme }) => theme.breakpoints.up('xs')} {
                align-items: center;
            }

            img {
                width: 40px;
            }
        }
    }

    .popup-footer {
        position: relative;
        z-index: 1;

        background: #ffecc8;
        box-shadow: 0 40px 40px -10px rgba(0, 0, 0, 0.1);
        padding: 15px 20px;

        ${({ theme }) => theme.breakpoints.up('lg')} {
            padding: 20px 25px;
        }
    }
`;
