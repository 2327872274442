import { createSelector } from 'reselect';

const dailyDealsAllSelector = (state) => state.dailyDeals;

const dailyDealsSelector = createSelector(
    [dailyDealsAllSelector],
    (dailyDeals) => dailyDeals.dailyDealData,
);

const refreshDealsButtonSelector = createSelector(
    [dailyDealsAllSelector],
    (dailyDeals) => dailyDeals.refreshDealButtonData,
);

const refreshDealsSelector = createSelector(
    [dailyDealsAllSelector],
    (dailyDeals) => dailyDeals.refreshDealData,
);

const isLoadingSelector = createSelector(
    [dailyDealsAllSelector],
    (dailyDeals) => dailyDeals.isLoading,
);

export const dailyDealSel = {
    dailyDealsSelector,
    refreshDealsButtonSelector,
    refreshDealsSelector,
    isLoadingSelector,
};
