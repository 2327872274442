import { lazy } from 'react';
import Home from './views/pages/Home/Home';
import Store from './views/pages/Store/Store';
import LoyaltyPointShop from './views/pages/LoyaltyPointShop/LoyaltyPointShop';
import Support from './views/pages/Support/Support';
import NewsPage from './views/pages/News/NewsPage';
import NewsInnerPage from './views/pages/News/NewsInnerPage';
import UserGuidesPage from './views/pages/UsersGuides/UserGuidesPage';
import SingleUserGuidesPage from './views/pages/UsersGuides/SingleUserGuidePage';
import Leaderboard from './views/pages/Leaderboard/Leaderboard';

const StyleGuide = lazy(() => import('./views/pages/StyleGuide/StyleGuide'));

const APP_ROUTES = [
    {
        path: '/',
        component: Home,
        exact: true,
        name: 'home',
    },
    {
        path: '/store',
        component: Store,
        exact: true,
        name: 'store',
    },
    {
        path: '/loyalty-point-shop',
        component: LoyaltyPointShop,
        exact: true,
        name: 'loyalty-point-shop',
    },
    {
        path: '/leaderboards',
        component: Leaderboard,
        exact: true,
        name: 'leaderboards',
    },
    // {
    //     path: '/leaderboards/players/:url',
    //     component: LeaderboardPlayers,
    //     exact: true,
    //     name: 'leaderboards',
    // },
    // {
    //     path: '/leaderboards/families/:url',
    //     component: LeaderboardPlayers,
    //     exact: true,
    //     name: 'leaderboards',
    // },
    {
        path: '/news',
        component: NewsPage,
        exact: true,
        name: 'news',
    },
    {
        path: '/news/:newsUrl',
        component: NewsInnerPage,
        exact: true,
        name: 'news',
    },
    {
        path: '/support',
        component: Support,
        exact: true,
        name: 'support',
    },
    {
        path: '/styleguide',
        component: StyleGuide,
        exact: true,
        name: 'styleguide',
    },
    {
        path: '/user-guides',
        component: UserGuidesPage,
        exact: true,
        name: 'user-guides',
    },
    {
        path: '/user-guides/:userGuidesUrl',
        component: SingleUserGuidesPage,
        exact: true,
        name: 'user-guides',
    },
];

export { APP_ROUTES };
