import styled from 'styled-components';
import ItemBg from '../../../../assets/images/Leaderboard/leadersBg.jpg';
import Level1 from '../../../../assets/images/Leaderboard/level1.svg';
import Level2 from '../../../../assets/images/Leaderboard/level2.svg';
import Level3 from '../../../../assets/images/Leaderboard/level3.svg';
import levelCustom from '../../../../assets/images/Leaderboard/levelCustom.svg';

export const TopLeadersStyled = styled.div`
    &.WP-top-leaders {
        overflow: hidden;

        margin-right: -32px;
        margin-left: -32px;
        padding: 0 32px 22px 32px;

        ${({ theme }) => theme.breakpoints.down('xs')} {
            margin-right: -20px;
            margin-left: -20px;
            padding: 0 20px 30px 20px;
        }
    }

    .WP-top-leader-container {
        height: 100%;

        border-radius: 30px;
        overflow: hidden;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    }

    .WP-leaders-body {
        height: 100%;

        background-image: url(${ItemBg});
        background-position: top;
        background-size: cover;
    }

    .WP-leader-item {
        border-radius: 10px;
        border: 1px solid ${({ theme }) => theme.palette.orange.A400};
    }

    .WP-leader-item-image {
        border: 1px solid ${({ theme }) => theme.palette.orange.A700};
        border-radius: 50%;
    }

    .see-more-items {
        font-size: 14px;
        color: ${({ theme }) => theme.palette.orange.A800};
        text-decoration: underline;
        white-space: nowrap;
        padding-left: 4px;
    }

    .hexagon-shape {
        width: 30px;
        height: 30px;
        font-family: 'poppins';

        &.level-1 {
            background-image: url(${Level1});
            background-position: center;
            background-size: 120%;
            color: #ffe19b;
        }
        &.level-2 {
            background-image: url(${Level2});
            background-position: center;
            background-size: cover;
            color: ${({ theme }) => theme.palette.orange.A50};
        }
        &.level-3 {
            background-image: url(${Level3});
            background-position: center;
            background-size: cover;
            color: ${({ theme }) => theme.palette.orange.A100};
        }
        &.level-standard {
            background-image: url(${levelCustom});
            background-position: center;
            background-size: cover;
            color: ${({ theme }) => theme.palette.brown.A500};
        }
    }
`;
