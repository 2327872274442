import { getSessionToken } from '../../bridge';
import { fetch } from '../../core/infra/dice-admin/FetchService';

import { dailyOffersSlice } from './dailyOffersSlice';
import { shopSlice } from '../shop/shopSlice';
import { calculateDiscount } from '../../helpers/utils';
import { shopOp } from '../shop';
import globalSlice from '../global';

export const getDailyOffers = () => {
    const { setDailyOffers, setLoading } = dailyOffersSlice.actions;
    const SessionToken = getSessionToken();

    return async (dispatch, getState) => {
        const state = getState();
        const purchases = state.shop.purchases;
        try {
            const { data } = await fetch({
                endPoint: 'offers-calendar',
                method: 'GET',
                params: {
                    SessionToken,
                    BypassSignature: false,
                    c_bundle: 'yahtzeenux',
                    c_store: 'xsolla',
                    c_platform: 'web',
                },
            });

            if (data) {
                const Offers = data?.Data?.Calendar?.Offers;

                if (Offers) {
                    dispatch(formatOffers(Offers, purchases));
                    dispatch(shopSlice.actions.addSections(['WebDailyOffers']));
                } else {
                    dispatch(shopSlice.actions.removeSection('WebDailyOffers'));
                }
                dispatch(setDailyOffers(Offers));
                dispatch(setLoading(false));
                dispatch(globalSlice.actions.setShowTour(true));
            }
        } catch (err) {
            dispatch(setLoading(false));
            // eslint-disable-next-line no-console
            console.log(err);
        }
    };
};

const formatOffers = (data, purchases) => {
    const { getCommoditiesList } = shopOp;
    let CommodityItems = new Set();
    let count = 1;
    for (let offer of data) {
        const item = offer?.ListingsSku;
        if (!item) continue;
        const loyaltyPoints = item?.Credit?.Adjustments.find((elem) => {
            return elem.CommodityKey === 'LoyaltyPoint';
        });
        const discountedCommodity = item?.Debit?.Adjustments[0];
        if (!discountedCommodity) {
            item['ShowPrice'] = false;
            continue;
        }

        const originalCommodity = item?.OriginalListing?.Debit?.Adjustments[0];
        const divider = discountedCommodity?.CommodityKey === 'USP' ? 100 : 1;
        const originalQuantity = originalCommodity ? originalCommodity?.Quantity / divider : null;
        const discountedQuantity = discountedCommodity
            ? discountedCommodity?.Quantity / divider
            : null;

        item['discountedCommodity'] = discountedCommodity;
        item['originalCommodity'] = originalCommodity;
        item['originalQuantity'] = originalQuantity;
        item['discountedQuantity'] = discountedQuantity;
        item['discount'] = calculateDiscount(originalQuantity, discountedQuantity);
        item['earnLP'] = loyaltyPoints;
        item['isUpcomming'] = new Date(offer.StartDate) > new Date();
        item['availableCount'] = purchases[item.Name]
            ? item.PurchaseLimitPerCustomer - purchases[item.Name]
            : item?.PurchaseLimitPerCustomer;
        offer['offerIndex'] = count;
        count++;

        for (const commodity of item?.Credit?.Adjustments)
            CommodityItems.add(commodity['CommodityKey']);
    }

    return async (dispatch) => {
        dispatch(getCommoditiesList(CommodityItems));
    };
};

export const dailyOffersOp = {
    getDailyOffers,
};
