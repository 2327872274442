import styled from 'styled-components';

export const LogOutStateStyled = styled.div`
    .WP-bg-logout {
        background-image: url(${({ bgImageUrl }) => bgImageUrl});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .WP-main-image-logout {
        ${({ theme }) => theme.breakpoints.up('xs')} {
            height: 86px;
        }

        ${({ theme }) => theme.breakpoints.up('sm')} {
            height: 110px;
        }

        ${({ theme }) => theme.breakpoints.up('lg')} {
            height: 128px;
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            height: 194px;
        }
    }

    .WP-additional-image-logout {
        width: 300px;
        position: absolute;
        top: 14px;
        left: 14px;

        ${({ theme }) => theme.breakpoints.down('lg')} {
            display: none;
        }
    }
`;
