import React from 'react';
import PropTypes from 'prop-types';

import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { NewsSliderStyled } from './NewsSlider.styled';
import SwiperSlider from '../SwiperSlider/SwiperSlider';
import { newsSlider } from '../../../constants/sliderBreakpoints';
import NewsCard from '../NewsCard/NewsCard';

const NewsSlider = ({ data, isHomePage = false }) => {
    const theme = useTheme();
    const breakpointsXS = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });

    return (
        <Box className="WP-news-slider" component={NewsSliderStyled}>
            <SwiperSlider
                className="orange-slider-arrows"
                navigation={!breakpointsXS}
                breakpoints={newsSlider}
                spaceBetween={breakpointsLG ? 20 : 16}
                allowTouchMove
                pagination={breakpointsXS}
            >
                {data.map((item, index) => {
                    return <NewsCard key={`${index}-home-new`} item={item} />;
                })}
            </SwiperSlider>
        </Box>
    );
};

NewsSlider.propTypes = {
    data: PropTypes.array,
    isHomePage: PropTypes.bool,
};

export default NewsSlider;
