import React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';
import { useTheme } from '@material-ui/core/styles';

import { useTranslation } from '../../../../hooks/useTranslation';

import FbLogin from '../../../components/LoginWithFacebook/LoginWithFacebook';
import ScopelyLogin from '../../../components/LoginWithScopely/LoginWithScopely';
import UserProfile from '../UserProfile/UserProfile';
import UserProfileSkeleton from '../UserProfile/components/UserProfileSkeleton/UserProfileSkeleton';
import { FB_APP_ID } from '../../../../constants/api.constants';
import { LOGINBUTTONTYPE } from '../../../../enums/enums';
import { globalSel } from '../../../../store/global';

const UserWrapper = () => {
    const t = useTranslation();
    const theme = useTheme();

    const authenticated = useSelector(globalSel.isAuthSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);
    const isLoading = useSelector(globalSel.isProfileLoading);

    return (
        <>
            {isLoading ? (
                <UserProfileSkeleton />
            ) : authenticated && currentUser ? (
                <UserProfile />
            ) : (
                <Box mt={5}>
                    <Box
                        component={Typography}
                        className="text-uppercase"
                        variant="p12"
                        fWeight="bold"
                        textAlign="center"
                        color={theme.palette.brown.main}
                    >
                        {t('do.you.have.an.account')}
                    </Box>

                    <Box my={3}>
                        <Box mb={3}>
                            <FbLogin
                                appId={FB_APP_ID}
                                type={LOGINBUTTONTYPE.FB}
                                disableMobileRedirect={true}
                                rightSideBtn
                            />
                        </Box>

                        <ScopelyLogin />
                    </Box>

                    <Box
                        component={Typography}
                        variant="p12"
                        fWeight="regular"
                        color={theme.palette.brown.A700}
                    >
                        {t('account.info.text')}
                    </Box>
                </Box>
            )}
        </>
    );
};

export default UserWrapper;
