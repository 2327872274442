import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { LabelStyled } from './Label.styled';

export function Label({
    children,
    variant,
    color,
    size,
    hasAction,
    className,
    hasFullWidth,
    rounded,
}) {
    return (
        <LabelStyled
            hasAction={hasAction}
            hasFullWidth={hasFullWidth}
            rounded={rounded}
            color={color}
            className={clsx(variant, variant + '-' + color, size, className)}
        >
            {children}
        </LabelStyled>
    );
}

Label.defaultProps = {
    size: 'medium', // 'medium' | 'small' | 'extra-small',
    color: 'orange', // 'orange' | 'green' | 'blue' | 'red' | 'subtle' | 'grey-1' | 'grey-2',
    variant: 'contained', // 'contained' | 'outlined',
    className: '',
    hasAction: true,
    hasFullWidth: false,
    rounded: false,
};

Label.propTypes = {
    size: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    hasAction: PropTypes.bool,
    hasFullWidth: PropTypes.bool,
    rounded: PropTypes.bool,
};
