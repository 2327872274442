import styled from 'styled-components';

export const NewsCardStyled = styled.div`
    background-color: ${({ theme }) => theme.palette.orange.A50};
    box-shadow: ${({ theme }) => theme.elevation[100]};
    overflow: hidden;

    .WP-NewsCard-Image {
        position: relative;

        img {
            object-fit: cover;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .WP-NewsCard-Title {
        ${({ theme }) => theme.breakpoints.down('xs')} {
            font-size: ${({ theme }) => theme.typography.size.p16.fontSize};
        }
    }

    .WP-NewsCard-ReadMore {
        ${({ theme }) => theme.breakpoints.up('lg')} {
            font-size: ${({ theme }) => theme.typography.size.h6.fontSize};
        }
    }

    .WP-NewsCard-Date {
        ${({ theme }) => theme.breakpoints.down('md')} {
            font-size: 12px;
        }
    }

    .WP-NewsCard-Desc {
        font-size: ${({ theme }) => theme.typography.size.p14.fontSize};
        font-weight: ${({ theme }) => theme.typography.weight.medium};
        color: #424242;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            font-size: ${({ theme }) => theme.typography.size.p16.fontSize};
        }

        p {
            display: inline;
        }
    }
`;
