import styled from 'styled-components';

export const RefreshButtonsStyled = styled.div`
    .WP-refresh-button-icon {
        .icon-loyality-point {
            margin: 0 4px 0 16px;

            ${({ theme }) => theme.breakpoints.down('sm')} {
                margin: 0 4px;
            }
        }
    }
`;
