import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from 'portal-modules/core-ui';

import { useTranslation } from '../../../../hooks/useTranslation';
import { Button } from '../../ui-components/Button/Button';
import HighlightedText from '../../HighlightedText/HighlightedText';
import { dailyRewardsOp, dailyRewardsSel } from '../../../../store/dailyReward';
import { DailyRewardBannerStyled } from '../DailyRewardBanner.styled';
import { shopSel } from '../../../../store/shop';
import { DAILY_LOGIN_CALENDAR_MODAL } from '../../../../constants/modals.constants';
import { setGAEvent } from '../../../../helpers/GA4Helper';

const Unclaimed = () => {
    const dispatch = useDispatch();
    const t = useTranslation();
    const theme = useTheme();
    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

    const dailyRewards = useSelector(dailyRewardsSel.dailyRewardsSelector);
    const commodities = useSelector(shopSel.commoditiesSelector);
    const dailyProgress = dailyRewards?.DailyProgress;

    const showDailyLoginCalendar = () => {
        setGAEvent('clickEvent', {
            screen_id: 'web_store',
            screen_name: 'web_store',
            screen_type: 'screen',
            screen_category: 'daily_reward',
            interaction_type: 'tap',
            interaction_object: 'claim',
            interaction_object_category: 'display',
            flow_order: '0',
        });
        dispatch(
            dailyRewardsOp.handleOpenModal({
                open: true,
                variant: DAILY_LOGIN_CALENDAR_MODAL,
            }),
        );
    };

    if (!dailyRewards) return false;

    const activeDailyReward = dailyRewards?.CalendarGifts?.[dailyProgress];
    const activeGifts = activeDailyReward?.Gifts;

    return (
        <>
            {dailyRewards?.CalendarGifts?.[dailyProgress] && (
                <Box
                    width={90}
                    flexDirection="row"
                    flexShrink={0}
                    position="relative"
                    bgcolor={theme.palette.brown.main}
                    data-testid="daily-reward-unclaimed-banner"
                    component={DailyRewardBannerStyled}
                    className={`WP-daily-reward-img-box flex-center WP-unclaimed-gifts-${activeGifts.length}`}
                >
                    {activeGifts?.map((data, index) => {
                        return (
                            <Box key={index} position="relative" px={1}>
                                <Box
                                    component="img"
                                    className="WP-unclaimed-gifts-img"
                                    src={commodities?.[data?.CommodityKey]?.image}
                                    alt="gift-img"
                                    position="relative"
                                    zIndex={1}
                                />

                                <HighlightedText
                                    className="WP-daily-reward-quantity"
                                    text={data?.Quantity}
                                    fontSize={['p16', 'h6']}
                                    textCenter
                                />
                            </Box>
                        );
                    })}
                </Box>
            )}

            <Box
                width={1}
                display="flex"
                flexDirection={['column', 'row']}
                className="WP-unclaimed-body"
                p={3}
            >
                <Box flexGrow={1}>
                    <Box
                        component={Typography}
                        className="WP-daily-reward-banner-title text-uppercase "
                        variant={['p12', 'p14', 'p14', 'h6', 'h6']}
                        lineHeight="18px"
                        fWeight="bold"
                        color={theme.palette.brown.main}
                        mb={1}
                    >
                        {t('daily.reward.banner.title')}
                    </Box>

                    <Box
                        component={Typography}
                        className="WP-daily-reward-banner-description"
                        variant={['p12', 'p16']}
                        fWeight="medium"
                        color={theme.palette.brown.A700}
                        mb={[2, 0]}
                    >
                        {t('daily.reward.banner.text')}
                    </Box>
                </Box>

                <Button
                    onClick={showDailyLoginCalendar}
                    variant="secondary"
                    preventLoading={true}
                    className="WP-claim-button flex-center cursor-pointer"
                    size={breakpointsSM ? 'small' : 'extra-small'}
                    shine
                >
                    {t('daily.reward.banner.claim')}
                </Button>
            </Box>
        </>
    );
};

export default Unclaimed;
