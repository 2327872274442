import React from 'react';

import { Box, Grid, useMediaQuery } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from 'react-redux';
import { globalSel } from '../../../../store/global';

const TopLeadersItemSkeleton = () => {
    const theme = useSelector(globalSel.themeSelector);
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });

    const blockItems = [0, 1, 2, 3];
    const items = [0, 1, 2];

    return (
        <>
            <Box mb={[4, 5, 5, 5, 6]}>
                <Box display="flex" alignItems="center">
                    <Box height={32} width={32} flexShrink={0} mr={2}>
                        <Skeleton variant="circle" animation="wave" height="100%" width="100%" />
                    </Box>

                    <Box height={[32, 32, 32, 32, 40]} width={180}>
                        <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                    </Box>
                </Box>

                <Box height={24} width={270} mt={1}>
                    <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                </Box>
            </Box>

            <Grid container spacing={breakpointsXL ? 10 : breakpointsLG ? 5 : 4}>
                {blockItems.map((item, index) => {
                    return (
                        <Grid key={index} item xs={12} sm={6}>
                            <Box flexGrow={1} borderRadius={12} bgcolor={theme.palette.orange.A50}>
                                <Box px={5} py={3}>
                                    <Skeleton
                                        variant="text"
                                        animation="wave"
                                        height={24}
                                        width={180}
                                    />
                                    <Skeleton
                                        variant="text"
                                        animation="wave"
                                        height={24}
                                        width={100}
                                    />
                                </Box>

                                <Box py={3} px={5}>
                                    {items.map((item) => {
                                        return (
                                            <Box
                                                key={item}
                                                display="flex"
                                                alignItems="center"
                                                mb={3}
                                            >
                                                <Box mr={3}>
                                                    <Skeleton
                                                        variant="circle"
                                                        animation="wave"
                                                        width={32}
                                                        height={32}
                                                    />
                                                </Box>
                                                <Box width="100%">
                                                    <Skeleton
                                                        variant="text"
                                                        animation="wave"
                                                        height={54}
                                                    />
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

export default TopLeadersItemSkeleton;
