import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { globalSel } from '../../../store/global';
import { Box, Icon } from 'portal-modules/core-ui';

import clsx from 'clsx';
import { AvatarStyled } from './Avatar.styled';

export default function Avatar({ isPlayer, image, handleClick, size, iconSize, hasBorder }) {
    const [imageNotFoundId, setImageNotFoundId] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const theme = useSelector(globalSel.themeSelector);

    useEffect(() => {
        if (image) {
            setImageUrl(image);
        } else {
            setImageNotFoundId(true);
        }
    }, [image]);

    return (
        <AvatarStyled hasBorder={hasBorder}>
            {imageNotFoundId ? (
                <Box
                    component={Icon}
                    width={size}
                    height={size}
                    size={iconSize}
                    onClick={handleClick ? handleClick : null}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="50%"
                    bgcolor={theme.palette.background['A100']}
                    className={clsx(
                        'has-not-avatar',
                        'avatar',
                        isPlayer ? 'icon-account' : 'icon-account-group-1',
                    )}
                />
            ) : (
                <Box
                    component="img"
                    src={imageUrl}
                    alt="avatar"
                    onError={() => setImageNotFoundId(true)}
                    className="avatar"
                    onClick={handleClick ? handleClick : null}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={size}
                    height={size}
                    borderRadius="50%"
                />
            )}
        </AvatarStyled>
    );
}
Avatar.defaultProps = {
    size: 32,
    hasBorder: true,
};
Avatar.propTypes = {
    image: PropTypes.string,
    isPlayer: PropTypes.bool,
    hasBorder: PropTypes.bool,
    handleClick: PropTypes.func,
    size: PropTypes.number,
    iconSize: PropTypes.number,
    theme: PropTypes.object,
};
