import styled from 'styled-components';

export const OfferCardStyled = styled.figure`
    margin: 0;
    padding: 0;

    .WP-card-bgImg {
        background-image: url(${({ bgImageUrl }) => bgImageUrl});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .WP-card-title,
    .WP-card-discount,
    .WP-card-earn {
        ${({ theme }) => theme.breakpoints.up('lg')} {
            font-size: ${({ theme }) => theme.typography.size.h6.fontSize};
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            font-size: ${({ theme }) => theme.typography.size.h5.fontSize};
        }
    }

    .WP-card-subtitle {
        ${({ theme }) => theme.breakpoints.down('xs')} {
            font-size: ${({ theme }) => theme.typography.size.p14.fontSize};
        }
    }

    .button-text {
        .icon-loyality-point {
            margin-right: 4px;
        }
    }
`;
