import styled from 'styled-components';

export const LoginWithFacebookStyled = styled.div`
    position: relative;
    transition: all 200ms ease-out;

    .button-text {
        width: calc(100% - 20px);
        justify-content: center;
        gap: 5px;
    }

    .logo-container {
        background-color: white;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        position: relative;
    }

    .icon-style {
        color: #245fdc;
        position: absolute;
        left: 0px;
        bottom: -3px;
    }
`;
