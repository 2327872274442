import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import DayIso from '../DayIso/DayIso';
import { useTranslation } from '../../../hooks/useTranslation';

import { DayLabelStyled } from './DayLabel.styled';

const DayLabel = ({ date, swopWords }) => {
    const t = useTranslation();

    return (
        <Box component={DayLabelStyled} className="WP-day-label">
            {swopWords ? (
                <>
                    <span>{t('pack')}</span> <DayIso date={date} />
                </>
            ) : (
                <>
                    <DayIso date={date} /> <span>{t('pack')}</span>
                </>
            )}
        </Box>
    );
};

export default DayLabel;

DayLabel.propTypes = {
    date: PropTypes.string,
    swopWords: PropTypes.bool,
};
