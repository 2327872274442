import { theme } from '../../theme/themeMUI';
import { getStorageItem } from '../../helpers/localStorage';
import { LANGUAGES } from '../../constants/languages.constantants';
import { getSessionToken } from '../../bridge';

export function initialGlobalState() {
    return {
        theme: { ...theme },
        language: getStorageItem('language') || LANGUAGES[0],
        authenticated: !!getSessionToken(),
        scopelyLogin: false,
        fbLogin: false,
        currentUser: null,
        currentLevel: null,
        isLoading: false,
        isProfileLoading: !!getSessionToken(),
        globalLeaderboards: null,
        isLeaderboardsLoading: true,
        notFound: false,
        tabsLoading: true,
        configs: {
            appLoaded: false,
            IsUnderMaintenance: false,
        },
        snackbar: {
            open: false,
            variant: '',
        },
        modal: {
            open: false,
            variant: '',
        },
        interstitials: {
            open: false,
        },
        fbData: getStorageItem('fbData'),
        popUps: {},
        loggedIn: false,
        showTour: false,
    };
}
