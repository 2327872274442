import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from '../../../hooks/useTranslation';

import { Icon, Typography } from 'portal-modules/core-ui';
import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Button } from '../ui-components/Button/Button';

import CountdownUTC from '../CountdownUTC/CountdownUTC';
import HighlightedText from '../HighlightedText/HighlightedText';
import { DailyRewardsStyled } from './DailyRewards.styled';

import { dailyRewardsOp, dailyRewardsSel } from '../../../store/dailyReward';
import { shopSel } from '../../../store/shop';
import { DAILY_LOGIN_CALENDAR_MODAL } from '../../../constants/modals.constants';
import TooltipWrapper from '../Tooltip/Tooltip';
import { setGAEvent } from '../../../helpers/GA4Helper';

const DailyRewards = () => {
    const theme = useTheme();
    const t = useTranslation();
    const dispatch = useDispatch();

    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
    const dailyRewards = useSelector(dailyRewardsSel.dailyRewardsSelector);
    const commodities = useSelector(shopSel.commoditiesSelector);

    const calendarGifts = dailyRewards?.CalendarGifts;
    const megaReward = dailyRewards?.MegaReward;
    const dailyProgress = dailyRewards?.DailyProgress;
    const dailyGiftClaimed = dailyRewards?.DailyGiftClaimed;

    useEffect(() => {
        setGAEvent('impression', {
            screen_name: 'daily_login_reward',
            screen_type: 'pop-up',
        });
    }, []);

    if (!dailyRewards) return false;

    const handleClaimReward = () => {
        !dailyGiftClaimed && dispatch(dailyRewardsOp.claimDailyRewards(false));

        setGAEvent('clickEvent', {
            screen_name: 'daily_login_reward',
            screen_type: 'pop-up',
            interaction_object: 'claim_CTA',
            interaction_object_category: 'gift',
            offer_id: dailyGiftClaimed ? `D${dailyProgress}` : `D${dailyProgress + 1}`,
        });
    };

    const handleCountDownCallback = () => {
        dispatch(dailyRewardsOp.getDailyRewards());

        dispatch(
            dailyRewardsOp.handleOpenModal({
                open: true,
                variant: DAILY_LOGIN_CALENDAR_MODAL,
            }),
        );
    };

    return (
        <Box
            component={DailyRewardsStyled}
            width="100%"
            className="WP-modal-medium"
            bgcolor={theme.palette.common.white}
            data-testid="daily-reward-modal"
        >
            <div className="WP-daily-reward-before" />

            <Box
                className="WP-daily-header flex-center"
                flexDirection="column"
                textAlign="center"
                py={4}
                pb={7}
                pt={12}
            >
                <Box
                    component={Typography}
                    variant={['h5', 'h3']}
                    mb={[1, 2]}
                    fWeight="bold"
                    color={theme.palette.yellow.A200}
                    className="WP-header"
                    px={2}
                >
                    {t('daily.reward')}
                </Box>
                <Box
                    component={Typography}
                    variant="p14"
                    mb={2}
                    fWeight="semiBold"
                    color={theme.palette.yellow.A200}
                    className="WP-subheader"
                    px={2}
                >
                    {t('daily.reward.description')}
                </Box>

                <Box mb={0} width="fit-content">
                    <CountdownUTC
                        text={t('daily.reward.timer')}
                        start={dailyRewards?.NextClaimDate}
                        interval={60 * 1000}
                        h
                        m
                        callback={() => handleCountDownCallback()}
                        isDailyReward
                    />
                </Box>
            </Box>

            <Box className="WP-body-container">
                <Box
                    className="WP-daily-body flex-center"
                    px={[4, 12]}
                    pt={5}
                    pb={dailyGiftClaimed ? 0 : 5}
                >
                    <Box className="WP-daily-days">
                        {calendarGifts?.map((data, index) => {
                            return (
                                <Box
                                    key={index}
                                    className={`WP-day-container-${index + 1} WP-day-container`}
                                    px={[2, 3]}
                                    py={[2, 3]}
                                >
                                    <TooltipWrapper
                                        header={t('daily.reward.tooltip.header')}
                                        text={t('daily.reward.tooltip.body')}
                                        position="top"
                                    >
                                        <Box
                                            className={` ${
                                                dailyProgress > index
                                                    ? 'WP-reward-claimed'
                                                    : dailyGiftClaimed === false &&
                                                      dailyProgress === index
                                                    ? 'WP-reward-available'
                                                    : ''
                                            }  WP-day`}
                                            width="130px"
                                            height="130px"
                                        >
                                            <Box
                                                className="WP-day-header text-truncate"
                                                color={theme.palette.yellow.A200}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                px={2}
                                                py={1}
                                            >
                                                <Box
                                                    component={Typography}
                                                    variant="p16"
                                                    fWeight="bold"
                                                >
                                                    {dailyProgress > index ? (
                                                        <>{t('claimed')}</>
                                                    ) : dailyGiftClaimed === false &&
                                                      dailyProgress === index ? (
                                                        <>{t('available')}!</>
                                                    ) : (
                                                        <>
                                                            {t('day')} {index + 1}
                                                        </>
                                                    )}
                                                </Box>

                                                {dailyProgress < index ||
                                                (dailyProgress === index &&
                                                    dailyGiftClaimed !== false) ? (
                                                    <Box
                                                        component={Icon}
                                                        className="icon-lock icon"
                                                        size={16}
                                                    />
                                                ) : null}
                                            </Box>
                                            <Box
                                                className="WP-day-body flex-center"
                                                flexDirection="column"
                                                px={3}
                                                pt={1}
                                                pb={2}
                                            >
                                                <Box display="flex">
                                                    {data?.Gifts?.map((gift, i) => {
                                                        return (
                                                            <Box key={i} px={1}>
                                                                <Box
                                                                    className="WP-daily-image-container"
                                                                    textAlign="center"
                                                                >
                                                                    <img
                                                                        src={
                                                                            commodities?.[
                                                                                gift.CommodityKey
                                                                            ]?.image
                                                                        }
                                                                        alt="Reward"
                                                                        width={[36, 48]}
                                                                        height={[36, 48]}
                                                                    />
                                                                    <Box
                                                                        className="WP-highlighted-bg flex-center"
                                                                        height={[20, 24]}
                                                                        position="relative"
                                                                        borderRadius={10}
                                                                        px={[5, 5, 5, 8, 8]}
                                                                        mt={[-5, -5, -5, -5, 0]}
                                                                    >
                                                                        <HighlightedText
                                                                            text={gift?.Quantity}
                                                                            fontSize={[
                                                                                'p12',
                                                                                'p16',
                                                                            ]}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        );
                                                    })}
                                                </Box>

                                                {index + 1 === calendarGifts.length &&
                                                    megaReward?.Gifts.length > 0 && (
                                                        <Box
                                                            className="flex-center mega--reward-container"
                                                            flexDirection="column"
                                                        >
                                                            <Box my={1} mx={2}>
                                                                <HighlightedText
                                                                    highlightedText
                                                                    className="WP-highlighted-gold"
                                                                    text="+"
                                                                    fontSize={['p12', 'p16']}
                                                                />
                                                            </Box>

                                                            <Box display="flex">
                                                                {megaReward?.Gifts?.map(
                                                                    (mega, ind) => {
                                                                        return (
                                                                            <Box key={ind} px={1}>
                                                                                <Box
                                                                                    className="WP-daily-image-container"
                                                                                    textAlign="center"
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            commodities?.[
                                                                                                mega
                                                                                                    .CommodityKey
                                                                                            ]?.image
                                                                                        }
                                                                                        alt="Reward"
                                                                                        width={[
                                                                                            36, 48,
                                                                                        ]}
                                                                                        height={[
                                                                                            36, 48,
                                                                                        ]}
                                                                                    />
                                                                                    <Box
                                                                                        className="WP-highlighted-bg flex-center"
                                                                                        height={[
                                                                                            20, 24,
                                                                                        ]}
                                                                                        position="relative"
                                                                                        borderRadius={
                                                                                            10
                                                                                        }
                                                                                        px={[
                                                                                            5, 5, 5,
                                                                                            8, 8,
                                                                                        ]}
                                                                                        mt={[
                                                                                            -5, -5,
                                                                                            -5, -5,
                                                                                            0,
                                                                                        ]}
                                                                                    >
                                                                                        <HighlightedText
                                                                                            text={
                                                                                                mega?.Quantity
                                                                                            }
                                                                                            fontSize={[
                                                                                                'p12',
                                                                                                'p16',
                                                                                            ]}
                                                                                        />
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        );
                                                                    },
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    )}
                                            </Box>
                                        </Box>
                                    </TooltipWrapper>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>

                <Box className="WP-daily-footer" px={7} pb={dailyGiftClaimed ? 3 : 0}>
                    {dailyProgress !== calendarGifts.length && !dailyGiftClaimed && (
                        <Button
                            variant="secondary"
                            onClick={handleClaimReward}
                            data-testid="daily-reward-claim-btn"
                            size={
                                breakpointsXL
                                    ? 'large'
                                    : breakpointsLG
                                    ? 'medium'
                                    : breakpointsSM
                                    ? 'small'
                                    : 'extra-small'
                            }
                        >
                            {t('claim.rewards')}!
                        </Button>
                    )}

                    <Box
                        component={Typography}
                        variant="p14"
                        fWeight="medium"
                        color={theme.palette.brown.A600}
                        pt={3}
                        pb={dailyProgress === calendarGifts?.length ? 10 : 3}
                        textAlign="center"
                        data-testid="daily.reward.info"
                    >
                        {t('daily.reward.info')}
                    </Box>
                </Box>
            </Box>

            <div className="WP-daily-reward-after" />
        </Box>
    );
};

DailyRewards.propTypes = {
    theme: PropTypes.object,
};
export default DailyRewards;
