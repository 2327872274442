import CookieStore from 'store/storages/cookieStorage';

const addCookie = (cName, cValue, expHours, expTime) => {
    let date = new Date();
    if (expTime) {
        const dateTime = expTime.split(':');
        date.setHours(dateTime[0], dateTime[1], dateTime[2]);
    } else date.setTime(date.getTime() + expHours * 60 * 60 * 1000);

    const expires = 'expires=' + date;
    document.cookie = cName + '=' + cValue + '; ' + expires + '; path=/';
};
const removeCookie = (key) => CookieStore.remove(key);
const getCookie = (key) => CookieStore.read(key) || '';
export { addCookie, removeCookie, getCookie };
