import React, { Suspense } from 'react';
import PageLoader from './views/components/PageLoader/PageLoader';
import { Route, Switch } from 'react-router-dom';
import { APP_ROUTES } from './AppRoutes';
import NotFoundPage from './views/pages/NotFound/NotFound';
import InterstitialPopup from './views/components/InterstitialPopup/InterstitialPopup';
import Layout from './views/layout/Layout';
import { useSelector } from 'react-redux';
import { globalSel } from './store/global';
import LoginProvider from './context/login/LoginProvider';
import NotificationPipProvider from './context/notification-pip/NotificationPipProvider';
import AuthBlockProvider from './context/auth-block/AuthBlockProvider';

const PageRoutes = () => {
    const flagedPages = useSelector(globalSel.pageFlags);
    return (
        <AuthBlockProvider>
            <NotificationPipProvider>
                <LoginProvider>
                    <Layout>
                        <Suspense fallback={<PageLoader />}>
                            <Switch>
                                {APP_ROUTES.map((route) => {
                                    const isHidden =
                                        route.name &&
                                        flagedPages?.[route.name.toLowerCase()]?.Enabled === false;
                                    return (
                                        !isHidden && (
                                            <Route
                                                key={route.path}
                                                path={route.path}
                                                exact={route.exact}
                                            >
                                                <route.component />
                                            </Route>
                                        )
                                    );
                                })}
                                <Route component={NotFoundPage} />
                            </Switch>
                        </Suspense>
                        <InterstitialPopup />
                    </Layout>
                </LoginProvider>
            </NotificationPipProvider>
        </AuthBlockProvider>
    );
};

export default PageRoutes;
