import React from 'react';
import PropTypes from 'prop-types';

import { scopelyId } from '../../../configs/scopelyId.config';
import { getStorageItem, removeStorageItem, setStorageItem } from '../../../helpers/localStorage';
import { Button } from '../ui-components/Button/Button';
import { useTranslation } from '../../../hooks/useTranslation';
import { Box } from '@material-ui/core';

import scopelyLogo from '../../../assets/images/Global/scopelyLogo.svg';
import { globalSel } from '../../../store/global';
import { useDispatch, useSelector } from 'react-redux';
import { globalSlice } from '../../../store/global/globalSlice';
import { setGAEvent } from '../../../helpers/GA4Helper';
import { LoginWithScopelyStyled } from './LoginWithScopely.styled';
import { globalOp } from '../../../store/global';
import { FACEBOOK_OPEN_TAB_REMINDER } from '../../../constants/modals.constants';

const LoginWithScopely = (props) => {
    const { loginCallback = () => null } = props;
    const pathname = window.location.pathname;
    const t = useTranslation();
    const loginStatus = useSelector(globalSel.scopelyLoginStatus);
    const scopelyLoginStatus = globalSlice.actions.setScopelyLogin;
    const dispatch = useDispatch();

    const handleClick = () => {
        if (getStorageItem('authorizing')) {
            if (getStorageItem('loginProcessing') === 'withFacebook') {
                dispatch(
                    globalOp.handleOpenModal({
                        open: true,
                        variant: FACEBOOK_OPEN_TAB_REMINDER,
                        data: FACEBOOK_OPEN_TAB_REMINDER,
                    }),
                );
            }
            return;
        }

        setTimeout(handelCancel, 3000);
        setStorageItem('loginProcessing', 'withScopely');
        setStorageItem('authorizing', true);
        scopelyId.login('scopely-login');
        dispatch(scopelyLoginStatus(true));
        setGAEvent('clickEvent', {
            screen_name: `${pathname === '/' ? '/home' : pathname}`,
            screen_type: 'screen',
            screen_section: 'right_menu',
            interaction_object: 'scopely_id_login',
            interaction_object_category: 'login',
        });
        loginCallback();
    };

    const handelCancel = () => {
        removeStorageItem('loginProcessing');
        removeStorageItem('authorizing');

        dispatch(scopelyLoginStatus(false));
    };

    return (
        <LoginWithScopelyStyled>
            <Button
                variant="scopely-btn"
                onClick={handleClick}
                isLoading={loginStatus}
                preventLoading={true}
                data-testid="scopely-btn"
            >
                <Box width={1} className="flex-center">
                    <Box component="img" src={scopelyLogo} alt="scopely logo" mr={1} />
                    <p className="WP-scopely-text text-truncate">{t('login.with.scopely')}</p>
                </Box>
            </Button>
        </LoginWithScopelyStyled>
    );
};

LoginWithScopely.propTypes = {
    loginCallback: PropTypes.func,
};

export default LoginWithScopely;
