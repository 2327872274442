import { Link } from '@material-ui/core';
import { SCOPELY_URLS } from '../../../../constants/scopely.constants';
import { Box, Typography } from 'portal-modules/core-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { globalSel } from '../../../../store/global';
import { useTranslation } from '../../../../hooks/useTranslation';
import { getLanguage } from '../../../../helpers/utils';

const ExternalLinks = () => {
    const theme = useSelector(globalSel.themeSelector);
    const t = useTranslation();
    const language = getLanguage();
    const LOCALE = language.code === 'en' ? '' : `-${language.code}`;

    React.useEffect(() => {
        if (window.reloadOTBanner) {
            window.reloadOTBanner();
        }
    }, []);

    return (
        <Box className="WP-external-links" py={8}>
            <Link href={`${SCOPELY_URLS.TERMS}${LOCALE}`} target="_blank" underline="none">
                <Typography color={theme.palette.brown.main} variant="p14">
                    {t('terms.of.service')}
                </Typography>
            </Link>
            <Box mt={5}>
                <Link
                    href={`${SCOPELY_URLS.PRIVACY_POLICY}${LOCALE}`}
                    target="_blank"
                    underline="none"
                >
                    <Typography color={theme.palette.brown.main} variant="p14">
                        {t('privacy.policy')}
                    </Typography>
                </Link>
            </Box>
            <Box mt={5}>
                {/*OneTrust Cookies Settings button start */}
                {/*eslint-disable-next-line*/}
                <a id="ot-sdk-link" className="ot-sdk-show-settings" href="#">
                    Cookies Settings
                </a>
                {/*OneTrust Cookies Settings button end */}
            </Box>
        </Box>
    );
};

export default ExternalLinks;
