import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';

import TopLeaderItem from './TopLeaderItem';
import { useTranslation } from '../../../../hooks/useTranslation';

const TopLeaders = ({ item, type, changed, maxTextLenght = null }) => {
    const theme = useTheme();
    const t = useTranslation();

    const changeFormatItem = (item) => {
        let newItem = { ...item };
        if (maxTextLenght && newItem.FamilyName) {
            newItem.FamilyName.split(' ').forEach((word) => {
                if (word.length > maxTextLenght) {
                    newItem.FamilyName = `${word.slice(0, maxTextLenght - 2)}-\n${word.slice(
                        maxTextLenght - 2,
                    )}`;
                }
            });
        }
        if (!changed) return newItem;

        if (!newItem.FamilyName) return newItem;

        newItem = { ...newItem, Name: newItem.FamilyName };
        delete newItem.FamilyName;
        return newItem;
    };

    const list = useMemo(() => item.Items, [item.Items]);

    return (
        <Box className="WP-top-leader-container" bgcolor={theme.palette.common.white}>
            <Box
                className="WP-leaders-header"
                minHeight="100px"
                display="flex"
                alignItems="baseline"
                justifyContent="space-between"
                bgcolor={theme.palette.orange.A50}
                p={5}
            >
                <Box width="100%">
                    <Box
                        component={Typography}
                        variant={['p16', 'p16', 'p16', 'p16', 'h6']}
                        fWeight="bold"
                        color={theme.palette.brown.A800}
                        mb={1}
                    >
                        {item.HeaderText || item.Name}
                    </Box>

                    <Box display="flex" justifyContent="space-between">
                        <Typography
                            variant="p14"
                            fWeight="regular"
                            color={theme.palette.brown.A700}
                        >
                            {item.TimeSpanInDays
                                ? t('last.x.days').replace('{x}', item.TimeSpanInDays)
                                : t('last.30.days')}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box className="WP-leaders-body" px={5} py={4}>
                {list.map((item, i) => {
                    return (
                        <TopLeaderItem
                            item={changeFormatItem(item)}
                            key={i}
                            index={i + 1}
                            type={type}
                        />
                    );
                })}
            </Box>
        </Box>
    );
};

TopLeaders.propTypes = {
    item: PropTypes.object,
    type: PropTypes.number,
    changed: PropTypes.bool,
    maxTextLenght: PropTypes.number,
};

export default TopLeaders;
