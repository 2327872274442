import { createSlice } from '@reduxjs/toolkit';
import { initialShopState } from './initialState';

export const shopSlice = createSlice({
    name: 'shop',
    initialState: initialShopState(),
    reducers: {
        setOffer(state, action) {
            state.processingOrder = action.payload;
        },
        setOffers(state, action) {
            state.offers = action.payload;
        },
        setLimits(state, action) {
            state.limits = action.payload;
        },
        setPurchases(state, action) {
            state.purchases = action.payload;
        },
        setOfferData(state, action) {
            state.offerData = action.payload;
        },
        setCommodities(state, action) {
            state.commodities = state.commodities
                ? { ...state.commodities, ...action.payload }
                : action.payload;
        },
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setXsollaToken(state, action) {
            state.xsollaToken = action.payload;
        },
        setSections(state, action) {
            state.sections = action.payload;
        },
        addSections(state, action) {
            state.sections = state.sections.length
                ? [...state.sections, ...action.payload]
                : action.payload;
        },
        removeSection(state, action) {
            state.sections = state.sections.filter((elem) => {
                return elem !== action.payload;
            });
        },
        setVersion(state, action) {
            state.version = action.payload;
        },
    },
});
