import React from 'react';
import PropTypes from 'prop-types';

import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { TopLeadersStyled } from './TopLeaders.styled';
import SwiperSlider from '../../SwiperSlider/SwiperSlider';
import { leaderboardSlider } from '../../../../constants/sliderBreakpoints';
import TopLeaders from './TopLeaders';

const TopLeadersSlider = ({ data, isHomePage = false }) => {
    const theme = useTheme();
    const breakpointsXS = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });

    return (
        <Box className="WP-top-leaders" component={TopLeadersStyled}>
            <SwiperSlider
                className="orange-slider-arrows"
                navigation={!breakpointsXS}
                breakpoints={leaderboardSlider}
                spaceBetween={breakpointsLG ? 20 : 16}
                allowTouchMove
                pagination={breakpointsXS}
            >
                {data.map((item, index) => {
                    return (
                        <TopLeaders
                            type={item.Category}
                            key={index}
                            item={item}
                            isHomePage={isHomePage}
                            changed={index > 3}
                            maxTextLenght={18}
                        />
                    );
                })}
            </SwiperSlider>
        </Box>
    );
};

TopLeadersSlider.propTypes = {
    data: PropTypes.array,
    isHomePage: PropTypes.bool,
};

export default TopLeadersSlider;
