import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '../../../../../hooks/useTranslation';

import { Box } from 'portal-modules/core-ui';

import { LoyaltyPointsLearnStyled } from './LoyaltyPointsLearn.styled';

import lpOnboardingEarn from '../../../../../assets/images/LoyaltyPointsShop/LPOnboardingEarn.png';
import lpOnboardingRedeem from '../../../../../assets/images/LoyaltyPointsShop/LPOnboardingRedeem.png';
import lpOnboardingPlay from '../../../../../assets/images/LoyaltyPointsShop/LPOnboardingPlay.png';
import { setGAEvent } from '../../../../../helpers/GA4Helper.js';

const LoyaltyPointsLearnModal = ({ theme }) => {
    const t = useTranslation();

    useEffect(() => {
        setGAEvent('clickEvent', {
            screen_id: 'loyalty_point_store',
            screen_name: 'loyalty_point_store',
            screen_type: 'pop-up',
            screen_section: 'web',
            interaction_type: 'load',
            interaction_object_category: 'display',
            flow_name: 'redeem_banner',
            screen_content: 'learn_more_popup',
        });

        let timeoutId;

        const handleScroll = () => {
            // send scroll event
            clearTimeout(timeoutId);
            timeoutId = setTimeout(
                setGAEvent('clickEvent', {
                    screen_id: 'loyalty_point_store',
                    screen_name: 'loyalty_point_store',
                    screen_type: 'pop-up',
                    screen_section: 'web',
                    interaction_type: 'scroll',
                    interaction_object_category: 'display',
                    flow_name: 'redeem_banner',
                    screen_content: 'learn_more_popup',
                }),
                200,
            );
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Box
            component={LoyaltyPointsLearnStyled}
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            overflow="hidden"
            className="WP-leaders-modal"
        >
            <Box className="WP-loyalty-points-learn-cards">
                <Box className="WP-loyalty-points-learn-card">
                    <img
                        src={lpOnboardingEarn}
                        className="WP-loyalty-points-learn-card-image"
                        alt="earn"
                    />
                    <h6 className="WP-loyalty-points-learn-card-title">
                        {t('loyalty.points.learn.earn')}
                    </h6>
                    <p className="WP-loyalty-points-learn-card-text">
                        {t('loyalty.points.learn.text.earn')}
                    </p>
                </Box>
                <Box className="WP-loyalty-points-learn-card">
                    <img
                        src={lpOnboardingRedeem}
                        className="WP-loyalty-points-learn-card-image"
                        alt="redeem"
                    />
                    <h6 className="WP-loyalty-points-learn-card-title">
                        {t('loyalty.points.learn.redeem')}
                    </h6>
                    <p className="WP-loyalty-points-learn-card-text">
                        {t('loyalty.points.learn.text.redeem')}
                    </p>
                </Box>
                <Box className="WP-loyalty-points-learn-card">
                    <img
                        src={lpOnboardingPlay}
                        className="WP-loyalty-points-learn-card-image"
                        alt="play"
                    />
                    <h6 className="WP-loyalty-points-learn-card-title">
                        {t('loyalty.points.learn.play')}
                    </h6>
                    <p className="WP-loyalty-points-learn-card-text">
                        {t('loyalty.points.learn.text.play')}
                    </p>
                </Box>
            </Box>
        </Box>
    );
};

LoyaltyPointsLearnModal.defaultProps = {
    badge: false,
};

LoyaltyPointsLearnModal.propTypes = {
    theme: PropTypes.object,
    data: PropTypes.object,
    badge: PropTypes.bool,
};

export default LoyaltyPointsLearnModal;
