import styled from 'styled-components';

import bgImage from '../../../../assets/images/Home/Logout/tipsAndTricksBg.png';
import leftTipsAndTricks from '../../../../assets/images/Home/Logout/leftTipsAndTricks.png';
import rightTipsAndTricks from '../../../../assets/images/Home/Logout/rightTipsAndTricks.png';
import shine from '../../../../assets/images/Home/Logout/shine.png';

export const TipsAndTricksStyled = styled.section`
    min-height: 570px;

    justify-content: center;
    flex-direction: column;

    background-image: url(${bgImage});
    z-index: 3;

    ${({ theme }) => theme.breakpoints.up('lg')} {
        min-height: 380px;
        flex-direction: row;
    }

    &:before,
    &:after {
        width: 200px;
        height: 200px;
        background-size: contain;

        ${({ theme }) => theme.breakpoints.up('lg')} {
            width: 300px;
            height: 300px;
        }
    }

    &:before {
        background-image: url(${leftTipsAndTricks});
    }

    &:after {
        background-image: url(${rightTipsAndTricks});
    }

    .WP-logout-tipsAndTricks-content {
        width: fit-content;
        height: 100%;

        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        z-index: 3;

        ${({ theme }) => theme.breakpoints.up('lg')} {
            flex-direction: row;
        }

        &:before {
            width: 100%;
            height: 100%;

            position: absolute;
            left: -110%;
            top: -80px;
            content: url(${shine});
            z-index: -1;

            display: inline-block;
            mix-blend-mode: color-dodge;

            ${({ theme }) => theme.breakpoints.up('lg')} {
                content: none;
            }
        }
    }

    .WP-logo-wrapper {
        ${({ theme }) => theme.breakpoints.up('lg')} {
            width: 50%;
            text-align: right;
        }

        .WP-tips-logo {
            width: 280px;

            ${({ theme }) => theme.breakpoints.up('lg')} {
                width: 400px;
            }
        }
    }
`;
