import backgroundDay1 from '../../../../../assets/images/DailyOffer/day-1.png';
import backgroundDay2 from '../../../../../assets/images/DailyOffer/day-2.png';
import backgroundDay3 from '../../../../../assets/images/DailyOffer/day-3.png';

import activeBackgroundDay1 from '../../../../../assets/images/DailyOffer/active-day-1.png';
import activeBackgroundDay2 from '../../../../../assets/images/DailyOffer/active-day-2.png';
import activeBackgroundDay3 from '../../../../../assets/images/DailyOffer/active-day-3.png';

export const backgrounds = [
    { active: activeBackgroundDay1, passive: backgroundDay1 },
    { active: activeBackgroundDay2, passive: backgroundDay2 },
    { active: activeBackgroundDay3, passive: backgroundDay3 },
];
