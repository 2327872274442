import { Settings, BrowserTracker, BuildType } from '@scopely/web-sdk-analytics';

const debug = process.env.REACT_APP_ENVIRONMENT !== 'production';
const buildType =
    process.env.REACT_APP_ENVIRONMENT === 'production' ? BuildType.Production : BuildType.Debug;
const config = new Settings(process.env.REACT_APP_TITAN_API_KEY, buildType)
    .withDebug(debug)
    .withFlushIntervalMillis(5000);

const tracker = new BrowserTracker(config);
tracker.register();

const Titan = {};

Titan.SendEvent = function (event) {
    if (event.name && event.parameters) {
        tracker.track(event.name, event.parameters);
        window?.DD_RUM?.addAction('titan-events-count', {
            event: event.name,
            params: event.parameters,
        });
    } else {
        // eslint-disable-next-line no-console
        console.error('Trying to send malformed event: ' + JSON.stringify(event));
    }
};

export { Titan, tracker };
