import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { LatestUpdatesSliderStyled } from './LatestUpdatesSlider.styled';
import SwiperSlider from '../SwiperSlider/SwiperSlider';
import LatestUpdatesCard from './LatestUpdatesCard';

const LatestUpdatesSlider = ({ data }) => {
    const theme = useTheme();
    const [breakpointsXXS, setBreakpointXXS] = useState(window.innerWidth < 500);
    const breakpointsXS = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });

    const delay = 12000;
    const [count, setCount] = useState(0);
    const [paused, setPaused] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        let timer;
        if (count < delay / 1000 && !paused) {
            timer = setInterval(() => {
                setCount((prevCount) => prevCount + 0.1);
            }, 100);
        }

        return () => clearInterval(timer);
    }, [count]);

    const onAutoplay = () => {
        setCount(0);
    };

    const onTouchStart = () => {
        setPaused(true);
    };

    const onTouchEnd = () => {
        setPaused(false);
        setCount(0);
    };

    useEffect(() => {
        const handleResize = setBreakpointXXS(window.innerWidth < 500);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Box className="WP-news-slider" component={LatestUpdatesSliderStyled}>
            <SwiperSlider
                className="orange-slider-arrows"
                navigation={!breakpointsXS}
                slidesPerView="auto"
                centeredSlides={breakpointsXXS}
                spaceBetween={breakpointsLG ? 20 : 16}
                allowTouchMove
                pagination={breakpointsXS}
                autoplay={{ delay, disableOnInteraction: false, stopOnLastSlide: true }}
                onAutoplay={onAutoplay}
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
                onActiveIndexChange={(swip) => {
                    setActiveIndex(swip.activeIndex);
                }}
            >
                {data.map((item, index) => (
                    <>
                        <LatestUpdatesCard key={`${index}-home-new`} item={item} />
                        {index === activeIndex && !breakpointsLG && (
                            <Box
                                className="WP-news-slider-progress-bar"
                                style={{
                                    width: `${
                                        (count / delay) * 100000 <= 100
                                            ? (count / delay) * 100000
                                            : 100
                                    }%`,
                                }}
                            />
                        )}
                    </>
                ))}
            </SwiperSlider>
        </Box>
    );
};

LatestUpdatesSlider.propTypes = {
    data: PropTypes.array,
    isHomePage: PropTypes.bool,
};

export default LatestUpdatesSlider;
