import { createSlice } from '@reduxjs/toolkit';
import { initialDailyRewardsState } from './initialState';

export const dailyRewardsSlice = createSlice({
    name: 'dailyRewards',
    initialState: initialDailyRewardsState(),
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setDailyRewards(state, action) {
            state.dailyRewardData = action.payload;
            state.isLoading = false;
        },
        setModal(state, action) {
            state.modal = action.payload;
        },
        setCalendarBanner(state, action) {
            state.calendarBanner = action.payload;
        },
    },
});
