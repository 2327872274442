import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../hooks/useTranslation';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Box, useMediaQuery } from '@material-ui/core';

import WrapperSkeleton from '../OfferCardSkeleton/WrapperSkeleton';
import SectionTitle from '../SectionTitle/SectionTitle';
import SeeMoreItems from '../SeeMoreItems/SeeMoreItems';

import { globalSel } from '../../../store/global';
import { shopSel } from '../../../store/shop';

const DiceAndMore = ({
    redirectMoreItems,
    selector = 'WebExclusiveVanity',
    limit,
    limitSkeleton,
    cardGridSizeXl,
    skeletonGridSize,
}) => {
    const t = useTranslation();
    const history = useHistory();

    const section = useSelector(shopSel[selector]);
    const theme = useSelector(globalSel.themeSelector);
    const isLoading = useSelector(shopSel.isLoading);

    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

    const data =
        history.location.pathname === '/'
            ? breakpointsXL
                ? section?.Listings
                : breakpointsSM
                ? section?.Listings?.slice(0, 3)
                : section?.Listings
            : section?.Listings;

    return (
        <Box component="section" id="dice-more" className="WP-dice-more" pb={[10, 12, 12, 16, 20]}>
            {isLoading ? (
                <WrapperSkeleton
                    limitSkeleton={limitSkeleton}
                    skeletonGridSize={skeletonGridSize}
                    cardGridSizeXl={cardGridSizeXl}
                />
            ) : !data || !data.length ? null : (
                <>
                    <SectionTitle
                        title={t('dice.and.more')}
                        subtitle={t('dice.and.more.subtitle')}
                        customIcon="vanity-offers"
                    />
                    <SeeMoreItems
                        section="dice_and_more"
                        data={data}
                        limit={limit}
                        redirectTo={
                            redirectMoreItems
                                ? { page: '/loyalty-point-shop', section: 'WP-dice-more' }
                                : null
                        }
                        spacing={breakpointsLG ? 5 : 4}
                        isDiceAndMoreDetails
                        cardGridSizeXl={cardGridSizeXl}
                    />
                </>
            )}
        </Box>
    );
};

DiceAndMore.propTypes = {
    redirectMoreItems: PropTypes.bool,
    selector: PropTypes.string,
    limit: PropTypes.number,
    limitSkeleton: PropTypes.array,
    cardGridSizeXl: PropTypes.number,
    skeletonGridSize: PropTypes.bool,
};

export default DiceAndMore;
