import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';

import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from '../../../../hooks/useTranslation';

import Item from './Item';
import { useSelector } from 'react-redux';
import { shopSel } from '../../../../store/shop';
import ListSkeleton from './ListSkeleton';

const List = ({ data, isLoading = undefined }) => {
    const t = useTranslation();
    const theme = useTheme();
    const isLoadingGlobal = useSelector(shopSel.isLoading);

    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });

    isLoading = isLoading !== undefined ? isLoading : isLoadingGlobal;

    return (
        <Box component="section">
            {isLoading ? (
                <ListSkeleton />
            ) : (
                <>
                    <Box
                        component={Typography}
                        className="WP-support-subtitle"
                        variant={['h6', 'h6', 'h6', 'h4']}
                        fWeight="bold"
                        color={theme.palette.brown.main}
                        mb={5}
                    >
                        {t('most.visited.support.sections')}
                    </Box>

                    <Grid container spacing={breakpointsXL ? 10 : breakpointsLG ? 5 : 4}>
                        {data.map((item) => {
                            return (
                                <Grid key={item.id} item xs={12} sm={6}>
                                    <Item item={item} isTranslated />
                                </Grid>
                            );
                        })}
                    </Grid>
                </>
            )}
        </Box>
    );
};

List.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default List;
