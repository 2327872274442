import styled from 'styled-components';

export const LatestUpdatesSliderStyled = styled.div`
    &.WP-news-slider {
        overflow: hidden;

        margin-right: -32px;
        margin-left: -32px;
        padding: 0 32px 22px 32px;

        ${({ theme }) => theme.breakpoints.down('xs')} {
            margin-right: -20px;
            margin-left: -20px;
            padding: 0 20px 30px 20px;
        }

        .WP-news-slider-progress-bar {
            position: absolute;
            height: 3px;
            background-color: #ccc;
        }
    }

    .swiper-slide {
        width: 322px;
    }

    .swiper-wrapper {
        @media (min-width: 1592px) {
            display: flex;
            justify-content: center;
        }

        @media (max-width: 1280px) and (min-width: 1062px) {
            display: flex;
            justify-content: center;
        }
    }

    .WP-latest-update-container {
        height: 345px;
        background-color: ${({ theme }) => theme.palette.orange.A50};
        background-size: cover;

        border-radius: 30px;
        overflow: hidden;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);

        padding-top: 12px;
        padding-bottom: 15px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 15px;

        .WP-latest-update-content {
            flex-direction: column;
            display: flex;
            flex-grow: 1;

            .WP-latest-update-header {
                padding: 0 12px;

                .WP-latest-update-header-title-image {
                    background-size: cover;
                    background-position: 50% 0%;
                    border-radius: 50px;
                    height: 30px;
                }

                .WP-latest-update-header-title {
                    padding-bottom: 4px;
                    text-align: center;
                    font-weight: ${({ theme }) => theme.typography.weight.bold};
                    font-size: ${({ theme }) => theme.typography.size.p18.fontSize};
                    color: ${({ theme }) => theme.palette.brown.main};
                }

                margin-bottom: 10px;
            }

            .WP-latest-update-contents {
                display: flex;
                flex-grow: 1;
                flex-direction: column;
                gap: 12px;

                .WP-latest-update-content-box {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 20px;

                    .WP-latest-update-content-box-element {
                        width: 100%;

                        font-size: ${({ theme }) => theme.typography.size.p12.fontSize};
                        font-weight: ${({ theme }) => theme.typography.weight.bold};
                        color: ${({ theme }) => theme.palette.brown.A800};
                    }

                    .WP-latest-update-content-box-element-full {
                        padding: 0 12px;
                    }

                    .WP-latest-update-content-box-image {
                        text-align: center;
                        margin: auto;
                        height: 100%;
                        background-size: cover;
                        background-position: center;
                    }
                }

                .WP-latest-update-content-box-image-full {
                    flex-grow: 1;
                }

                .WP-latest-update-content-box-split {
                    padding: 0 12px;
                    flex-grow: 1;

                    .WP-latest-update-content-box-image {
                        border-radius: 20px;
                    }
                }
            }
        }

        .WP-latest-update-footer {
            padding: 0 12px;
            width: 100%;
            text-align: center;
        }
    }
`;
