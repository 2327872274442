import React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';

import { useTranslation } from '../../../../hooks/useTranslation';
import SectionTitle from '../../SectionTitle/SectionTitle';
import DailyOfferSkeleton from './DailyOffersSkeleton';
import SwiperSlider from '../../SwiperSlider/SwiperSlider';

import { dailyOffersSel } from '../../../../store/dailyOffers';
import DailySliderItem from '../DailyOffersSlider/DailySliderItem';

const DailyOffersSection = () => {
    const t = useTranslation();
    const dailyOffers = useSelector(dailyOffersSel.dailyOffers);
    const isLoading = useSelector(dailyOffersSel.isLoading);

    return (
        <Box
            component="section"
            className="WP-daily-offer WP-hide-empty-space"
            data-testid="daily-offers-section"
            pb={[10, 12, 12, 16, 20]}
        >
            {isLoading ? (
                <DailyOfferSkeleton />
            ) : dailyOffers ? (
                <>
                    <SectionTitle
                        title={t('daily.offers')}
                        subtitle={t('daily.offers.subtitle')}
                        icon="icon-calendar"
                    />
                    <SwiperSlider
                        navigation
                        spaceBetween={32}
                        itemwidth="100%"
                        className="WP-slider-spacing"
                        pagination={{
                            clickable: true,
                        }}
                    >
                        {dailyOffers?.reduce((acc, element) => {
                            return element?.ListingsSku
                                ? [
                                      ...acc,
                                      <DailySliderItem
                                          key={element?.StoreFrontName}
                                          offer={element}
                                      />,
                                  ]
                                : acc;
                        }, [])}
                    </SwiperSlider>
                </>
            ) : null}
        </Box>
    );
};

export default DailyOffersSection;
