export function hexToRgba(hex, opacity) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? opacity
            ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
                  result[3],
                  16,
              )}, ${opacity})`
            : `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
                  result[3],
                  16,
              )})`
        : null;
}
