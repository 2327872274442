export class EnergyEnabledGetter {
    constructor(configRepository) {
        this.configRepository = configRepository;
    }

    async execute() {
        const { 'entry-system-energy-enabled': entrySystemEnergyEnabled } =
            await this.configRepository.getConfig();

        try {
            return JSON.parse(entrySystemEnergyEnabled);
        } catch (error) {
            return null;
        }
    }
}
