const boardsSelector = ({ leaders }) => leaders?.boards;
const playersBoardsSelector = ({ leaders }) => leaders?.boards?.Players;
const familiesBoardsSelector = ({ leaders }) => leaders?.boards?.Families;
const getBoardInfo = ({ leaders }) => leaders?.selected;
const gamesModesSelector = ({ leaders }) => leaders?.gamesModes;
const leaderInfoSelector = ({ leaders }) => leaders?.leader;
const isLoading = ({ leaders }) => leaders?.isLoading;

export const leadersSel = {
    boardsSelector,
    playersBoardsSelector,
    familiesBoardsSelector,
    gamesModesSelector,
    isLoading,
    leaderInfoSelector,
    getBoardInfo,
};
