import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Icon, Typography } from 'portal-modules/core-ui';
import { Box, Hidden, Tooltip, useMediaQuery } from '@material-ui/core';
import { useTranslation } from '../../../../hooks/useTranslation';
import { Button } from '../../ui-components/Button/Button';
import DailyTimer from './DailyTimer';
import { withPurchase } from '../../../../hoc/withPurchase';
import DailyOfferCommodity from '../../DailyOfferCommodity/DailyOfferCommodity';
import Badge from '../../Badge/Badge';
import DayLabel from '../../DayLabel/DayLabel';
import { getLanguage, IncludeTheWordInArray, USNumberFormat } from '../../../../helpers/utils';
import { globalOp } from '../../../../store/global';
import { dailyOffersOp } from '../../../../store/dailyOffers';
import { DAILY_OFFER_CALENDAR_MODAL } from '../../../../constants/modals.constants';
import { DailySliderItemStyled } from './DailySliderItemStyled';
import LoyaltyPointsImage from '../../../../assets/images/Global/loyaltyPoints.png';
import UpcomingSliderItem from './UpcomingSliderItem';
import { useTheme } from '@material-ui/core/styles';
import { SPECIAL_LANGUAGES } from '../../../../constants/languages.constantants';
function DailySliderItem({ offer, handlePurchase }) {
    const t = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();

    const language = getLanguage();
    const location = useLocation();
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
    const breakpointsMac = useMediaQuery(theme.breakpoints.between(1780, 1800), {
        noSsr: true,
    });

    const { StartDate: start, EndDate: end } = offer;
    const item = offer?.ListingsSku;

    if (!item) return false;

    const openModal = () => {
        if (location?.pathname === '/store') {
            dispatch(
                globalOp.handleOpenModal({
                    open: true,
                    variant: DAILY_OFFER_CALENDAR_MODAL,
                    data: offer,
                }),
            );
        }
    };
    return (item.isUpcomming && !offer.ShowPrice) || !offer.ShowPrice ? (
        <UpcomingSliderItem offer={offer} openModal={openModal} />
    ) : (
        <Box
            key={item.IapSku}
            component={DailySliderItemStyled}
            className={`WP-day-${offer.offerIndex}`}
            width={1}
            height={[400, 244, 244, breakpointsMac ? 385 : 288, 450]}
            display="flex"
            alignItems="center"
            backgroundImage={offer?.BackgroundImageUrl}
            boxShadow={theme.elevation[800]}
            overflow="hidden"
            onClick={openModal}
            data-testid="daily-offers-modal-open"
        >
            <Box
                className="WP-slider-left-col"
                display="flex"
                flexDirection="column"
                alignItems="center"
                width={['100%', '60%']}
                height={1}
                pt={[2, 5, 5, breakpointsMac ? 10 : 5, 20]}
                pb={[2, 3, 3, 4, 5]}
                px={[3, 0]}
            >
                <Box className="WP-slider-mobile" flexGrow={1} width={1}>
                    <Box
                        component={Typography}
                        className="WP-slider-title text-uppercase"
                        fontFamily="rubik"
                        variant={['h4', 'h2', 'h2', 'h1']}
                        fWeight="bold"
                        textAlign="center"
                    >
                        {t('daily.offers')}
                    </Box>

                    <Box width="fit-content" mx="auto" mt={[1, 4, 4, 5, 10]}>
                        <DailyTimer
                            start={start}
                            end={end}
                            callback={() => {
                                if (location?.pathname === '/store') {
                                    dispatch(
                                        globalOp.handleOpenModal({
                                            open: false,
                                            variant: DAILY_OFFER_CALENDAR_MODAL,
                                        }),
                                    );
                                    return dispatch(dailyOffersOp.getDailyOffers());
                                }
                            }}
                        />
                    </Box>

                    <Hidden smUp>
                        <Box width="fit-content" mx="auto" mt={4}>
                            <DayLabel date={start} />
                        </Box>

                        <Box className="WP-commodity-position">
                            <DailyOfferCommodity data={item?.Credit?.Adjustments} />
                        </Box>
                    </Hidden>
                </Box>

                <Box width={1}>
                    <Box className="flex-center" flexDirection="column">
                        {item.originalQuantity &&
                            item.originalQuantity !== item.discountedQuantity && (
                                <Box
                                    className="WP-slider-discount text-decoration-line-through"
                                    variant="p16"
                                    fWeight="bold"
                                    textAlign="center"
                                >
                                    {item.originalQuantity.CommodityKey === 'USP' ? (
                                        <span>$</span>
                                    ) : (
                                        <Box
                                            component="img"
                                            src={LoyaltyPointsImage}
                                            alt="loyalty points"
                                            width={[16, 20, 20, 24, 30]}
                                            height={[16, 20, 20, 24, 30]}
                                        />
                                    )}
                                    {USNumberFormat(item.originalQuantity)}
                                </Box>
                            )}

                        <Box
                            position="relative"
                            minWidth={['100%', 300, 300, breakpointsMac ? 550 : 300, 550]}
                            mt={[1, 1, 1, 2]}
                            mb={2}
                        >
                            {item.discount && item.availableCount ? (
                                <Badge text={`${item.discount}% ${t('off')}`} buttonBadge />
                            ) : item?.DisplayDetails?.BannerText ? (
                                <Badge text={item?.DisplayDetails?.BannerText} buttonBadge />
                            ) : null}

                            <Button
                                completed={item.availableCount != null && item.availableCount <= 0}
                                // unAvailable={item.isUpcomming}
                                onClick={handlePurchase({
                                    commodity: item.discountedCommodity?.CommodityKey,
                                    iapSku: item.IapSku,
                                    storeListingName: item.Name,
                                    item: {
                                        ga: true,
                                        name: item?.DisplayDetails?.DisplayName,
                                        price: item.discountedQuantity,
                                        section: 'daily_offer',
                                    },
                                })}
                                preventLoading
                                size={breakpointsXL ? 'large' : breakpointsLG ? 'medium' : 'small'}
                                variant="secondary"
                            >
                                {item.availableCount != null && item.availableCount <= 0 ? (
                                    t('purchased')
                                ) : (
                                    <>
                                        {item.discountedCommodity.CommodityKey === 'USP' ? (
                                            <span>$</span>
                                        ) : (
                                            <Box
                                                component="img"
                                                src={LoyaltyPointsImage}
                                                alt="loyalty points"
                                                width={[16, 20, 20, 24, 30]}
                                                height={[16, 20, 20, 24, 30]}
                                            />
                                        )}
                                        {item.discountedQuantity &&
                                            USNumberFormat(item.discountedQuantity)}
                                    </>
                                )}
                            </Button>
                        </Box>
                    </Box>

                    {item.earnLP && (
                        <Box className="flex-center">
                            <Tooltip
                                enterTouchDelay={0}
                                title={t('loyalty.point.tooltip')}
                                arrow
                                placement="top-start"
                                PopperProps={{ disablePortal: true }}
                            >
                                <Box
                                    width={16}
                                    height={16}
                                    className="flex-center"
                                    borderRadius="50%"
                                    bgcolor={theme.palette.common.white}
                                >
                                    <Icon
                                        className="icon-info"
                                        size={8}
                                        color={theme.DailyOffer.modal.infoIconColor}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                </Box>
                            </Tooltip>

                            <Box
                                component={Typography}
                                variant={['p12', 'p12', 'p12', 'p14']}
                                fWeight="medium"
                                color={theme.palette.common.white}
                                mx={1}
                            >
                                {`${t('purchase.and.earn')} ${item.earnLP?.Quantity}`}
                            </Box>

                            <Box
                                component="img"
                                src={LoyaltyPointsImage}
                                alt="loyalty point"
                                width={16}
                                height={16}
                            />
                        </Box>
                    )}
                </Box>
            </Box>

            <Hidden xsDown>
                <Box
                    className="WP-slider-right-col"
                    width="40%"
                    height={1}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    position="relative"
                    pt={[0, 4, 4, breakpointsMac ? 5 : 4, 10]}
                    pb={[2, 3, 3, 3, 5]}
                >
                    <DayLabel
                        date={start}
                        swopWords={IncludeTheWordInArray(SPECIAL_LANGUAGES, language.code)}
                    />
                    <Box className="WP-commodity-position">
                        <DailyOfferCommodity data={item?.Credit?.Adjustments} />
                    </Box>
                </Box>
            </Hidden>
        </Box>
    );
}

export default withPurchase(DailySliderItem);

DailySliderItem.propTypes = {
    offer: PropTypes.object,
    handlePurchase: PropTypes.func,
};
