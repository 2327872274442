import styled from 'styled-components';

export const LeftSidebarFooterStyled = styled.div`
    padding: 0 12px 120px;

    p {
        transition: color 200ms ease-out;

        &:hover {
            color: ${({ theme }) => theme.palette.brown.A800};
        }
    }

    .WP-external-links {
        border-top: 1px solid ${({ theme }) => theme.palette.brown.A50};
    }

    .WP-social-links {
        ${({ theme }) => theme.breakpoints.down('sm')} {
            margin-bottom: 32px;
        }

        a {
            margin-right: 10px;
            color: ${({ theme }) => theme.palette.brown.A600};
            transition: color 200ms ease-out;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                color: ${({ theme }) => theme.palette.brown.A500};
            }
        }
    }

    .discord-button {
        padding: 8px 16px;
        text-align: left;
        text-transform: none;

        i {
            margin-right: 16px;
        }
    }

    .WP-rights-reserved {
        p {
            line-height: 16px;
        }
    }
`;
