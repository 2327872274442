import styled from 'styled-components';
import shine from '../../../assets/images/DailyRewards/shine.png';

export const DailyRewardBannerStyled = styled.div`
    box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.19);

    .WP-daily-reward-before,
    .WP-daily-reward-after {
        width: 14px;
        height: 10px;

        position: absolute;
        top: 0;
        z-index: 2;

        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        background-color: rgba(110, 7, 105, 0.2);

        &:after {
            content: '';
            display: block;

            width: 10px;
            height: 16px;

            position: absolute;
            top: -8px;
            left: 50%;
            transform: translateX(-50%);

            border-radius: 12px;
            background-color: #999999;
        }
    }

    .WP-daily-reward-before {
        left: 44px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            left: 34px;
        }
    }

    .WP-daily-reward-after {
        right: 44px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            right: 34px;
        }
    }

    .WP-daily-reward-img-box {
        background: linear-gradient(162.35deg, #ff5179 -1.14%, #c33bd9 73.13%, #8f00ff 132.13%);
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url(${shine}) no-repeat center;
            background-size: cover;
            mix-blend-mode: overlay;
        }

        > img {
            object-fit: contain;
        }
    }

    .WP-daily-reward-close-icon {
        top: 8px;
        right: 8px;
        color: ${({ theme }) => theme.palette.common.white};

        ${({ theme }) => theme.breakpoints.up('sm')} {
            top: 20px;
            right: 8px;
            transform: translateY(-50%);
        }
    }

    .WP-countdown {
        width: fit-content;
        margin-bottom: 4px;

        img {
            height: 20px;
        }
        // ${({ theme }) => theme.breakpoints.down('500')} {
        //     height: 24px;
        // }
    }

    .WP-daily-reward-quantity {
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
        z-index: 1;

        h6 {
            font-size: 16px;
        }

        p {
            font-size: 14px;
        }
    }

    .WP-daily-reward-banner-button {
        margin: 4px 0 0 0;
        padding: 0 8px;
        background-color: transparent;

        font-size: ${({ theme }) => theme.typography.size.p12.fontSize};
        font-weight: ${({ theme }) => theme.typography.weight.semiBold};
        border: 1px solid ${({ theme }) => theme.palette.brown.main};

        ${({ theme }) => theme.breakpoints.up('sm')} {
            font-size: ${({ theme }) => theme.typography.size.p14.fontSize};
            width: fit-content;
            padding: 0 16px;
        }
    }

    //Claim
    .WP-claim-container {
        border-radius: 12px;
        ${({ theme }) => theme.breakpoints.up('500')} {
            display: flex;
            flex-direction: row;
        }
    }

    .WP-daily-reward-claim {
        width: 100%;
        border-radius: 12px 12px 0 0;

        ${({ theme }) => theme.breakpoints.up('500')} {
            width: 197px;
            border-radius: 12px 0 0 12px;
        }
    }

    .WP-claim-button-container {
        width: 100%;

        ${({ theme }) => theme.breakpoints.up('500')} {
            width: unset;
        }
    }

    //Unclaimed banner
    &.WP-daily-reward-img-box {
        ${({ theme }) => theme.breakpoints.up('500')} {
            width: 197px;
        }
    }

    .WP-unclaimed-body {
        ${({ theme }) => theme.breakpoints.up('sm')} {
            align-items: center;
        }
    }

    .WP-claim-button {
        margin: 4px 0 0 0;

        ${({ theme }) => theme.breakpoints.up('500')} {
            width: fit-content;
        }
    }

    .WP-unclaimed-gifts-1 {
        .WP-unclaimed-gifts-img {
            width: 62px;
        }
    }

    .WP-unclaimed-gifts-2 {
        .WP-unclaimed-gifts-img {
            width: 34px;
        }
    }

    .WP-unclaimed-gifts-3 {
        flex-wrap: wrap;
        padding: 10px 0;

        ${({ theme }) => theme.breakpoints.up('500')} {
            flex-wrap: initial;
            padding: 0;
        }

        .WP-unclaimed-gifts-img {
            width: 32px;
        }
    }

    .WP-unclaimed-gifts-1,
    .WP-unclaimed-gifts-2,
    .WP-unclaimed-gifts-3 {
        .WP-unclaimed-gifts-img {
            ${({ theme }) => theme.breakpoints.up('500')} {
                width: 54px;
            }
        }
    }
`;
