import { fetch } from '../../core/infra/dice-admin/FetchService';

import { shopSlice } from './shopSlice';
import globalSlice, { globalOp } from '../global';
import { getUserData } from '../global/operations';
import { getStorageItem, removeStorageItem } from '../../helpers/localStorage';
import { getSessionToken } from '../../bridge';
import {
    DAILY_GIFT_MODAL,
    INSUFFICIENT_MODAL,
    PAYMENT_FAILURE_MODAL,
    PAYMENT_SUCCESS_MODAL,
    PAYMENT_FAILURE_MODAL_DATA,
    PAYMENT_SUCCESS_MODAL_DATA,
    DAILY_GIFT_MODAL_DATA,
} from '../../constants/modals.constants';
import { dailyDealsSlice } from '../dailyDeals/dailyDealsSlice';
import { setGAEvent } from '../../helpers/GA4Helper';
import { playgamiPayments } from '../../configs/payments.config';
import { PurchaseRequest } from '@scopely/web-sdk-payments';
import { titanAnalyticsEvents } from '../../helpers/titanAnalytics';
import { PurchaseStatus } from '@scopely/web-sdk-payments/dist/src/model/payment.model';

const env = process.env.REACT_APP_ENVIRONMENT;
const isDebug = env !== 'production';

const getOffers = () => {
    const SessionToken = getSessionToken();
    const { setOffers, setLoading, setLimits, setSections, setPurchases, setVersion } =
        shopSlice.actions;
    const { setTabsLoading } = globalSlice.actions;

    return async (dispatch) => {
        try {
            dispatch(setLoading(true));
            dispatch(setTabsLoading(true));
            dispatch(setOffers());
            const body = SessionToken
                ? { StoreFrontMappingOverrides: 'DefaultWebPortalStore' }
                : { StoreFrontMappingName: 'AnonymousWebPortalStore' };

            const { data } = await fetch({
                endPoint: SessionToken ? 'store' : 'store/web',
                method: 'GET',
                params: {
                    ...body,
                    SessionToken,
                    BypassSignature: !SessionToken,
                    c_store: 'xsolla',
                    c_platform: 'web',
                    c_bundle: 'yahtzeenux',
                },
            });

            if (data) {
                dispatch(setVersion(data?.Data.Store.Version));

                const {
                    WebBonusRolls = { StoreFront: [] },
                    WebExclusiveVanity = { StoreFront: [] },
                    WebFeatured = { StoreFront: [] },
                    WebLoyaltyPoint = { StoreFront: [] },
                    WebFreeDailyGift = { StoreFront: [] },
                    WebSticker = { StoreFront: [] },
                    WebLoyaltyBoosts = { StoreFront: [] },
                    WebLoyaltyBonusRoll = { StoreFront: [] },
                    WebLoyaltyPointsStoreEnergy = { StoreFront: [] },
                    WebEnergySKUs = { StoreFront: [] },
                    WebLoyaltySticker = { StoreFront: [] },
                    WebTopPlayers = { StoreFront: [] },
                    WebTopFamilies = { StoreFront: [] },
                    WebGameModes = { StoreFront: [] },
                    WebLeaderboards = { StoreFront: [] },
                } = data?.Data.Store.StoreFronts;

                let CommodityItems = new Set();

                const offers = {
                    WebBonusRolls,
                    WebExclusiveVanity,
                    WebFeatured,
                    WebLoyaltyPoint,
                    WebFreeDailyGift,
                    WebSticker,
                    WebLoyaltyBoosts,
                    WebLoyaltyBonusRoll,
                    WebLoyaltyPointsStoreEnergy,
                    WebEnergySKUs,
                    WebLoyaltySticker,
                    WebTopPlayers,
                    WebTopFamilies,
                    WebGameModes,
                    WebLeaderboards,
                };

                const sections = !SessionToken
                    ? [
                          'WebFeatured',
                          'WebOffers',
                          'WebDailyDeals',
                          'WebUserGuides',
                          'WebTopPlayers',
                          'WebTopFamilies',
                          'WebGameModes',
                      ]
                    : [
                          'WebDailyDeals',
                          'WebOffers',
                          'WebUserGuides',
                          'WebTopPlayers',
                          'WebTopFamilies',
                          'WebGameModes',
                          'WebLeaderboards',
                      ];
                const Purchases = data?.Data.PurchaseCounts?.UserStoreListingPurchaseCounts;

                for (const i in offers) {
                    const listing = offers[i]?.StoreFront?.Listings;
                    if (!listing || !listing.length) continue;
                    sections.push(i);

                    for (const element of listing) {
                        const purhcaseCount = Purchases[element?.StoreListing?.Name];

                        element.StoreListing['availableCount'] = purhcaseCount
                            ? element.StoreListing.PurchaseLimitPerCustomer - purhcaseCount
                            : element?.StoreListing?.PurchaseLimitPerCustomer;
                        for (const commodity of element?.StoreListing?.Credit?.Adjustments)
                            CommodityItems.add(commodity['CommodityKey']);
                    }
                }
                dispatch(setOffers(offers));

                let Limits = data?.Data?.PurchaseCounts?.LimitedPurchaseCountsWithResetTime;

                if (Limits?.FreeDailyWheel?.ResetTime) {
                    let changeTime =
                        new Date(Limits?.FreeDailyWheel?.ResetTime).getTime() - 60 * 1000;
                    Limits = {
                        ...Limits,
                        FreeDailyWheel: {
                            ...Limits?.FreeDailyWheel,
                            ResetTime: new Date(changeTime),
                        },
                    };
                }

                dispatch(setLimits(Limits));
                dispatch(setPurchases(Purchases));
                dispatch(setSections(sections));
                dispatch(getCommoditiesList(CommodityItems));
                dispatch(setLoading(false));
            }
        } catch (e) {
            dispatch(
                globalOp.handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'Not available order',
                }),
            );
            dispatch(setLoading(false));
        } finally {
            if (!SessionToken)
                setTimeout(() => {
                    dispatch(setTabsLoading(false));
                }, 200);
        }
    };
};

const getCommoditiesList = (CommodityItems) => {
    const { setCommodities } = shopSlice.actions;
    const myArr = Array.from(CommodityItems).join(',');
    return async (dispatch) => {
        const commoditiesData = await fetch({
            endPoint: `commodities/web/list`,
            method: 'GET',
            params: {
                BypassSignature: true,
                commodityKeys: myArr,
            },
        });

        if (commoditiesData?.data?.Data?.Assets) {
            const result = getCommoditiesAssets(commoditiesData?.data?.Data?.Assets);
            dispatch(setCommodities(result));
        }
    };
};

const getXsollaToken = (offerData, setXsollaToken, callback) => {
    const SessionToken = getSessionToken();

    return async (dispatch) => {
        try {
            //dispatch(setOffer(offerData));
            const { data } = await fetch({
                endPoint: `xsolla/token`,
                method: 'POST',
                params: {
                    BypassSignature: false,
                    SessionToken,
                    c_platform: 'web',
                    c_store: 'xsolla',
                    c_bundle: 'yahtzeenux',
                },
                body: offerData,
            });
            setXsollaToken(data.Data.Token);

            document.querySelector('#root').classList.remove('user-select-none');
        } catch (e) {
            document.querySelector('#root').classList.remove('user-select-none');
            removeStorageItem('processingOrder');
            removeStorageItem('orderInProgress');
            setXsollaToken('');

            dispatch(
                globalOp.handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'Not available order',
                }),
            );
        } finally {
            setTimeout(() => {
                callback(false);
            }, 400);
        }
    };
};

// This method is very similar to the event listeners for XSolla in useXsollaLoad.hook.js. We didn't refactor the
// code for both purchase mechanisms to be able to roll back with a feature flag
const playgamiPurchaseSuccess = (dispatch, pathname) => {
    document.querySelector('#root').classList.remove('user-select-none');
    // Processing order might be null if the user goes offline before accepting the order receipt, because
    // the web reloads, and wipes the local storage elements (including processingOrder).
    const processingOrder = getStorageItem('processingOrder');
    const isLoyaltyPointsSection = processingOrder?.item?.section === 'loyalty_points';
    dispatch(globalOp.getUserData());
    dispatch(
        globalOp.handleOpenModal({
            open: true,
            variant: PAYMENT_SUCCESS_MODAL,
            message: `${isLoyaltyPointsSection ? '.loyalty.points' : ''}`,
            data: PAYMENT_SUCCESS_MODAL_DATA,
        }),
    );
    window?.DD_RUM?.addAction('xsolla-payments-value', {
        price: Math.round(processingOrder?.item?.price),
    });

    setGAEvent('impression', {
        screen_name: `${
            pathname === '/' ? '/home_purchase_confirmation' : `${pathname}_purchase_confirmation`
        }`,
        screen_type: 'pop_up',
        screen_section: processingOrder?.item?.section,
        offer_id: processingOrder?.customParameters?.storeListingName,
    });

    removeStorageItem('processingOrder');
    removeStorageItem('orderInProgress');
};

const playgamiPurchaseIncomplete = () => {
    document.querySelector('#root').classList.remove('user-select-none');

    removeStorageItem('processingOrder');
    removeStorageItem('orderInProgress');
};

// This method is very similar to the event listeners for XSolla in useXsollaLoad.hook.js. We didn't refactor the
// code for both purchase mechanisms to be able to roll back with a feature flag
const playgamiPurchaseFailure = (dispatch, pathname, html) => {
    document.querySelector('#root').classList.remove('user-select-none');

    titanAnalyticsEvents.xsolla_payment({
        success: 'false',
        error: 'status troubled',
        // TODO: what data should we send here?
        // ...res?.paymentInfo,
    });

    html.remove('disable-scroll');
    const processingOrder = getStorageItem('processingOrder');

    setGAEvent('clickEvent', {
        screen_name: `${
            pathname === '/' ? '/home_purchase_failed' : `${pathname}_purchase_failed`
        }`,
        screen_type: 'pop_up',
        screen_section: processingOrder?.item?.section,
        offer_id: processingOrder?.item.name,
    });

    dispatch(
        globalOp.handleOpenModal({
            open: true,
            variant: PAYMENT_FAILURE_MODAL,
            data: PAYMENT_FAILURE_MODAL_DATA,
        }),
    );

    removeStorageItem('processingOrder');
    removeStorageItem('orderInProgress');
};

const doPlaygamiPurchase = (storeListingName, displayName, callback) => {
    const pathname = window.location.pathname;
    return async (dispatch) => {
        try {
            // userId will be filled by the backend delegate
            const purchaseRequest = new PurchaseRequest(
                'not_the_user_id',
                storeListingName,
            ).withMetadata({
                DisplayName: displayName,
            });
            const result = await playgamiPayments.startPurchase(purchaseRequest);
            if (
                result.status === PurchaseStatus.SUCCESSFUL ||
                result.status === PurchaseStatus.PENDING_CONFIRMATION
            ) {
                playgamiPurchaseSuccess(dispatch, pathname);
            } else if (result.status === PurchaseStatus.INCOMPLETE) {
                playgamiPurchaseIncomplete(dispatch, pathname);
            } else if (result.status === PurchaseStatus.FAILED) {
                throw new Error('Payment failed');
            }
        } catch (e) {
            playgamiPurchaseFailure(
                dispatch,
                pathname,
                document.getElementsByTagName('html')[0].classList,
            );
        } finally {
            setTimeout(() => {
                callback(false);
            }, 400);
        }
    };
};
const getCommoditiesAssets = (data) => {
    const result = {};
    for (const commodity of data) {
        result[commodity.CommodityKey] = {
            name: commodity?.PluralName || commodity?.CommodityName,
            image: commodity?.IconImageUrl,
        };
    }
    return result;
};

const makePurchase = (offerData, { storeListingName, iapSku = null, promoId = null }, callback) => {
    const SessionToken = getSessionToken();
    const { setLoading } = shopSlice.actions;
    const { setLoading: dailyDealsLoading } = dailyDealsSlice.actions;
    return async (dispatch) => {
        try {
            //TODO: change isDebug before launch
            const endPoint =
                storeListingName === 'DailyDealsFTUE'
                    ? 'daily-deals/ftue/purchase'
                    : 'store/internal/purchase';
            const { data } = await fetch({
                endPoint,
                method: 'POST',
                params: {
                    BypassSignature: false,
                    SessionToken,
                    c_bundle: 'yahtzeenux',
                    c_store: 'xsolla',
                    c_platform: 'web',
                    c_nonce: +new Date(),
                },
                body: {
                    storeListingName: storeListingName,
                    storeReceiptData: storeListingName,
                    storeReceiptSignature: storeListingName,
                    isDebug: isDebug,
                    analyticsData: {
                        referenceGlobalId: 'store_web',
                        itemInstanceId: iapSku,
                        promoId,
                    },
                },
            });

            if (data?.IsSuccess) {
                dispatch(setLoading(true));
                dispatch(dailyDealsLoading(true));
                dispatch(getUserData());
                dispatch(
                    globalOp.handleOpenModal({
                        open: true,
                        variant:
                            offerData.section === 'daily_gift'
                                ? DAILY_GIFT_MODAL
                                : PAYMENT_SUCCESS_MODAL,
                        data:
                            offerData.section === 'daily_gift'
                                ? DAILY_GIFT_MODAL_DATA
                                : PAYMENT_SUCCESS_MODAL_DATA,
                    }),
                );

                offerData.section === 'daily_gift'
                    ? setGAEvent('impression', {
                          screen_name: 'store_daily_gift_claimed',
                          screen_type: 'pop-up',
                          screen_section: 'bonus_rolls',
                          offer_id: data?.Data?.PurchasedStoreListing.Name,
                      })
                    : setGAEvent('impression', {
                          screen_name: 'loyalty_point_purchase_confirmation',
                          screen_type: 'pop_up',
                          screen_section: offerData?.section,
                          offer_id: storeListingName,
                      });
                removeStorageItem('processingOrder');
                removeStorageItem('orderInProgress');
            }
        } catch (err) {
            if (err?.data?.Error?.ErrorCode === 15021) {
                //for insufficient balance error
                setGAEvent('impression', {
                    screen_name: 'loyalty_point_not_enough',
                    screen_type: 'pop-up',
                    screen_section: offerData?.section,
                    offer_id: offerData?.name,
                });

                dispatch(
                    globalOp.handleOpenModal({
                        open: true,
                        variant: INSUFFICIENT_MODAL,
                    }),
                );
            } else if (err?.data.code === 12030) {
                removeStorageItem('processingOrder');
                removeStorageItem('orderInProgress');
                return false;
            } else {
                //for rest errors
                setGAEvent('impression', {
                    screen_name: 'store_purchase_failed',
                    screen_type: 'pop-up',
                    screen_section: offerData?.section,
                    offer_id: offerData?.name || '',
                });
                dispatch(
                    globalOp.handleOpenModal({
                        open: true,
                        variant: PAYMENT_FAILURE_MODAL,
                        data: PAYMENT_FAILURE_MODAL_DATA,
                    }),
                );
            }
            removeStorageItem('processingOrder');
            removeStorageItem('orderInProgress');
        } finally {
            setTimeout(() => {
                callback(false);
            }, 300);
        }
    };
};

const handleOfferData = (data) => {
    const { setOfferData } = shopSlice.actions;

    return (dispatch) => dispatch(setOfferData(data));
};

export const shopOp = {
    getXsollaToken,
    doPlaygamiPurchase,
    getOffers,
    makePurchase,
    handleOfferData,
    getCommoditiesList,
};
