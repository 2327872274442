import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../hooks/useTranslation';

import { Typography } from 'portal-modules/core-ui';
import { Box } from '@material-ui/core';

import { MailChimpStyled } from './MailChimp.styled';
import CustomCheckbox from './CustomCheckbox/CustomCheckbox';
import { MAILCHIMP_URL } from '../../../constants/api.constants';
import { SCOPELY_URLS } from '../../../constants/scopely.constants';
import { setGAEvent } from '../../../helpers/GA4Helper';
import { globalSel } from '../../../store/global';

const Subscribe = () => {
    const theme = useSelector(globalSel.themeSelector);
    const t = useTranslation();
    const pathname = window.location.pathname;

    const [emailInput, setEmailInput] = useState('');
    const [checked, setChecked] = useState(false);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setEmailInput(value);
    };

    const handleCheckboxChange = (e) => {
        setChecked(e.target.checked);
    };

    const setDefaultValues = () => {
        setTimeout(() => {
            setEmailInput('');
            setChecked(false);
        }, 100);

        setGAEvent('clickEvent', {
            screen_name: `${pathname === '/' ? '/home' : pathname}`,
            screen_type: 'screen',
            screen_section: 'right_menu',
            interaction_object: 'subscribe',
            interaction_object_category: 'display',
        });
    };

    return (
        <MailChimpStyled>
            <Box id="mc_embed_signup" py={15}>
                <form
                    action={MAILCHIMP_URL}
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate"
                    onSubmit={setDefaultValues}
                    target="_blank"
                    noValidate
                >
                    <Box id="mergeRow-gdpr">
                        <Box pb={2}>
                            <Typography
                                className="text-uppercase"
                                variant="p12"
                                fWeight="bold"
                                color={theme.palette.brown.main}
                            >
                                {t('join.our.mailing.list!')}
                            </Typography>
                        </Box>

                        <Box pb={5}>
                            <Typography variant="p12" color={theme.palette.brown.A700}>
                                {t('sign.up.for.special.updates')}
                            </Typography>
                        </Box>

                        <Box>
                            <Box pb={2}>
                                <Typography
                                    variant="p12"
                                    fWeight="semiBold"
                                    color={theme.palette.brown.main}
                                >
                                    {t('email')}
                                </Typography>
                            </Box>
                            <Box
                                component="input"
                                type="email"
                                id="mce-EMAIL"
                                name="EMAIL"
                                className="WP-MailChimp-input required email"
                                width="100%"
                                height={40}
                                color={theme.palette.brown.A700}
                                border={2}
                                borderColor={theme.palette.orange.A500}
                                borderRadius={10}
                                px={3}
                                py={5}
                                value={emailInput}
                                placeholder={t('email.address')}
                                autoComplete="off"
                                onChange={handleInputChange}
                                mb={5}
                            />
                        </Box>
                    </Box>
                    <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                        <input
                            type="text"
                            name="b_31d1c97a8c087bb5600673c63_e66edfa995"
                            tabIndex="-1"
                            defaultValue=""
                        />
                    </div>

                    <CustomCheckbox
                        checked={checked}
                        callback={handleCheckboxChange}
                        checkboxName={{
                            __html: t('right.panel.bottom.info.text', '', {
                                privacyValue: `<a href=${
                                    SCOPELY_URLS.PRIVACY_POLICY
                                } target="_blank" rel="noreferrer"> ${t('privacy.policy')}</a>`,
                                termsValue: `<a href=${
                                    SCOPELY_URLS.TERMS
                                } target="_blank" rel="noreferrer"> ${t('terms.of.service')}</a>`,
                            }),
                        }}
                    />

                    <Box pt={5}>
                        <Box
                            id="mc-embedded-subscribe"
                            name="subscribe"
                            component="button"
                            type="submit"
                            width={1}
                            height={38}
                            fontWeight={600}
                            fontSize={theme.typography.size.p10.fontSize}
                            bgcolor={theme.palette.orange.A800}
                            border="none"
                            borderRadius={10}
                            px={11}
                            py={4}
                            disabled={!emailInput && !checked}
                            className={`${
                                emailInput && checked
                                    ? 'WP-mailchimp-submit-btn cursor-pointer'
                                    : 'WP-mailchimp-disable-submit-btn touch-action-none'
                            } flex-center text-uppercase`}
                        >
                            {t('subscribe')}
                        </Box>
                    </Box>
                </form>
            </Box>
        </MailChimpStyled>
    );
};

export default Subscribe;
