import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../hooks/useTranslation';
import PropTypes from 'prop-types';

import { Box, useMediaQuery } from '@material-ui/core';

import WrapperSkeleton from '../OfferCardSkeleton/WrapperSkeleton';
import SectionTitle from '../SectionTitle/SectionTitle';
import SeeMoreItems from '../SeeMoreItems/SeeMoreItems';

import { globalSel } from '../../../store/global';
import { shopSel } from '../../../store/shop';

const Stickers = ({ sectionTitle, sectionSubtitle, selector, limit, limitSkeleton }) => {
    const t = useTranslation();
    const section = useSelector(shopSel[selector]);
    const isLoading = useSelector(shopSel.isLoading);

    const theme = useSelector(globalSel.themeSelector);
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });

    const data = section?.Listings || [];

    return (
        <Box
            component="section"
            className="WP-stickers WP-hide-empty-space"
            pb={[10, 12, 12, 16, 20]}
        >
            {isLoading ? (
                <WrapperSkeleton limitSkeleton={limitSkeleton} />
            ) : !data.length ? null : (
                <>
                    <SectionTitle
                        title={t(sectionTitle)}
                        subtitle={t(sectionSubtitle)}
                        customIcon="sticker-offers"
                    />

                    <SeeMoreItems
                        section="stickers_and_more"
                        data={data}
                        limit={limit}
                        spacing={breakpointsXL ? 10 : breakpointsLG ? 5 : 4}
                    />
                </>
            )}
        </Box>
    );
};

Stickers.propTypes = {
    sectionTitle: PropTypes.string,
    sectionSubtitle: PropTypes.string,
    selector: PropTypes.string,
    limit: PropTypes.number,
    limitSkeleton: PropTypes.array,
};

export default Stickers;
