import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const Date = ({ date, format = 'DD.MM.YY' }) => {
    if (!date) return false;
    switch (format) {
        case 'fromNow':
            return <span>{moment(date, 'YYYYMMDD').fromNow()}</span>;
        default:
            return <span>{moment(date).format(format)}</span>;
    }
};

Date.propTypes = {
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    format: PropTypes.string,
};

export default Date;
