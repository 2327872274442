import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, Typography } from 'portal-modules/core-ui';
import { Box, Hidden } from '@material-ui/core';

import AddBalance from './components/AddBalance/AddBalance';
import PlayerInfo from './components/PlayerInfo';
import PlayerAvatar from './components/PlayerAvatar';
import UserProfileSkeleton from './components/UserProfileSkeleton/UserProfileSkeleton';
import { thousandsFormatterWithDots } from '../../../../helpers/utils';
import { ADDBALLANCETYPE } from '../../../../enums/enums';
import globalSlice, { globalSel } from '../../../../store/global';
import { useTranslation } from '../../../../hooks/useTranslation';

import loyaltyPoints from '../../../../assets/images/Global/loyaltyPoints.png';
import bonusRoll from '../../../../assets/images/Global/bonusRolls.png';
import energies from '../../../../assets/images/Global/Energy.png';
import { energyEnabledGetter } from '../../../../core/application/config';

const UserProfile = () => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const theme = useSelector(globalSel.themeSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);
    const isProfileLoading = useSelector(globalSel.isProfileLoading);

    const [energyEnabled, setEnergyEnabled] = useState(false);

    const closeRightSidebar = () => {
        dispatch(globalSlice.actions.setSidebar({ side: 'rightSidebar', open: false }));
    };

    const fetchEnergyEnabled = async () => {
        const res = await energyEnabledGetter.execute();
        setEnergyEnabled(res);
    };

    useEffect(() => {
        fetchEnergyEnabled();
    }, []);

    return (
        <Box mt={25}>
            <Hidden mdUp>
                <Box
                    color={theme.palette.brown.A400}
                    position="absolute"
                    top="20px"
                    display="flex"
                    alignItems="center"
                    onClick={closeRightSidebar}
                >
                    <Icon className="icon-close cursor-pointer" size={18} />
                </Box>
            </Hidden>

            {isProfileLoading ? (
                <UserProfileSkeleton />
            ) : (
                <Box textAlign="center" justifyContent="center">
                    <PlayerAvatar />

                    <Box className="WP-level-badge flex-center">
                        <Typography fWeight="bold" variant="p14">
                            {currentUser.Level}
                        </Typography>
                    </Box>

                    <Box
                        height={56}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        bgcolor={theme.palette.common.white}
                        borderRadius={8}
                        px={3}
                        className="right-side-balance"
                    >
                        <PlayerInfo
                            value={thousandsFormatterWithDots(currentUser.BonusRollCount)}
                            title={t('bonus.rolls')}
                            image={bonusRoll}
                        />
                        <AddBalance
                            type={ADDBALLANCETYPE.BR}
                            dataTestId="bonus-rolls-plus-test-id"
                        />
                    </Box>

                    {energyEnabled && (
                        <Box
                            height={56}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            bgcolor={theme.palette.common.white}
                            borderRadius={8}
                            mt={3}
                            px={3}
                            className="right-side-balance"
                        >
                            <PlayerInfo
                                value={thousandsFormatterWithDots(currentUser.EntrySystemEnergy)}
                                title={t('energy.title')}
                                image={energies}
                            />
                            <AddBalance
                                type={ADDBALLANCETYPE.EN}
                                dataTestId="energy-plus-test-id"
                            />
                        </Box>
                    )}

                    <Box
                        height={56}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        bgcolor={theme.palette.common.white}
                        borderRadius={8}
                        mt={3}
                        px={3}
                        className="right-side-LP"
                    >
                        <PlayerInfo
                            value={thousandsFormatterWithDots(currentUser.LoyaltyPoints)}
                            title={t('loyalty.points')}
                            image={loyaltyPoints}
                        />
                        <AddBalance
                            type={ADDBALLANCETYPE.LP}
                            dataTestId="loyalty-points-plus-test-id"
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default UserProfile;
