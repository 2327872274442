export const icons = `
.icon-arrow:before {
  content: "\\e900";
}
.icon-bell:before {
  content: "\\e901";
}
.icon-booster:before {
  content: "\\e902";
}
.icon-calendar:before {
  content: "\\e903";
}
.icon-checked-star:before {
  content: "\\e904";
}
.icon-close:before {
  content: "\\e905";
}
.icon-coin:before {
  content: "\\e906";
}
.icon-crown:before {
  content: "\\e907";
}
.icon-cup:before {
  content: "\\e908";
}
.icon-dashboard:before {
  content: "\\e909";
}
.icon-default-user:before {
  content: "\\e90a";
}
.icon-dice:before {
  content: "\\e90b";
}
.icon-edit:before {
  content: "\\e90c";
}
.icon-facebook:before {
  content: "\\e90d";
}
.icon-families:before {
  content: "\\e90e";
}
.icon-flesh:before {
  content: "\\e90f";
}
.icon-games:before {
  content: "\\e910";
}
.icon-home:before {
  content: "\\e911";
}
.icon-info:before {
  content: "\\e912";
}
.icon-instagram:before {
  content: "\\e913";
}
.icon-leaderboard:before {
  content: "\\e914";
}
.icon-lock:before {
  content: "\\e915";
}
.icon-login-icon:before {
  content: "\\e916";
}
.icon-logout:before {
  content: "\\e917";
}
.icon-loyality-point:before {
  content: "\\e918";
}
.icon-news:before {
  content: "\\e919";
}
.icon-pacman:before {
  content: "\\e91a";
}
.icon-play-left:before {
  content: "\\e91b";
}
.icon-play-right:before {
  content: "\\e91c";
}
.icon-rate:before {
  content: "\\e91d";
}
.icon-refresh:before {
  content: "\\e91e";
}
.icon-search:before {
  content: "\\e91f";
}
.icon-star:before {
  content: "\\e920";
}
.icon-stats-line:before {
  content: "\\e921";
}
.icon-stats:before {
  content: "\\e922";
}
.icon-store-outlined:before {
  content: "\\e923";
}
.icon-store:before {
  content: "\\e924";
}
.icon-support:before {
  content: "\\e925";
}
.icon-twitter:before {
  content: "\\e926";
}
.icon-usd:before {
  content: "\\e927";
}
.icon-user-single-select:before {
  content: "\\e928";
}
.icon-user:before {
  content: "\\e929";
}
.icon-userInfo:before {
  content: "\\e92a";
}
.icon-vanity:before {
  content: "\\e92b";
}
.icon-webcoin:before {
  content: "\\e92c";
}
.icon-yahtzee:before {
  content: "\\e92d";
}
`;
