export const LOGINBUTTONTYPE = {
    FB: 1,
    SECONDARY: 2,
};

export const ADDBALLANCETYPE = {
    LP: 1,
    BR: 2,
    EN: 3,
};

export const LEADERBOARDSTATS = {
    FAMILY: 1,
    PLAYERS: 2,
};

export const BOARDCATEGORIES = {
    1: 'players',
    2: 'families',
};

export const LABELTYPE = {
    1: 'purple',
    2: 'red',
    3: 'blue',
    4: 'green',
    5: 'orange',
    6: 'brown',
    7: 'yellow',
};
