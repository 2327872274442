import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Typography } from 'portal-modules/core-ui';
import { Box } from '@material-ui/core';

import { globalSel } from '../../../../../store/global';

function PlayerInfo({ image, title, value }) {
    const theme = useSelector(globalSel.themeSelector);
    return (
        <Box display="flex" alignItems="center">
            <Box height={40} width={40} borderRadius={50} overflow="hidden">
                <Box component="img" src={image} width={1} height={1} alt="img" />
            </Box>
            <Box pl={2} textAlign="start">
                <Typography variant="p12" fWeight="semiBold" color={theme.palette.brown.A500}>
                    {title}
                </Typography>
                <Typography variant="p12" fWeight="semiBold" color={theme.palette.brown.main}>
                    {value}
                </Typography>
            </Box>
        </Box>
    );
}
PlayerInfo.propTypes = {
    image: PropTypes.any,
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hasAmount: PropTypes.bool,
};

export default PlayerInfo;
