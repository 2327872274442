import styled from 'styled-components';
// import bgEffect from '../../../assets/images/search-result-bg-effect.svg';

export const HeaderStyled = styled.header`
    height: 61px;

    z-index: ${({ theme }) => theme.zIndex.appBar};
    background-color: #ffc352;

    padding: 0 24px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        height: 54px;

        position: fixed;
        top: 0;
        right: 0;
        left: 0;

        border-bottom: 3px solid ${({ theme }) => theme.palette.yellow.main};
        background-image: linear-gradient(
            90deg,
            ${({ theme }) => theme.palette.red.main} 0%,
            ${({ theme }) => theme.palette.orange.main} 100%
        );
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
        padding: 0 20px;
    }

    .WP-notification-bell {
        ${({ theme }) => theme.breakpoints.up('md')} {
            position: absolute;
            right: 310px;
        }
    }

    .WP-notification-count {
        width: 20px;
        height: 20px;

        position: absolute;
        top: -10px;
        right: -10px;
        z-index: 1;

        border-radius: 50%;
        border: 2px solid ${({ theme }) => theme.palette.common.white};
    }

    .WP-loyalty-points-amount {
        background: rgba(1, 1, 1, 0.37);
    }

    .WP-header-user-image {
        > img {
            object-fit: cover;
        }

        .WP-user-icon {
            color: ${({ theme }) => theme.palette.orange.A200};
        }
    }

    .icon-notif {
        position: relative;
        top: -7px;
        left: -26px;
        width: 12px;
        height: 12px;
        background-color: #f05454;
        border: 2px solid #fffaf1;
        border-radius: 50%;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
    }
`;
