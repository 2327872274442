import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';

import { useTheme } from '@material-ui/core/styles';

const LpOnboarding = ({ data }) => {
    const theme = useTheme();

    return (
        <Box className="WP-onboarding" maxWidth={['100%', 650]}>
            <Box
                component="img"
                src={data?.ImageUrl}
                alt="onboarding"
                className="object-cover"
                height={[160, 160, 160, 250, 250]}
                width="100%"
            />

            <Box pt={4} px={4}>
                <Box
                    component={Typography}
                    variant={['h5', 'h5', 'h5', 'h5', 'h4']}
                    fWeight="bold"
                    color={theme.palette.brown.main}
                    mb={3}
                >
                    {data?.TitleText}
                </Box>

                <Box
                    component={Typography}
                    fWeight="semiBold"
                    variant="p16"
                    color={theme.palette.brown.A600}
                >
                    {data?.BodyText}
                </Box>
            </Box>
        </Box>
    );
};
LpOnboarding.propTypes = {
    data: PropTypes.object,
};
export default LpOnboarding;
