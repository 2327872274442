import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Box, Hidden } from '@material-ui/core';
import { Icon, Typography } from 'portal-modules/core-ui';

import PageHeaderSkeleton from './PageHeaderSkeleton';

import IconCustom from '../IconCustom/IconCustom';
import { globalSel } from '../../../store/global';
import { shopSel } from '../../../store/shop';
import { firstLatterOfTextToUppercase } from '../../../helpers/utils';
import AnchorsMenu from '../AnchorsMenu/AnchorsMenu';

const PageHeader = ({
    title,
    subtitle,
    desc,
    icon,
    customIcon,
    isLoading = undefined,
    anchors = undefined,
}) => {
    const theme = useSelector(globalSel.themeSelector);
    const isLoadingGlobal = useSelector(shopSel.isLoading);

    isLoading = isLoading !== undefined ? isLoading : isLoadingGlobal;

    return (
        <Box mb={[8, 12, 12]}>
            {isLoading ? (
                <PageHeaderSkeleton />
            ) : (
                <Box
                    display="flex"
                    alignItems={['baseline', 'center']}
                    justifyContent="space-between"
                    flexDirection={['column', 'row']}
                >
                    <Box mb={[2, 0]}>
                        <Box
                            variant={['h4', 'h4', 'h4', 'h3', 'h2']}
                            fWeight="bold"
                            color={theme.palette.brown.main}
                            component={Typography}
                            display="flex"
                            alignItems="center"
                        >
                            {icon && (
                                <Box
                                    component={Icon}
                                    size={28}
                                    className={icon}
                                    color={theme.palette.brown.main}
                                    mr={2}
                                />
                            )}
                            {customIcon && (
                                <IconCustom
                                    icon={customIcon}
                                    size={28}
                                    color={theme.palette.brown.main}
                                    className="WP-mr-8"
                                />
                            )}

                            {firstLatterOfTextToUppercase(title)}
                        </Box>

                        <Box
                            variant="p16"
                            fWeight="semiBold"
                            color={theme.palette.brown.main}
                            component={Typography}
                        >
                            {subtitle}
                        </Box>

                        <Hidden xsDown>
                            <Box
                                variant="p12"
                                color={theme.palette.brown.main}
                                component={Typography}
                            >
                                {desc}
                            </Box>
                        </Hidden>
                    </Box>
                </Box>
            )}
            {!isLoading && anchors && anchors.length > 0 && <AnchorsMenu anchors={anchors} />}
        </Box>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    desc: PropTypes.string,
    icon: PropTypes.string,
    customIcon: PropTypes.string,
    isLoading: PropTypes.bool,
    anchors: PropTypes.array,
};

export default PageHeader;
