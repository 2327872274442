import styled from 'styled-components';

export const AnchorsMenuStyled = styled.div`
    width: 100%;

    .WP-anchors-menu-placeholder {
        width: 100%;
        height: 70px;

        @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
            height: 50px;
        }
    }

    .WP-anchors-menu {
        overflow: hidden;
        padding: 20px 0px;

        background-color: #ffc352;

        z-index: 1000;

        display: flex;
        justify-content: center;

        position: relative;

        width: calc(100% + 32px + 32px);
        left: -32px;

        ${({ theme }) => theme.breakpoints.down('xs')} {
            width: 100vw;
            left: -20px;

            display: block;
            justify-content: normal;
            padding: 8px 0px;
        }
    }

    .fixed {
        position: fixed;
        top: 0px;
        left: -52px;
        height: 70px;

        ${({ theme }) => theme.breakpoints.down('sm')} {
            top: 51px;
            left: -32px;
        }

        ${({ theme }) => theme.breakpoints.down('xs')} {
            left: 0px;
            height: 50px;
        }
    }

    .WP-anchors-menu-slider {
        .swiper-button-prev:after,
        .swiper-button-next:after {
            width: 24px;
            height: 24px;
            background-size: 80%;
            @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
                right: -4px;
            }
        }

        .swiper-slide {
            width: auto;
        }

        .swiper-wrapper > .swiper-slide:first-child {
            margin-left: 16px;
            @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
                margin-left: 10px;
            }
        }

        .swiper-wrapper > .swiper-slide:last-child {
            margin-right: 16px;
            @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
                margin-right: 10px;
            }
        }

        .WP-anchors-menu-box {
            text-align: center;
            border: 0px;

            font-weight: ${({ theme }) => theme.typography.weight.bold};
            font-size: ${({ theme }) => theme.typography.size.p12.fontSize};
            color: ${({ theme }) => theme.palette.brown.main};
            text-transform: capitalize;
        }
    }
`;
