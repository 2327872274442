import styled from 'styled-components';

export const MailChimpStyled = styled.div`
    .WP-MailChimp-input {
        font-size: ${({ theme }) => theme.typography.size.p16.fontSize};

        ${({ theme }) => theme.breakpoints.up('sm')} {
            font-size: ${({ theme }) => theme.typography.size.p12.fontSize};
        }
    }

    .WP-MailChimp-input::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${({ theme }) => theme.palette.brown.A700};
        opacity: 1; /* Firefox */
    }

    .WP-MailChimp-input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({ theme }) => theme.palette.brown.A700};
    }

    .WP-MailChimp-input::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({ theme }) => theme.palette.brown.A700};
    }

    .WP-mailchimp-submit-btn {
        color: ${({ theme }) => theme.palette.common.white};
    }

    .WP-mailchimp-disable-submit-btn {
        color: ${({ theme }) => theme.palette.common.white};
        opacity: 0.5;
    }

    a {
        text-decoration: underline;
        cursor: pointer;
        padding: 0 4px;
        color: ${({ theme }) => theme.palette.brown.A700};
    }

    .WP-mailchimp-bottom-text {
        font-size: ${({ theme }) => theme.typography.size.p12.fontSize};
        font-weight: ${({ theme }) => theme.typography.weight.regular};
    }
`;
