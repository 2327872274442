import styled from 'styled-components';

export const NotFoundStyled = styled.div`
    background: linear-gradient(180deg, #ff6827 0%, #ffb932 100%);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        top: 30px;
    }

    .image-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .WP-NotFound-image {
        margin-top: 90px;
        margin-bottom: 85px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            margin-top: 20px;
            margin-bottom: 50px;
        }

        ${({ theme }) => theme.breakpoints.up('md')} {
            margin-top: 40px;
        }

        ${({ theme }) => theme.breakpoints.up('lg')} {
            margin-top: 0;
            margin-bottom: 0;
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            margin-top: 10px;
            margin-bottom: 0;
        }

        ${({ theme }) => theme.breakpoints.down(375)} {
            margin-top: 90px;
        }
    }

    .WP-NotFound-logo {
        margin: 0;

        ${({ theme }) => theme.breakpoints.down('sm')} {
            width: 150px;
        }

        ${({ theme }) => theme.breakpoints.up('md')} {
            width: 200px;
        }

        ${({ theme }) => theme.breakpoints.up('lg')} {
            width: 250px;
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            width: 350px;
        }
    }

    .WP-NotFound-title {
        font-family: ${({ theme }) => theme.typography.font.inter};
        font-size: ${({ theme }) => theme.typography.size.h1.fontSize};
        font-weight: ${({ theme }) => theme.typography.fontWeightBold};

        ${({ theme }) => theme.breakpoints.down('sm')} {
            font-size: ${({ theme }) => theme.typography.size.p14.fontSize};
        }

        ${({ theme }) => theme.breakpoints.up('md')} {
            font-size: ${({ theme }) => theme.typography.size.h5.fontSize};
        }

        ${({ theme }) => theme.breakpoints.up('lg')} {
            font-size: ${({ theme }) => theme.typography.size.h4.fontSize};
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            font-size: ${({ theme }) => theme.typography.size.h1.fontSize};
        }
    }

    .WP-NotFound-subTitle {
        font-family: ${({ theme }) => theme.typography.font.inter};
        font-size: ${({ theme }) => theme.typography.size.p16.fontSize};
        font-weight: ${({ theme }) => theme.typography.medium};

        ${({ theme }) => theme.breakpoints.down('sm')} {
            font-size: ${({ theme }) => theme.typography.size.p12.fontSize};
        }

        ${({ theme }) => theme.breakpoints.up('md')} {
            font-size: ${({ theme }) => theme.typography.size.p12.fontSize};
        }

        ${({ theme }) => theme.breakpoints.up('lg')} {
            font-size: ${({ theme }) => theme.typography.size.p14.fontSize};
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            font-size: ${({ theme }) => theme.typography.size.p16.fontSize};
        }
    }
`;
