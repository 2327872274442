import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../hooks/useTranslation';
import PropTypes from 'prop-types';

import { Box, useMediaQuery } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';
import { Button } from '../ui-components/Button/Button';

import { LogOutStateStyled } from './LogOutState.styled';
import { globalOp, globalSel } from '../../../store/global';
import { LOGIN_MODAL } from '../../../constants/modals.constants';
import { setGAEvent } from '../../../helpers/GA4Helper';

const LogOutState = ({ bgImage, mainImage, additionalImage, section, isLogOutState }) => {
    const theme = useSelector(globalSel.themeSelector);
    const dispatch = useDispatch();
    const t = useTranslation();
    const pathname = window.location.pathname;

    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
    const breakpointsMac = useMediaQuery(theme.breakpoints.between(1780, 1800), {
        noSsr: true,
    });

    const handleModalOpen = () => {
        setGAEvent('clickEvent', {
            screen_name: `${pathname === '/' ? '/home' : pathname}`,
            screen_type: 'screen',
            screen_section: section,
            interaction_object: 'welcome_login',
            interaction_object_category: 'login',
        });

        return dispatch(
            globalOp.handleOpenModal({
                isLogOutState,
                open: true,
                variant: LOGIN_MODAL,
                data: {
                    image: 'login',
                    title: 'login.title',
                    description: 'login.description',
                    buttonText: 'login',
                    loginWithFb: 'true',
                },
            }),
        );
    };

    return (
        <Box
            component={LogOutStateStyled}
            bgImageUrl={bgImage}
            borderRadius={[14, 20, 20, 24, 30]}
            overflow="hidden"
            bgcolor={theme.palette.orange.A50}
            boxShadow={theme.elevation[100]}
            position="relative"
        >
            {additionalImage && (
                <Box
                    component="img"
                    src={additionalImage}
                    alt="image"
                    className="WP-additional-image-logout"
                />
            )}

            <Box
                className="WP-bg-logout flex-center"
                height={[125, 138, 133, breakpointsMac ? 206 : 163, 250]}
            >
                <Box component="img" src={mainImage} alt="bonus" className="WP-main-image-logout" />
            </Box>

            <Box
                component="figcaption"
                pt={[4, 3, 4, 5, 6]}
                px={[2, 3, 3, 4, 5]}
                pb={[2, 3, 3, 4, 5]}
            >
                <Box mb={[4, 5, 6, 8, 10]}>
                    <Box
                        component={Typography}
                        variant={['h5', 'h4', 'h4', 'h3', 'h2']}
                        fWeight="bold"
                        textAlign="center"
                        color={theme.palette.brown.main}
                    >
                        {t('welcome.to.yahtzee.title')}
                    </Box>

                    <Box
                        component={Typography}
                        variant={['p16', 'p16', 'p16', 'h6', 'h5']}
                        fWeight="medium"
                        textAlign="center"
                        color={theme.palette.brown.A600}
                    >
                        {t('welcome.to.yahtzee.text')}
                    </Box>
                </Box>

                <Button
                    onClick={handleModalOpen}
                    variant="secondary"
                    preventLoading={true}
                    size={
                        breakpointsXL
                            ? 'large'
                            : breakpointsLG
                            ? 'medium'
                            : breakpointsSM
                            ? 'small'
                            : 'extra-small'
                    }
                >
                    {t('login')}
                </Button>
            </Box>
        </Box>
    );
};

LogOutState.defaultProps = {
    additionalImage: null,
};

LogOutState.propTypes = {
    bgImage: PropTypes.string,
    mainImage: PropTypes.string,
    additionalImage: PropTypes.string,
    section: PropTypes.string,
    isLogOutState: PropTypes.bool,
};

export default LogOutState;
