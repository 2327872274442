import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../../hooks/useTranslation';
import { Link } from '@material-ui/core';
import { Box, Icon, Typography } from 'portal-modules/core-ui';
import { LeftSidebarFooterStyled } from './LeftSidebarFooter.styled';
import LangSwitcher from '../LangSwitcher/LangSwitcher';
import { globalSel } from '../../../../store/global';
import scopely from '../../../../assets/images/Logos/scopely.png';
import ExternalLinks from '../ExternalLinks/ExternalLinks';
import IconCustom from '../../../components/IconCustom/IconCustom';

function LeftSidebarFooter() {
    const theme = useSelector(globalSel.themeSelector);
    const t = useTranslation();

    return (
        <LeftSidebarFooterStyled>
            <ExternalLinks />
            <LangSwitcher />
            <Box mb={6} className="WP-social-links" display="flex" justifyContent="space-between">
                <Box
                    width={44}
                    height={44}
                    className="flex-center"
                    bgcolor={theme.palette.orange.A100}
                    borderRadius={6}
                >
                    <Link
                        href="https://www.facebook.com/YahtzeeWithBuddies"
                        underline="none"
                        target="_blank"
                    >
                        <Icon className="icon-facebook" size={32} />
                    </Link>
                </Box>

                <Box
                    width={44}
                    height={44}
                    className="flex-center"
                    bgcolor={theme.palette.orange.A100}
                    borderRadius={6}
                >
                    <Link
                        href="https://twitter.com/YahtzeeBuddies"
                        underline="none"
                        target="_blank"
                    >
                        <IconCustom icon="x-twitter" size={32} color={theme.palette.brown.main} />
                    </Link>
                </Box>

                <Box
                    width={44}
                    height={44}
                    className="flex-center"
                    bgcolor={theme.palette.orange.A100}
                    borderRadius={6}
                >
                    <Link
                        href="https://www.instagram.com/yahtzeewithbuddies/?hl=en"
                        underline="none"
                        target="_blank"
                    >
                        <Icon className="icon-instagram" size={32} />
                    </Link>
                </Box>
            </Box>

            <Link
                href="https://scopely.com/game/yahtzee-with-buddies/"
                underline="none"
                target="_blank"
            >
                <Box component="img" px={5} width="80%" height="24" src={scopely} />
            </Link>

            <Box className="WP-rights-reserved" mt={6}>
                <Typography variant="p12" color={theme.palette.brown.main}>
                    {t('permission.text')}
                </Typography>

                <Box mt={3} component={Typography} variant="p12" color={theme.palette.brown.main}>
                    {t('all.rights.reserved.text')}
                </Box>
                <Box mt={3} component={Typography} variant="p12" color={theme.palette.brown.main}>
                    {t('all.rights.reserved.scopely.text')}
                </Box>
            </Box>
        </LeftSidebarFooterStyled>
    );
}

export default LeftSidebarFooter;
