import { reducers } from './reducers';
import { configureStore } from '@reduxjs/toolkit';
import ReduxThunk from 'redux-thunk';

import { IS_DEVELOPMENT } from '../constants';
import logger from 'redux-logger';
import preloadedState from './preloadedState';

let store;
export const getStore = () => store;

export function Store() {
    const middleware = [ReduxThunk];
    if (process.env.NODE_ENV === 'development' && JSON.parse(localStorage.getItem('debug'))) {
        middleware.push(logger);
    }
    store = configureStore({
        reducer: reducers(),
        middleware,
        devTools: IS_DEVELOPMENT,
        enhancers: [],
        preloadedState,
    });

    return store;
}
