import styled from 'styled-components';

export const NewsSliderStyled = styled.div`
    &.WP-news-slider {
        overflow: hidden;

        margin-right: -32px;
        margin-left: -32px;
        padding: 0 32px 22px 32px;

        ${({ theme }) => theme.breakpoints.down('xs')} {
            margin-right: -20px;
            margin-left: -20px;
            padding: 0 20px 30px 20px;
        }
    }
`;
