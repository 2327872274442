import React from 'react';
import { Box } from '@material-ui/core';

import { useTranslation } from '../../../../hooks/useTranslation';
import DangerouslyHighlightedText from '../../../components/DangerouslyHighlightedText/DangerouslyHighlightedText';

import { RewardsStyled } from './Rewards.styled';

import art from '../../../../assets/images/Home/Logout/rewardArt.png';

const Rewards = () => {
    const t = useTranslation();

    return (
        <Box component={RewardsStyled} className="WP-logout-rewards">
            <Box component="img" src={art} alt={art} className="WP-reward-art" />

            <DangerouslyHighlightedText
                className="WP-reward-highlighted"
                text={t('logout.reward.content')}
            />
        </Box>
    );
};

export default Rewards;
