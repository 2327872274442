import styled from 'styled-components';
import HeaderBg from '../../../assets/images/DailyRewards/header_bg.png';
import HeaderDots from '../../../assets/images/DailyRewards/dots.png';
import Check from '../../../assets/images/DailyRewards/check.png';

export const DailyRewardsStyled = styled.div`
    position: relative;
    border-radius: 14px;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        border-radius: 20px;
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
        border-radius: 24px;
    }

    ${({ theme }) => theme.breakpoints.up('xl')} {
        border-radius: 30px;
    }

    .WP-body-container {
        //@media only screen and (min-width: 768px) and (orientation: landscape) {
        //    max-height: calc(100vh - 240px);
        //    border-radius: 0 0 20px 20px;
        //    overflow: auto;
        //}
        @media only screen and (max-width: 767px) and (orientation: landscape) {
            max-height: calc(100vh - 250px);
            border-radius: 0 0 20px 20px;
            overflow: auto;
        }
    }

    .WP-daily-body {
        background-color: #fffaf1;
        ${({ theme }) => theme.breakpoints.down('500')} {
            max-height: calc(100vh - 430px);
            overflow: auto;

            align-items: baseline;
        }
    }

    .WP-daily-footer {
        background-color: #fffaf1;

        border-radius: 0 0 14px 14px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            border-radius: 0 0 20px 20px;
        }

        ${({ theme }) => theme.breakpoints.up('lg')} {
            border-radius: 0 0 24px 24px;
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            border-radius: 0 0 30px 30px;
        }

        ${({ theme }) => theme.breakpoints.down('500')} {
            padding-top: 12px;
            box-shadow: 0px -4px 18px rgba(0, 0, 0, 0.1);
        }
    }

    .WP-daily-reward-before,
    .WP-daily-reward-after {
        width: 33px;
        height: 25px;

        position: absolute;
        top: 0;
        z-index: 11;

        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        background-color: rgba(110, 7, 105, 0.2);

        &:after {
            content: '';
            display: block;

            width: 25px;
            height: 42px;

            position: absolute;
            top: -21px;
            left: 50%;
            transform: translateX(-50%);

            background-color: #999;
            border-radius: 30px;
        }
    }

    .WP-daily-reward-before {
        left: 42px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            left: 58px;
        }
    }

    .WP-daily-reward-after {
        right: 42px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            right: 58px;
        }
    }

    .WP-daily-header {
        position: relative;

        border-radius: 14px 14px 0 0;
        background: linear-gradient(162.35deg, #ff5179 -1.14%, #c33bd9 73.13%, #8f00ff 132.13%);

        &:before {
            width: 100%;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;
            content: '';
            display: block;
            z-index: 1;

            background: url(${HeaderBg}) no-repeat center;
            background-size: cover;
            mix-blend-mode: multiply;
            border-radius: 14px 14px 0 0;

            ${({ theme }) => theme.breakpoints.up('sm')} {
                border-radius: 20px 20px 0 0;
            }

            ${({ theme }) => theme.breakpoints.up('lg')} {
                border-radius: 24px 24px 0 0;
            }

            ${({ theme }) => theme.breakpoints.up('xl')} {
                border-radius: 30px 30px 0 0;
            }
        }

        &:after {
            width: 100%;
            height: 13px;

            position: absolute;
            bottom: -6px;
            left: 0;
            content: '';

            background: url(${HeaderDots}) top;
            background-size: cover;
        }

        ${({ theme }) => theme.breakpoints.up('sm')} {
            border-radius: 20px 20px 0 0;
        }

        ${({ theme }) => theme.breakpoints.up('lg')} {
            border-radius: 24px 24px 0 0;
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            border-radius: 30px 30px 0 0;
        }

        ${({ theme }) => theme.breakpoints.down('375')} {
            .WP-header {
                font-size: 20px;
            }

            .WP-subheader {
                font-size: 12px;
            }
        }
    }

    .WP-daily-days {
        display: grid;
        grid-template-columns: auto auto auto auto;

        ${({ theme }) => theme.breakpoints.down('600')} {
            grid-template-columns: repeat(6, 1fr);
        }
    }

    .WP-day {
        background-color: #dbdbdb;
        box-shadow: 0 2.35714px 3.53571px rgba(0, 0, 0, 0.25);
        border-radius: 14px;
        overflow: hidden;

        ${({ theme }) => theme.breakpoints.down('600')} {
            width: 120px;
            height: 120px;
            grid-column: span 2;
        }

        ${({ theme }) => theme.breakpoints.down('500')} {
            width: 100%;
            height: 90px;
        }

        &.WP-reward-available {
            background-color: #ddffd8;
            border: 2px solid #81f446;

            .WP-day-header {
                background: ${({ theme }) => theme.palette.green.A800};
            }
        }

        &.WP-reward-claimed {
            cursor: initial;
            position: relative;
            background-color: #e5e4e6;

            .WP-daily-image-container {
                position: relative;

                &:before {
                    width: 40px;
                    height: 40px;

                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    content: '';
                    z-index: 1;

                    display: inline-block;
                    background: url(${Check}) no-repeat center;
                    background-size: contain;

                    ${({ theme }) => theme.breakpoints.down('500')} {
                        width: 30px;
                        height: 30px;
                    }
                }
            }

            .WP-day-header {
                background: #cbcbcb;
                color: ${({ theme }) => theme.palette.common.white};
                .icon {
                    color: ${({ theme }) => theme.palette.common.white};
                }
            }

            .WP-day-body {
                position: relative;
                &:after {
                    width: 100%;
                    height: 100%;

                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';

                    display: inline-block;
                    background-color: #e5e4e6;
                    opacity: 0.5;
                }
            }

            & + .WP-tooltip {
                display: none;
            }
        }
    }

    .WP-day-container {
        ${({ theme }) => theme.breakpoints.down('600')} {
            grid-column: span 2;
        }

        ${({ theme }) => theme.breakpoints.down('500')} {
            grid-column: span 3;
        }

        .WP-tooltip-container {
            height: 100%;
        }

        .WP-tooltip {
            &.top,
            &.right {
                height: fit-content;

                top: -70%;
                left: 50%;
                background-color: #fff4cf;

                ${({ theme }) => theme.breakpoints.down('500')} {
                    top: -100%;
                }

                .WP-tooltip-header {
                    color: #734435;
                }

                .WP-tooltip-body {
                    color: #c27a62;
                }

                &:after {
                    border-color: #fff4cf transparent transparent transparent;
                }
            }
        }
    }

    .mega--reward-container {
        ${({ theme }) => theme.breakpoints.down('600')} {
            flex-direction: row;
        }
    }

    .WP-day-container-1 {
        .WP-tooltip {
            &.top,
            &.right {
                ${({ theme }) => theme.breakpoints.down('500')} {
                    top: 100%;
                    left: 60px;
                }
            }

            &.top:after {
                ${({ theme }) => theme.breakpoints.down('500')} {
                    left: 46px;
                    right: unset;
                    top: -10px;
                    transform: rotate(180deg);
                }
            }
        }
    }

    .WP-day-container-2 {
        .WP-tooltip {
            &.top,
            &.right {
                ${({ theme }) => theme.breakpoints.down('500')} {
                    top: 100%;
                    left: -60px;
                }
            }

            &.top:after {
                ${({ theme }) => theme.breakpoints.down('500')} {
                    left: unset;
                    right: 46px;
                    top: -10px;
                    transform: rotate(180deg);
                }
            }
        }
    }

    .WP-day-container-3,
    .WP-day-container-6 {
        //Tooltip
        .WP-tooltip {
            &.top,
            &.right {
                ${({ theme }) => theme.breakpoints.down('600')} {
                    left: -30px;
                }
            }

            &.top:after {
                ${({ theme }) => theme.breakpoints.down('600')} {
                    left: unset;
                    right: 46px;
                }
            }
        }
    }

    .WP-day-container-3 {
        .WP-tooltip {
            &.top,
            &.right {
                ${({ theme }) => theme.breakpoints.down('500')} {
                    top: -100%;
                    left: 60px;
                }
            }

            &.top:after {
                ${({ theme }) => theme.breakpoints.down('500')} {
                    left: 46px;
                    right: unset;
                }
            }
        }
    }

    .WP-day-container-4 {
        .WP-tooltip {
            &.top,
            &.right {
                ${({ theme }) => theme.breakpoints.down('500')} {
                    left: -10px;
                }
            }

            &.top:after {
                ${({ theme }) => theme.breakpoints.down('500')} {
                    left: unset;
                    right: 46px;
                }
            }
        }
    }

    .WP-day-container-7 {
        grid-column-start: 4;
        grid-row-start: 1;
        grid-row-end: 3;

        ${({ theme }) => theme.breakpoints.down('600')} {
            grid-column-start: 1;
            grid-column-end: 7;

            grid-row-start: unset;
            grid-row-end: unset;
        }

        .WP-day-body {
            height: calc(100% - 27px);
            flex-direction: column;

            ${({ theme }) => theme.breakpoints.down('600')} {
                flex-direction: row;
            }
        }

        .WP-day {
            height: 100%;

            ${({ theme }) => theme.breakpoints.down('600')} {
                width: 100%;
                height: 120px;
            }
        }

        //Tooltip
        .WP-tooltip {
            &.top,
            &.right {
                top: 0;
                left: -20px;

                ${({ theme }) => theme.breakpoints.down('600')} {
                    top: -70px;
                    left: 50%;
                }
            }

            &.top:after {
                ${({ theme }) => theme.breakpoints.up('601')} {
                    left: unset;
                    right: 46px;
                }
            }
        }
    }

    .WP-day-header {
        height: 27px;
        background: linear-gradient(162.35deg, #ff5179 -1.14%, #c33bd9 73.13%, #8f00ff 132.13%);

        .icon {
            color: ${({ theme }) => theme.palette.yellow.A200};
        }

        p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        ${({ theme }) => theme.breakpoints.down('375')} {
            p,
            .icon {
                font-size: 10px;
            }
        }
    }

    .WP-day-body {
        height: calc(100% - 27px);
        flex-direction: row;

        img {
            ${({ theme }) => theme.breakpoints.down('375')} {
                width: 30px;
                height: 30px;
            }
        }
    }

    .WP-highlighted-bg {
        width: 50px;
        background-color: rgba(0, 0, 0, 0.2);

        margin-top: -20px;

        padding-left: 4px;
        padding-right: 4px;

        ${({ theme }) => theme.breakpoints.down('500')} {
            width: 46px;
            p {
                font-size: 12px;
            }
        }
    }
`;
