import styled from 'styled-components';
import ModalBg from '../../../../assets/images/Modal/modal_bg.png';
import ModalGiftBg from '../../../../assets/images/Modal/daily_gift_bg.png';
import ModalRewardBg from '../../../../assets/images/Modal/daily_reward_bg.png';
import ModalNoConnectedFb from '../../../../assets/images/Modal/noConnectedFb.png';
import ModalNoConnectedScopely from '../../../../assets/images/Modal/noConnectedScopely.png';
import GiftShine from '../../../../assets/images/Modal/giftShine.png';
import RewardShine from '../../../../assets/images/Modal/rewardShine.png';

export const AccountModalStyled = styled.div`
    box-shadow: 0 40px 40px -10px rgba(0, 0, 0, 0.1);

    .WP-inner-scroll {
        position: relative;
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: block;
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background-color: ${({ theme }) => theme.palette.orange.A200};
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${({ theme }) => theme.palette.orange.A400};
            border-radius: 10px;
        }

        ${({ theme }) => theme.breakpoints.down(500)} {
            max-height: 300px;
        }

        @media screen and (max-width: 767px) and (orientation: landscape) {
            height: 160px;
        }
    }

    &.WP-daily-gift {
        .WP-modal-header {
            background-image: url(${ModalGiftBg});

            &:before {
                background-image: url(${GiftShine});
            }
        }

        .WP-image-container {
            box-shadow: 0 0 0 10px #f48945;
        }

        .WP-image-bg {
            background: radial-gradient(
                50% 50% at 50% 50%,
                rgba(255, 134, 107, 0.6) 0%,
                rgba(255, 208, 118, 0.6) 100%
            );
            border: 8px solid #f5ba78;

            &:before {
                border: 6px solid #faa74a;
            }

            &:after {
                border: 6px solid #ff9544;
            }
        }
    }

    &.WP-noConnectedFb {
        .WP-modal-header {
            background-image: url(${ModalNoConnectedFb});

            &:before {
                content: initial;
            }
        }
    }

    &.WP-noConnectedScopely {
        .WP-modal-header {
            background-image: url(${ModalNoConnectedScopely});

            &:before {
                content: initial;
            }
        }
    }

    &.WP-daily-reward {
        .WP-modal-header {
            background-image: url(${ModalRewardBg});

            &:before {
                background-image: url(${RewardShine});
            }
        }

        .WP-image-container {
            box-shadow: 0 0 0 10px rgba(234, 103, 195, 0.2),
                inset 0 0 0 10px rgba(247, 158, 239, 0.6);
        }

        .WP-image-bg {
            background: radial-gradient(
                50% 50% at 50% 50%,
                rgba(255, 254, 255, 0.6) 0%,
                rgba(255, 201, 250, 0.6) 100%
            );

            border: 6px solid #f27ee3;

            &:before {
                border: 6px solid #f27ee3;
            }

            &:after {
                border: 6px solid #f792f1;
            }
        }

        .WP-modal-title {
            ${({ theme }) => theme.breakpoints.down('sm')} {
                font-size: 26px;
            }
        }

        .WP-modal-subtitle {
            font-weight: ${({ theme }) => theme.typography.weight.medium};

            ${({ theme }) => theme.breakpoints.down('sm')} {
                font-size: ${({ theme }) => theme.typography.size.p16.fontSize};
            }
        }
    }

    &.WP-leaders-modal {
        .WP-modal-header {
            background-image: url(${ModalBg});
        }

        .WP-leader-image {
            border: 2px solid ${({ theme }) => theme.palette.orange.main};
            border-radius: 50%;
            overflow: hidden;
        }

        .WP-leader-family {
            background: rgba(10, 10, 10, 0.4);
            backdrop-filter: blur(58px);

            border-radius: 8px;
        }

        .WP-stats-item {
            display: flex;
            align-items: center;
            border: 1px solid ${({ theme }) => theme.palette.orange.A400};
            background-color: ${({ theme }) => theme.palette.common.white};
            border-radius: 8px;
            padding: 7px 10px;
        }

        .WP-level-badge {
            top: 54px;
            left: 60px;
            ${({ theme }) => theme.breakpoints.down('xs')} {
                top: 24px;
                left: 30px;
            }
        }

        .WP-family-info {
            background-color: rgba(0, 0, 0, 0.3);
            backdrop-filter: blur(58px);

            border-radius: 10px;
            ${({ theme }) => theme.breakpoints.down('xs')} {
                border-radius: 20px;
            }
        }
    }

    &.WP-account-modal {
        .WP-modal-header {
            background-image: url(${ModalGiftBg});

            &:before {
                background-image: url(${GiftShine});
            }
        }

        .WP-image-container {
            box-shadow: 0 0 0 10px #f48945;
        }

        .WP-image-bg {
            background: radial-gradient(
                50% 50% at 50% 50%,
                rgba(255, 134, 107, 0.6) 0%,
                rgba(255, 208, 118, 0.6) 100%
            );
            border: 8px solid #f5ba78;

            &:before {
                border: 6px solid #faa74a;
            }

            &:after {
                border: 6px solid #ff9544;
            }
        }
    }

    &.WP-logout-modal {
        .WP-modal-title {
            ${({ theme }) => theme.breakpoints.down('xs')} {
                font-size: ${({ theme }) => theme.typography.size.p16.fontSize};
            }
        }

        .WP-modal-subtitle {
            ${({ theme }) => theme.breakpoints.down('xs')} {
                font-size: ${({ theme }) => theme.typography.size.p12.fontSize};
            }
        }
    }

    .WP-modal-header {
        background-size: cover;
        background-position: center;

        &:before {
            width: 100%;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;
            content: '';

            background-size: cover;
            background-position: center;
            mix-blend-mode: overlay;
        }
    }

    .WP-modal-body {
        @media screen and (max-width: 767px) and (orientation: landscape) {
            height: 80px;
            min-height: auto;
            overflow: auto;

            button {
                overflow: unset;
            }
        }
    }

    .WP-earn {
        margin-left: 2px;
        display: inline-block;
    }

    .WP-image-container {
        border-radius: 50%;
        padding: 18px;
    }

    .WP-image-bg {
        position: relative;
        padding: 10px;
        z-index: 1;

        border-radius: 50%;

        &:before,
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: '';
            z-index: -1;

            display: inline-block;
            border-radius: 50%;
        }

        &:before {
            width: 108px;
            height: 108px;
            border: 6px solid #90f2fd;

            ${({ theme }) => theme.breakpoints.down('xs')} {
                width: 82px;
                height: 82px;
            }
        }

        &:after {
            width: 120px;
            height: 120px;
            border: 6px solid rgba(255, 255, 255, 0.7);

            ${({ theme }) => theme.breakpoints.down('xs')} {
                width: 94px;
                height: 94px;
            }
        }

        img {
            width: 100px;
            height: 100px;
            object-fit: contain;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            ${({ theme }) => theme.breakpoints.down('xs')} {
                width: 74px;
                height: 74px;
            }
        }
    }

    .back-to-game {
        color: inherit;
    }

    .MuiGrid-spacing-xs-3 {
        width: 100%;
        margin: 0;
    }
`;
