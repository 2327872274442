import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { globalSel, globalOp } from '../../../store/global';
import { addStorageItem, getStorageItem, removeStorageItem } from '../../../helpers/localStorage';
import { validPath } from '../../../helpers/utils';

import { INTERSTITIAL_LOGOUT_MODAL } from '../../../constants/modals.constants';
import { getSessionToken } from '../../../bridge';
import { addCookie } from '../../../bridge/cookieStorage';
import { useLocation } from 'react-router-dom';
import Interstitials from '../Interstitials/Interstitials';

let timer;
const InterstitialPopup = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const pages = getStorageItem('pages');

    const isAuthenticated = getSessionToken();

    const isValidPath = validPath(window.location.pathname);
    const currentUser = useSelector(globalSel.isLoggedIn);
    const pathname = window.location.pathname === '/' ? 'home' : window.location.pathname.slice(1);
    const popups = useSelector(globalSel.popUpsSelector)?.[pathname];
    const ftueInformation = useSelector(globalSel.ftueSelector);

    useEffect(() => {
        if (currentUser) {
            clearInterval(timer);
            timer = setInterval(() => {
                dispatch(globalOp.getPopups()).then(() => {
                    removeStorageItem('pages');
                });
            }, 30 * 60 * 1000);
        } else {
            clearInterval(timer);
        }
    }, [dispatch, currentUser]);

    useEffect(() => {
        const interstitials = getStorageItem('interstitials');
        if (currentUser && popups?.length /* && (!pages || !pages[pathname])*/) {
            let record;
            if (!interstitials || !interstitials[currentUser]) record = popups[0];
            else {
                record = popups.find((item) => {
                    return interstitials[currentUser].indexOf(item.Id) < 0;
                });
            }

            if (
                pathname === 'loyalty-point-shop' &&
                (!ftueInformation || !ftueInformation?.Claimed)
            ) {
                return false;
            }

            if (record) {
                dispatch(
                    globalOp.openInterstitials({
                        open: true,
                        screen: pathname,
                        data: record,
                    }),
                );

                const records =
                    interstitials && interstitials[currentUser]
                        ? [...interstitials[currentUser], record.Id]
                        : [record.Id];
                addStorageItem('interstitials', {
                    [currentUser]: records,
                });
                addCookie('interstitials', 1, 12);
            }
            // else {
            //     addStorageItem('pages', { [pathname]: [1] });
            // }
        }
    }, [currentUser, dispatch, location, pathname, popups, pages, ftueInformation]);

    useEffect(() => {
        if (
            !isAuthenticated &&
            isValidPath &&
            !getStorageItem('authorizing') &&
            location.pathname !== '/'
        ) {
            dispatch(
                globalOp.handleOpenModal({
                    open: true,
                    variant: INTERSTITIAL_LOGOUT_MODAL,
                }),
            );
        }
    }, [currentUser, dispatch, isAuthenticated, isValidPath, pathname]);

    if (!popups || !popups.length || !currentUser) return false;

    return <Interstitials />;
};

export default InterstitialPopup;
