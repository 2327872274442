export function initialDailyRewardsState() {
    return {
        isLoading: true,
        dailyRewardsData: {},
        calendarBanner: true,
        modal: {
            open: false,
            variant: '',
        },
    };
}
