import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Hidden } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { globalSel } from '../../../../store/global';

const TopLeadersSkeleton = () => {
    const theme = useSelector(globalSel.themeSelector);
    const items = [0, 1, 2];

    return (
        <Box component="section" mb={8}>
            <Box height={28} width={115} mb={4} borderRadius={4}>
                <Skeleton variant="text" animation="wave" height="100%" width="100%" />
            </Box>

            <Box display="flex" mt={6}>
                <Hidden xsDown>
                    <Box
                        flexGrow={1}
                        borderRadius={12}
                        mr={[8, 8, 8, 0]}
                        bgcolor={theme.palette.orange.A50}
                    >
                        <Box px={5} py={3}>
                            <Skeleton variant="text" animation="wave" height={24} width={180} />
                            <Skeleton variant="text" animation="wave" height={24} width={100} />
                        </Box>

                        <Box py={3} px={5}>
                            {items.map((item) => {
                                return (
                                    <Box key={item} display="flex" alignItems="center" mb={3}>
                                        <Box mr={3}>
                                            <Skeleton
                                                variant="circle"
                                                animation="wave"
                                                width={32}
                                                height={32}
                                            />
                                        </Box>
                                        <Box width="100%">
                                            <Skeleton variant="text" animation="wave" height={54} />
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                </Hidden>

                <Hidden mdDown>
                    <Box flexGrow={1} borderRadius={12} mx={8} bgcolor={theme.palette.orange.A50}>
                        <Box px={5} py={3}>
                            <Skeleton variant="text" animation="wave" height={24} width={180} />
                            <Skeleton variant="text" animation="wave" height={24} width={100} />
                        </Box>

                        <Box py={3} px={5}>
                            {items.map((item) => {
                                return (
                                    <Box key={item} display="flex" alignItems="center" mb={3}>
                                        <Box mr={3}>
                                            <Skeleton
                                                variant="circle"
                                                animation="wave"
                                                width={32}
                                                height={32}
                                            />
                                        </Box>
                                        <Box width="100%">
                                            <Skeleton variant="text" animation="wave" height={54} />
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                </Hidden>

                <Box flexGrow={1} borderRadius={12} bgcolor={theme.palette.orange.A50}>
                    <Box px={5} py={3}>
                        <Skeleton variant="text" animation="wave" height={24} width={180} />
                        <Skeleton variant="text" animation="wave" height={24} width={100} />
                    </Box>

                    <Box py={3} px={5}>
                        {items.map((item) => {
                            return (
                                <Box key={item} display="flex" alignItems="center" mb={3}>
                                    <Box mr={3}>
                                        <Skeleton
                                            variant="circle"
                                            animation="wave"
                                            width={32}
                                            height={32}
                                        />
                                    </Box>
                                    <Box width="100%">
                                        <Skeleton variant="text" animation="wave" height={54} />
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default TopLeadersSkeleton;
