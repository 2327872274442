import styled from 'styled-components';

import mascot from '../../../assets/images/Global/supportMascot.png';

export const SupportStyled = styled.div`
    &.WP-support-banner {
        &:after {
            content: '';
            display: block;
            width: 130px;
            height: 200px;

            background: url(${mascot}) no-repeat center;
            background-size: cover;

            position: absolute;
            right: 0;
            top: -50px;

            ${({ theme }) => theme.breakpoints.up('sm')} {
                width: 200px;
                height: 260px;
                top: -70px;
            }

            ${({ theme }) => theme.breakpoints.up('lg')} {
                width: 290px;
                height: 360px;

                bottom: 0;
                top: auto;
            }

            ${({ theme }) => theme.breakpoints.up('xl')} {
                width: 250px;
                height: 380px;

                right: 40px;
            }
        }

        ${({ theme }) => theme.breakpoints.up('sm')} {
            .WP-support-button {
                width: fit-content;
            }
        }
    }

    .WP-support-button {
        width: 100%;

        color: ${({ theme }) => theme.palette.text.main};
        font-family: ${({ theme }) => theme.typography.font.inter}, sans-serif;
        font-weight: 600;

        position: relative;
        transition: 200ms ease-out;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border: 2px solid;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }

        &.large {
            font-size: 24px;
            line-height: 32px;
            padding: 10px 44px;
            border-radius: 10px;
        }

        &.medium {
            font-size: 20px;
            line-height: 28px;
            white-space: nowrap;
            padding: 8px 28px;
            border-radius: 10px;
        }

        &.small {
            font-size: 16px;
            line-height: 24px;
            padding: 5px 16px;
            border-radius: 8px;
        }

        &.extra-small {
            font-size: 16px;
            line-height: 24px;
            padding: 2px 8px;
            border-radius: 6px;
        }

        &.primary {
            background-color: ${({ theme }) => theme.palette.primary.main};
            border-color: ${({ theme }) => theme.palette.blue.A200};

            &:hover {
                background-color: ${({ theme }) => theme.palette.blue.A500};
            }

            &:active {
                background-color: ${({ theme }) => theme.palette.blue.A800};
            }

            &:disabled {
                background-color: ${({ theme }) => theme.palette.blue.A200};
                border-color: ${({ theme }) => theme.palette.blue.A200};
            }
        }
    }
`;
