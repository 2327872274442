import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Typography } from 'portal-modules/core-ui';
import { Box, useMediaQuery } from '@material-ui/core';

import GiftsSkeleton from './GiftsSkeleton';
import { Button } from '../ui-components/Button/Button';
import CountdownUTC from '../CountdownUTC/CountdownUTC';

import { GiftsStyled } from './Gifts.styled';
import { globalSel } from '../../../store/global';

import { withPurchase } from '../../../hoc/withPurchase';
import { shopOp, shopSel } from '../../../store/shop';
import { setGAEvent } from '../../../helpers/GA4Helper';

const Gifts = ({ subtitle, handlePurchase }) => {
    const dispatch = useDispatch();
    const t = useTranslation();
    const theme = useSelector(globalSel.themeSelector);
    const isLoading = useSelector(shopSel.isLoading);
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });

    const authenticated = useSelector(globalSel.isAuthSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);
    const section = useSelector(shopSel.WebFreeDailyGift);
    const limits = useSelector(shopSel.limitsSelector);
    const giftItem = section?.Listings?.[0] ? section?.Listings?.[0] : null;
    const resetTime = limits?.FreeDailyWheel?.ResetTime;
    const [processing, setProcessing] = useState(false);

    const handleOpenGift = () => {
        setProcessing(true);
        setGAEvent('clickEvent', {
            screen_name: 'store',
            screen_type: 'screen',
            screen_section: 'bonus_rolls',
            interaction_object: 'claim_daily_gift',
            interaction_object_category: 'display',
            offer_id: giftItem?.Name,
        });

        handlePurchase({
            commodity: 'FreePoint',
            iapSku: giftItem?.StoreListing?.IapSku,
            storeListingName: giftItem?.StoreListing?.Name,
            item: {
                name: giftItem?.StoreListing?.DisplayDetails?.DisplayName,
                section: 'daily_gift',
            },
        })();
    };

    useEffect(() => {
        if (resetTime) setProcessing(false);
    }, [resetTime]);

    return (
        <>
            {authenticated && currentUser ? (
                <Box
                    component={GiftsStyled}
                    className="WP-gift WP-hide-empty-space"
                    pb={[10, 12, 12, 16, 20]}
                >
                    {isLoading ? (
                        <GiftsSkeleton />
                    ) : !giftItem ? null : (
                        <>
                            <Box
                                mb={[4, 5, 5, 5, 6]}
                                width="fit-content"
                                className="WP-gift-countdown-wrapper"
                            >
                                <CountdownUTC
                                    text={t('free.gift.timer')}
                                    start={resetTime}
                                    interval={60 * 1000}
                                    h
                                    m
                                    callback={() => dispatch(shopOp.getOffers())}
                                />
                            </Box>

                            <Box
                                onClick={!resetTime ? handleOpenGift : null}
                                display="flex"
                                alignItems="center"
                                className={`${resetTime ? '' : 'cursor-pointer'}`}
                                height={['auto', 112, 112, 132, 132]}
                                borderRadius={[14, 20, 20, 24, 30]}
                                overflow="hidden"
                                bgcolor={theme.palette.orange.A50}
                                flexDirection={['column', 'row']}
                            >
                                <Box
                                    className="WP-gift-img-container flex-center"
                                    width={['100%', 112, 112, 132, 132]}
                                    height="100%"
                                    p={5}
                                >
                                    <Box
                                        component="img"
                                        src={giftItem?.StoreListing?.DisplayDetails?.IconImage}
                                        alt="daily-gift"
                                        className="WP-gift-img"
                                        width={[80, 70]}
                                    />
                                </Box>

                                <Box
                                    width={1}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    flexDirection={['column', 'row']}
                                    pl={[2, 6]}
                                    pr={[2, 10]}
                                    pt={[4, 0]}
                                    pb={[2, 0]}
                                >
                                    <Box display="flex" alignItems="center">
                                        <Box textAlign={['center', 'left']}>
                                            <Box
                                                component={Typography}
                                                variant="h5"
                                                fWeight="semiBold"
                                                color={theme.palette.brown.main}
                                                className="WP-gift-title"
                                                mb={2}
                                            >
                                                {
                                                    giftItem?.StoreListing?.DisplayDetails
                                                        ?.DisplayName
                                                }
                                            </Box>

                                            <Box
                                                component={Typography}
                                                variant="p16"
                                                fWeight="medium"
                                                color={theme.palette.brown.A700}
                                                className="WP-gift-subtitle"
                                            >
                                                {subtitle}
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box
                                        className="WP-gift-button"
                                        minWidth={['100%', 230]}
                                        mt={[2, 0]}
                                    >
                                        <Button
                                            propogate
                                            disabled={!!resetTime}
                                            variant={resetTime ? 'completed' : 'secondary'}
                                            data-testid={`${
                                                resetTime ? 'WP-claimed-gift' : 'WP-claim-gift'
                                            }`}
                                            size={
                                                breakpointsXL
                                                    ? 'large'
                                                    : breakpointsLG
                                                    ? 'medium'
                                                    : 'small'
                                            }
                                            isLoading={processing}
                                        >
                                            {resetTime ? t('claimed') : t('claim')}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            ) : null}
        </>
    );
};

Gifts.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    handlePurchase: PropTypes.func,
};
export default withPurchase(Gifts);
