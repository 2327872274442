import React from 'react';

import { Box } from '@material-ui/core';

import { LeaderboardStyled } from './Leaderboard.styled';
import { useTranslation } from '../../../../hooks/useTranslation';

import DangerouslyHighlightedText from '../../../components/DangerouslyHighlightedText/DangerouslyHighlightedText';

import art from '../../../../assets/images/Home/Logout/leaderboardArt.png';

const Leaderboard = () => {
    const t = useTranslation();

    return (
        <Box component={LeaderboardStyled} className="WP-logout-leaderboard">
            <Box className="WP-leaderboard-content">
                <DangerouslyHighlightedText
                    className="WP-leaderboard-highlighted"
                    text={t('logout.leaderboard.content')}
                />
            </Box>

            <Box>
                <Box component="img" src={art} alt="art" className="WP-leaderboard-art" />
            </Box>
        </Box>
    );
};

export default Leaderboard;
