import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Box, Grid, useMediaQuery } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import OfferCardSkeleton from './OfferCardSkeleton';

import { globalSel } from '../../../store/global';

const WrapperSkeleton = ({ limitSkeleton, skeletonGridSize, cardGridSizeSm, cardGridSizeXl }) => {
    const theme = useSelector(globalSel.themeSelector);
    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
    const customXS = useMediaQuery(theme.breakpoints.down(375), { noSsr: true });

    const [count, setCount] = useState(limitSkeleton[0]);
    const data = Array(count).fill();

    useEffect(() => {
        if (breakpointsXL) {
            setCount(limitSkeleton[2]);
        } else if (breakpointsSM) {
            setCount(limitSkeleton[1]);
        } else {
            setCount(limitSkeleton[0]);
        }
    }, [breakpointsSM, breakpointsXL, limitSkeleton]);

    return (
        <>
            <Box mb={[4, 5, 5, 5, 6]}>
                <Box display="flex" alignItems="center">
                    <Box height={32} width={32} flexShrink={0} mr={2}>
                        <Skeleton variant="circle" animation="wave" height="100%" width="100%" />
                    </Box>

                    <Box height={[32, 32, 32, 32, 40]} width={180}>
                        <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                    </Box>
                </Box>

                <Box height={24} width={270} mt={1}>
                    <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                </Box>
            </Box>

            {skeletonGridSize ? (
                <Grid container spacing={breakpointsLG ? 5 : 4}>
                    {data.map((dataItem, index) => {
                        return (
                            <Grid
                                item
                                xs={customXS ? 12 : 6}
                                sm={cardGridSizeSm}
                                xl={cardGridSizeXl}
                                key={index}
                            >
                                <OfferCardSkeleton />
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <Grid container spacing={breakpointsXL ? 10 : breakpointsLG ? 5 : 4}>
                    {data.map((dataItem, index) => {
                        return (
                            <Grid item xs={customXS ? 12 : 6} sm={4} key={index}>
                                <OfferCardSkeleton />
                            </Grid>
                        );
                    })}
                </Grid>
            )}

            <Box
                height={[38, 38, 38, 38, 56]}
                boxShadow={theme.elevation[100]}
                borderRadius={10}
                mt={[5, 6, 6, 8, 8]}
            >
                <Skeleton variant="rect" animation="wave" height="100%" width="100%" />
            </Box>
        </>
    );
};

WrapperSkeleton.defaultProps = {
    cardGridSizeSm: 4,
    cardGridSizeXl: 4,
};

WrapperSkeleton.propTypes = {
    limitSkeleton: PropTypes.array,
    skeletonGridSize: PropTypes.bool,
    cardGridSizeSm: PropTypes.number,
    cardGridSizeXl: PropTypes.number,
};

export default WrapperSkeleton;
