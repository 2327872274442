import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../../../../hooks/useTranslation';
import PropTypes from 'prop-types';

import { Box, Typography } from 'portal-modules/core-ui';
import { Button } from '../../../ui-components/Button/Button';
import { useMediaQuery } from '@material-ui/core';

import { WelcomeStyled } from './Welcome.styled';
import LoyaltyPoint from '../../../../../assets/images/Modal/loyaltyPoint.png';
import DiceImage from '../../../../../assets/images/Modal/dice.png';
import HighlightedText from '../../../HighlightedText/HighlightedText';
import { globalOp } from '../../../../../store/global';

const Welcome = ({ theme }) => {
    const t = useTranslation();
    const dispatch = useDispatch();

    const breakpointsXS = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
    const breakpointsMD = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

    const handleCLoseModal = () => {
        dispatch(globalOp.handleCloseModal({}));
        dispatch(globalOp.getLoyaltyPoints());
    };

    return (
        <Box
            component={WelcomeStyled}
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            overflow="hidden"
            className="WP-modal-small"
        >
            <Box
                height={[160, 250]}
                position="relative"
                bgcolor={theme.palette.orange.main}
                className="flex-center WP-welcome-modal-header"
            >
                <Box className="flex-center">
                    <Box className="WP-image-bg" display="flex" flexDirection="column" mr={10}>
                        <Box
                            component="img"
                            src={DiceImage}
                            alt="Processing image"
                            width={[64, 100, 120]}
                            height={[52, 80, 100]}
                        />

                        <Box
                            className="WP-highlighted-bg flex-center"
                            height={[28, 32, 32, 40, 40]}
                            position="relative"
                            borderRadius={10}
                            px={[5, 5, 5, 8, 8]}
                            mt={[-5, -5, -5, -5, 0]}
                        >
                            <HighlightedText text={5} fontSize={['h6', 'h4', 'h4', 'h3', 'h3']} />
                        </Box>
                    </Box>
                    <Box className="WP-image-bg flex-center">
                        <Box
                            component="img"
                            src={LoyaltyPoint}
                            alt="Processing image"
                            width={[64, 100, 120]}
                            height={[52, 80, 100]}
                        />

                        <Box
                            className="WP-highlighted-bg flex-center"
                            height={[28, 32, 32, 40, 40]}
                            position="relative"
                            borderRadius={10}
                            px={[5, 5, 5, 8, 8]}
                            mt={[-5, -5, -5, -5, 0]}
                        >
                            <HighlightedText text={50} fontSize={['h6', 'h4', 'h4', 'h3', 'h3']} />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box
                minHeight={250}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                px={[4, 6]}
                pb={5}
                pt={2}
            >
                <Box>
                    <Box
                        component={Typography}
                        variant={['h6', 'h5', 'h4']}
                        fWeight="bold"
                        color={theme.palette.brown.main}
                        mb={3}
                    >
                        {t('welcome.modal.title')}
                    </Box>

                    <Box
                        component={Typography}
                        variant={['p14', 'p16']}
                        fWeight="semiBold"
                        color={theme.palette.brown.A700}
                        mb={5}
                    >
                        {t('welcome.modal.description')}
                    </Box>
                </Box>
                <Box
                    onClick={handleCLoseModal}
                    component={Button}
                    variant="secondary"
                    size={breakpointsXS ? 'small' : breakpointsMD ? 'medium' : 'large'}
                >
                    {t('claim.rewards')}
                </Box>
            </Box>
        </Box>
    );
};

Welcome.propTypes = {
    theme: PropTypes.object,
};

export default Welcome;
