import styled from 'styled-components';

export const DefaultModalStyled = styled.div`
    background-image: url(${({ bgImageUrl }) => bgImageUrl});
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 767px) and (orientation: landscape) {
        height: calc(100vh - 100px);
        overflow: auto;
    }

    .WP-header-image {
        object-fit: contain;
    }

    .WP-body-image {
        object-fit: contain;

        ${({ theme }) => theme.breakpoints.down('500')} {
            height: 140px;
        }
    }

    .WP-countdown-container {
        width: fit-content;

        position: absolute;

        bottom: 11px;
        left: 50%;
        transform: translateX(-50%);

        ${({ theme }) => theme.breakpoints.up('sm')} {
            bottom: 13px;
        }

        ${({ theme }) => theme.breakpoints.up('md')} {
            bottom: 16px;
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            bottom: 18px;
        }
    }
    .WP-modal-footer {
        button {
            z-index: 2;
        }
    }
    .WP-modal-footer-text {
        ${({ theme }) => theme.breakpoints.down('500')} {
            font-size: 18px;
        }

        color: white;
        text-shadow: 2px 2px 0 black, -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black,
            0px 3px 3px black, 0 -3px 2px black, 3px 0 2px black, -3px 0 2px black;
        letter-spacing: 1px;
    }
`;
