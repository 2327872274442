import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';

import { globalSel } from '../../../store/global';

const OrderLabel = ({ text }) => {
    const theme = useSelector(globalSel.themeSelector);

    return (
        <Box
            height={[18, 20, 20, 20, 24]}
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            px={[3, 3, 3, 3, 4]}
            bgcolor={theme.palette.brown.A200}
            border={`1px solid ${theme.palette.brown.A400}`}
            borderRadius={30}
            mb={1}
        >
            <Typography
                variant={['p10', 'p12']}
                fWeight="semiBold"
                color={theme.palette.brown.main}
            >
                {text}
            </Typography>
        </Box>
    );
};

OrderLabel.propTypes = {
    text: PropTypes.string,
};

export default OrderLabel;
