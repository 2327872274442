const notificationsSelector = ({ notifications }) => notifications.notifications;
const messagesSelector = ({ notifications }) => notifications.messages;
const newsSelector = ({ notifications }) => notifications.news;
const unreadCountSelector = ({ notifications }) => notifications.unreadCount;
const isLoadingSelector = ({ notifications }) => notifications.isLoading;

export const notificationsSel = {
    notificationsSelector,
    messagesSelector,
    unreadCountSelector,
    newsSelector,
    isLoadingSelector,
};
