import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Box, Typography } from 'portal-modules/core-ui';

import { useTranslation } from '../../../hooks/useTranslation';
import { Button } from '../ui-components/Button/Button';
import DateFormat from '../DateFormat/DateFormat';

const UserGuidesCard = ({ item }) => {
    const history = useHistory();
    const t = useTranslation();
    const theme = useTheme();
    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
    const breakpointsMac = useMediaQuery(theme.breakpoints.between(1780, 1800), {
        noSsr: true,
    });

    const handleRedirect = () => {
        history.push({
            pathname: `user-guides/${item.url}`,
        });
    };

    return (
        <Box
            className="WP-userGuide-card"
            borderRadius={[14, 20, 20, 24, 30]}
            overflow="hidden"
            height={1}
            bgcolor={theme.palette.orange.A50}
            boxShadow={theme.elevation[100]}
        >
            <Box
                component="img"
                className="object-cover"
                src={item?.image}
                alt={item?.title}
                width={1}
                maxHeight={[126, 138, 133, breakpointsMac ? 206 : 163, 250]}
            />

            <Box
                component="figcaption"
                height={[126, 138, 133, breakpointsMac ? 206 : 163, 211]}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                pb={[2, 3, 3, 4, 5]}
                pt={2}
                px={[5, 5, 5, 5, 6]}
            >
                <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="start" justifyContent="space-between" mb={2}>
                        <Box
                            component={Typography}
                            variant={['p14', 'p14', 'p16', 'p16', 'p16']}
                            fWeight="semiBold"
                            color={theme.palette.brown.A600}
                            mb={[0, 0, 0, 0, 2]}
                            className="WP-UserGuid-Date"
                        >
                            <DateFormat date={item?.createdAt} format="MMMM D, YYYY" />
                        </Box>
                    </Box>

                    <Box
                        component={Typography}
                        variant={['h6', 'h6', 'h5', 'h5', 'h5']}
                        fWeight="bold"
                        color={theme.palette.brown.main}
                        className="WP-UserGuid-Title text-truncate"
                    >
                        {item?.title}
                    </Box>
                </Box>

                <Button
                    onClick={handleRedirect}
                    variant="primary"
                    color="inherit"
                    size={
                        breakpointsXL
                            ? 'large'
                            : breakpointsLG
                            ? 'medium'
                            : breakpointsSM
                            ? 'small'
                            : 'extra-small'
                    }
                >
                    {t('read.more')}
                </Button>
            </Box>
        </Box>
    );
};

UserGuidesCard.propTypes = {
    item: PropTypes.object,
};

export default UserGuidesCard;
