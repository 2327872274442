import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box } from 'portal-modules/core-ui';

import { globalSel } from '../../../../../store/global';
import UserImage from '../components/UserImage/UserImage';
import { useTranslation } from '../../../../../hooks/useTranslation';

const PlayerAvatar = () => {
    const t = useTranslation();
    const theme = useSelector(globalSel.themeSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);

    return (
        <>
            <Box width={100} height={100} mx="auto" mt={10} mb={5} position="relative">
                <UserImage
                    imageSrc={currentUser?.PictureUrlLarge || currentUser?.PictureUrlMedium}
                    mainBorderColor={theme.palette.orange.A800}
                    secondaryBorderColor={theme.palette.common.white}
                />
            </Box>
            <Typography variant="p16" fWeight="semiBold" color={theme.palette.brown.main}>
                {currentUser.Name}
            </Typography>
            <Typography variant="p12" fWeight="regular" color={theme.palette.brown.A700}>
                {t('level')}
            </Typography>
        </>
    );
};

export default PlayerAvatar;
