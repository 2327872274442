import { getSessionToken } from '../../bridge';
import { fetch } from '../../core/infra/dice-admin/FetchService';

import { dailyRewardsSlice } from './dailyRewardsSlice';
import { shopOp } from '../shop';
import { getUserData } from '../global/operations';
import { DAILY_GIFT_MODAL, DAILY_GIFT_MODAL_DATA } from '../../constants/modals.constants';
import { setGAEvent } from '../../helpers/GA4Helper';

export const getDailyRewards = () => {
    const { setDailyRewards } = dailyRewardsSlice.actions;
    const SessionToken = getSessionToken();
    const { getCommoditiesList } = shopOp;

    return async (dispatch) => {
        try {
            const { data } = await fetch({
                endPoint: 'daily-calendar-gift/v2',
                method: 'GET',
                params: {
                    SessionToken,
                    BypassSignature: false,
                },
            });

            let CommodityItems = new Set();
            const gifts = data?.Data;

            for (const gift of gifts?.CalendarGifts) {
                for (const item of gift?.Gifts) {
                    CommodityItems.add(item['CommodityKey']);
                }
            }

            for (const megareward of gifts?.MegaReward?.Gifts) {
                CommodityItems.add(megareward['CommodityKey']);
            }

            dispatch(getCommoditiesList(CommodityItems));
            dispatch(setDailyRewards(data?.Data));
        } catch (err) {
            // eslint-disable-next-line no-console
            // console.log(err);
        }
    };
};

export const claimDailyRewards = (isMissed) => {
    const SessionToken = getSessionToken();

    return async (dispatch, getState) => {
        try {
            const { data } = await fetch({
                endPoint: 'daily-calendar-gift/claim',
                method: 'POST',
                params: {
                    SessionToken,
                    BypassSignature: false,
                },
            });

            dispatch(getUserData());
            data && dispatch(getDailyRewards());

            const state = getState();
            const dailyRewards = state.dailyRewards.dailyRewardData;

            if (
                dailyRewards?.DailyProgress === dailyRewards?.CalendarGifts?.length - 1 &&
                dailyRewards?.DailyGiftClaimed === false
            ) {
                setGAEvent('impression', {
                    screen_name: 'claim_daily_login_reward',
                    screen_type: 'pop-up',
                    offer_id: dailyRewards?.DailyGiftClaimed
                        ? `D${dailyRewards?.DailyProgress}`
                        : `D${dailyRewards?.DailyProgress + 1}`,
                });

                return dispatch(
                    dailyRewardsOp.handleOpenModal({
                        open: true,
                        variant: DAILY_GIFT_MODAL,
                        data: DAILY_GIFT_MODAL_DATA,
                        isDailyRewardCompleted: true,
                    }),
                );
            } else {
                dispatch(
                    dailyRewardsOp.handleOpenModal({
                        open: true,
                        variant: DAILY_GIFT_MODAL,
                        data: DAILY_GIFT_MODAL_DATA,
                    }),
                );
            }
        } catch (err) {
            if (err?.data?.Error?.ErrorCode === -73000) {
                dispatch(getDailyRewards());

                dispatch(
                    dailyRewardsOp.handleOpenModal({
                        open: true,
                        variant: DAILY_GIFT_MODAL,
                        data: DAILY_GIFT_MODAL_DATA,
                    }),
                );
            }
        }
    };
};

const handleOpenModal = (data) => (dispatch) => {
    const { setModal } = dailyRewardsSlice.actions;

    dispatch(setModal(data));
};

const handleCloseCalendarBanner = (data) => (dispatch) => {
    const { setCalendarBanner } = dailyRewardsSlice.actions;

    dispatch(setCalendarBanner(data));
};

const handleCloseModal = () => (dispatch) => {
    const { setModal } = dailyRewardsSlice.actions;

    dispatch(
        setModal({
            open: false,
            variant: '',
        }),
    );
};

export const dailyRewardsOp = {
    getDailyRewards,
    claimDailyRewards,
    handleOpenModal,
    handleCloseModal,
    handleCloseCalendarBanner,
};
