import { dailyDealsSlice } from './dailyDealsSlice';
import { fetch } from '../../core/infra/dice-admin/FetchService';
import globalSlice, { globalOp } from '../global';
import {
    INSUFFICIENT_MODAL,
    REFRESH_FAILURE_MODAL,
    REFRESH_FAILURE_MODAL_DATA,
    DAILY_DEALS_REFRESH_EXPIRED_MODAL,
    DAILY_DEALS_REFRESH_EXPIRED_MODAL_DATA,
} from '../../constants/modals.constants';
import { getSessionToken } from '../../bridge';
import shopSlice, { shopOp } from '../shop';

const getDailyDeals = () => {
    const { setLoading } = dailyDealsSlice.actions;

    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            dispatch(getManifest()).then(() => dispatch(getDailyDealsData()));
        } catch (err) {
            // todo Add error handler
        }
    };
};

const getDailyDealsData = () => {
    const { setDailyDeals, setLoading } = dailyDealsSlice.actions;
    const { setTabsLoading } = globalSlice.actions;
    const { getCommoditiesList } = shopOp;
    const SessionToken = getSessionToken();

    return async (dispatch, getState) => {
        try {
            const store = getState();
            const dealsId = store?.dailyDeals?.refreshDealButtonData?.Key;

            const { data } = await fetch({
                endPoint: `daily-deals/${dealsId}/me`,
                method: 'GET',
                params: {
                    BypassSignature: false,
                    SessionToken,
                    c_platform: 'web',
                    c_store: 'xsolla',
                    c_bundle: 'yahtzeenux',
                },
            });

            if (!data?.Data?.Deals?.Items?.length) {
                return dispatch(shopSlice.actions.removeSection('WebDailyDeals'));
            } else {
                dispatch(shopSlice.actions.addSections(['WebDailyDeals']));
            }

            let CommodityItems = new Set();

            const Purchases = store?.shop?.purchases;

            const offers = data?.Data?.Deals?.Items;

            for (const offer of offers) {
                const credits = offer?.StoreListing?.Credit?.Adjustments;

                const purhcaseCount = Purchases[offer?.StoreListing?.Name];
                offer.StoreListing['availableCount'] = purhcaseCount
                    ? offer.StoreListing.PurchaseLimitPerCustomer - purhcaseCount
                    : offer?.StoreListing?.PurchaseLimitPerCustomer;

                for (const element of credits) {
                    CommodityItems.add(element['CommodityKey']);
                }
            }
            dispatch(getCommoditiesList(CommodityItems));
            dispatch(setDailyDeals(data?.Data?.Deals));
        } catch (err) {
            /*const store = getState();
            const sections = store?.shop?.sections?.filter((element) => {
                return element !== 'WebDailyDeals';
            });*/
            dispatch(shopSlice.actions.removeSection('WebDailyDeals'));

            // todo Add error handler
        } finally {
            setTimeout(() => {
                dispatch(setLoading(false));
                dispatch(setTabsLoading(false));
            }, 200);
        }
    };
};

const getManifest = () => {
    const { setRefreshButtonDeals } = dailyDealsSlice.actions;
    const SessionToken = getSessionToken();

    return async (dispatch) => {
        try {
            const { data } = await fetch({
                endPoint: `daily-deals/manifest`,
                method: 'GET',
                params: {
                    BypassSignature: false,
                    SessionToken,
                    c_platform: 'web',
                },
            });
            const obj = data?.Data?.ActiveDealsConfigurations;
            const manifestData = obj[Object.keys(obj)[0]];
            // data?.Data?.ActiveDealsConfigurations && dispatch(setRefreshButtonDeals(manifestData));
            dispatch(setRefreshButtonDeals(manifestData));
        } catch (err) {
            // todo Add error handler
        }
    };
};

const getRefreshedDeals = () => {
    const { setDailyDeals, setLoading } = dailyDealsSlice.actions;
    const { getCommoditiesList } = shopOp;
    const SessionToken = getSessionToken();

    return async (dispatch, getState) => {
        dispatch(setLoading(true));

        const state = getState();
        const dealsId = state?.dailyDeals?.dailyDealData?.Key;
        const forceRefreshCount = state?.dailyDeals?.dailyDealData?.ForceRefreshCount ?? 0;
        const forceRefreshCosts = state?.dailyDeals?.refreshDealButtonData?.ForceRefreshCosts;

        const expectedCost =
            forceRefreshCount >= forceRefreshCosts.length
                ? forceRefreshCosts?.[forceRefreshCosts.length - 1]
                : forceRefreshCosts?.[forceRefreshCount];

        const expectedCount =
            forceRefreshCount >= forceRefreshCosts.length
                ? forceRefreshCosts.length - 1
                : forceRefreshCount;

        try {
            const { data } = await fetch({
                endPoint: `daily-deals/${dealsId}/refresh`,
                method: 'POST',
                params: {
                    BypassSignature: false,
                    SessionToken,
                    c_platform: 'web',
                    c_store: 'xsolla',
                    c_bundle: 'yahtzeenux',
                },
                body: {
                    ExpectedCount: expectedCount,
                    ExpectedCost: {
                        CommodityKey: expectedCost?.CommodityKey,
                        Quantity: expectedCost?.Quantity,
                    },
                },
            });

            let CommodityItems = new Set();
            const Purchases = state?.shop?.purchases;
            const offers = data?.Data?.Deals?.Items;
            for (const offer of offers) {
                const credits = offer?.StoreListing?.Credit?.Adjustments;

                const purhcaseCount = Purchases[offer?.StoreListing?.Name];
                offer.StoreListing['availableCount'] = purhcaseCount
                    ? offer.StoreListing.PurchaseLimitPerCustomer - purhcaseCount
                    : offer?.StoreListing?.PurchaseLimitPerCustomer;

                for (const element of credits) {
                    CommodityItems.add(element['CommodityKey']);
                }
            }

            dispatch(globalOp.getLoyaltyPoints());
            dispatch(setDailyDeals(data?.Data?.Deals));
            dispatch(getCommoditiesList(CommodityItems));
        } catch (err) {
            var variant = null;
            var data = null;

            switch (err?.data?.Error?.ErrorCode) {
                case 15021:
                    variant = INSUFFICIENT_MODAL;
                    data = {};
                    break;

                case 95008:
                    variant = DAILY_DEALS_REFRESH_EXPIRED_MODAL;
                    data = DAILY_DEALS_REFRESH_EXPIRED_MODAL_DATA;
                    break;

                default:
                    variant = REFRESH_FAILURE_MODAL;
                    data = REFRESH_FAILURE_MODAL_DATA;
            }

            dispatch(
                globalOp.handleOpenModal({
                    open: true,
                    variant,
                    data,
                }),
            );
        } finally {
            setTimeout(() => {
                dispatch(setLoading(false));
            }, 200);
        }
    };
};

export const dailyDealsOp = {
    getDailyDeals,
    getRefreshedDeals,
};
