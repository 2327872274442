import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import { Box, Grid, useMediaQuery } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const ListSkeleton = () => {
    const theme = useTheme();
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });

    const breakpointsMac = useMediaQuery(theme.breakpoints.between(1780, 1800), {
        noSsr: true,
    });

    const item = [0, 1];

    return (
        <>
            <Box height={[32, 32, 32, 32, 40]} width={180} mb={5}>
                <Skeleton variant="text" animation="wave" height="100%" width="100%" />
            </Box>

            <Grid container spacing={breakpointsXL ? 10 : breakpointsLG ? 5 : 4}>
                {item.map((item) => {
                    return (
                        <Grid key={item} item xs={12} sm={6}>
                            <Box
                                className="WP-support-skeleton"
                                borderRadius={[14, 20, 20, 24, 30]}
                                bgcolor={theme.palette.orange.A50}
                                boxShadow={theme.elevation[100]}
                                overflow="hidden"
                            >
                                <Box
                                    height={[126, 138, 133, breakpointsMac ? 206 : 163, 250]}
                                    flexShrink={0}
                                >
                                    <Skeleton
                                        variant="rect"
                                        animation="wave"
                                        height="100%"
                                        width="100%"
                                    />
                                </Box>

                                <Box
                                    className="WP-support-figcaption-skeleton"
                                    height={[110, 126, 126, 200, 220]}
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="space-between"
                                    p={[2, 3, 3, 5]}
                                >
                                    <Box>
                                        <Box height={[16, 24, 24, 28, 32]} width="100%">
                                            <Skeleton
                                                variant="text"
                                                animation="wave"
                                                height="100%"
                                                width="100%"
                                            />
                                        </Box>

                                        <Box height={[24, 28, 28, 32, 40]} width="100%">
                                            <Skeleton
                                                variant="text"
                                                animation="wave"
                                                height="100%"
                                                width="100%"
                                            />
                                        </Box>
                                    </Box>

                                    <Box height={[32, 38, 38, 48, 56]}>
                                        <Skeleton
                                            variant="rect"
                                            animation="wave"
                                            height="100%"
                                            width="100%"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

export default ListSkeleton;
