import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { MessageBannerStyled } from './MessageBanner.styled';
import { getStorageItem, removeStorageItem, setStorageItem } from '../../../helpers/localStorage';
import { setGAEvent } from '../../../helpers/GA4Helper';
import { webMessageBannerGetter } from '../../../core/application/message-banner';

import { globalSel } from '../../../store/global';

import webMessageDiceOffer from '../../../assets/images/MessageBanner/WebMessageDiceOffer.png';
import webMessageDiceGeneric from '../../../assets/images/MessageBanner/WebMessageDiceGeneric.png';
import webMessageDiceAnnouncement from '../../../assets/images/MessageBanner/WebMessageDiceAnnouncement.png';
import webMessageDiceError from '../../../assets/images/MessageBanner/WebMessageDiceError.png';

const variants = {
    'Web Floating Banner Offer': {
        className: 'banner-offer',
        diceIcon: webMessageDiceOffer,
        closeStroke: '#fff',
        screenCategoy: 'Offer',
    },
    'Web Floating Banner Generic': {
        className: 'banner-generic',
        diceIcon: webMessageDiceGeneric,
        closeStroke: '#844733',
        screenCategoy: 'Generic',
    },
    'Web Floating Banner Announcement': {
        className: 'banner-announcement',
        diceIcon: webMessageDiceAnnouncement,
        closeStroke: '#fff',
        screenCategoy: 'Announcement',
    },
    'Web Floating Banner Error/Change': {
        className: 'banner-error',
        diceIcon: webMessageDiceError,
        closeStroke: '#fff',
        screenCategoy: 'Error/Change',
    },
};

export default function MessageBanner() {
    const [showNotification, setShowNotification] = useState(true);
    const [notification, setNotification] = useState(null);
    const authenticated = useSelector(globalSel.isAuthSelector);

    const fetchWebMessageBanner = async () => {
        webMessageBannerGetter
            .execute()
            .then((response) => {
                if (response) {
                    const notificationClosed = getStorageItem('notificationClosed');
                    if (response.id === notificationClosed) {
                        setShowNotification(false);
                    } else {
                        setGAEvent('impression', {
                            screen_id: 'message_banner',
                            screen_name: 'message_banner',
                            screen_type: 'screen',
                            screen_category: variants[response.categoryName].screenCategoy,
                            screen_subcategory: 'web',
                            interaction_type: 'load',
                        });
                        removeStorageItem('notificationClosed');
                        setShowNotification(true);
                    }
                    setNotification(response);
                }
            })
            .catch((err) => {});
    };

    useEffect(() => {
        if (authenticated) {
            fetchWebMessageBanner();
        } else {
            setShowNotification(false);
        }
    }, [authenticated]);

    const handleClickOnBanner = () => {
        setGAEvent('clickEvent', {
            screen_id: 'message_banner',
            screen_name: 'message_banner',
            screen_type: 'pop-up',
            screen_category: variants[notification.categoryName].screenCategoy,
            screen_subcategory: 'web',
            interaction_type: 'tap',
            interaction_object_category: 'display',
            flow_name: 'web_message_banner',
            screen_content: `${notification.title}\n${notification.subtitle || ''}`,
        });
        if (notification.link) {
            setStorageItem('notificationClosed', notification.id);
            setShowNotification(false);
            window.location.href = notification.link.includes('http')
                ? notification.link
                : `https://${notification.link}`;
        }
    };

    const handleCloseMessageBanner = (ev) => {
        if (notification) {
            setStorageItem('notificationClosed', notification.id);
            setShowNotification(false);
            setGAEvent('clickEvent', {
                screen_id: 'message_banner',
                screen_name: 'message_banner',
                screen_type: 'pop-up',
                screen_category: variants[notification.categoryName].screenCategoy,
                screen_subcategory: 'web',
                interaction_type: 'tap',
                interaction_object: 'close',
                interaction_object_category: 'display',
                flow_name: 'web_message_banner',
                screen_content: `${notification.title}\n${notification.subtitle || ''}`,
            });
        }

        ev.stopPropagation();
    };

    return (
        notification &&
        showNotification && (
            <MessageBannerStyled
                onClick={() => handleClickOnBanner()}
                className={variants[notification.categoryName].className}
            >
                <img src={variants[notification.categoryName].diceIcon} alt="message-banner" />
                <div className="messageBannerText">
                    <p className={notification.subtitle && 'smallText'}>{notification.title}</p>
                    <p className={notification.subtitle && 'smallText'}>{notification.subtitle}</p>
                </div>

                <div className="closeBanner" onClick={(ev) => handleCloseMessageBanner(ev)}>
                    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                        <line
                            x1="1"
                            y1="1"
                            x2="19"
                            y2="19"
                            stroke={variants[notification.categoryName].closeStroke}
                            strokeWidth="4"
                        />
                        <line
                            x1="1"
                            y1="19"
                            x2="19"
                            y2="1"
                            stroke={variants[notification.categoryName].closeStroke}
                            strokeWidth="4"
                        />
                    </svg>
                </div>
            </MessageBannerStyled>
        )
    );
}
