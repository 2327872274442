import styled from 'styled-components';

export const MainStyled = styled.main`
    height: 100%;

    position: relative;
    background: linear-gradient(
        180deg,
        #ffc453 0%,
        #ffc453 40%,
        #ffc453 60%,
        #ff8128 80%,
        #ff8128 100%
    );

    background-blend-mode: normal, overlay, normal;
`;
