import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, useMediaQuery } from '@material-ui/core';

import Ftue from './LP_Onboarding/Ftue';
import DailyDeals from '../../components/DailyDeals/DailyDeals';
import BonusRolls from '../../components/BonusRolls/BonusRolls';
import Energy from '../../components/Energy/Energy';
import DiceAndMore from '../../components/DiceAndMore/DiceAndMore';
import Stickers from '../../components/Stickers/Stickers';

import { energyEnabledGetter } from '../../../core/application/config';
import { globalSel } from '../../../store/global';
import LoyaltyPointsTop from '../../components/LoyaltyPointsTop/LoyaltyPointsTop.jsx';
import CommodityCounter from '../../components/CommodityCounter/CommodityCounter.jsx';

import {
    dailyDealsAnchor,
    bonusRollsAnchor,
    energyAnchor,
    diceAndMoreAnchor,
    stickersAndMoreAnchor,
} from '../../../constants/anchors.constants';

import { dailyDealSel } from '../../../store/dailyDeals/selectors.js';
import AnchorsMenu from '../../components/AnchorsMenu/AnchorsMenu.jsx';

const LoyaltyPointShop = () => {
    const theme = useSelector(globalSel.themeSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);

    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });

    const dailyDealsData = useSelector(dailyDealSel.dailyDealsSelector);

    const [anchors, setAnchors] = useState([
        bonusRollsAnchor,
        diceAndMoreAnchor,
        stickersAndMoreAnchor,
    ]);

    const fetchEnergyEnabled = async () => {
        const res = await energyEnabledGetter.execute();
        if (res) {
            setAnchors((prev) => prev.filter((anchor) => anchor.anchor !== 'WP-energy'));
            setAnchors((prev) =>
                prev.reduce(
                    (acc, anchor) =>
                        anchor.anchor === 'WP-dice-more'
                            ? [...acc, energyAnchor, anchor]
                            : [...acc, anchor],
                    [],
                ),
            );
        }
    };

    useEffect(() => {
        fetchEnergyEnabled();
    }, []);

    useEffect(() => {
        setAnchors((prev) => prev.filter((anchor) => anchor.anchor !== 'WP-daily-deals'));

        if (dailyDealsData?.Items && dailyDealsData?.Items.length > 0) {
            setAnchors((prev) => [dailyDealsAnchor, ...prev]);
        }
    }, [dailyDealsData?.Items]);

    return (
        <Box className="WP-loyalty-point-page" pt={[5, 5, 5, 7]}>
            {currentUser && <Ftue />}

            <LoyaltyPointsTop />

            <CommodityCounter />

            <AnchorsMenu anchors={anchors} />

            <DailyDeals
                isTimingLabelExist
                isRefreshButtonExist
                isLogOutState
                limit={4}
                limitSkeleton={[4, 4, 4]}
                cardGridSizeXl={3}
                skeletonGridSize
                mt={[4, 0, 0]}
            />

            <BonusRolls
                sectionSubtitle="bonus.rolls.subtitle.loyalty.point"
                selector="WebLoyaltyBonusRoll"
                limit={breakpointsSM ? 6 : 4}
                limitSkeleton={[4, 6, 6]}
            />

            <Energy
                sectionSubtitle="energy.subtitle.loyalty.point"
                selector="WebLoyaltyPointsStoreEnergy"
                limit={breakpointsSM ? 3 : 2}
                limitSkeleton={[2, 2, 2]}
                cardGridSizeSm={4}
            />

            <DiceAndMore
                selector="WebExclusiveVanity"
                limit={breakpointsXL ? 4 : breakpointsSM ? 3 : 4}
                limitSkeleton={[4, 3, 4]}
                cardGridSizeXl={3}
                skeletonGridSize
            />

            <Stickers
                sectionTitle="stickers.and.more"
                sectionSubtitle="stickers.and.more.subtitle"
                selector="WebLoyaltySticker"
                limit={breakpointsSM ? 6 : 4}
                limitSkeleton={[4, 6, 6]}
            />
        </Box>
    );
};

export default LoyaltyPointShop;
