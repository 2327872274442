import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';

export const dailyOffersSlice = createSlice({
    name: 'dailyOffers',
    initialState: initialState(),
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setDailyOffers(state, action) {
            state.offers = action.payload;
        },
        setActiveOffer(state, action) {
            state.activeIndex = action.payload;
        },
    },
});
