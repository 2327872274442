import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import { DailyOfferCommodityStyled } from './DailyOfferCommodity.styled';
import CommodityIcon from '../CommodityIcon/CommodityIcon';

const DailyOfferCommodity = ({ data }) => {
    if (!data || !data.length) return false;

    return (
        <Box
            component={DailyOfferCommodityStyled}
            className={`WP-commodity-row-${data.length} flex-center`}
        >
            {data.map((item, index) => {
                return (
                    <CommodityIcon
                        key={`${item.CommodityKey}-${index}`}
                        commodity={item}
                        className={`WP-commodity-col-${data.length}`}
                    />
                );
            })}
        </Box>
    );
};

export default DailyOfferCommodity;

DailyOfferCommodity.propTypes = {
    data: PropTypes.array,
};
