import styled from 'styled-components';

export const LeftSidebarStyled = styled.aside`
    width: 240px;
    height: 100vh;

    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: ${({ theme }) => theme.zIndex.drawer};

    transition: transform 200ms ease-out;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 250, 241);

    .WP-left-sidebar {
        height: 100%;
        overflow: auto;
        scrollbar-width: none;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        transform: translateX(-240px);
        &.show {
            transform: translateX(0);
        }
    }

    .WP-Logo-Text {
        height: 65px;
        display: flex;
        align-items: center;
        background-image: linear-gradient(
            90deg,
            ${({ theme }) => theme.palette.red.main} 0%,
            ${({ theme }) => theme.palette.orange.main} 100%
        );
        border-bottom: 3px solid ${({ theme }) => theme.palette.yellow.main};

        > a {
            transform: translateX(50%);
        }
    }
`;
