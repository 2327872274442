import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';

import { useTheme } from '@material-ui/core/styles';
import HighlightedText from '../../../components/HighlightedText/HighlightedText';

import LoyaltyPoint from '../../../../assets/images/Modal/loyaltyPoint.png';

const LpWelcomeGift = ({ data, count }) => {
    const theme = useTheme();

    return (
        <Box className="WP-daily-gift-lp" maxWidth={370}>
            <Box className="WP-header flex-center" height="170px">
                <Box position="relative">
                    <Box className="WP-image-bg flex-center" width={[82, 108]} height={[82, 108]}>
                        <Box
                            component="img"
                            src={data?.IconUrl || LoyaltyPoint}
                            alt="Insufficient image"
                            className="object-contain"
                            width={[74, 100]}
                            height={[74, 100]}
                        />
                    </Box>

                    <Box
                        className="flex-center"
                        width={1}
                        height={[28, 32, 32]}
                        position="absolute"
                        bottom={-18}
                        zIndex={2}
                        borderRadius={10}
                        bgcolor={theme.hexToRgba(theme.palette.common.black, 0.2)}
                    >
                        <HighlightedText text={count || 50} fontSize={['h5']} />
                    </Box>
                </Box>
            </Box>

            <Box component="figcaption" className="WP-onboarding-figcaption" pt={4} px={4}>
                <Box
                    component={Typography}
                    variant={['h5', 'h5', 'h5', 'h5', 'h4']}
                    fWeight="bold"
                    color={theme.palette.brown.main}
                    mb={3}
                >
                    {data?.TitleText}
                </Box>

                <Box
                    component={Typography}
                    fWeight="semiBold"
                    variant="p16"
                    color={theme.palette.brown.A600}
                >
                    {data?.BodyText}
                </Box>
            </Box>
        </Box>
    );
};
LpWelcomeGift.propTypes = {
    data: PropTypes.object,
    count: PropTypes.number,
};
export default LpWelcomeGift;
