import React from 'react';
import { useSelector } from 'react-redux';

import { Box, useMediaQuery } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';

import { MaintenanceStyled } from './Maintenance.styled';
import { globalSel } from '../../../store/global';

import logo from '../../../assets/images/Maintenance/logo.svg';
import human from '../../../assets/images/Maintenance/human.svg';

const Maintenance = () => {
    const theme = useSelector(globalSel.themeSelector);
    const customXS = useMediaQuery(theme.breakpoints.down(375), { noSsr: true });
    const breakpointsSM = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

    return (
        <Box component={MaintenanceStyled}>
            <Box
                component="header"
                className="WP-maintenance-header"
                height={[50, 70, 80, 80, 110]}
                pt={[3, 3, 5, 6, 6]}
                pl={[3, 3, 5, 6, 6]}
            >
                <Box component="img" src={logo} alt="logo" height="100%" />
            </Box>

            <Box
                className="WP-maintenance-body flex-center"
                flexDirection={breakpointsSM ? 'column-reverse' : 'row'}
                width="100%"
                position="relative"
                zIndex={1}
            >
                <Box width={['100%', '100%', '45%']} textAlign="center">
                    <Box
                        component="img"
                        src={human}
                        alt="Human"
                        height={[customXS ? 200 : 300, 400, 550, 550, 700]}
                    />
                </Box>

                <Box className="WP-maintenance-block" width={['100%', '100%', '55%']}>
                    <Box className="WP-maintenance-block-inner">
                        <Box position="relative" display="flex" justifyContent="flex-start">
                            <Box className="WP-maintenance-something WP-maintenance-highlighted">
                                SOMETHING
                            </Box>
                            <Box className="WP-maintenance-something" position="absolute" top={0}>
                                SOMETHING
                            </Box>
                        </Box>

                        <Box position="relative" display="flex" justifyContent="flex-start">
                            <Box className="WP-maintenance-big WP-maintenance-highlighted">BIG</Box>
                            <Box className="WP-maintenance-big" position="absolute" top={0}>
                                BIG
                            </Box>
                        </Box>

                        <Box position="relative" display="flex" justifyContent="flex-start">
                            <Box className="WP-maintenance-coming WP-maintenance-highlighted">
                                IS COMING!
                            </Box>
                            <Box className="WP-maintenance-coming" position="absolute" top={0}>
                                IS COMING!
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box
                component="footer"
                className="WP-maintenance-footer flex-center"
                height={[60, 80, 90, 90, 125]}
            >
                <Typography
                    className="WP-maintenance-footer-text"
                    fontFamily="rubik"
                    variant={['h5', 'h5', 'h4', 'h4', 'h2']}
                    fWeight="bold"
                    color={theme.palette.common.white}
                >
                    Our site is currently under maintenance.
                    <br />
                    Check back again soon for the latest deals!
                </Typography>
            </Box>
        </Box>
    );
};

export default Maintenance;
