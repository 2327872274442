import styled from 'styled-components';

export const StoreLinksStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    .WP-store-link {
        > img {
            width: 70%;
        }
    }
`;
