import styled from 'styled-components';

export const AccordionStyled = styled.div`
    max-width: 100%;
    padding-right: 12px;
    padding-left: 12px;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        max-width: 500px;
        margin-right: auto;
        margin-left: auto;
    }

    ${({ theme }) => theme.breakpoints.up('md')} {
        max-width: 720px;
    }

    .WP-accordion-highlighted {
        font-size: ${({ theme }) => theme.typography.size.h6.fontSize};
        font-weight: ${({ theme }) => theme.typography.weight.bold};
        text-transform: uppercase;
        text-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }

    .WP-accordion-wrapper {
        .WP-accordion-text,
        .WP-accordion-content {
            font-family: 'Inter';
            font-weight: 600;
            font-size: 16px;
            color: #323232;
        }

        .MuiAccordionSummary-expandIcon {
            position: relative;
            z-index: 1;
        }

        .WP-accordion-header {
            &:after {
                content: '';
                display: block;
                width: 56px;
                height: 100%;
                background-color: #53b7ff;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
`;
