import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../../../../hooks/useTranslation';
import PropTypes from 'prop-types';

import { Box, Typography } from 'portal-modules/core-ui';
import { Button } from '../../../ui-components/Button/Button';
import { useMediaQuery } from '@material-ui/core';

import { LoyaltyPointsTransitionStyled } from './LoyaltyPointsNewLook.styled';
import { globalOp } from '../../../../../store/global';

const LoyaltyPointsTransitionModal = ({ theme }) => {
    const t = useTranslation();
    const dispatch = useDispatch();

    const breakpointsXS = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
    const breakpointsMD = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

    const handleCLoseModal = () => {
        dispatch(globalOp.handleCloseModal({}));
    };

    return (
        <Box
            component={LoyaltyPointsTransitionStyled}
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            overflow="hidden"
            className="WP-modal-small"
        >
            <Box
                height={[160, 250]}
                position="relative"
                className="flex-center WP-welcome-modal-header"
            ></Box>

            <Box
                minHeight={250}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                px={[4, 6]}
                pb={5}
                pt={2}
            >
                <Box>
                    <Box
                        component={Typography}
                        variant={['h6', 'h5', 'h4']}
                        fWeight="bold"
                        color={theme.palette.brown.main}
                        mb={3}
                    >
                        {t('loyalty.points.transition.title')}
                    </Box>

                    <Box
                        component={Typography}
                        variant={['p14', 'p16']}
                        fWeight="semiBold"
                        color={theme.palette.brown.A700}
                        mb={5}
                    >
                        {t('loyalty.points.transition.description')}
                    </Box>
                </Box>
                <Box
                    onClick={handleCLoseModal}
                    component={Button}
                    variant="secondary"
                    size={breakpointsXS ? 'small' : breakpointsMD ? 'medium' : 'large'}
                >
                    {t('view.rewards')}
                </Box>
            </Box>
        </Box>
    );
};

LoyaltyPointsTransitionModal.propTypes = {
    theme: PropTypes.object,
};

export default LoyaltyPointsTransitionModal;
