import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import AppLogo from '../../components/AppLogo/AppLogo';
import LeftSidebarFooter from './LeftSidebarFooter/LeftSidebarFooter';
import Nav from './Nav/Nav';
import { LeftSidebarStyled } from './LeftSidebar.styled';
import { navigationList } from './navigationList';
import globalSlice, { globalSel } from '../../../store/global';
import StoreLinks from './StoreLinks/StoreLinks';

const LeftSidebar = () => {
    const theme = useSelector(globalSel.themeSelector);
    const dispatch = useDispatch();
    const openLeftSidebar = useSelector(globalSel.leftSidebar);

    const closeLeftSidebar = () => {
        dispatch(globalSlice.actions.setSidebar({ side: 'leftSidebar', open: false }));
    };

    return (
        <LeftSidebarStyled className={openLeftSidebar ? 'show' : ''}>
            <Box className="WP-left-sidebar">
                <AppLogo isLogoWidthExist={110} isCloseExist closeLeftSidebar={closeLeftSidebar} />

                <Box bgcolor={theme.palette.orange.A50} px={2} py={4} flexGrow={1}>
                    <Nav navigationList={navigationList} />
                    <StoreLinks />
                    <LeftSidebarFooter />
                </Box>
            </Box>
        </LeftSidebarStyled>
    );
};

LeftSidebar.propTypes = {
    openLeftSidebar: PropTypes.bool,
    setOpenLeftSidebar: PropTypes.func,
};

export default LeftSidebar;
