import React from 'react';
import { Link } from '@material-ui/core';
import { Box } from 'portal-modules/core-ui';
import { useSelector } from 'react-redux';
import { globalSel } from '../../../store/global';
import { DOWNLOAD_LINKS } from '../../../constants/scopely.constants';
import { setGAEvent } from '../../../helpers/GA4Helper';
import socialMediaIcons from '../../layout/LeftSidebar/LeftSidebarFooter/SocialIcons';

const DownloadLinks = () => {
    const { code: languageCode } = useSelector(globalSel.languageSelector);
    const pathname = window.location.pathname;
    const clickLinks = (name) => {
        setGAEvent('clickEvent', {
            screen_name: `${pathname === '/' ? '/home' : pathname}`,
            screen_type: 'screen',
            screen_section: 'left_menu',
            interaction_object: name,
            interaction_object_category: 'redirect',
        });
    };
    return (
        <Box className="WP-download-links">
            <Link
                href={DOWNLOAD_LINKS.APP_STORE}
                className="WP-store-link display-block"
                onClick={() => clickLinks('app_store')}
                underline="none"
                target="_blank"
            >
                <img src={socialMediaIcons.appStore[languageCode]} alt="AppStore" />
            </Link>

            <Link
                href={DOWNLOAD_LINKS.PLAY_STORE}
                className="WP-store-link display-block"
                onClick={() => clickLinks('google_play')}
                underline="none"
                target="_blank"
            >
                <img src={socialMediaIcons.googlePlay[languageCode]} alt="PlayMarket" />
            </Link>
        </Box>
    );
};
export default DownloadLinks;
