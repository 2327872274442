import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';

import HighlightedText from '../../../../HighlightedText/HighlightedText';
import Badge from '../../../../Badge/Badge';

import { globalSel } from '../../../../../../store/global';
import { shopSel } from '../../../../../../store/shop';
import { calculateDiscount } from '../../../../../../helpers/utils';

const OfferCardBody = ({ data }) => {
    const theme = useSelector(globalSel.themeSelector);
    const t = useTranslation();
    const commodities = useSelector(shopSel.commoditiesSelector);

    const discountedCommodity = data?.Debit?.Adjustments[0];
    const originalCommodity = data?.OriginalListing?.Debit?.Adjustments[0];

    const divider = discountedCommodity.CommodityKey === 'USP' ? 100 : 1;
    const originalQuantity = originalCommodity ? originalCommodity?.Quantity / divider : null;
    const discountedQuantity = discountedCommodity ? discountedCommodity?.Quantity / divider : null;
    const Credits = data?.Credit?.Adjustments;
    const commoditiesCount = Credits.length;
    const discount = calculateDiscount(originalQuantity, discountedQuantity);

    const MainImage = data?.DisplayDetails?.IconImage
        ? data?.DisplayDetails?.IconImage
        : commodities?.[Credits[0]?.CommodityKey]?.image
        ? commodities?.[Credits[0]?.CommodityKey]?.image
        : null;

    return (
        <Box
            className="WP-card-modal-bg"
            minHeight="150px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            p={[2, 2, 2, 3, 5]}
        >
            {discount ? (
                <Badge text={`${discount}% ${t('off')}`} />
            ) : data?.DisplayDetails?.BannerText ? (
                <Badge text={data?.DisplayDetails?.BannerText} />
            ) : null}

            <Box className="flex-center" flexDirection="column">
                {Credits[0] && MainImage && (
                    <Box
                        component="img"
                        src={MainImage}
                        alt="bonus"
                        height={[92, 110, 110, 140, 150]}
                    />
                )}

                <Box
                    className="WP-card-modal-highlighted flex-center"
                    height={[28, 32, 32, 40, 40]}
                    position="relative"
                    borderRadius={10}
                    px={[5, 5, 5, 8, 8]}
                    mt={[-5, -5, -5, -5, 0]}
                >
                    <HighlightedText
                        text={Credits[0]?.Quantity}
                        fontSize={['h6', 'h4', 'h4', 'h3', 'h3']}
                    />
                </Box>
            </Box>

            {commoditiesCount !== 2 && commoditiesCount !== 1 ? (
                <Box position="relative" mt={8}>
                    <Box className="WP-card-modal-extra-value">
                        <Typography
                            variant="p16"
                            fWeight="bold"
                            textAlign="center"
                            color={theme.palette.yellow.A600}
                        >
                            {t('extra.value')}
                        </Typography>
                    </Box>

                    <Box
                        component="ul"
                        className="WP-card-modal-bonus-list"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        minHeight={[90, 128, 128, 154, 174]}
                        borderRadius={[15, 20]}
                        overflow="hidden"
                    >
                        {Credits?.map((listItem, index) => {
                            if (index === 0) return false;

                            return (
                                <Box component="li" key={listItem.CommodityKey}>
                                    {commodities?.[listItem.CommodityKey]?.image && (
                                        <Box
                                            component="img"
                                            src={commodities?.[listItem.CommodityKey]?.image}
                                            alt="bonus"
                                            height={[40, 50, 50, 60, 70]}
                                        />
                                    )}

                                    <Box mt={[-4, -4, -4, 0, 0]}>
                                        <HighlightedText
                                            className="WP-card-modal-bonus-list-amount"
                                            text={listItem.Quantity}
                                            fontSize="p16"
                                            textCenter
                                        />
                                    </Box>

                                    <Typography
                                        className="WP-card-modal-bonus-list-text text-shadow"
                                        variant={['p12', 'p14']}
                                        fWeight="bold"
                                        color={theme.palette.common.white}
                                    >
                                        {commodities?.[listItem.CommodityKey]?.name}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            ) : null}
        </Box>
    );
};

OfferCardBody.propTypes = {
    theme: PropTypes.object,
    data: PropTypes.object,
};

export default OfferCardBody;
