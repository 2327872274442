import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LoyaltyPointsTopStyled } from './LoyaltyPointsTop.styled';
import { Box } from '@material-ui/core';
import { Button } from '../ui-components/Button/Button';
import { useTranslation } from '../../../hooks/useTranslation';
import { LOYALTY_POINTS_LEARN_MODAL } from '../../../constants/modals.constants';
import { globalOp } from '../../../store/global';
import { setGAEvent } from '../../../helpers/GA4Helper.js';

import diceImg from '../../../assets/images/LoyaltyPointsShop/Generic_Art_HighRollers.png';
import SectionTitle from '../SectionTitle/SectionTitle.jsx';

const LoyaltyPointsTop = () => {
    const t = useTranslation();
    const history = useHistory();

    const dispatch = useDispatch();

    useEffect(() => {
        setGAEvent('impression', {
            screen_id: 'loyalty_point_store',
            screen_name: 'loyalty_point_store',
            screen_type: 'screen',
            screen_section: 'web',
            interaction_type: 'load',
            interaction_object_category: 'display',
            screen_content: 'redeem_banner',
        });
    }, []);

    const leftButtonOnClick = () => {
        setGAEvent('clickEvent', {
            screen_id: 'loyalty_point_store',
            screen_name: 'loyalty_point_store',
            screen_type: 'screen',
            screen_section: 'web',
            interaction_type: 'tap',
            interaction_object: 'learn_more',
            interaction_object_category: 'display',
            flow_name: 'redeem_banner',
        });
        dispatch(
            globalOp.handleOpenModal({
                open: true,
                variant: LOYALTY_POINTS_LEARN_MODAL,
            }),
        );
    };

    const rightButtonOnClick = () => {
        setGAEvent('clickEvent', {
            screen_id: 'loyalty_point_store',
            screen_name: 'loyalty_point_store',
            screen_type: 'screen',
            screen_section: 'web',
            interaction_type: 'tap',
            interaction_object: 'check_it_out',
            interaction_object_category: 'display',
            flow_name: 'redeem_banner',
        });
        history.push('/loyalty-point-shop', 'WP-daily-deals-section-title');
    };

    return (
        <>
            <Box className="WP-daily-deals-section-title">
                <SectionTitle
                    title={t('loyalty.point.shop')}
                    subtitle={t('loyalty.point.page.sub.title')}
                    customIcon="loyalty-point-offers"
                />
            </Box>
            <Box component={LoyaltyPointsTopStyled} id="loyalty-points-top">
                <div className="WP-loyalty-points-top-bg" />

                <h6 className="WP-loyalty-points-top-title">
                    {t('lps.top.title') ||
                        'Redeem all your earned Loyalty Points for other rewards'}
                </h6>

                <Box className="WP-loyalty-points-top-buttons">
                    <Button
                        onClick={leftButtonOnClick}
                        variant="primary"
                        size="medium"
                        preventLoading={true}
                        fullWidth={false}
                    >
                        {t('lps.top.learn') || 'LEARN MORE'}
                    </Button>
                    <Button
                        onClick={rightButtonOnClick}
                        variant="secondary"
                        size="medium"
                        preventLoading={true}
                        fullWidth={false}
                    >
                        {t('lps.top.check') || 'CHECK IT OUT'}
                    </Button>
                </Box>

                <img src={diceImg} className="WP-loyalty-points-top-dice" alt="dice-top" />
            </Box>
        </>
    );
};

LoyaltyPointsTop.propTypes = {
    handleAnchor: PropTypes.func,
};

export default LoyaltyPointsTop;
