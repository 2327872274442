import styled from 'styled-components';

export const AvatarStyled = styled.div`
    .avatar {
        border: ${({ hasBorder }) => (hasBorder ? '1px solid' : 0)};
        border-color: ${({ hasBorder, theme }) => (hasBorder ? theme.palette.black.light[100] : 0)};
    }

    .has-not-avatar {
        color: ${({ theme }) => theme.palette.brown.light[600]};
        border: ${({ hasBorder }) => (hasBorder ? '1px solid' : 0)};
        border-color: ${({ hasBorder, theme }) => (hasBorder ? theme.palette.black.light[100] : 0)};
    }
`;
