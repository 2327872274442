import unitedStates from '../../../assets/images/Flags/united-states.svg';
import france from '../../../assets/images/Flags/france.svg';
import germany from '../../../assets/images/Flags/germany.svg';
import italy from '../../../assets/images/Flags/italy.svg';
import spain from '../../../assets/images/Flags/spain.svg';

export const navigationList = [
    {
        id: 1,
        title: 'home',
        subTitle: 'your.online.home',
        href: '/',
        iconClassname: 'icon-home',
        className: 'home',
        name: 'home',
    },
    {
        id: 2,
        title: 'leaderboard',
        subTitle: 'top.yahtzee.players',
        href: '/leaderboards',
        iconClassname: 'icon-leaderboard',
        className: 'leaderboard',
        name: 'leaderboards',
    },
    {
        id: 3,
        title: 'news',
        subTitle: 'latest.news.updates',
        href: '/news',
        iconClassname: 'icon-news',
        className: 'news',
        name: 'news',
    },
    {
        id: 4,
        title: 'support',
        subTitle: 'all.answers.in.one.place',
        href: '/support',
        iconClassname: 'icon-support',
        className: 'support',
        name: 'support',
    },
    {
        id: 5,
        title: 'user.guides',
        subTitle: 'source.of.knowledge',
        href: '/user-guides',
        iconClassname: 'icon-userInfo',
        className: 'user-guide',
        name: 'user-guide',
    },
    {
        id: 6,
        title: 'store',
        subTitle: 'exclusive.offers.on.game.items',
        href: '/store',
        iconClassname: 'icon-store',
        className: 'store',
        name: 'store',
        pip: 'storePip',
    },
    {
        id: 7,
        title: 'loyalty.point.shop',
        subTitle: 'redeem.points.for.rewards',
        href: '/loyalty-point-shop',
        iconClassname: 'icon-coin',
        className: 'loyalty-point',
        name: 'loyalty-point',
        pip: 'lpPip',
    },
];

export const languageList = [
    {
        id: 1,
        icon: unitedStates,
        langId: 'en',
    },
    {
        id: 2,
        icon: france,
        langId: 'fr',
    },
    {
        id: 3,
        icon: germany,
        langId: 'de',
    },
    {
        id: 4,
        icon: italy,
        langId: 'it',
    },
    {
        id: 5,
        icon: spain,
        langId: 'sp',
    },
];
