import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from '../../../hooks/useTranslation';
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import PageHeader from '../../components/PageHeader/PageHeader';
import UserGuidesSelectedItem from './UserGuidesSelectedItem';
import UserGuideSinglePageSkeleton from './UserGuideSinglePageSkeleton';
import { userGuideGetter } from '../../../core/application/user-guides';

const SingleUserGuidePage = () => {
    const [userGuideItem, setUserGuideItem] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const params = useParams();
    const t = useTranslation();
    const theme = useTheme();

    const fetchUserGuide = async () => {
        setIsLoading(true);
        userGuideGetter
            .execute(params.userGuidesUrl)
            .then((res) => {
                setUserGuideItem(res);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchUserGuide();
    }, []);

    return (
        <Box pt={[5, 5, 5, 7]}>
            {isLoading ? (
                <UserGuideSinglePageSkeleton isPageNews />
            ) : (
                <>
                    <PageHeader
                        title={t('user.guides')}
                        subtitle={t('user.guides.subtitle')}
                        icon="icon-userInfo"
                        isLoading={isLoading}
                    />

                    <UserGuidesSelectedItem userGuideItem={userGuideItem} theme={theme} />
                </>
            )}
        </Box>
    );
};

export default SingleUserGuidePage;
