import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box, useMediaQuery } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';

import { useTranslation } from '../../../../../../hooks/useTranslation';
import DailyTimer from '../../../../DailyOffers/DailyOffersSlider/DailyTimer';

import { globalSel } from '../../../../../../store/global';
import { DailyUpcomingOfferBodyStyled } from '../DailyOffer.styled';

const DailyUpcomingOfferBody = ({ offer }) => {
    const { StartDate: start, EndDate: end } = offer;
    const t = useTranslation();
    const theme = useSelector(globalSel.themeSelector);
    const breakpointsMacSm = useMediaQuery(theme.breakpoints.between(1441, 1779), {
        noSsr: true,
    });
    const breakpointsMac = useMediaQuery(theme.breakpoints.between(1780, 1800), {
        noSsr: true,
    });

    return (
        <Box
            component={DailyUpcomingOfferBodyStyled}
            className="WP-daily-upcoming flex-center"
            height={[300, 350, 350, breakpointsMacSm ? 410 : breakpointsMac ? 410 : 450, 450]}
            width={1}
            overflow="hidden"
        >
            <Box
                className="flex-center"
                flexDirection="column"
                maxWidth={['100%', '60%', '60%', '70%']}
            >
                <Box
                    component={Typography}
                    className="WP-daily-upcoming-text text-uppercase"
                    variant={['h6', 'h6', 'h6', 'h3']}
                    textAlign="center"
                    fWeight="bold"
                    color={theme.palette.common.white}
                >
                    {offer.offerIndex === 2
                        ? t('daily.upcoming.offer.text')
                        : t('daily.upcoming.after.tomorrow.text')}
                </Box>

                <Box width="fit-content" mx="auto" mt={[5, 5, 5, 5, 7]}>
                    <DailyTimer start={start} end={end} />
                </Box>
            </Box>
        </Box>
    );
};
DailyUpcomingOfferBody.propTypes = {
    offer: PropTypes.object,
};
export default DailyUpcomingOfferBody;
