import React from 'react';

import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import { NewsPageStyled } from './NewsPage.styled';

const NewsInnerPageSkeleton = () => {
    const theme = useTheme();
    const customXS = useMediaQuery(theme.breakpoints.down(768), { noSsr: true });
    const breakpointsXS = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
    const breakpointsSM = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
    const breakpointsLG = useMediaQuery(theme.breakpoints.down('lg'), { noSsr: true });

    const items = [0, 1, 2];

    return (
        <Box component="section" mb={8}>
            <Box height={28} width={115} mb={4} borderRadius={4}>
                <Skeleton variant="text" animation="wave" height="100%" width="100%" />
            </Box>

            <Box component={NewsPageStyled} mt={[4, 15]} mb={[6, 40]}>
                <Box
                    className="WP-news-page-wrapper"
                    width={['100%', '80%']}
                    borderRadius={[14, 20, 20, 24, 30]}
                    display="flex"
                    flexDirection="column"
                >
                    <Box bgcolor={theme.palette.orange.A50} px={7} py={7}>
                        <Box width="70%" mb={5}>
                            <Skeleton variant="text" animation="wave" height={24} />
                            <Skeleton variant="text" animation="wave" height={24} />
                        </Box>

                        <Box width="100%" height={[146, 340]} mb={4}>
                            <Skeleton variant="rect" animation="wave" height="100%" />
                        </Box>

                        <Box width="70%" mb={5}>
                            <Skeleton variant="text" animation="wave" height={24} />
                            <Skeleton variant="text" animation="wave" height={24} />
                        </Box>

                        <Box mr={3} width="100%" height={206}>
                            <Skeleton variant="rect" animation="wave" height="100%" />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Grid container spacing={breakpointsXS ? 3 : breakpointsSM ? 4 : breakpointsLG ? 5 : 7}>
                {items.map((item) => (
                    <Grid item key={item} xs={customXS ? 12 : 4}>
                        <Box bgcolor={theme.palette.orange.A50} borderRadius={16} p={4}>
                            <Box mr={3} width="100%" height={[110, 136, 136, 136, 206]}>
                                <Skeleton variant="rect" animation="wave" height="100%" />
                            </Box>

                            <Box width="70%" mt={5} height={180}>
                                <Skeleton variant="text" animation="wave" height={24} />
                                <Skeleton variant="text" animation="wave" height={24} />
                            </Box>

                            <Box width="20%" mt={5}>
                                <Skeleton variant="text" animation="wave" height={24} />
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default NewsInnerPageSkeleton;
