import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Icon } from 'portal-modules/core-ui';
import { Box, Hidden } from '@material-ui/core';

import { globalSel } from '../../../store/global';
import logo from '../../../assets/images/Logos/app-logo.png';
import { Link } from 'react-router-dom';
import Avatar from '../Avatar/Avatar';

function AppLogo({ isCloseExist, isLogoWidthExist, isLogoHeightExist, closeLeftSidebar = null }) {
    const theme = useSelector(globalSel.themeSelector);

    return (
        <Box position="relative" className="WP-Logo-Text">
            <Link to="/" underline="none">
                <Box
                    component="img"
                    src={logo}
                    onClick={closeLeftSidebar}
                    className="object-cover"
                    width={isLogoWidthExist}
                    height={isLogoHeightExist}
                />
            </Link>

            {isCloseExist && (
                <Hidden mdUp>
                    <Box
                        color={theme.palette.common.white}
                        position="absolute"
                        top="20px"
                        right="20px"
                        display="flex"
                        alignItems="center"
                        onClick={closeLeftSidebar}
                    >
                        <Icon className="icon-close cursor-pointer" size={18} />
                    </Box>
                </Hidden>
            )}
        </Box>
    );
}

Avatar.defaultProps = {
    isLogoWidthExist: false,
    isLogoHeightExist: false,
};

AppLogo.propTypes = {
    isCloseExist: PropTypes.bool,
    isLogoWidthExist: PropTypes.number,
    isLogoHeightExist: PropTypes.number,
    closeLeftSidebar: PropTypes.func,
};

export default React.memo(AppLogo, () => true);
