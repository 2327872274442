export class PipGetter {
    constructor(configRepository) {
        this.configRepository = configRepository;
    }

    async execute() {
        this.configRepository.clearCache();

        const { 'web-loyalty-point-shop-pip': lpPip, 'web-store-pip': storePip } =
            await this.configRepository.getConfig();

        try {
            return { lpPip: JSON.parse(lpPip), storePip: JSON.parse(storePip) };
        } catch (error) {
            return {};
        }
    }
}
