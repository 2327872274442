import React from 'react';
import PropTypes from 'prop-types';

import { Box, Hidden, Link, useMediaQuery } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';
import { useTheme } from '@material-ui/core/styles';

import { SupportStyled } from '../Support.styled';
import { useSelector } from 'react-redux';
import { shopSel } from '../../../../store/shop';
import BannerSkeleton from './BannerSkeleton';
import { firstLatterOfTextToUppercase } from '../../../../helpers/utils';

const Banner = ({ data, isLoading = undefined }) => {
    const theme = useTheme();
    const isLoadingGlobal = useSelector(shopSel.isLoading);

    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
    const breakpointsMac = useMediaQuery(theme.breakpoints.between(1780, 1800), {
        noSsr: true,
    });

    isLoading = isLoading !== undefined ? isLoading : isLoadingGlobal;

    return (
        <Box component="section" pb={[10, 12, 12, 16, 20]}>
            {isLoading ? (
                <BannerSkeleton />
            ) : (
                <Box
                    component={SupportStyled}
                    className="WP-support-banner"
                    minHeight={[270, 190, 190, 280]}
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="column"
                    position="relative"
                    borderRadius={[14, 20, 20, 24, 30]}
                    bgcolor={theme.palette.orange.A50}
                    p={[3, 4, 4, 5, 10]}
                >
                    <Hidden xsDown>
                        <Box
                            maxWidth={[
                                '60%',
                                '60%',
                                '60%',
                                breakpointsMac ? '100%' : '60%',
                                '100%',
                            ]}
                        >
                            <Box
                                component={Typography}
                                className="WP-support-title"
                                variant={['h5', 'h5', 'h5', 'h4']}
                                fWeight="bold"
                                color={theme.palette.brown.main}
                                mb={3}
                            >
                                {firstLatterOfTextToUppercase(data.title)}
                            </Box>

                            <Box
                                component={Typography}
                                className="WP-support-description"
                                variant={['h6', 'h6', 'h6', 'h5']}
                                fWeight="semiBold"
                                color={theme.palette.orange.main}
                            >
                                {data.description}
                            </Box>
                        </Box>

                        <Link
                            href={data.sectionUrl}
                            className={`${
                                breakpointsXL ? 'large' : breakpointsLG ? 'medium' : 'small'
                            } WP-support-button primary`}
                            target="_blank"
                        >
                            {data.buttonText}
                        </Link>
                    </Hidden>

                    <Hidden smUp>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            flexDirection="column"
                            flexGrow={1}
                        >
                            <Box
                                component={Typography}
                                className="WP-support-title text-uppercase"
                                variant="h5"
                                fWeight="bold"
                                color={theme.palette.brown.main}
                                width={160}
                            >
                                {data.title}
                            </Box>

                            <Box pt={[18.75, 15]}>
                                <Box
                                    component={Typography}
                                    className="WP-support-description"
                                    variant="h6"
                                    fWeight="semiBold"
                                    color={theme.palette.orange.main}
                                    mb={5}
                                >
                                    {data.description}
                                </Box>

                                <Link
                                    href={data.sectionUrl}
                                    className="WP-support-button primary extra-small"
                                    target="_blank"
                                >
                                    {data.buttonText}
                                </Link>
                            </Box>
                        </Box>
                    </Hidden>
                </Box>
            )}
        </Box>
    );
};

Banner.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
};

export default Banner;
