import React from 'react';
import { BackToGameStyled } from './BackToGame.styled';
import { Link } from '@material-ui/core';
import { deviceType } from '../../../helpers/deviceType';
import { getStorageItem } from '../../../helpers/localStorage';
import { getSessionToken } from '../../../bridge';

import app_icon from '../../../assets/images/Global/backToGame.png';

export default function BackToGame() {
    const checkMobileOrTablet = deviceType();
    const isLoggedIn = getSessionToken();

    var uri = undefined;
    switch (getStorageItem('fromSource')) {
        case 'dicefree':
            uri = 'dux://game/home/open';
            break;
        case 'yux':
            uri = 'yux://game/open';
            break;
        default:
            break;
    }

    return (
        <>
            {checkMobileOrTablet && uri && isLoggedIn && (
                <BackToGameStyled>
                    <Link href={uri}>
                        <img src={app_icon} alt="back-to-game" />
                    </Link>
                </BackToGameStyled>
            )}
        </>
    );
}
