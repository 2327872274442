import React from 'react';
import { useTranslation } from '../../../../hooks/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Box, Hidden, useMediaQuery } from '@material-ui/core';
import { Icon } from 'portal-modules/core-ui';
import { Button } from '../../ui-components/Button/Button';

import RefreshButtonsSkeleton from './RefreshButtonsSkeleton';
import { RefreshButtonsStyled } from './RefreshButtons.styled';

import { dailyDealSel, dailyDealsOp } from '../../../../store/dailyDeals';
import { globalOp, globalSel } from '../../../../store/global';
import { setGAEvent } from '../../../../helpers/GA4Helper';
import {
    DAILY_DEALS_REFRESH_LIMIT_MODAL,
    DAILY_DEALS_REFRESH_LIMIT_MODAL_DATA,
} from '../../../../constants/modals.constants';

const RefreshButtons = ({ forceRefreshCount, isLoading }) => {
    const t = useTranslation();
    const dispatch = useDispatch();

    const theme = useSelector(globalSel.themeSelector);
    const refreshButtons = useSelector(dailyDealSel.refreshDealsButtonSelector);
    const refreshButtonsCosts = refreshButtons?.ForceRefreshCosts;
    const limitForcedRefresh = refreshButtons?.LimitForcedRefresh;

    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });

    const handleRefreshDeals = (setLoading) => {
        if (limitForcedRefresh && forceRefreshCount >= refreshButtonsCosts?.length) {
            dispatch(
                globalOp.handleOpenModal({
                    open: true,
                    variant: DAILY_DEALS_REFRESH_LIMIT_MODAL,
                    data: DAILY_DEALS_REFRESH_LIMIT_MODAL_DATA,
                }),
            );
            setLoading(false);
        } else {
            setGAEvent('clickEvent', {
                screen_name: 'loyalty_point_store',
                screen_type: 'screen',
                screen_section: 'daily_deals',
                interaction_object: 'refresh',
                interaction_object_category: 'purchase',
            });

            dispatch(dailyDealsOp.getRefreshedDeals());
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    return isLoading ? (
        <RefreshButtonsSkeleton />
    ) : (
        <Box
            component={RefreshButtonsStyled}
            borderRadius={[14, 20, 20, 24, 30]}
            bgcolor={theme.palette.blue.A50}
            py={[3, 4]}
            px={[2, 5, 3, 5]}
        >
            <Button
                onClick={handleRefreshDeals}
                size={breakpointsLG ? 'large' : 'small'}
                className="WP-refresh-button-icon"
            >
                <Hidden smUp>
                    <Icon className="icon-refresh" size={20} />
                </Hidden>

                <Hidden xsDown>{t('refresh')}</Hidden>

                <Icon className="icon-loyality-point" size={25} />
                {forceRefreshCount >= refreshButtonsCosts?.length
                    ? refreshButtonsCosts?.[refreshButtonsCosts.length - 1]?.Quantity
                    : refreshButtonsCosts?.[forceRefreshCount]?.Quantity}
            </Button>
        </Box>
    );
};

RefreshButtons.propTypes = {
    refreshButtonsCosts: PropTypes.array,
    forceRefreshCount: PropTypes.number,
    isLoading: PropTypes.bool,
};

export default RefreshButtons;
