import React from 'react';
import PropTypes from 'prop-types';
import { CustomCheckboxStyled } from './CustomCheckbox.styled';

export default function CustomCheckbox({ checkboxName, checked, callback, id, name }) {
    return (
        <CustomCheckboxStyled>
            <input
                id={id}
                name={name}
                type="checkbox"
                className="WP-custom-checkbox"
                onChange={callback}
                checked={checked}
            />
            <label htmlFor={id} />
            <div className="WP-custom-checkbox-text" dangerouslySetInnerHTML={checkboxName} />
        </CustomCheckboxStyled>
    );
}

CustomCheckbox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    checked: PropTypes.bool,
    checkboxName: PropTypes.object,
    callback: PropTypes.func,
};
