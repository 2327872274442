import React from 'react';

import { Box, Hidden } from '@material-ui/core';

import { useTranslation } from '../../../../hooks/useTranslation';
import DangerouslyHighlightedText from '../../../components/DangerouslyHighlightedText/DangerouslyHighlightedText';

import { LandingStyled } from './Landing.styled';

import logo from '../../../../assets/images/Logos/app-logo.png';
import commodity from '../../../../assets/images/Home/Logout/comodityAmount.svg';
import { setGAEvent } from '../../../../helpers/GA4Helper';
import { globalOp } from '../../../../store/global';
import { LOGIN_MODAL } from '../../../../constants/modals.constants';
import { useDispatch } from 'react-redux';

const Landing = () => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const pathname = window.location.pathname;

    const handleModalOpen = () => {
        setGAEvent('clickEvent', {
            screen_name: `${pathname === '/' ? '/home' : pathname}`,
            screen_type: 'screen',
            interaction_object: 'welcome_login',
            interaction_object_category: 'login',
        });

        return dispatch(
            globalOp.handleOpenModal({
                open: true,
                variant: LOGIN_MODAL,
                data: {
                    image: 'login',
                    title: 'login.title',
                    description: 'login.description',
                    buttonText: 'login',
                    loginWithFb: 'true',
                },
            }),
        );
    };

    return (
        <Box component={LandingStyled} className="WP-logout-landing flex-center">
            <Hidden mdDown>
                <Box className="WP-logo-wrapper">
                    <Box component="img" src={logo} alt="logo" className="WP-landing-logo" />
                </Box>
            </Hidden>

            <Box className="WP-content-wrapper">
                <Box className="WP-content-inner">
                    <DangerouslyHighlightedText
                        className="WP-landing-highlighted-title"
                        text={t('welcome.to.the.game.portal')}
                    />

                    <DangerouslyHighlightedText
                        className="WP-landing-highlighted-text"
                        text={t('login.to.discover')}
                    />

                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Box
                            component="button"
                            onClick={handleModalOpen}
                            className="WP-button flex-center cursor-pointer"
                        >
                            {t('login')}
                        </Box>

                        <Box className="WP-bonus flex-center">
                            <Box className="WP-bonus-plus">+</Box>
                            <Box
                                component="img"
                                src={commodity}
                                alt="commodity"
                                className="WP-bonus-img"
                            />
                            <Box
                                className="WP-bonus-text"
                                dangerouslySetInnerHTML={{
                                    __html: t('for.your.first.login'),
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Landing;
