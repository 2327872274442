import styled from 'styled-components';

export const NavStyled = styled.nav`
    .nav-link {
        display: block;
        font-size: ${({ theme }) => theme.typography.size.p14.fontSize};
        color: ${({ theme }) => theme.palette.brown.main};
        transition: color 200ms ease-in-out;
        white-space: nowrap;
        margin-bottom: ${({ theme }) => theme.spacing(2)}px;
        text-decoration: none;

        .WP-Nav-title {
            font-size: ${({ theme }) => theme.typography.size.p14.fontSize};
            line-height: 1.5;
            white-space: initial;
        }
        .WP-Nav-subTitle {
            margin-top: -4px;
            color: ${({ theme }) => theme.hexToRgba(theme.palette.brown.main, 0.6)};
            font-size: ${({ theme }) => theme.typography.size.p10.fontSize};
            line-height: 16px;
            white-space: initial;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            color: ${({ theme }) => theme.palette.brown.A700};
            .WP-Nav-subTitle {
                color: ${({ theme }) => theme.hexToRgba(theme.palette.brown.main, 0.5)};
            }
        }

        .image-icon {
            width: 24px;
            height: 24px;
            margin-right: 12px;
            border-radius: 4px;
        }

        .icon-notif {
            position: relative;
        }

        .icon-notif::after {
            content: '';
            position: absolute;
            top: -4px;
            right: -2px;
            width: 8px;
            height: 8px;
            background-color: #f05454;
            border: 2px solid #fffaf1;
            border-radius: 50%;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
        }
    }

    .active-link {
        color: ${({ theme }) => theme.palette.text.main};
        background: ${({ theme }) => theme.palette.orange.A800};
        border-radius: 12px;

        .WP-Nav-title {
            font-weight: ${({ theme }) => theme.typography.weight.bold};
        }

        .WP-Nav-subTitle {
            color: ${({ theme }) => theme.hexToRgba(theme.palette.text.main, 0.6)};
        }

        &:hover {
            color: ${({ theme }) => theme.palette.text.main};
            .WP-Nav-subTitle {
                color: ${({ theme }) => theme.hexToRgba(theme.palette.text.main, 0.6)};
            }
        }
    }

    i {
        margin-right: 12px;
    }

    .nav-label {
        text-transform: uppercase;
    }

    .back-to-game-wrapper {
        display: flex;
        justify-content: center;

        .back-to-game {
            display: block;
            font-size: ${({ theme }) => theme.typography.size.p16.fontSize};
            font-weight: ${({ theme }) => theme.typography.weight.semiBold};
            color: ${({ theme }) => theme.palette.brown.main};
            text-align: center;
            width: 90%;

            .back-to-game-title {
                border: 1px solid ${({ theme }) => theme.palette.brown.main};
                border-radius: 10px;
                padding: 12px 12px;
            }
        }

        &:hover {
            .back-to-game {
                color: ${({ theme }) => theme.palette.brown.A700};

                .back-to-game-title {
                    border: 1px solid ${({ theme }) => theme.palette.brown.A700};
                }
            }
        }
    }

    .back-to-game-active {
        .back-to-game {
            color: ${({ theme }) => theme.palette.brown.A800};

            .back-to-game-title {
                border: 1px solid ${({ theme }) => theme.palette.brown.A800};
            }
        }
    }
`;
