import styled from 'styled-components';

import extraBadgeXs from '../../../../../../assets/images/Modal/OfferCard/extraBadgeXs.png';
import extraBadgeSm from '../../../../../../assets/images/Modal/OfferCard/extraBadgeSm.png';
import extraBadgeLg from '../../../../../../assets/images/Modal/OfferCard/extraBadgeLg.png';
import extraBadgeXl from '../../../../../../assets/images/Modal/OfferCard/extraBadgeXl.png';

export const OfferCardBodyStyled = styled.div`
    .WP-card-modal-bg {
        background-image: url(${({ bgImageUrl }) => bgImageUrl});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: #00d1ff;
    }

    .WP-card-modal-highlighted {
        background-color: rgba(0, 0, 0, 0.2);
    }

    .WP-card-footer-container {
        .WP-card-footer {
            @media screen and (max-width: 767px) and (orientation: landscape) {
                overflow: auto;
                height: 60px;
                min-height: unset;
            }

            &::-webkit-scrollbar {
                display: block;
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                background-color: ${({ theme }) => theme.palette.orange.A200};
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: ${({ theme }) => theme.palette.orange.A400};
                border-radius: 10px;
            }
        }
    }

    .WP-card-modal-extra-value {
        height: 32px;
        min-width: 172px;
        text-align: center;
        background: url(${extraBadgeXs}) no-repeat center;
        background-size: cover;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            height: 38px;
            min-width: 205px;
            top: -23px;
            background-image: url(${extraBadgeSm});
        }

        ${({ theme }) => theme.breakpoints.up('lg')} {
            height: 48px;
            min-width: 260px;
            top: -30px;
            background-image: url(${extraBadgeLg});
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            height: 48px;
            min-width: 260px;
            top: -28px;
            background-image: url(${extraBadgeXl});
        }

        > p {
            text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

            ${({ theme }) => theme.breakpoints.up('sm')} {
                padding-top: 4px;
            }

            ${({ theme }) => theme.breakpoints.up('lg')} {
                font-size: ${({ theme }) => theme.typography.size.h5.fontSize};
            }
        }
    }

    .WP-card-modal-bonus-list {
        margin: 0;
        padding: 4px 0;
        list-style: none;

        background: rgba(4, 78, 115, 0.3);

        > li {
            padding: 0 8px;
            text-align: center;
            width: 33.333%;
        }
    }

    .WP-card-modal-bonus-list-amount {
        p {
            ${({ theme }) => theme.breakpoints.up('sm')} {
                font-size: ${({ theme }) => theme.typography.size.h5.fontSize};
            }

            ${({ theme }) => theme.breakpoints.up('lg')} {
                font-size: ${({ theme }) => theme.typography.size.h4.fontSize};
            }
        }
    }

    .WP-card-modal-bonus-list-text {
        ${({ theme }) => theme.breakpoints.up('lg')} {
            font-size: ${({ theme }) => theme.typography.size.h6.fontSize};
        }
    }

    .WP-card-modal-title {
        ${({ theme }) => theme.breakpoints.up('lg')} {
            font-size: ${({ theme }) => theme.typography.size.h6.fontSize};
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            font-size: ${({ theme }) => theme.typography.size.h5.fontSize};
        }
    }

    .WP-card-modal-discount,
    .WP-card-modal-earn {
        ${({ theme }) => theme.breakpoints.up('lg')} {
            font-size: ${({ theme }) => theme.typography.size.h6.fontSize};
        }
    }
`;
