import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../hooks/useTranslation';
import PropTypes from 'prop-types';

import { Box, useMediaQuery } from '@material-ui/core';

import WrapperSkeleton from '../OfferCardSkeleton/WrapperSkeleton';
import { shopSel } from '../../../store/shop';
import SectionTitle from '../SectionTitle/SectionTitle';
import SeeMoreItems from '../SeeMoreItems/SeeMoreItems';
import { globalSel } from '../../../store/global';

const Energy = ({
    sectionSubtitle,
    selector = 'WebEnergySKUs',
    limit,
    limitSkeleton,
    cardGridSizeSm,
    skeletonGridSize,
}) => {
    const t = useTranslation();
    const section = useSelector(shopSel[selector]);
    const theme = useSelector(globalSel.themeSelector);
    const isLoading = useSelector(shopSel.isLoading);

    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });

    const data = section?.Listings || [];

    return (
        <Box
            component="section"
            className="WP-energy WP-hide-empty-space"
            pb={[10, 12, 12, 16, 20]}
        >
            {isLoading ? (
                <WrapperSkeleton
                    limitSkeleton={limitSkeleton}
                    skeletonGridSize={skeletonGridSize}
                    cardGridSizeSm={cardGridSizeSm}
                />
            ) : !data.length ? null : (
                <>
                    <SectionTitle
                        title={t('energy.title')}
                        subtitle={t(sectionSubtitle)}
                        customIcon="energy-offers"
                    />

                    <SeeMoreItems
                        section="energy"
                        data={data}
                        limit={limit}
                        spacing={breakpointsXL ? 10 : breakpointsLG ? 5 : 4}
                        cardGridSizeSm={cardGridSizeSm}
                    />
                </>
            )}
        </Box>
    );
};

Energy.propTypes = {
    sectionSubtitle: PropTypes.string,
    selector: PropTypes.string,
    limit: PropTypes.number,
    limitSkeleton: PropTypes.array,
    cardGridSizeSm: PropTypes.number,
    skeletonGridSize: PropTypes.bool,
};

export default Energy;
