export const leaderboardSlider = {
    300: {
        slidesPerView: 1.03,
    },
    400: {
        slidesPerView: 1.03,
    },
    768: {
        slidesPerView: 2,
    },
    1280: {
        slidesPerView: 2,
        allowTouchMove: false,
    },
    1440: {
        slidesPerView: 2.5,
        allowTouchMove: false,
    },
    1620: {
        slidesPerView: 3,
        allowTouchMove: false,
    },
    1920: {
        slidesPerView: 3.5,
        allowTouchMove: false,
    },
};

export const newsSlider = {
    300: {
        slidesPerView: 1.03,
    },
    400: {
        slidesPerView: 1.03,
    },
    768: {
        slidesPerView: 2,
    },
    1280: {
        slidesPerView: 2,
        allowTouchMove: false,
    },
    1440: {
        slidesPerView: 2.5,
        allowTouchMove: false,
    },
    1620: {
        slidesPerView: 3,
        allowTouchMove: false,
    },
    1920: {
        slidesPerView: 3.5,
        allowTouchMove: false,
    },
};
