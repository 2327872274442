import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '../../../../../hooks/useTranslation';

import { Box, Typography } from 'portal-modules/core-ui';
import { InterstitialLogoutStyled } from './InterstitialLogout.styled';

import Logo from '../../../../../assets/images/Logos/app-logo.png';
import Dice from '../../../../../assets/images/Modal/Iterstitial/dice.png';
import { globalOp } from '../../../../../store/global';
import { useDispatch } from 'react-redux';
import HighlightedText from '../../../HighlightedText/HighlightedText';
import { Button } from '../../../ui-components/Button/Button';
import { LOGIN_MODAL } from '../../../../../constants/modals.constants';
import { useMediaQuery } from '@material-ui/core';
import { setGAEvent } from '../../../../../helpers/GA4Helper';
import { setStorageItem } from '../../../../../helpers/localStorage';

const InterstitialLogout = ({ theme }) => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });

    setGAEvent('impression', {
        screen_name: 'welcome_experience',
        screen_type: 'pop-up',
    });

    const handleModalOpen = () => {
        setStorageItem('claimed_clicked', true);
        setGAEvent('clickEvent', {
            screen_name: 'welcome_experience',
            screen_type: 'pop-up',
            interaction_object: 'claim_rewards',
            interaction_object_category: 'display',
        });

        return dispatch(
            globalOp.handleOpenModal({
                open: true,
                variant: LOGIN_MODAL,
                data: {
                    image: 'login',
                    title: 'login.title',
                    description: 'login.description',
                    buttonText: 'login',
                    loginWithFb: 'true',
                },
            }),
        );
    };

    return (
        <Box
            component={InterstitialLogoutStyled}
            borderRadius={16}
            width="100%"
            height="100%"
            className="WP-modal-small"
        >
            <Box className="popup-header flex-center" height={[120, 120, 120, 125, 147]} py={2}>
                <Box
                    height={1}
                    position="relative"
                    display="flex"
                    alignItems={['center', 'end']}
                    ml={[3, 10, 10, 10, 10]}
                >
                    <Box
                        component={Typography}
                        fWeight="bold"
                        fontFamily="rubik"
                        variant={['p16', 'h6', 'h6', 'h5', 'h4']}
                        color={theme.palette.yellow.A200}
                        className="popup-header-welcome"
                    >
                        {t('welcome.to')}
                    </Box>
                    <Box
                        component="img"
                        src={Logo}
                        alt="logo"
                        className="object-cover"
                        width={[170, 190, 190, 190, 220]}
                        height={[80.45, 89.92, 89.92, 89.92, 104.12]}
                        mt={[3, 0]}
                        position="relative"
                    />
                </Box>
            </Box>
            <Box className="popup-content interstitial-body" pt={3}>
                <Box className="content-title-container flex-center">
                    <Box
                        className="content-title"
                        color={theme.palette.yellow.A200}
                        px={[2, 4, 5, 15]}
                        py={1}
                        dangerouslySetInnerHTML={{
                            __html: t('get.reward.for.first.login'),
                        }}
                    />
                </Box>

                <Box
                    className="WP-bonus-container flex-center"
                    position="relative"
                    pt={[7, 15]}
                    pb={[10, 15]}
                >
                    <Box
                        component="img"
                        src={Dice}
                        alt="Dice Image"
                        className="object-contain"
                        height={['138px', '138px', '138px', '170px', '193px']}
                        width={['170px', '170px', '170px', '170px', '240px']}
                    />

                    <HighlightedText highlightedText text="5" />
                </Box>

                <Box className="claim-bonus flex-center" py={1}>
                    <Box
                        className="claim-bonus-title"
                        dangerouslySetInnerHTML={{
                            __html: t('interstitial.exclusive.offer'),
                        }}
                    />
                </Box>
            </Box>

            <Box className="popup-footer" px={2}>
                <Button
                    onClick={handleModalOpen}
                    variant="secondary"
                    size={
                        breakpointsXL
                            ? 'large'
                            : breakpointsLG
                            ? 'medium'
                            : breakpointsSM
                            ? 'small'
                            : 'extra-small'
                    }
                >
                    {t('claim.rewards')}
                </Button>
            </Box>
        </Box>
    );
};

InterstitialLogout.propTypes = {
    theme: PropTypes.object,
    pathName: PropTypes.string,
};

export default InterstitialLogout;
