import axios from 'axios';
import { API_URL } from '../../../constants/api.constants';
import { DEFAULT_HEADERS } from '../../../constants';
import { SignatureGenerator } from '@scopely/withbuddies-web-signature-generator';

import { getStorageItem } from '../../../helpers/localStorage';
import { LANGUAGES } from '../../../constants/languages.constantants';
import { removeSessionStorageItem } from '../../../helpers/sessionStorage';
import { getSessionToken } from '../../../bridge';
import { tracker } from '../../../configs/titan.config';

removeSessionStorageItem('session');
const env = process.env.REACT_APP_ENVIRONMENT;
const addSignature = env === 'production' || env === 'staging';
const axiosInstance = axios.create({
    baseURL: API_URL,
});

const fetch = async ({
    url = API_URL,
    endPoint = '/',
    params = {},
    body = {},
    method = 'GET',
    headers = {},
}) => {
    const SessionToken = getSessionToken() && !params?.BypassSignature ? getSessionToken() : null;
    const c_locale = getStorageItem('language')?.locale || LANGUAGES[0].locale;
    const c_version = getStorageItem('app_version');
    const c_titan_token = tracker.getDeviceToken();

    let config = {
        method,
        params: {
            c_locale,
            BypassSignature: false,
            ...(SessionToken && { SessionToken: SessionToken }),
            c_platform: 'web',
            c_store: 'xsolla',
            c_bundle: 'yahtzeenux',
            ...params,
        },
        url: `${url}${endPoint}`,
    };
    if (c_version) config.params.c_version = c_version;
    if (c_titan_token) config.params.c_titan_token = c_titan_token;

    config.headers = {
        ...DEFAULT_HEADERS,
        ...headers,
    };

    if (method === 'POST') {
        config.data = {
            c_locale,
            ...body,
        };
    }
    if (addSignature) {
        try {
            const request = {
                path: endPoint,
                method: config.method,
                query: config?.params ? jsonToQueryParams(config.params) : '',
                body: JSON.stringify(config?.data),
            };

            const signatureGenerator = SignatureGenerator.getInstance().setUserToken(SessionToken);
            config.headers['X-WithBuddies-Signature'] = await signatureGenerator.calculateSignature(
                request,
            );
        } catch (error) {
            /* eslint no-console: "off" */
            console.error(error);
        }
    }

    return axiosInstance(config);
};

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.response) {
            error.data =
                error.response.status === 500
                    ? { message: 'Something went wrong, please try again.' }
                    : error.response.data;
        } else if (!window.navigator.onLine) {
            error.data = {
                code: 12030,
                message: 'Connection lost. Please try again...',
            };
        } else {
            error.data = {
                message: 'Something went wrong, please try again.',
            };
        }
        return Promise.reject(error);
    },
);

const jsonToQueryParams = (data) => {
    return Object.keys(data)
        .map((key) => {
            return encodeURIComponent(key) + '=' + data[key]; //encodeURIComponent(data[key]);
        })
        .join('&');
};

export { fetch, axiosInstance };
