import { globalSlice } from './globalSlice';
import { fetch } from '../../core/infra/dice-admin/FetchService';

import { getSessionToken, removeSessionToken, saveSessionToken } from '../../bridge';
import { getStorageItem, removeStorageItem, setStorageItem } from '../../helpers/localStorage';
import { formatPopups, isEmptyObject } from '../../helpers/utils';
import { notificationsOp } from '../notifications';
import { dailyDealsOp } from '../dailyDeals';
import { shopOp } from '../shop';
import { shopSlice } from '../shop/shopSlice';
import {
    ACCOUNT_SUSPENDED_MODAL,
    NO_CONNECTED_FB_ACCOUNT_MODAL,
    NO_CONNECTED_SCOPELY_ACCOUNT_MODAL,
    WELCOME_MODAL,
} from '../../constants/modals.constants';
import { LANGUAGES } from '../../constants/languages.constantants';
import { titanAnalyticsEvents } from '../../helpers/titanAnalytics';
import { loginEvent, setGAEvent } from '../../helpers/GA4Helper';
import dailyRewardsSlice, { dailyRewardsOp } from '../dailyReward';
import { dailyOffersOp } from '../dailyOffers';
import { paymentsBackendDelegate } from '../../configs/payments.config';
import {
    TRACKING_ERROR_CODES,
    TRACKING_ACTIONS,
    TRACKING_TYPES,
    loginAnalyticsTracking,
} from '../../helpers/loginAnalyticsTracking';

export const loginAsync = () => {
    const { setLoading, setProfileLoading, setAuthenticated } = globalSlice.actions;
    const token = getStorageItem('accessToken');
    const type = getStorageItem('loginProcessing');
    return async (dispatch) => {
        try {
            const { data } = await fetch({
                endPoint: 'me/sessions',
                method: 'POST',
                params: {
                    BypassSignature: true,
                    c_bundle: 'yahtzeenux',
                    c_store: 'xsolla',
                    c_platform: 'web',
                },
                body: { ssoToken: token, ssoType: type === 'withFacebook' ? 1 : 9, email: '' },
            });
            if (!data) {
                loginAnalyticsTracking({
                    type:
                        getStorageItem('loginProcessing') === 'withFacebook'
                            ? TRACKING_TYPES.FACEBOOK
                            : TRACKING_TYPES.SCOPELY_ID,
                    action: TRACKING_ACTIONS.LOGIN_FAILED,
                    errorCode: `${TRACKING_ERROR_CODES.ME_SESSIONS_NOT_DATA}${TRACKING_ERROR_CODES.UNKNOWN_ERROR}`,
                });
                return {};
            }

            let connectionType =
                getStorageItem('loginProcessing') === 'withFacebook' ? 'Facebook' : 'ScopelyId';

            paymentsBackendDelegate.setCurrentUserId(data.Data.Id);
            titanAnalyticsEvents.updateAnalyticsUser(data.Data.Id);
            titanAnalyticsEvents.login(connectionType, data.Data.Id, data.Data.Email);

            loginEvent(connectionType, data.Data.Id);

            setStorageItem('accessToken', token);
            saveSessionToken(data?.Data?.SecretKey);

            dispatch(globalOp.getFeatures());
            dispatch(globalOp.handleCloseModal());
            dispatch(globalSlice.actions.setSidebar({ side: 'rightSidebar', open: false }));

            loginAnalyticsTracking({
                type:
                    getStorageItem('loginProcessing') === 'withFacebook'
                        ? TRACKING_TYPES.FACEBOOK
                        : TRACKING_TYPES.SCOPELY_ID,
                action: TRACKING_ACTIONS.LOGIN_SUCCESSFULL,
            });

            dispatch(setAuthenticated(true));
            removeStorageItem('authorizing');

            window?.DD_RUM?.addAction('logins-count', {});
        } catch (err) {
            loginAnalyticsTracking({
                type:
                    getStorageItem('loginProcessing') === 'withFacebook'
                        ? TRACKING_TYPES.FACEBOOK
                        : TRACKING_TYPES.SCOPELY_ID,
                action: TRACKING_ACTIONS.LOGIN_FAILED,
                errorCode: `${TRACKING_ERROR_CODES.ME_SESSIONS_FETCH_CATCH}${
                    err?.data?.Error?.ErrorCode || TRACKING_ERROR_CODES.UNKNOWN_ERROR
                }`,
            });

            if (err?.data?.Error?.ErrorCode === 4003 || err?.data?.Error?.ErrorCode === 4108) {
                dispatch(shopSlice.actions.setLoading(false));
                dispatch(setProfileLoading(false));

                setGAEvent('impression', {
                    screen_name: 'no_connected_account',
                    screen_type: 'pop-up',
                });

                removeStorageItem('accessToken');
                setStorageItem('noConnectedAccount', true);
                removeStorageItem('authorizing');

                return dispatch(
                    globalOp.handleOpenModal({
                        open: true,
                        variant:
                            type === 'withFacebook'
                                ? NO_CONNECTED_FB_ACCOUNT_MODAL
                                : NO_CONNECTED_SCOPELY_ACCOUNT_MODAL,
                        data:
                            type === 'withFacebook'
                                ? NO_CONNECTED_FB_ACCOUNT_MODAL
                                : NO_CONNECTED_SCOPELY_ACCOUNT_MODAL,
                    }),
                );
            }

            if (err?.data?.Error?.ErrorCode === 4002) {
                removeStorageItem('accessToken');
                removeSessionToken();
            } else {
                handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'Error on login',
                });
            }
            removeStorageItem('authorizing');
            dispatch(setProfileLoading(false));
            dispatch(setLoading(false));
        }
    };
};
export const getFtue = (claimed = false) => {
    const { setFtue } = globalSlice.actions;
    const SessionToken = getSessionToken();
    return async (dispatch) => {
        try {
            //dispatch(setFtue());
            const { data } = await fetch({
                endPoint: `daily-deals/ftue/me`,
                method: 'GET',
                params: {
                    claim: claimed,
                    BypassSignature: false,
                    SessionToken,
                    c_bundle: 'yahtzeenux',
                    c_store: 'xsolla',
                },
            });
            dispatch(setFtue(data?.Data?.Ftue));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }
    };
};
export const getPopups = () => {
    const { setPopUps } = globalSlice.actions;
    const SessionToken = getSessionToken();

    return async (dispatch) => {
        try {
            const { data } = await fetch({
                endPoint: 'popups',
                method: 'GET',
                params: {
                    SessionToken,
                    BypassSignature: false,
                },
            });

            /** FOR TESTING INTERSTITIAL MODAL UNCOMMENT THIS */
            // const data = {
            //     IsSuccess: true,
            //     AutoConfigGitHash: '8dc6e7d8223d076e8ee840a24e30c9f932fa6fd0',
            //     Data: {
            //         PopUps: [
            //             {
            //                 Id: 'ada67b90-67b6-4b7d-b41a-efac60d10292',
            //                 TriggerScreen: 'home',
            //                 StartDate: '2024-01-17T08:00:00Z',
            //                 EndDate: '2024-01-29T07:59:00Z',
            //                 Priority: 5,
            //                 ImageUrl:
            //                     'https://s3.us-east-1.amazonaws.com/withbuddies-images/w7AURXcFX77ZMtOgBA3bgZSUJXVE4670V_Z3gfwHXXw1',
            //                 BackgroundImageUrl:
            //                     'https://s3.us-east-1.amazonaws.com/withbuddies-images/X6YX7HTia6eH-6y2F_iCwOfaWnq9Po4oYlNhm6OC9u41',
            //                 HeaderImageUrl:
            //                     'https://s3.us-east-1.amazonaws.com/withbuddies-images/r3g3ZUeUxD8E7dcStTK3LbB9Sfmz0PWKq7TIRaRalkk1',
            //                 BodyText: 'Incredible Dice, Wallpaper, and more on the web!',
            //                 CtaText: 'Check it out!',
            //                 CtaLink: 'https://playyahtzee.com/loyalty-point-shop',
            //             },
            //         ],
            //     },
            // };
            /** END TESTING INTERSTITIAL */

            const forattedPopups = formatPopups(data?.Data?.PopUps);
            dispatch(setPopUps(forattedPopups));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }
    };
};

export const claimRewards = () => {
    const SessionToken = getSessionToken();
    const referenceId = '2a79f019-49dd-4187-96c4-99034d2c8bdd';

    return async (dispatch) => {
        try {
            const { data } = await fetch({
                endPoint: `direct-rewards/claim/${referenceId}`,
                method: 'POST',
                params: { BypassSignature: false, SessionToken },
            });
            const Inventory = data?.Data?.Inventory;
            if (Inventory) {
                dispatch(
                    globalOp.handleOpenModal({
                        open: true,
                        variant: WELCOME_MODAL,
                    }),
                );
            }
            //setStorageItem('rewards', 'claimed');
        } catch (err) {
            alert(err.data.Error.Message);
        }
    };
};

export const getUserData = () => {
    const SessionToken = getSessionToken();

    const { setCurrentUser, setProfileLoading, setTabsLoading } = globalSlice.actions;
    const { setLoading } = shopSlice.actions;

    return async (dispatch, getState) => {
        const state = getState();
        dispatch(setProfileLoading(true));
        dispatch(setLoading(true));
        dispatch(setTabsLoading(true));

        try {
            const user = await fetch({
                endPoint: `me/profile`,
                method: 'GET',
                params: {
                    BypassSignature: false,
                    SessionToken,
                },
            });

            const emptyObject = isEmptyObject(user?.data?.Data?.BanStatuses);

            if (!emptyObject) {
                if (getSessionToken()) dispatch(logout());
                dispatch(setProfileLoading(false));
                dispatch(shopSlice.actions.setLoading(false));

                setGAEvent('impression', {
                    screen_name: 'account_suspended',
                    screen_type: 'pop-up',
                });

                return dispatch(
                    globalOp.handleOpenModal({
                        open: true,
                        variant: ACCOUNT_SUSPENDED_MODAL,
                        data: {
                            image: 'accountSuspend',
                            title: 'account.suspended.title',
                            description: 'account.suspended.description',
                            buttonText: 'customer.support',
                            className: 'WP-account-suspend',
                            redirectTo: 'helpshift',
                        },
                    }),
                );
            }

            const inventory = await fetch({
                endPoint: `inventory/me/inventory`,
                method: 'GET',
                params: {
                    BypassSignature: false,
                    SessionToken,
                },
            });

            const UserId = user?.data?.Data?.UserId;

            const level = await fetch({
                endPoint: `user-infos/find`,
                method: 'POST',
                params: { BypassSignature: false, SessionToken },
                body: {
                    userIds: [UserId],
                },
            });

            if (user?.data) {
                const Level = level?.data?.Data?.UserInfos?.[UserId]?.StarLevel;
                const loyaltyPoints = inventory?.data?.Data?.OwnedItems?.LoyaltyPoint?.Count;
                const entrySystemEnergy =
                    inventory?.data?.Data?.OwnedItems?.EntrySystemEnergy?.Count;
                user.data.Data.Level = Level ? Level : 0;
                user.data.Data.LoyaltyPoints = loyaltyPoints ? loyaltyPoints : 0;
                user.data.Data.EntrySystemEnergy = entrySystemEnergy ? entrySystemEnergy : 0;
                paymentsBackendDelegate.setCurrentUserId(user.data.Data.UserId);
                dispatch(setCurrentUser(user.data.Data));
                setStorageItem('userID', user.data.Data.UserId);

                dispatch(getPageData());
                dispatch(setProfileLoading(false));
                if (!state?.global?.loggedIn) dispatch(getPopups());

                dispatch(claimRewards());
            }
        } catch (err) {
            if (err?.data?.Error?.StatusCode === 403) {
                if (err?.data?.Error?.ErrorCode === 4102) {
                    dispatch(logout());
                }
            } else {
                handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'Error on login',
                });
            }
            dispatch(setProfileLoading(false));
        }
    };
};

const getLoyaltyPoints = () => {
    const SessionToken = getSessionToken();
    const { setProfileLoading, setLoyaltyPoints } = globalSlice.actions;
    return async (dispatch) => {
        try {
            dispatch(setProfileLoading(true));
            const loyaltyPoints = await fetch({
                endPoint: `inventory/me/inventory`,
                method: 'GET',
                params: {
                    BypassSignature: false,
                    SessionToken,
                },
            });

            if (loyaltyPoints) {
                const LoyaltyPoints = loyaltyPoints?.data?.Data?.OwnedItems?.LoyaltyPoint?.Count;
                const BonusRolls = loyaltyPoints?.data?.Data?.OwnedItems?.BonusRoll?.Count;
                dispatch(setLoyaltyPoints({ LoyaltyPoints, BonusRolls }));
                dispatch(setProfileLoading(false));
            }
        } catch (err) {
            if (err?.data?.Error?.StatusCode !== 403) {
                handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'Error on login',
                });
            }
            dispatch(setProfileLoading(false));
        }
    };
};

const getPageData = (lng) => {
    const { getNotifications } = notificationsOp;
    const SessionToken = getSessionToken();
    const { setLanguage, setTabsLoading } = globalSlice.actions;
    const selectedLng = lng || getStorageItem('language') || LANGUAGES[0];
    return async (dispatch) => {
        try {
            dispatch(setLanguage(selectedLng));
            dispatch(shopOp.getOffers()).then(() => {
                if (SessionToken) {
                    dispatch(getNotifications());
                    dispatch(dailyDealsOp.getDailyDeals());
                    dispatch(dailyRewardsOp.getDailyRewards());
                    dispatch(dailyOffersOp.getDailyOffers());
                } else {
                    dispatch(setTabsLoading(false));
                }
            });
        } catch (err) {
            handleOpenSnackbar({
                open: true,
                variant: 'error',
                message: 'Error on login',
            });
        }
    };
};

const logout = () => (dispatch, getState) => {
    const { setCurrentUser, setPopUps, setProfileLoading } = globalSlice.actions;
    const { setDailyRewards } = dailyRewardsSlice.actions;
    const state = getState();
    const user = state.global.currentUser;

    paymentsBackendDelegate.setCurrentUserId(null);
    titanAnalyticsEvents.updateAnalyticsUser();
    setTimeout(() => {
        titanAnalyticsEvents.logout('facebook', user?.UserId, user?.Email);
        loginAnalyticsTracking({
            type:
                getStorageItem('loginProcessing') === 'withFacebook'
                    ? TRACKING_TYPES.FACEBOOK
                    : TRACKING_TYPES.SCOPELY_ID,
            action: TRACKING_ACTIONS.LOG_OUT,
        });
    }, 1000);

    dispatch(setCurrentUser(null));
    dispatch(setPopUps(null));
    dispatch(setDailyRewards(null));
    dispatch(shopSlice.actions.removeSection('WebNews'));

    removeStorageItem('accessToken');
    removeSessionToken();
    removeStorageItem('userID');
    removeStorageItem('fbData');
    removeStorageItem('loginProcessing');
    removeStorageItem('pages');
    removeStorageItem('source');

    dispatch(globalOp.getFeatures());
    dispatch(setProfileLoading(false));
};

const handleOpenSnackbar = (data) => (dispatch) => {
    const { setSnackbar } = globalSlice.actions;
    dispatch(setSnackbar(data));
};

const handleCloseSnackbar = () => (dispatch) => {
    const { setSnackbar } = globalSlice.actions;

    dispatch(
        setSnackbar({
            open: false,
            variant: '',
        }),
    );
};

const handleChangeTheme = (data) => (dispatch) => {
    const { setTheme } = globalSlice.actions;

    dispatch(setTheme(data));
};

const handleOpenModal = (data) => (dispatch) => {
    const { setModal } = globalSlice.actions;

    dispatch(setModal(data));
};

const handleCloseModal = () => (dispatch) => {
    const { setModal } = globalSlice.actions;

    dispatch(
        setModal({
            open: false,
            variant: '',
        }),
    );
};

const openInterstitials = (data) => (dispatch) => {
    const { setInterstitials } = globalSlice.actions;

    dispatch(setInterstitials(data));
};

const closeInterstitials = () => (dispatch) => {
    const { setInterstitials } = globalSlice.actions;

    dispatch(
        setInterstitials({
            open: false,
        }),
    );
};

const getConfigs = () => {
    const { setConfigs } = globalSlice.actions;

    return async (dispatch) => {
        try {
            const { data } = await fetch({
                endPoint: 'config/web',
                method: 'GET',
                params: {
                    BypassSignature: true,
                    c_bundle: 'yahtzeenux',
                    c_store: 'xsolla',
                    c_platform: 'web',
                },
            });
            dispatch(setConfigs(data?.Data));
        } catch (err) {
            handleOpenSnackbar({
                open: true,
                variant: 'error',
                message: 'Error on login',
            });
        }
    };
};

const getFeatures = () => {
    const { setFeatures } = globalSlice.actions;

    const SessionToken = getSessionToken();
    return async (dispatch) => {
        try {
            const { data } = await fetch({
                endPoint: 'web/features',
                method: 'GET',
                params: {
                    BypassSignature: !SessionToken,
                    SessionToken: SessionToken || undefined,
                    c_bundle: 'yahtzeenux',
                    c_store: 'xsolla',
                    c_platform: 'web',
                },
            });
            dispatch(setFeatures(data?.Data));
        } catch (err) {
            handleOpenSnackbar({
                open: true,
                variant: 'error',
                message: 'Error on login',
            });
        }
    };
};

const setNotFound = (data) => (dispatch) => {
    const { setNotFound } = globalSlice.actions;

    dispatch(setNotFound(data));
};

const handleFbData = (data) => {
    const { setFacebookData } = globalSlice.actions;

    return (dispatch) => dispatch(setFacebookData(data));
};

export const globalOp = {
    loginAsync,
    handleCloseSnackbar,
    handleOpenSnackbar,
    handleChangeTheme,
    handleOpenModal,
    handleCloseModal,
    logout,
    getConfigs,
    getFeatures,
    handleFbData,
    getUserData,
    getFtue,
    getPopups,
    getPageData,
    getLoyaltyPoints,
    claimRewards,
    setNotFound,
    openInterstitials,
    closeInterstitials,
};
