import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';

import SectionTitle from '../SectionTitle/SectionTitle';
import TopLeadersSkeleton from '../Leaderboard/TopLeaders/TopLeadersSkeleton';
import { useTranslation } from '../../../hooks/useTranslation';

import LatestUpdatesSlider from './LatestUpdatesSlider';
import { getSlicedArray } from '../../../helpers/utils';
import { __mockLatestUpdates } from './__mockData';

const HomeLatestUpdates = () => {
    const t = useTranslation();
    const [filteredData, setFilteredData] = useState([]);
    const isLoading = false;

    const getLatestUpdates = async () => {
        const latestUpdates = await __mockLatestUpdates();

        setFilteredData(getSlicedArray(latestUpdates, 0, 3));
    };
    useEffect(() => {
        getLatestUpdates();
    }, []);

    return (
        <Box component="section" mt={(5, 6, 6, 8, 10)} pb={[10, 12, 12, 16, 20]}>
            {isLoading ? (
                <TopLeadersSkeleton />
            ) : !filteredData?.length ? null : (
                <>
                    <SectionTitle title={t('welcome')} />

                    <LatestUpdatesSlider isHomePage data={filteredData} />
                </>
            )}
        </Box>
    );
};

export default HomeLatestUpdates;
