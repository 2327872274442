import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { useTranslation } from '../../../hooks/useTranslation';

const DayIso = ({ date, sameDay, format = 'dddd' }) => {
    const t = useTranslation();
    return (
        <span>
            {sameDay && moment().isSame(moment(date), 'day')
                ? t('today')
                : moment(date).format(format)}
        </span>
    );
};

export default DayIso;

DayIso.propTypes = {
    date: PropTypes.string,
    sameDay: PropTypes.bool,
    format: PropTypes.string,
};
