import { notificationsSlice } from './notificationsSlice';
import { globalOp } from '../global';
import { fetch } from '../../core/infra/dice-admin/FetchService';

import { getStorageItem } from '../../helpers/localStorage';
import { oneDayDiff } from '../../helpers/utils';
import { getSessionToken } from '../../bridge';
import shopSlice from '../shop';

const getNotifications = () => {
    const { setData, setLoading } = notificationsSlice.actions;
    const SessionToken = getSessionToken();

    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const notifications = await fetch({
                endPoint: `me/news`,
                method: 'GET',
                params: {
                    BypassSignature: false,
                    SessionToken,
                    c_platform: 'web',
                },
            });

            const NotificationsData = {
                messages: [],
                notifications: [],
                news: [],
                unreadCount: 0,
            };

            for (const element of notifications?.data?.Data?.Items) {
                if (element?.Config?.Category?.Name === 'Web Message Banner') {
                    if (element?.Status === 0 && new Date(element?.Config?.EndDate) > new Date()) {
                        NotificationsData.notifications.push(element);
                        NotificationsData.unreadCount++;
                    } else if (
                        element?.Status === 1 &&
                        oneDayDiff(
                            getStorageItem('readDates')?.[element?.Config.Id],
                            new Date(element?.Config?.EndDate),
                        )
                    )
                        NotificationsData.notifications.push(element);
                } else NotificationsData.news.push(element);
            }
            if (NotificationsData.news.length) {
                dispatch(shopSlice.actions.addSections(['WebNews']));
            }
            dispatch(setData(NotificationsData));
        } catch (err) {
            // todo Add error handler
        } finally {
            dispatch(setLoading(false));
        }
    };
};

const readNotification = (type, id) => {
    const { changeStatus, setLoading } = notificationsSlice.actions;
    const SessionToken = getSessionToken();

    return async (dispatch) => {
        try {
            const statusUpdate = await fetch({
                endPoint: `me/news/${id}/read`,
                method: 'POST',
                params: { BypassSignature: false, SessionToken },
            });
            if (statusUpdate?.data?.IsSuccess === true) dispatch(changeStatus({ type, id }));
        } catch (err) {
            globalOp.handleOpenSnackbar({
                open: true,
                variant: 'error',
                message: 'Something went wrong',
            });
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const notificationsOp = {
    getNotifications,
    readNotification,
};
