import styled from 'styled-components';

import bgImage from '../../../../assets/images/Home/Logout/CTABg.png';
import rightCTAArt from '../../../../assets/images/Home/Logout/rightCTAArt.png';
import leftCTAArt from '../../../../assets/images/Home/Logout/leftCTAArt.png';
import shine2 from '../../../../assets/images/Home/Logout/shine2.png';

export const CTAStyled = styled.section`
    min-height: 400px;

    justify-content: center;
    flex-direction: column;

    background-image: url(${bgImage});
    z-index: 2;
    overflow: hidden;

    ${({ theme }) => theme.breakpoints.up('lg')} {
        flex-direction: row;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 6px;
            z-index: -1;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &:before {
            width: 125px;
            height: 350px;
            background-image: url(${leftCTAArt});
            border-bottom-left-radius: 20px;
            left: 0;
        }

        &:after {
            width: 240px;
            height: 210px;
            background-image: url(${rightCTAArt});
            border-bottom-right-radius: 20px;
            right: 0;
        }
    }

    .WP-logo-wrapper {
        position: relative;
        ${({ theme }) => theme.breakpoints.up('lg')} {
            width: 50%;
        }

        .WP-CTA-logo {
            width: 100%;

            ${({ theme }) => theme.breakpoints.up('lg')} {
                width: 400px;
            }
        }

        &:before {
            width: 100%;
            height: 100%;

            position: absolute;
            left: -266px;
            top: -17px;
            content: url(${shine2});
            z-index: -1;

            display: inline-block;
            mix-blend-mode: screen;
        }
    }

    .WP-download-links {
        display: flex;
        position: absolute;
        bottom: 12px;
        left: 50%;
        transform: translateX(-50%);

        ${({ theme }) => theme.breakpoints.up('lg')} {
            display: block;
            position: initial;
            transform: initial;
        }

        > a {
            width: 170px;
            height: 54px;

            > img {
                width: 100%;
            }

            ${({ theme }) => theme.breakpoints.up('lg')} {
                width: auto;
                height: 81px;

                > img {
                    height: 100%;
                }
            }
        }

        > a ~ a {
            margin-left: 12px;

            ${({ theme }) => theme.breakpoints.up('lg')} {
                margin-top: 12px;
                margin-left: 0;
            }
        }
    }
`;
