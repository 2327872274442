import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box, useMediaQuery } from '@material-ui/core';
import { Icon, Typography } from 'portal-modules/core-ui';

import { withPurchase } from '../../../../../../hoc/withPurchase';
import { USNumberFormat } from '../../../../../../helpers/utils';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { Button } from '../../../../ui-components/Button/Button';
import Badge from '../../../../Badge/Badge';
import TooltipWrapper from '../../../../Tooltip/Tooltip';
import DailyOfferCommodity from '../../../../DailyOfferCommodity/DailyOfferCommodity';
import DayLabel from '../../../../DayLabel/DayLabel';
import { globalSel } from '../../../../../../store/global';
import { DailyOfferBodyStyled } from '../DailyOffer.styled';
import LoyaltyPointsImage from '../../../../../../assets/images/Global/loyaltyPoints.png';
import DailyTimer from '../../../../DailyOffers/DailyOffersSlider/DailyTimer';
import DailyUpcomingOfferBody from './DailyUpcomingOfferBody';
const DailyOfferBody = ({ offer, handlePurchase }) => {
    const theme = useSelector(globalSel.themeSelector);
    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
    const breakpointsMacSm = useMediaQuery(theme.breakpoints.between(1441, 1779), {
        noSsr: true,
    });
    const breakpointsMac = useMediaQuery(theme.breakpoints.between(1780, 1800), {
        noSsr: true,
    });

    const { StartDate: start, EndDate: end } = offer;
    const item = offer?.ListingsSku;
    const t = useTranslation();

    if (!item) return false;

    return (item.isUpcomming && !offer.ShowPrice) || !offer.ShowPrice ? (
        <DailyUpcomingOfferBody offer={offer} />
    ) : (
        <Box
            component={DailyOfferBodyStyled}
            className="WP-modal-body"
            backgroundImage={offer?.BackgroundImageUrl}
            display="flex"
            alignItems="center"
            flexDirection="column"
            position="relative"
            height={[320, 370, 370, breakpointsMacSm ? 410 : breakpointsMac ? 410 : 450, 450]}
            py={[2, 3]}
            px={[2, 3, 3, 5]}
        >
            <Box flexGrow={1} width={1}>
                <Box
                    component={Typography}
                    className="WP-modal-title text-uppercase"
                    fontFamily="rubik"
                    variant={['h4', 'h4', 'h4', breakpointsMac ? 'h4' : 'h3', 'h3']}
                    fWeight="bold"
                    textAlign="center"
                >
                    {t('daily.offer')}
                </Box>

                <Box width="fit-content" mx="auto">
                    <DailyTimer start={start} end={end} />
                </Box>

                <Box width="fit-content" mx="auto" mt={[3, 4]}>
                    <DayLabel date={start} />
                </Box>
            </Box>

            <Box className="WP-commodity-position">
                <DailyOfferCommodity data={item?.Credit?.Adjustments} />
            </Box>

            <Box width={1}>
                <Box
                    component={Typography}
                    className="WP-modal-discount text-decoration-line-through"
                    variant="p16"
                    fWeight="bold"
                    textAlign="center"
                >
                    {item.discount}
                </Box>

                <Box position="relative" my={[1, 1, 1, 2]}>
                    {item.discount && item.availableCount ? (
                        <Badge text={`${item.discount}% ${t('off')}`} buttonBadge />
                    ) : item?.DisplayDetails?.BannerText ? (
                        <Badge text={item?.DisplayDetails?.BannerText} buttonBadge />
                    ) : null}

                    <Button
                        completed={item.availableCount != null && item.availableCount <= 0}
                        onClick={handlePurchase({
                            commodity: item.discountedCommodity?.CommodityKey,
                            iapSku: item.IapSku,
                            storeListingName: item.Name,
                            item: {
                                ga: true,
                                name: item?.DisplayDetails?.DisplayName,
                                price: item.discountedQuantity,
                                section: 'daily_offer',
                            },
                        })}
                        preventLoading
                        size={
                            breakpointsXL
                                ? 'large'
                                : breakpointsLG
                                ? 'medium'
                                : breakpointsSM
                                ? 'small'
                                : 'extra-small'
                        }
                        variant="secondary"
                    >
                        {item.availableCount != null && item.availableCount <= 0 ? (
                            t('purchased')
                        ) : (
                            <>
                                {item.discountedCommodity.CommodityKey === 'USP' ? (
                                    <span>$</span>
                                ) : (
                                    <Box
                                        component="img"
                                        src={LoyaltyPointsImage}
                                        alt="loyalty points"
                                        width={[16, 20, 20, 24, 30]}
                                        height={[16, 20, 20, 24, 30]}
                                    />
                                )}
                                {item.discountedQuantity && USNumberFormat(item.discountedQuantity)}
                            </>
                        )}
                    </Button>
                </Box>

                {item.earnLP && (
                    <Box className="flex-center">
                        <TooltipWrapper text={t('loyalty.point.tooltip')} position="top">
                            <Box
                                width={16}
                                height={16}
                                className="flex-center"
                                borderRadius="50%"
                                bgcolor={theme.palette.common.white}
                            >
                                <Icon
                                    className="icon-info"
                                    size={8}
                                    color={theme.DailyOffer.modal.infoIconColor}
                                />
                            </Box>
                        </TooltipWrapper>

                        <Box
                            component={Typography}
                            variant="p16"
                            fWeight="medium"
                            color={theme.palette.common.white}
                            mx={1}
                        >
                            {`${t('purchase.and.earn')} ${item.earnLP?.Quantity}`}
                        </Box>

                        <Box
                            component="img"
                            src={LoyaltyPointsImage}
                            alt="loyalty point"
                            width={14}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default withPurchase(DailyOfferBody);

DailyOfferBody.propTypes = {
    offer: PropTypes.object,
    handlePurchase: PropTypes.func,
};
