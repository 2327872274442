import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '../../../../../hooks/useTranslation';

import { Grid, useMediaQuery } from '@material-ui/core';
import { Box, Icon, Typography } from 'portal-modules/core-ui';

import { AccountModalStyled } from '../AccountModal.styled';
import DateFormat from '../../../DateFormat/DateFormat';

const TopLeadersModal = ({ theme, data }) => {
    const t = useTranslation();
    const breakpointsXS = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
    const { type, item } = data;

    const overallWinRateRound = (score) => {
        let winScoreRate = `${score}`;
        return winScoreRate.endsWith('00') ? `${Math.ceil(+winScoreRate)} %` : `${winScoreRate} %`;
    };
    const statsItem =
        type === 2
            ? [
                  {
                      icon: 'icon-rate',
                      key: t('win.rate'),
                      score: `${overallWinRateRound((item.WinRate * 100).toFixed(2))}`,
                  },
                  {
                      icon: 'icon-stats-line',
                      key: t('game.per.member'),
                      score: item.AveragePoints,
                  },
                  {
                      icon: 'icon-cup',
                      key: t('total.victories'),
                      score: item.GamesWon,
                  },

                  {
                      icon: 'icon-games',
                      key: t('game.played'),
                      score: item.GamesPlayed,
                  },
              ]
            : [
                  {
                      icon: 'icon-stats',
                      key: t('average.points'),
                      score: item.AveragePoints,
                  },
                  {
                      icon: 'icon-stats-line',
                      key: t('total.points.won'),
                      score: item.TotalPoints,
                  },
                  {
                      icon: 'icon-games',
                      key: t('game.played'),
                      score: item.GamesPlayed,
                  },
                  {
                      icon: 'icon-yahtzee',
                      key: t('yahtzee'),
                      score: item.TotalYahtzees,
                  },
                  {
                      icon: 'icon-cup',
                      key: t('total.victories'),
                      score: item.GamesWon,
                  },

                  {
                      icon: 'icon-dice',
                      key: t('top.scored.points'),
                      score: item.TopScoredPoints,
                  },
              ];

    const familyInfo = [
        {
            icon: 'icon-star',
            key: t('active.members'),
            score: `${item.TotalMembers} / ${item.FamilyCapacity}`,
        },
        {
            icon: 'icon-star',
            key: t('help.points.this.month'),
            score: item.HelpPointsLastMonth,
        },
        {
            icon: 'icon-star',
            key: t('creation.date'),
            score: <DateFormat date={item.CreationDate} format="DD/MM/YYYY" />,
        },
    ];

    return (
        <Box
            component={AccountModalStyled}
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            overflow="hidden"
            className="WP-leaders-modal WP-modal-medium"
        >
            <Box
                height={type !== 2 ? [160, 250] : [112, 202]}
                position="relative"
                className="flex-center WP-modal-header"
            >
                <Box className="WP-image-container flex-center" flexDirection="column" width="100%">
                    {type !== 2 && (
                        <Box position="relative">
                            <Box
                                className="WP-leader-image flex-center"
                                width={['70px', '100px']}
                                height={['70px', '100px']}
                                mb={[1, 4]}
                            >
                                <Box
                                    component="img"
                                    width="100%"
                                    src={item.AvatarUrl}
                                    alt="Leader Image"
                                />
                            </Box>

                            {item.Level && (
                                <Box
                                    className="WP-level-badge flex-center"
                                    position="absolute"
                                    left={['64px', '64px']}
                                    top={['64px', '64px']}
                                >
                                    <Typography fWeight="bold" variant="p14">
                                        {item.Level}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    )}

                    <Box
                        component={Typography}
                        variant={['p16', 'h4']}
                        fWeight="bold"
                        color={theme.palette.text.main}
                        pb={[1, 4]}
                        sx={{ 'text-shadow': '0px 4px #00000010' }}
                    >
                        {item.Name}
                    </Box>

                    {type === 2 ? (
                        !breakpointsXS && (
                            <Grid container spacing={3}>
                                {familyInfo.map((item, index) => {
                                    return (
                                        <Grid key={index} item sm={4}>
                                            <Box
                                                className="WP-family-info"
                                                display="flex"
                                                alignItems="center"
                                                px={2}
                                                py={2}
                                            >
                                                <Icon
                                                    className={item.icon}
                                                    color={theme.palette.common.white}
                                                    size={24}
                                                />

                                                <Box pl={3}>
                                                    <Box
                                                        component={Typography}
                                                        variant="p12"
                                                        fWeight="normal"
                                                        color={theme.palette.common.white}
                                                    >
                                                        {item.key}
                                                    </Box>
                                                    <Box
                                                        component={Typography}
                                                        variant="p12"
                                                        fWeight="medium"
                                                        color={theme.palette.common.white}
                                                    >
                                                        {item.score}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        )
                    ) : item.FamilyName || item.FamilyAvatarUrl ? (
                        <Box className="WP-leader-family flex-center" px={3} py={1}>
                            {item.FamilyAvatarUrl.startsWith('https:') ||
                            item.FamilyAvatarUrl.endsWith('.png') ? (
                                <Box
                                    component="img"
                                    width="22px"
                                    height="22px"
                                    src={item.FamilyAvatarUrl}
                                    alt="Family Image"
                                    mr={2}
                                />
                            ) : null}

                            <Typography
                                variant="p14"
                                fWeight="bold"
                                color={theme.palette.common.white}
                            >
                                {item.FamilyName}
                            </Typography>
                        </Box>
                    ) : null}
                </Box>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                pb={5}
                pt={2}
                bgcolor={theme.palette.orange.A50}
                px={1}
            >
                <Box className="WP-inner-scroll" px={[4, 6]}>
                    {type === 2 && breakpointsXS && (
                        <Box mb={3} mt={4}>
                            <Grid container spacing={3}>
                                {familyInfo.map((item, index) => {
                                    return (
                                        <Grid key={index} item xs={12}>
                                            <Box
                                                className="WP-family-info"
                                                display="flex"
                                                alignItems="center"
                                                px={[3, 2]}
                                                py={2}
                                            >
                                                <Icon
                                                    className={item.icon}
                                                    color={theme.palette.common.white}
                                                    size={24}
                                                />

                                                <Box
                                                    pl={3}
                                                    width="100%"
                                                    display="flex"
                                                    justifyContent="space-between"
                                                >
                                                    <Box
                                                        component={Typography}
                                                        variant="p12"
                                                        fWeight="normal"
                                                        color={theme.palette.common.white}
                                                    >
                                                        {item.key}
                                                    </Box>
                                                    <Box
                                                        component={Typography}
                                                        variant="p12"
                                                        fWeight="medium"
                                                        color={theme.palette.common.white}
                                                    >
                                                        {item.score}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    )}
                    <Box
                        component={Typography}
                        variant="h6"
                        fWeight="bold"
                        color={theme.palette.brown.A800}
                        mb={3}
                    >
                        {type === 2 ? t('family.stats') : t('player.stats')}
                    </Box>

                    <Grid container spacing={3}>
                        {statsItem.map((item, index) => {
                            return (
                                <Grid item key={index} xs={12} sm={6}>
                                    <Box className="WP-stats-item">
                                        <Icon
                                            className={`${item.icon}`}
                                            color={theme.palette.orange.main}
                                            size={30}
                                        />
                                        <Box ml={4}>
                                            <Box
                                                component={Typography}
                                                variant="p12"
                                                fWeight="normal"
                                                color={theme.palette.brown.A700}
                                            >
                                                {item.key}
                                            </Box>
                                            <Box
                                                component={Typography}
                                                variant="p16"
                                                fWeight="bold"
                                                color={theme.palette.brown.main}
                                            >
                                                {item.score}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Box
                        component={Typography}
                        variant="p14"
                        fWeight="medium"
                        color={theme.palette.common.black}
                        pt={3}
                        textAlign="center"
                    >
                        {t('leaderboard.footer.text')}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

TopLeadersModal.defaultProps = {
    badge: false,
};

TopLeadersModal.propTypes = {
    theme: PropTypes.object,
    data: PropTypes.object,
    badge: PropTypes.bool,
};

export default TopLeadersModal;
