import { isEmptyObject } from './utils';

const setSessionStorageItem = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

const addSessionStorageItem = (key, value) => {
    const isset = sessionStorage.getItem(key);
    if (isset) {
        let data = JSON.parse(isset);
        if (typeof data === 'string') data = value;
        else if (Array.isArray(data)) {
            data = [...data, ...value];
        } else {
            data = { ...data, ...value };
        }
        sessionStorage.setItem(key, JSON.stringify(data));
    } else {
        sessionStorage.setItem(key, JSON.stringify(value));
    }
};

const getSessionStorageItem = (key) => {
    try {
        return JSON.parse(sessionStorage.getItem(key));
    } catch (e) {
        sessionStorage.removeItem(key);
    }
};

const removeSessionStorageItem = (key) => {
    sessionStorage.removeItem(key);
};

const removeInStorageItem = (key, value) => {
    const isset = sessionStorage.getItem(key);
    if (isset) {
        let data = JSON.parse(isset);
        if (typeof data === 'string') sessionStorage.removeItem(key);
        else if (Array.isArray(data)) {
            data = data.filter((element) => {
                return element !== value;
            });
        } else {
            delete data[value];
        }

        if ((Array.isArray(data) && data.length === 0) || isEmptyObject(data))
            sessionStorage.removeItem(key);
        else sessionStorage.setItem(key, JSON.stringify(data));
    } else {
        return false;
    }
};

export {
    setSessionStorageItem,
    addSessionStorageItem,
    getSessionStorageItem,
    removeSessionStorageItem,
    removeInStorageItem,
};
