import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useWindowSize } from '../hooks';
import { deviceType } from '../helpers/deviceType';
import { LOGIN_MODAL } from '../constants/modals.constants';
import { setGAEvent } from '../helpers/GA4Helper';
import { getStorageItem, setStorageItem } from '../helpers/localStorage';
import { shopOp } from '../store/shop';
import globalSlice, { globalOp, globalSel } from '../store/global';
import { XSOLLA_URL } from '../constants/api.constants';

const env = process.env.REACT_APP_ENVIRONMENT;
const isDebug = env !== 'production';

export const withPurchase = (WrappedComponent) => {
    // eslint-disable-next-line react/display-name
    const WrapperFunc = (props) => {
        const dispatch = useDispatch();
        const [xsollaToken, setXsollaToken] = useState('');
        const currentUser = useSelector(globalSel.currentUserSelector);
        const flaggedPages = useSelector(globalSel.pageFlags);
        const width = useWindowSize();
        const pathname = window.location.pathname;
        const showTour = globalSlice.actions.setShowTour;
        const isIOS = (arr) => {
            return (
                arr.includes(navigator.platform) ||
                // iPad on iOS 13 detection
                (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
            );
        };

        const openXsollaPopupOrWindow = (access_token) => {
            const xsollaUrl = `${XSOLLA_URL}paystation3/?access_token=${access_token}`;
            if (isIOS(['iPhone Simulator', 'iPhone'])) {
                if (width > 768) {
                    window.XPayStationWidget.open();
                } else {
                    window.location.href = xsollaUrl;
                }
            } else if (isIOS(['iPad Simulator', 'iPod Simulator', 'iPad', 'iPod'])) {
                if (window.navigator?.userAgent.match('CriOS')) {
                    if (width > 768) {
                        window.XPayStationWidget.open();
                    } else {
                        window.location.href = xsollaUrl;
                    }
                }
            } else {
                if (!deviceType()) {
                    window.XPayStationWidget.open();
                } else {
                    window.location.href = xsollaUrl;
                }
            }
        };

        const handleXsollaEvents = useCallback((token) => {
            //TODO: change sandbox to false before launch
            if (window.XPayStationWidget) {
                const options = {
                    access_token: token,
                    sandbox: isDebug,
                    lightbox: {
                        zIndex: 3000,
                        height: '90%',
                    },
                };

                window.XPayStationWidget.init(options);
                openXsollaPopupOrWindow(token);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const handlePurchase =
            ({ commodity = 'USP', item, ...rest }) =>
            (callback = () => {}) => {
                handleRemoveTour();
                if (getStorageItem('orderInProgress')) return false;
                if (callback && typeof callback === 'function') callback(true);

                item.section !== 'daily_gift' &&
                    setGAEvent('clickEvent', {
                        screen_name: `${pathname === '/' ? '/home' : pathname}`,
                        screen_type: item.openType,
                        screen_section: item.section,
                        interaction_object: 'offer_card',
                        interaction_object_category: `${
                            pathname === '/loyalty-point-shop' ? 'purchase' : 'payment'
                        }`,
                        offer_id: rest.storeListingName,
                    });

                if (!currentUser) {
                    setTimeout(() => callback(false), 200);

                    return dispatch(
                        globalOp.handleOpenModal({
                            open: true,
                            variant: LOGIN_MODAL,
                            data: {
                                image: 'login',
                                title: 'login.title',
                                description: 'login.description',
                                buttonText: 'login',
                                loginWithFb: 'true',
                            },
                        }),
                    );
                }
                setStorageItem('orderInProgress', true);
                if (commodity === 'USP') {
                    if (flaggedPages?.['playgamipayments']?.Enabled) {
                        PlaygamiPurchase(item, rest, commodity, callback);
                    } else {
                        XsollaPurchase(item, rest, commodity, callback);
                    }
                } else RegularPurchase(item, rest, commodity, callback);
            };
        const handleRemoveTour = () => {
            dispatch(showTour(false));
        };
        const XsollaPurchase = (item, customParameters, commodity, callback) => {
            //TODO: change sandbox to false before launch
            const tokenData = {
                item,
                customParameters: {
                    ...customParameters,
                },
                ReturnUrl: `${window.location.origin}${window.location.pathname}`,
                currency: 'USD',
                isSandbox: isDebug,
                purchaseAmount: 1,
                commodity,
            };

            setStorageItem('processingOrder', tokenData);
            dispatch(shopOp.handleOfferData(tokenData));
            dispatch(shopOp.getXsollaToken(tokenData, setXsollaToken, callback));
        };
        const PlaygamiPurchase = (item, customParameters, commodity, callback) => {
            const tokenData = {
                item,
                customParameters: {
                    ...customParameters,
                },
                ReturnUrl: `${window.location.origin}${window.location.pathname}`,
                currency: 'USD',
                isSandbox: isDebug,
                purchaseAmount: 1,
                commodity,
            };
            setStorageItem('processingOrder', tokenData);
            dispatch(shopOp.handleOfferData(tokenData));
            dispatch(
                shopOp.doPlaygamiPurchase(customParameters.storeListingName, item.name, callback),
            );
        };
        const RegularPurchase = (item, rest, commodity, callback) => {
            const tokenData = {
                commodity,
                item,
                customParameters: {
                    ...rest,
                },
            };

            setStorageItem('internalPurchase', true);
            setStorageItem('processingOrder', tokenData);
            dispatch(shopOp.handleOfferData(tokenData));
            dispatch(shopOp.makePurchase(item, rest, callback));
        };

        useEffect(() => {
            if (xsollaToken) {
                handleXsollaEvents(xsollaToken);
            }
        }, [handleXsollaEvents, xsollaToken]);

        return <WrappedComponent {...props} handlePurchase={handlePurchase} />;
    };

    WrapperFunc.propTypes = {
        data: PropTypes.object,
        purchaseParams: PropTypes.object,
    };

    return WrapperFunc;
};
