import styled from 'styled-components';

export const LangSwitcherStyled = styled.aside`
    position: relative;
    cursor: pointer;
    .dropdown-header {
        padding: 10px 12px;
        border: 1px solid ${({ theme }) => theme.palette.brown.A300};
    }

    .dropdown-body {
        padding: 10px 0;
        border: 1px solid ${({ theme }) => theme.palette.orange.A500};
        display: none;

        &.open {
            width: 200px;
            position: absolute;
            top: 42px;
            display: flex;
            flex-direction: column;
        }
    }

    .dropdown-item {
        color: #030303;
        cursor: pointer;
        padding: 5px 12px;
        &:hover {
            background-color: ${({ theme }) => theme.palette.orange.A100};
        }

        &.active-menu {
            background-color: ${({ theme }) => theme.palette.orange.A200};
        }
    }

    .open-menu {
        transform: rotate(-90deg);
        transition: all 0.2s ease;
    }

    .close-menu {
        transform: rotate(90deg);
        transition: all 0.2s ease;
    }
`;
