import styled from 'styled-components';

import backgroundImageXS from '../../../../assets/images/DailyOffer/backgroundImage-xs.png';
import backgroundImageMD from '../../../../assets/images/DailyOffer/backgroundImage-md.jpg';
import backgroundImageXL from '../../../../assets/images/DailyOffer/backgroundImage-xl.jpg';

export const UpcomingSliderItemStyled = styled.div`
    background-image: url(${backgroundImageXS});
    background-color: #2e20ac;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 14px;
    position: relative;
    z-index: 2;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        background-image: url(${backgroundImageMD});
        border-radius: 20px;
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
        border-radius: 24px;
    }

    ${({ theme }) => theme.breakpoints.up('1780')} {
        background-image: url(${backgroundImageXL});
    }

    ${({ theme }) => theme.breakpoints.up('xl')} {
        border-radius: 30px;
    }

    .WP-daily-upcoming-text {
        line-height: 28px;

        ${({ theme }) => theme.breakpoints.up('lg')} {
            line-height: 38px;
        }

        ${({ theme }) => theme.breakpoints.up('1780')} {
            line-height: 72px;
        }
    }
`;
