import { LANGUAGES } from '../../../constants/languages.constantants';
export class MostVisitedSupportsGetter {
    constructor(supportsRepository) {
        this.supportsRepository = supportsRepository;
    }

    async execute(locale = LANGUAGES[0].locale) {
        return await this.supportsRepository.getSupports(locale);
    }
}
