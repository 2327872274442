import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../hooks/useTranslation';
import PropTypes from 'prop-types';

import { Box, useMediaQuery } from '@material-ui/core';

import WrapperSkeleton from '../OfferCardSkeleton/WrapperSkeleton';
import SectionTitle from '../SectionTitle/SectionTitle';
import SeeMoreItems from '../SeeMoreItems/SeeMoreItems';
import { globalSel } from '../../../store/global';
import { shopSel } from '../../../store/shop';

const BonusRolls = ({
    sectionSubtitle,
    redirectMoreItems,
    selector = 'bonusRollsSelector',
    limit,
    limitSkeleton,
    imageSizeHeight,
}) => {
    const t = useTranslation();
    const section = useSelector(shopSel[selector]);
    const theme = useSelector(globalSel.themeSelector);
    const isLoading = useSelector(shopSel.isLoading);

    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });

    const data = section?.Listings || [];

    return (
        <Box
            component="section"
            className="WP-bonus-rolls WP-hide-empty-space"
            pb={[10, 12, 12, 16, 20]}
        >
            {isLoading ? (
                <WrapperSkeleton limitSkeleton={limitSkeleton} />
            ) : !data.length ? null : (
                <>
                    <SectionTitle
                        title={t('bonus.rolls')}
                        subtitle={t(sectionSubtitle)}
                        customIcon="bonus-roll-offers"
                    />

                    <SeeMoreItems
                        section="bonus_rolls"
                        data={data}
                        limit={limit}
                        redirectTo={
                            redirectMoreItems ? { page: '/store', section: 'WP-bonus-rolls' } : null
                        }
                        spacing={breakpointsXL ? 10 : breakpointsLG ? 5 : 4}
                        isBonusRollsDetails
                        imageSizeHeight={imageSizeHeight}
                    />
                </>
            )}
        </Box>
    );
};

BonusRolls.propTypes = {
    sectionSubtitle: PropTypes.string,
    redirectMoreItems: PropTypes.bool,
    selector: PropTypes.string,
    limit: PropTypes.number,
    limitSkeleton: PropTypes.array,
    imageSizeHeight: PropTypes.array,
};

export default BonusRolls;
