import styled from 'styled-components';

export const CommodityCounterStyled = styled.div`
    margin: auto;
    max-width: 350px;
    margin-bottom: 30px;

    display: flex;
    justify-content: center;
    gap: 20px;

    .WP-commodity-counter-card {
        background-color: ${({ theme }) => theme.palette.orange.A50};
        height: 70px;
        width: 100px;

        border-radius: 10px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        font-size: ${({ theme }) => theme.typography.size.p16.fontSize};
        font-weight: bold;

        .WP-commodity-counter-card-img {
            position: relative;
            margin: auto;
            top: -25px;
        }

        .WP-commodity-counter-card-text {
            position: relative;
            top: -25px;
        }
    }
`;
