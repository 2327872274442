import { useSelector } from 'react-redux';
import { translationsSel } from '../store/translations';

const generateTemplateString = (function () {
    let cache = {};

    function generateTemplate(template) {
        let fn = cache[template];

        if (!fn) {
            // Replace ${expressions} (etc) with ${map.expressions}.

            let sanitized = template
                .replace(/\$\{([\s]*[^;\s{]+[\s]*)\}/g, function (_, match) {
                    return `$\{map.${match.trim()}}`;
                })
                // Afterwards, replace anything that's not ${map.expressions}' (etc) with a blank string.
                .replace(/(\$\{(?!map\.)[^}]+\})/g, '');

            // eslint-disable-next-line no-new-func
            fn = Function('map', `return \`${sanitized}\``);
        }

        return fn;
    }

    return generateTemplate;
})();

export const useTranslation = () => {
    const translations = useSelector(translationsSel.translationsSelector);
    const { code: selectedLang } = useSelector(translationsSel.languageSelector);

    const t = (key, fallbackText = '', dynamicVariables) => {
        const hasTranslation = translations[key];

        if (hasTranslation) {
            let translationText = '';
            if (hasTranslation[selectedLang]) {
                translationText = hasTranslation[selectedLang];
            } else {
                translationText = hasTranslation['en'];
            }
            if (dynamicVariables && Object.keys(dynamicVariables).length !== 0 && translationText) {
                let generatedString = generateTemplateString(translationText);
                translationText = generatedString(dynamicVariables);
            }
            if (translationText) return translationText;
        }
        return fallbackText ? fallbackText : key;
    };
    return t;
};

export const useTranslationByKey = () => {
    const translations = useSelector(translationsSel.translationsSelector);
    const selectedLang = useSelector(translationsSel.languageSelector);

    const t = (key) => {
        const hasTranslation = translations[key];

        if (hasTranslation) {
            const hasTranslationWithCurrentLanguage = translations[key][selectedLang];

            if (!hasTranslationWithCurrentLanguage && !translations[key]['en']) {
                return '';
            }

            if (hasTranslationWithCurrentLanguage) {
                return hasTranslationWithCurrentLanguage;
            }

            return translations[key]['en'];
        }

        return key;
    };

    return t;
};
