import { createSlice } from '@reduxjs/toolkit';
import { initialDailyDealsState } from './initialState';

export const dailyDealsSlice = createSlice({
    name: 'dailyDeals',
    initialState: initialDailyDealsState(),
    reducers: {
        setDailyDeals(state, action) {
            state.dailyDealData = action.payload;
        },

        setRefreshButtonDeals(state, action) {
            state.refreshDealButtonData = action.payload;
        },

        setRefreshedDailyDeals(state, action) {
            state.refreshDealData = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },
    },
});
