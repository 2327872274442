import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Typography } from 'portal-modules/core-ui';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { Button } from '../../../../ui-components/Button/Button';
import { useTheme } from '@material-ui/core/styles';
import { globalOp } from '../../../../../../store/global';
import CountdownUTC from '../../../../CountdownUTC/CountdownUTC';
import { DefaultModalStyled } from './DefaultModal.styled';
import { setGAEvent } from '../../../../../../helpers/GA4Helper';
import { APP_REDIRECT_URL } from '../../../../../../constants/api.constants';

const DefaultModal = ({ data }) => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const history = useHistory();

    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });

    const closePopup = () => {
        dispatch(globalOp.closeInterstitials());

        setGAEvent('clickEvent', {
            screen_name: 'interstitial',
            screen_type: 'pop-up',
            interaction_object: 'interstitial_CTA',
            interaction_object_category: 'redirect',
            interstitial_id: data?.Id,
        });

        if (data?.CtaLink.includes(APP_REDIRECT_URL)) {
            history.push({
                pathname: data?.CtaLink.replace(APP_REDIRECT_URL, '/'),
            });
        } else {
            window.location.href = data.CtaLink;
        }
    };

    useEffect(() => {
        setGAEvent('impression', {
            screen_name: 'interstitial',
            screen_type: 'pop-up',
            interstitial_id: data?.Id,
        });
    }, [data?.Id]);

    if (!data) return false;

    return (
        <Box
            component={DefaultModalStyled}
            width="100%"
            height="100%"
            bgImageUrl={data.BackgroundImageUrl}
            className="WP-modal-small WP-modal-gradient-background"
        >
            <Box
                className="flex-center WP-header-container"
                minHeight="80px"
                position="relative"
                px={[4, 4, 4, 5, 6]}
            >
                {data.HeaderImageUrl && (
                    <Box
                        component="img"
                        className="WP-header-image"
                        src={data.HeaderImageUrl}
                        alt="point"
                        width={['calc(100% - 80px)', 'calc(100% - 100px)']}
                        height={[130, 150]}
                        mb={[3, 5]}
                    />
                )}
                <Box className="WP-countdown-container">
                    {data.EndDate && (
                        <CountdownUTC text={t('ends.in')} start={data.EndDate} d h m />
                    )}
                </Box>
            </Box>

            {data.ImageUrl && (
                <Box className="flex-center" py={[2, 3, 3, 3, 4]} px={[4, 4, 4, 5, 6]}>
                    <Box
                        component="img"
                        className="WP-body-image"
                        src={data.ImageUrl}
                        alt="points"
                        maxWidth="calc(100% - 40px)"
                        width="100%"
                        height={[214, 240]}
                    />
                </Box>
            )}

            <Box className="WP-modal-footer" px={[4, 4, 4, 5, 6]}>
                {data.BodyText && (
                    <Box
                        component={Typography}
                        variant={['h6', 'h5', 'h5', 'h5', 'h4']}
                        fWeight="bold"
                        textAlign="center"
                        color={theme.palette.yellow.A200}
                        pb={[2, 2, 2, 2, 3]}
                        pt={[5, 6]}
                        className="WP-modal-footer-text"
                    >
                        {data.BodyText}
                    </Box>
                )}
                <Box className="WP-modal-footer flex-center" component="footer" py={6}>
                    {data.CtaLink && (
                        <Button
                            variant="secondary"
                            size={
                                breakpointsXL
                                    ? 'large'
                                    : breakpointsLG
                                    ? 'medium'
                                    : breakpointsSM
                                    ? 'small'
                                    : 'extra-small'
                            }
                            onClick={closePopup}
                        >
                            {data.CtaText || t('go.to.the.game')}
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

DefaultModal.propTypes = {
    theme: PropTypes.object,
    data: PropTypes.object,
};

export default DefaultModal;
