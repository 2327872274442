import { translationsSlice } from './translationsSlice';
import { fetch } from '../../core/infra/dice-admin/FetchService';

import { GRIDLY_API_KEY, GRIDLY_VIEW_ID } from '../../constants/api.constants';
import { getStorageItem } from '../../helpers/localStorage';
import { LANGUAGES } from '../../constants/languages.constantants';

const setLanguage = (lang) => {
    const { setLanguage: changeLanguage } = translationsSlice.actions;
    return (dispatch) => {
        dispatch(changeLanguage(lang));
    };
};

const getTranslations = () => {
    const { setTranslations } = translationsSlice.actions;
    const selectedLanguage = getStorageItem('language') || LANGUAGES[0];

    return async (dispatch) => {
        try {
            const columnIds =
                selectedLanguage?.code !== 'en' ? `en, ${selectedLanguage.code}` : 'en';

            const translations = await fetch({
                url: `https://api.gridly.com/v1/views/${GRIDLY_VIEW_ID}/records`,
                method: 'GET',
                params: {
                    columnIds,
                    page: {
                        limit: 1000,
                    },
                },
                headers: {
                    Authorization: `ApiKey ${GRIDLY_API_KEY}`,
                },
            });

            if (translations?.data) {
                dispatch(convertTranslations(translations?.data));
            } else {
                import('../../locales/en.json').then((response) => {
                    dispatch(setTranslations(response.default));
                    dispatch(setLanguage(selectedLanguage));
                });
            }
        } catch (err) {
            import('../../locales/en.json').then((response) => {
                dispatch(setTranslations(response.default));
                dispatch(setLanguage(selectedLanguage));
            });
        }
    };
};

const convertTranslations = (translations) => {
    const { setTranslations } = translationsSlice.actions;
    if (!translations) return;
    const converted = {};

    translations.forEach((translation) => {
        if (Array.isArray(translation.cells)) {
            translation.cells.forEach((item) => {
                if (item) {
                    converted[translation.id] = {
                        ...(converted[translation.id] || {}),
                        [item.columnId]: item.value,
                    };
                }
            });
        }
    });

    return (dispatch) => {
        dispatch(setTranslations(converted));
        dispatch(setLanguage(getStorageItem('language') || LANGUAGES[0]));
    };
};

export const translationsOp = {
    getTranslations,
    convertTranslations,
    setLanguage,
};
