import React from 'react';

import { Box } from 'portal-modules/core-ui';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from 'react-redux';
import { globalSel } from '../../../store/global';

const BannerSkeleton = () => {
    const theme = useSelector(globalSel.themeSelector);

    return (
        <Box
            height={100}
            width={1}
            display="flex"
            alignItems="center"
            borderRadius={12}
            bgcolor={theme.palette.orange.A50}
        >
            <Box width={100} height={100} mr={3}>
                <Skeleton variant="rect" animation="wave" height="100%" width="100%" />
            </Box>

            <Box>
                <Box height={32} width={240}>
                    <Skeleton variant="rect" animation="wave" height="100%" width="100%" />
                </Box>
                <Box height={24} width={200} mb={1}>
                    <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                </Box>
                <Box height={28} width={240}>
                    <Skeleton variant="rect" animation="wave" height="100%" width="100%" />
                </Box>
            </Box>
        </Box>
    );
};

export default BannerSkeleton;
