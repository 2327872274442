import { OktaProviderConfig, ScopelyId } from '@scopely/web-sdk-scopelyid';
import { Config as ScopelyIdConfig } from '@scopely/web-sdk-scopelyid/dist/src/settings';
import { tracker } from './titan.config';
import {
    APP_REDIRECT_URL,
    SCOPELY_ID_AUTH_URL,
    SCOPELY_ID_CLIENT_ID,
    SCOPELY_ID_PROVIDER_URL,
} from '../constants/api.constants';

const providerConfig = new OktaProviderConfig(
    SCOPELY_ID_PROVIDER_URL,
    SCOPELY_ID_CLIENT_ID,
    APP_REDIRECT_URL,
    APP_REDIRECT_URL,
);
const scopelyIdCfg = new ScopelyIdConfig(providerConfig, SCOPELY_ID_AUTH_URL);
// See Analytics section on Scopely Web SDK to know how to build the analytics tracker
const scopelyId = new ScopelyId(scopelyIdCfg, tracker);

export { scopelyId };
