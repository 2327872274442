import React, { useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from 'portal-modules/core-ui';
import { Box, Hidden, useMediaQuery } from '@material-ui/core';

import AppLogo from '../../components/AppLogo/AppLogo';
import { HeaderStyled } from './Header.styled';
import globalSlice, { globalSel } from '../../../store/global';

import hamburger from '../../../assets/images/menu-hamburger.svg';
import UserCoins from './UserCoins/UserCoins';
import NotificationPipContext from '../../../context/notification-pip/NotificationPipContext';

const Header = () => {
    const headerRef = useRef();
    const dispatch = useDispatch();
    const theme = useSelector(globalSel.themeSelector);
    const authenticated = useSelector(globalSel.isAuthSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);
    const avatar = currentUser?.PictureUrlMedium || currentUser?.PictureUrlLarge;

    const { hamburguerNotification } = useContext(NotificationPipContext);

    const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'), { noSsr: true });

    const openRightSidebar = () => {
        dispatch(globalSlice.actions.setSidebar({ side: 'rightSidebar', open: true }));
    };

    const openLeftSidebar = () => {
        dispatch(globalSlice.actions.setSidebar({ side: 'leftSidebar', open: true }));
    };

    return (
        <HeaderStyled ref={headerRef}>
            <Box display="flex" alignItems="center" justifyContent="space-between" height={1}>
                <Hidden mdUp>
                    <Box
                        component="img"
                        mr={4}
                        width={24}
                        onClick={openLeftSidebar}
                        src={hamburger}
                        alt="hamburger"
                        className="cursor-pointer"
                    />
                    <Box className={`${hamburguerNotification && 'icon-notif'}`} />
                    <Box flexGrow={1}>
                        <AppLogo isLogoHeightExist={44} />
                    </Box>
                </Hidden>
                <UserCoins />
                <Hidden mdUp>
                    {authenticated && currentUser ? (
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            {!mdUp && (
                                <Box
                                    width={36}
                                    height={36}
                                    borderRadius={50}
                                    overflow="hidden"
                                    ml={5}
                                    onClick={openRightSidebar}
                                    className="WP-header-user-image"
                                >
                                    {avatar ? (
                                        <Box
                                            component="img"
                                            src={avatar}
                                            alt="user"
                                            width={1}
                                            height={1}
                                        />
                                    ) : (
                                        <Box width={1} height={1}>
                                            <Box
                                                component={Icon}
                                                className="WP-user-icon icon-default-user"
                                                size={32}
                                                mr={2}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Box
                            width={28}
                            height={28}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            ml={4}
                            bgcolor={theme.palette.common.white}
                            borderRadius="50%"
                            padding={1}
                        >
                            <Icon
                                className="icon-user cursor-pointer"
                                size={20}
                                color={theme.palette.brown.A600}
                                onClick={openRightSidebar}
                            />
                        </Box>
                    )}
                </Hidden>
            </Box>
        </HeaderStyled>
    );
};

export default Header;
