import React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import { Icon, Typography } from 'portal-modules/core-ui';

import { useTranslation } from '../../../../../../hooks/useTranslation';
import CountdownUTC from '../../../../CountdownUTC/CountdownUTC';
import DailyOfferList from './DailyOfferList';

import { globalSel } from '../../../../../../store/global';
import { dailyOffersSel } from '../../../../../../store/dailyOffers';

import { DailyOfferFooterStyled } from '../DailyOffer.styled';

const DailyOfferFooter = () => {
    const dailyOffers = useSelector(dailyOffersSel.dailyOffers);
    const theme = useSelector(globalSel.themeSelector);
    const t = useTranslation();
    if (!dailyOffers) return false;

    return (
        <Box
            component={DailyOfferFooterStyled}
            className="WP-modal-footer"
            bgcolor={theme.palette.orange.A50}
            pt={[3, 5, 5, 3]}
            px={[3, 6, 6, 7]}
            pb={[3, 3, 3, 4]}
        >
            <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center" color={theme.palette.brown.main} mr={2}>
                    <Box component={Icon} className="icon-calendar" size={20} />

                    <Box
                        component={Typography}
                        className="WP-modal-footer-title text-uppercase"
                        fontFamily="rubik"
                        variant="p12"
                        fWeight="bold"
                        ml={1}
                    >
                        {t('next.daily.offer')}
                    </Box>
                </Box>
                {dailyOffers?.[1]?.StartDate && (
                    <CountdownUTC
                        text=""
                        start={dailyOffers[1].StartDate}
                        interval={60 * 1000}
                        h
                        m
                    />
                )}
            </Box>

            <DailyOfferList data={dailyOffers} />

            <Box
                component={Typography}
                className="WP-modal-footer-text"
                variant="p14"
                fWeight="medium"
                textAlign="center"
                color={theme.palette.brown.A600}
            >
                {t('come.back.tomorrow.to.see.offer')}
            </Box>
        </Box>
    );
};

export default DailyOfferFooter;
