import React from 'react';
import { Box } from '@material-ui/core';
import NewsWrapper from '../../../views/components/NewsWrapper/NewsWrapper';

const NewsPage = () => {
    return (
        <Box pt={[5, 5, 5, 7]}>
            <NewsWrapper />
            {/*<PaginationSection />*/}
        </Box>
    );
};

export default NewsPage;
