import React from 'react';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTheme } from '@material-ui/core/styles';

const BannerSkeleton = () => {
    const theme = useTheme();

    return (
        <Box
            className="WP-banner-skeleton"
            minHeight={[270, 230, 230, 280]}
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            borderRadius={[14, 20, 20, 24, 30]}
            bgcolor={theme.palette.orange.A50}
            boxShadow={theme.elevation[100]}
            overflow="hidden"
            p={[3, 3, 3, 5, 10]}
        >
            <Box>
                <Box height={[16, 24, 24, 28, 32]} width="100%">
                    <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                </Box>

                <Box height={[24, 28, 28, 32, 40]} width="100%">
                    <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                </Box>
            </Box>

            <Box height={[32, 38, 38, 48, 56]}>
                <Skeleton variant="rect" animation="wave" height="100%" width="100%" />
            </Box>
        </Box>
    );
};

export default BannerSkeleton;
