import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Box, Typography } from 'portal-modules/core-ui';
import { Button } from '../../../ui-components/Button/Button';
import { useMediaQuery } from '@material-ui/core';

import { AccountModalStyled } from '../AccountModal.styled';
import ScopelyLogin from '../../../LoginWithScopely/LoginWithScopely';
import FbLogin from '../../../LoginWithFacebook/LoginWithFacebook';
import { LOGINBUTTONTYPE } from '../../../../../enums/enums';
import { FB_APP_ID } from '../../../../../constants/api.constants';
import { globalOp } from '../../../../../store/global';
import { deviceType } from '../../../../../helpers/deviceType';
import ModalStoreLinks from './ModalStoreLinks/ModalStoreLinks';

const Processing = ({ theme, data, isLogOutState, isNoConnectedFb, isNoConnectedScopely }) => {
    const breakpointsXS = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
    const breakpointsMD = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

    const t = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const checkMobileOrTablet = deviceType();

    if (!data) return false;

    const openHelpshift = () => {
        dispatch(globalOp.handleCloseModal({}));
        window.Helpshift('open');
    };

    const handleRedirectTo = () => {
        switch (data.redirectTo) {
            case 'refresh':
                return window.location.reload();
            case 'close':
                return dispatch(globalOp.handleCloseModal({}));
            case 'helpshift':
                return openHelpshift();
            case 'yux-settings':
                return window.open('yux://game/settings/open', '_blank');
            default:
                return history.push(data.redirectTo);
        }
    };

    return (
        <Box
            component={AccountModalStyled}
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            overflow="hidden"
            className={
                isNoConnectedFb
                    ? 'WP-noConnectedFb WP-modal-small'
                    : isNoConnectedScopely
                    ? 'WP-noConnectedScopely WP-modal-small'
                    : isLogOutState
                    ? 'WP-logout-modal WP-modal-small'
                    : 'WP-account-modal WP-modal-small'
            }
        >
            <Box
                height={[160, 250]}
                position="relative"
                bgcolor="#7aeaff"
                className="flex-center WP-modal-header"
            >
                {!isNoConnectedFb && !isNoConnectedScopely ? (
                    <Box className="WP-image-container">
                        <Box
                            className="WP-image-bg flex-center"
                            width={[82, 108]}
                            height={[82, 108]}
                        >
                            <Box
                                component="img"
                                src={
                                    require(`../../../../../assets/images/Modal/${data?.image}.png`)
                                        .default
                                }
                                alt="Processing image"
                            />
                        </Box>
                    </Box>
                ) : null}
            </Box>

            <Box
                minHeight={250}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                px={[4, 6]}
                pb={5}
                pt={2}
                className="WP-modal-body"
            >
                <Box mb={5}>
                    <Box
                        component={Typography}
                        className="WP-modal-title"
                        variant={['h6', 'h5', 'h4']}
                        fWeight="bold"
                        color={theme.palette.brown.main}
                        mb={3}
                    >
                        {t(data.title)}
                    </Box>

                    <Box
                        component={Typography}
                        className="WP-modal-subtitle"
                        variant={['p14', 'p16']}
                        fWeight="semiBold"
                        color={theme.palette.brown.A700}
                    >
                        {t(data.description)}
                    </Box>

                    {data.subDescription && (
                        <Box
                            component={Typography}
                            className="WP-modal-subtitle"
                            variant={['p14', 'p16']}
                            fWeight="semiBold"
                            color={theme.palette.brown.A700}
                            mt={4}
                        >
                            {t(data.subDescription)}
                        </Box>
                    )}
                </Box>

                {data.buttonText &&
                    (data.loginWithFb ? (
                        <>
                            <Box mb={3}>
                                <FbLogin
                                    type={LOGINBUTTONTYPE.FB}
                                    appId={FB_APP_ID}
                                    disableMobileRedirect={true}
                                    text={t('login')}
                                />
                            </Box>

                            <ScopelyLogin />
                        </>
                    ) : (
                        <Box>
                            {!checkMobileOrTablet && data.redirectTo === 'yux-settings' ? (
                                <ModalStoreLinks />
                            ) : (
                                <Box
                                    onClick={handleRedirectTo}
                                    component={Button}
                                    variant="secondary"
                                    size={
                                        breakpointsXS ? 'small' : breakpointsMD ? 'medium' : 'large'
                                    }
                                    data-testid={data.dataTestId ? data.dataTestId : 'data-test-id'}
                                >
                                    {t(data.buttonText)}
                                </Box>
                            )}
                        </Box>
                    ))}
            </Box>
        </Box>
    );
};

Processing.defaultProps = {
    dailyGift: false,
};

Processing.propTypes = {
    theme: PropTypes.object,
    data: PropTypes.object,
    isNoConnectedFb: PropTypes.bool,
    isNoConnectedScopely: PropTypes.bool,
    isLogOutState: PropTypes.bool,
    callback: PropTypes.func,
};

export default Processing;
