import { leaderboardSlice } from './leaderboardSlice';
import { fetch } from '../../core/infra/dice-admin/FetchService';

import { globalOp } from '../global';
import { TOP_LEADERS_MODAL } from '../../constants/modals.constants';
import { BOARDCATEGORIES } from '../../enums/enums';

export const getBoards = (page = 5) => {
    const { setBoards, setLoading } = leaderboardSlice.actions;

    return async (dispatch) => {
        try {
            dispatch(setLoading(true));

            const { data } = await fetch({
                endPoint: 'web/leaderboards/top',
                method: 'GET',
                params: {
                    BypassSignature: true,
                    itemsPerLeaderboard: page,
                },
            });

            dispatch(setBoards(data.Data));
            dispatch(setLoading(false));
        } catch (err) {
            dispatch(setLoading(false));
        }
    };
};

export const resetBoards = () => {
    const { setBoards } = leaderboardSlice.actions;

    return async (dispatch) => {
        dispatch(setBoards([]));
    };
};

export const getGameModes = () => {
    //const { setGamesModes } = leaderboardSlice.actions;

    return async () => {
        try {
            await fetch({
                endPoint: `web/leaderboards/top/game-modes/players`,
                method: 'GET',
                params: {
                    BypassSignature: true,
                },
            });
        } catch (err) {
            // eslint-disable-next-line no-console
            // console.log(err);
        }
    };
};

export const getStats = (category, id) => {
    return async (dispatch) => {
        try {
            const { data } = await fetch({
                endPoint: `web/leaderboards/stats/${BOARDCATEGORIES[category]}/${id}`,
                method: 'GET',
                params: {
                    BypassSignature: true,
                },
            });

            dispatch(
                globalOp.handleOpenModal({
                    open: true,
                    variant: TOP_LEADERS_MODAL,
                    data: {
                        type: category,
                        item: data?.Data,
                    },
                }),
            );
        } catch (err) {
            // eslint-disable-next-line no-console
            // console.log(err);
        }
    };
};

export const getBoardInfo = (params, page = 0, size = 25) => {
    const { setSelected } = leaderboardSlice.actions;

    return async (dispatch) => {
        try {
            const { data } = await fetch({
                endPoint: `web/leaderboards/query/${params[0]}/${params[1]}/${params[2]}/from/${page}`,
                method: 'GET',
                params: {
                    BypassSignature: true,
                    size,
                },
            });

            dispatch(setSelected(data.Data));
        } catch (err) {
            // eslint-disable-next-line no-console
            // console.log(err);
        }
    };
};

export const leadersOp = {
    getBoards,
    getStats,
    getGameModes,
    getBoardInfo,
    resetBoards,
};
