import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { useTranslation } from '../../../hooks/useTranslation';
import PageHeader from '../../components/PageHeader/PageHeader';
import NewsInnerPageSkeleton from './NewsSinglePageSkeleton';
import NewsSelectedItem from './NewsSelectedItem';
import { newGetter } from '../../../core/application/news';

const NewsInnerPage = () => {
    const [newItem, setNewItem] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const t = useTranslation();
    const theme = useTheme();
    const params = useParams();

    const fetchNew = async () => {
        setIsLoading(true);
        newGetter
            .execute(params.newsUrl)
            .then((res) => {
                setNewItem(res);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchNew();
    }, []);

    return (
        <Box pt={[5, 5, 5, 7]}>
            {isLoading ? (
                <NewsInnerPageSkeleton isPageNews />
            ) : (
                <>
                    <PageHeader
                        title={t('news')}
                        subtitle={t('follow.the.latest.updates')}
                        icon="icon-news"
                        isLoading={isLoading}
                    />

                    <NewsSelectedItem newItem={newItem} theme={theme} />

                    {/*---------------- Related post section start ----------------*/}
                    {/*<Box>*/}
                    {/*    <Box*/}
                    {/*        component={Typography}*/}
                    {/*        variant={['h6', 'h4']}*/}
                    {/*        fWeight="bold"*/}
                    {/*        color={theme.palette.brown.main}*/}
                    {/*        mb={5}*/}
                    {/*    >*/}
                    {/*        {t('related.pots')}*/}
                    {/*    </Box>*/}

                    {/*    <Grid container spacing={breakpointsXL ? 10 : breakpointsLG ? 5 : 4}>*/}
                    {/*        {filteredData?.map((item) => (*/}
                    {/*            <Grid item key={item.Config?.Id} xs={12} sm={6}>*/}
                    {/*                <NewsCard item={item} />*/}
                    {/*            </Grid>*/}
                    {/*        ))}*/}
                    {/*    </Grid>*/}
                    {/*</Box>*/}
                    {/*---------------- Related post section end ----------------*/}
                </>
            )}
        </Box>
    );
};

export default NewsInnerPage;
