import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getSessionToken } from '../../bridge';
import AuthBlockContext from './AuthBlockContext';

import { globalSel } from '../../store/global';

const AuthBlockProvider = ({ children }) => {
    const location = useLocation();
    const history = useHistory();
    const authenticated = useSelector(globalSel.isAuthSelector);

    useEffect(() => {
        const isLoggedIn = getSessionToken();

        if (
            !isLoggedIn &&
            (location.pathname === '/store' || location.pathname === '/loyalty-point-shop')
        ) {
            history.push({
                pathname: '/',
                state: { accesDenied: true },
            });
        }
    }, [location, history, getSessionToken, authenticated]);

    return <AuthBlockContext.Provider value={{}}>{children}</AuthBlockContext.Provider>;
};

export default AuthBlockProvider;

AuthBlockProvider.propTypes = {
    children: PropTypes.node,
};
