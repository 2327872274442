import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import DailyOfferBody from './component/DailyOfferBody';
import DailyOfferFooter from './component/DailyOfferFooter';

import { DailyOfferStyled } from './DailyOffer.styled';
import { useSelector } from 'react-redux';
import { dailyOffersSel } from '../../../../../store/dailyOffers';

const DailyOffer = ({ data }) => {
    const dailyOffers = useSelector(dailyOffersSel.dailyOffers);
    if (!data && dailyOffers?.[0]) data = dailyOffers[0];
    else if (!data && !dailyOffers?.[0]) return false;

    return (
        <Box
            component={DailyOfferStyled}
            className={`WP-modal-lg WP-day-${data.offerIndex} WP-modal-gradient-background`}
            width="100%"
            height="100%"
        >
            <DailyOfferBody offer={data} />
            <DailyOfferFooter />
        </Box>
    );
};

DailyOffer.propTypes = {
    data: PropTypes.object,
};

export default DailyOffer;
