import React from 'react';
import { useSelector } from 'react-redux';

import { Box, useMediaQuery } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { globalSel } from '../../../store/global';

function GiftsSkeleton() {
    const theme = useSelector(globalSel.themeSelector);
    const customXS = useMediaQuery(theme.breakpoints.down(375), { noSsr: true });

    return (
        <Box
            height={[335, 112, 112, 132, 132]}
            display="flex"
            alignItems="center"
            bgcolor={theme.palette.orange.A50}
            borderRadius={30}
            pl={5}
            pr={customXS ? 6 : 12}
            mb={[10, 12, 12, 16, 20]}
        >
            <Box mr={3}>
                <Skeleton variant="circle" animation="wave" height={70} width={70} />
            </Box>

            <Box
                width={1}
                display="flex"
                alignItems={['baseline', 'center']}
                justifyContent="space-between"
                flexDirection={['column', 'row']}
            >
                <Box>
                    <Box height={24} width={115}>
                        <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                    </Box>
                    <Box height={24} width={200} mb={1}>
                        <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                    </Box>
                </Box>

                <Box mr={3}>
                    <Skeleton variant="rect" animation="wave" height={38} width={200} />
                </Box>
            </Box>
        </Box>
    );
}

export default GiftsSkeleton;
