// import { NewsRepository } from '../../infra/old-mock-load/news/NewsRepository';
import { PostsRepository as NewsRepository } from '../../infra/dice-admin/posts/PostsRepository';

import { NewGetter } from './NewGetter';
import { NewsGetter } from './NewsGetter';

const newsRepository = new NewsRepository();

export const newGetter = new NewGetter(newsRepository);
export const newsGetter = new NewsGetter(newsRepository);
