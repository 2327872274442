import React from 'react';

const IconArrow = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="20"
            fill="none"
            viewBox="0 0 31 20"
        >
            <path stroke="#FFFAF1" strokeWidth="5" d="M2 2l13.5 13.5L29 2" />
        </svg>
    );
};

export default IconArrow;
