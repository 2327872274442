import styled from 'styled-components';
import ModalGiftBg from '../../../../../assets/images/Modal/daily_gift_bg.png';
import GiftShine from '../../../../../assets/images/Modal/giftShine.png';

export const WelcomeStyled = styled.div`
    .WP-welcome-modal-header {
        background-size: cover;
        background-position: center;
        background-image: url(${ModalGiftBg});
        z-index: 1;
        &:before {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            background-image: url(${GiftShine});
            background-size: cover;
            background-position: center;
            mix-blend-mode: overlay;
        }
    }
    .WP-image-bg {
        position: relative;
        z-index: 1;
        background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(255, 134, 107, 0.6) 0%,
            rgba(255, 208, 118, 0.6) 100%
        );
        box-shadow: 0 0 0 10px #ffda561a;
        border: 8px solid #fd1a1a1a;
        border-radius: 50%;
        s img {
            object-fit: contain;
        }
        &:before {
            border: 6px solid #faa74a;
        }
        &:after {
            border: 6px solid #ff9544;
        }
    }
    .WP-highlighted-bg {
        position: absolute;
        bottom: -40px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.2);
        ${({ theme }) => theme.breakpoints.down('xs')} {
            bottom: -30px;
        }
    }
`;
