import React from 'react';

import { Box } from '@material-ui/core';

import { CTAStyled } from './CTA.styled';

import art from '../../../../assets/images/Home/Logout/CTAArt.png';
import DownloadLinks from '../../../components/DownloadLinks/DownloadLinks';

const CTA = () => {
    return (
        <Box component={CTAStyled} className="WP-logout-CTA">
            <Box className="WP-logo-wrapper">
                <Box component="img" src={art} alt="logo" className="WP-CTA-logo" />
            </Box>

            <DownloadLinks />
        </Box>
    );
};

export default CTA;
