import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import DailyOfferItem from './DailyOfferItem';

const DailyOfferList = ({ data }) => {
    return (
        <Box
            component="ul"
            className="WP-list flex-center list-style-type-none"
            my={[3, 5, 5, 4]}
            p={0}
        >
            {data.map((item, index) => {
                return <DailyOfferItem key={item.StoreFrontName} offer={item} index={index} />;
            })}
        </Box>
    );
};

DailyOfferList.propTypes = {
    data: PropTypes.array,
};
export default DailyOfferList;
