import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { globalOp } from '../store/global';
import { setGAEvent } from '../helpers/GA4Helper';
import { getStorageItem, removeStorageItem, setStorageItem } from '../helpers/localStorage';
import {
    PAYMENT_FAILURE_MODAL,
    PAYMENT_SUCCESS_MODAL,
    PAYMENT_SUCCESS_MODAL_DATA,
    PAYMENT_FAILURE_MODAL_DATA,
} from '../constants/modals.constants';
import { titanAnalyticsEvents } from '../helpers/titanAnalytics';

export function useXsollaLoadHook() {
    const dispatch = useDispatch();
    const pathname = window.location.pathname;
    const html = document.getElementsByTagName('html')[0].classList;

    const handleXsollaLoaded = () => {
        window.XPayStationWidget.on(window.XPayStationWidget.eventTypes.INIT, () => {
            removeStorageItem('orderInProgress');
        });

        window.XPayStationWidget.on(window.XPayStationWidget.eventTypes.LOAD, () => {
            dispatch(globalOp.handleCloseModal());
        });

        window.XPayStationWidget.on(
            window.XPayStationWidget.eventTypes.STATUS_DELIVERING,
            function (event, res) {
                if (res?.paymentInfo?.status === 'delivering' && res?.paymentInfo?.invoice) {
                    html.remove('disable-scroll');
                    setStorageItem('xsolla_status', 'delivering');
                }
            },
        );

        window.XPayStationWidget.on(
            window.XPayStationWidget.eventTypes.STATUS_DONE,
            function (event, res) {
                html.remove('disable-scroll');
                if (res?.paymentInfo?.status === 'done') {
                    purchaseSuccess();
                }
            },
        );

        window.XPayStationWidget.on(
            window.XPayStationWidget.eventTypes.STATUS_TROUBLED,
            function (event, res) {
                setStorageItem('xsolla_status', 'status_troubled');
                titanAnalyticsEvents.xsolla_payment({
                    success: 'false',
                    error: 'status troubled',
                    ...res?.paymentInfo,
                });

                html.remove('disable-scroll');
                const processingOrder = getStorageItem('processingOrder');

                setGAEvent('clickEvent', {
                    screen_name: `${
                        pathname === '/' ? '/home_purchase_failed' : `${pathname}_purchase_failed`
                    }`,
                    screen_type: 'pop_up',
                    screen_section: processingOrder?.item?.section,
                    offer_id: processingOrder?.item.name,
                });

                dispatch(
                    globalOp.handleOpenModal({
                        open: true,
                        variant: PAYMENT_FAILURE_MODAL,
                        data: PAYMENT_FAILURE_MODAL_DATA,
                    }),
                );

                removeStorageItem('processingOrder');
            },
        );

        window.XPayStationWidget.on(window.XPayStationWidget.eventTypes.CLOSE, () => {
            const processingOrder = getStorageItem('processingOrder');

            !processingOrder && html.remove('disable-scroll');
            if (getStorageItem('xsolla_status') === 'delivering') {
                purchaseSuccess();
            }
            removeStorageItem('xsolla_status');
            requestAnimationFrame(() => {});
            removeStorageItem('processingOrder');
            setTimeout(() => {
                document.body.style.overflow = 'auto';
            }, 0);
        });
    };

    const purchaseSuccess = () => {
        const processingOrder = getStorageItem('processingOrder');
        dispatch(globalOp.getUserData());
        dispatch(
            globalOp.handleOpenModal({
                open: true,
                variant: PAYMENT_SUCCESS_MODAL,
                data: PAYMENT_SUCCESS_MODAL_DATA,
            }),
        );
        window?.DD_RUM?.addAction('xsolla-payments-value', {
            price: Math.round(processingOrder.item.price),
        });

        setGAEvent('impression', {
            screen_name: `${
                pathname === '/'
                    ? '/home_purchase_confirmation'
                    : `${pathname}_purchase_confirmation`
            }`,
            screen_type: 'pop_up',
            screen_section: processingOrder?.item?.section,
            offer_id: processingOrder?.customParameters?.storeListingName,
        });
    };

    useEffect(() => {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = '//cdn.xsolla.net/embed/paystation/1.0.7/widget.min.js';
        s.onload = handleXsollaLoaded;
        const head = document.getElementsByTagName('head')[0];
        head.appendChild(s);
        // eslint-disable-next-line
    }, []);
}
