import styled from 'styled-components';

export const NewsPageStyled = styled.div`
    .WP-news-page-wrapper {
        margin: 0 auto;
        overflow: hidden;
    }

    .WP-NewsCard-description {
        color: #424242;

        ${({ theme }) => theme.breakpoints.down('xs')} {
            font-size: 14px;
            font-weight: 400;
        }

        img {
            display: flex;
            margin: auto;
            border-radius: 30px;
        }
    }

    .WP-news-buttons {
        margin-left: auto;
        margin-right: auto;

        button {
            min-width: 180px;

            ${({ theme }) => theme.breakpoints.down('xs')} {
                min-width: 130px;
            }
        }

        .button-text {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
`;
