import React from 'react';
import PropTypes from 'prop-types';

import CountdownUTC from '../../CountdownUTC/CountdownUTC';
import { useTranslation } from '../../../../hooks/useTranslation';

const DailyTimer = ({ start, end, callback }) => {
    const t = useTranslation();
    const now = new Date();
    const startDate = new Date(start);
    const timerObj = {
        text: startDate > now ? 'available.in' : 'offer.end.in',
        date: startDate > now ? start : end,
    };

    return (
        <CountdownUTC
            text={t(timerObj.text)}
            start={timerObj.date}
            interval={60 * 1000}
            d
            h
            m
            callback={callback}
        />
    );
};

export default DailyTimer;

DailyTimer.propTypes = {
    start: PropTypes.string,
    end: PropTypes.string,
    callback: PropTypes.func,
};
