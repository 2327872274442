import styled from 'styled-components';
import bgImage from '../../../assets/images/LoyaltyPointsShop/topHeaderPattern.png';

export const LoyaltyPointsTopStyled = styled.div`
    position: relative;
    overflow: hidden;
    background-color: ${({ theme }) => theme.palette.blue.main};

    border-radius: 24px;

    margin-bottom: 40px;
    padding: 30px 0px 0px 0px;

    display: flex;
    flex-direction: column;
    gap: 80px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
        gap: 160px;
    }

    @media (max-width: 425px) {
        gap: 90px;
    }

    .WP-loyalty-points-top-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${bgImage});
        mask-image: linear-gradient(to right, transparent 5%, rgba(0, 0, 0, 0.2));

        ${({ theme }) => theme.breakpoints.down('md')} {
            mask-image: linear-gradient(
                to right,
                transparent 0%,
                rgba(0, 0, 0, 0.2) 50%,
                rgba(0, 0, 0, 0.2) 100%
            );
        }
    }

    .WP-loyalty-points-top-title {
        font-family: ${({ theme }) => theme.typography.font.museoSansRounded};
        font-weight: ${({ theme }) => theme.typography.weight.bolder};
        font-size: ${({ theme }) => theme.typography.size.h3.fontSize};
        color: ${({ theme }) => theme.palette.common.white};
        padding: 0px 30px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            max-width: 60%;
        }

        ${({ theme }) => theme.breakpoints.up('lg')} {
            max-width: 80%;
        }
    }

    .WP-loyalty-points-top-buttons {
        z-index: 10;
        display: flex;
        justify-content: center;
        gap: 16px;
        padding: 16px;

        button {
            width: 350px;
        }

        ${({ theme }) => theme.breakpoints.down('xs')} {
            gap: 10px;
        }
    }

    .WP-loyalty-points-top-dice {
        position: absolute;
        right: 0;
        height: 228px;

        ${({ theme }) => theme.breakpoints.down('sm')} {
            height: 219px;
            bottom: 20px;
        }

        ${({ theme }) => theme.breakpoints.down('xs')} {
            bottom: 0px;
        }

        @media (max-width: 425px) {
            height: 190px;
        }
    }
`;
