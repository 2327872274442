import styled from 'styled-components';

import bgImage from '../../../../assets/images/Home/Logout/leaderboradBg.png';
import rightConffeti from '../../../../assets/images/Home/Logout/rightConffeti.png';
import leftConffeti from '../../../../assets/images/Home/Logout/leftConfetti.png';

export const LeaderboardStyled = styled.section`
    min-height: 500px;

    justify-content: center;
    flex-direction: column-reverse;

    background-image: url(${bgImage});
    z-index: 4;

    ${({ theme }) => theme.breakpoints.up('lg')} {
        min-height: 380px;
        flex-direction: row;
    }

    &:before,
    &:after {
        width: 150px;
        height: 100%;

        top: 0;
        bottom: initial !important;
        border-radius: 0;
        background-size: cover;

        ${({ theme }) => theme.breakpoints.up('lg')} {
            width: 300px;
            height: 350px;
            background-size: contain;
        }
    }

    &:before {
        background-image: url(${leftConffeti});
    }

    &:after {
        background-image: url(${rightConffeti});
    }

    .WP-leaderboard-content {
        ${({ theme }) => theme.breakpoints.up('lg')} {
            width: 50%;
        }
    }

    .WP-leaderboard-art {
        min-width: 400px;
        max-width: 400px;
    }
`;
