export const typography = {
    size: {
        h1: { fontSize: '48px' },
        h2: { fontSize: '40px' },
        h3: { fontSize: '32px' },
        h4: { fontSize: '28px' },
        h5: { fontSize: '24px' },
        h6: { fontSize: '20px' },
        p18: { fontSize: '18px' },
        p16: { fontSize: '16px' },
        p14: { fontSize: '14px' },
        p12: { fontSize: '12px' },
        p10: { fontSize: '10px' },
    },
    weight: {
        bolder: 900,
        bold: 700,
        semiBold: 600,
        medium: 500,
        regular: 400,
    },
    font: {
        inter: 'Inter',
        museoSansRounded: 'Museo Sans Rounded',
        rubik: 'Rubik',
    },
};
