import styled from 'styled-components';

export const DailyOfferCommodityStyled = styled.div`
    flex-wrap: wrap;
    margin-right: auto;
    margin-left: auto;

    .WP-commodity-quantity-wrapper {
        width: 100%;
        border-radius: 6px;
        background-color: rgba(0, 0, 0, 0.2);
        padding-top: 2px;
        padding-bottom: 2px;
        margin-top: -8px;
    }

    .WP-commodity-col-1 {
        width: 120px;

        .WP-commodity-img {
            height: 80px;

            ${({ theme }) => theme.breakpoints.up('lg')} {
                height: 100px;
            }
        }

        .WP-commodity-quantity-wrapper {
            border-radius: 10px;
        }

        .WP-commodity-quantity {
            font-size: 24px;
        }
    }

    .WP-commodity-col-2 {
        width: 50%;

        .WP-commodity-inner {
            padding: 6px;
        }

        .WP-commodity-img {
            height: 68px;

            ${({ theme }) => theme.breakpoints.up('mac')} {
                height: 76px;
            }
        }

        .WP-commodity-quantity-wrapper {
            border-radius: 10px;
        }

        .WP-commodity-quantity {
            font-size: 20px;

            ${({ theme }) => theme.breakpoints.up('mac')} {
                font-size: 24px;
            }
        }
    }

    .WP-commodity-col-3 {
        width: 33.3333%;

        .WP-commodity-inner {
            padding: 6px;
        }

        .WP-commodity-img {
            height: 58px;

            ${({ theme }) => theme.breakpoints.up('mac')} {
                height: 72px;
            }
        }

        .WP-commodity-quantity-wrapper {
            border-radius: 8px;

            ${({ theme }) => theme.breakpoints.up('mac')} {
                border-radius: 10px;
            }
        }

        .WP-commodity-quantity {
            font-size: 18px;

            ${({ theme }) => theme.breakpoints.up('mac')} {
                font-size: 24px;
            }
        }
    }

    .WP-commodity-col-4,
    .WP-commodity-col-5,
    .WP-commodity-col-6,
    .WP-commodity-col-7,
    .WP-commodity-col-8 {
        .WP-commodity-img {
            height: 40px;

            ${({ theme }) => theme.breakpoints.up('lg')} {
                height: 60px;
            }

            ${({ theme }) => theme.breakpoints.up('mac')} {
                height: 64px;
            }
        }

        .WP-commodity-quantity {
            font-size: 14px;

            ${({ theme }) => theme.breakpoints.up('lg')} {
                font-size: 18px;
            }

            ${({ theme }) => theme.breakpoints.up('mac')} {
                font-size: 20px;
            }
        }
    }

    .WP-commodity-col-4 {
        width: 50%;

        .WP-commodity-inner {
            padding: 6px;
        }
    }

    .WP-commodity-col-5,
    .WP-commodity-col-6 {
        width: 33.3333%;

        .WP-commodity-inner {
            padding: 6px 4px;
        }
    }

    .WP-commodity-col-7,
    .WP-commodity-col-8 {
        width: 25%;

        .WP-commodity-inner {
            padding: 6px 4px;
        }
    }

    .WP-commodity-col-9,
    .WP-commodity-col-10 {
        width: 20%;

        .WP-commodity-inner {
            padding: 4px 2px;
        }

        .WP-commodity-img {
            height: 35px;

            ${({ theme }) => theme.breakpoints.up('lg')} {
                height: 46px;
            }

            ${({ theme }) => theme.breakpoints.up('mac')} {
                height: 60px;
            }
        }

        .WP-commodity-quantity {
            font-size: 12px;

            ${({ theme }) => theme.breakpoints.up('lg')} {
                font-size: 16px;
            }

            ${({ theme }) => theme.breakpoints.up('mac')} {
                font-size: 20px;
            }
        }
    }
`;
