import styled from 'styled-components';

export const DailySliderItemStyled = styled.div`
    background-image: url(${({ backgroundImage }) => backgroundImage});
    background-color: #18a0aa;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 14px;
    position: relative;
    z-index: 2;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        border-radius: 20px;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
        }
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
        border-radius: 24px;
    }

    ${({ theme }) => theme.breakpoints.up('xl')} {
        border-radius: 30px;
    }

    &.WP-day-1 {
        ${({ theme }) => theme.breakpoints.up('sm')} {
            &:after {
                background-image: linear-gradient(
                    90deg,
                    rgba(26, 170, 165, 0) 42%,
                    #096ec9 60%,
                    #084f8f 100%
                );
            }
        }

        .WP-slider-title {
            color: #096ec9;
        }

        .WP-day-label {
            color: #005fa6;
        }
    }

    &.WP-day-2 {
        ${({ theme }) => theme.breakpoints.up('sm')} {
            &:after {
                background-image: linear-gradient(
                    90deg,
                    rgba(235, 0, 255, 0) 42%,
                    #5d22da 60%,
                    #491ba9 100%
                );
            }
        }

        .WP-slider-title {
            color: #4f1adb;
        }

        .WP-day-label {
            color: #4e19bc;
        }
    }

    &.WP-day-3 {
        ${({ theme }) => theme.breakpoints.up('sm')} {
            &:after {
                background-image: linear-gradient(
                    90deg,
                    rgba(26, 170, 165, 0) 42%,
                    #0031ae 60%,
                    #0031ae 100%
                );
            }
        }

        .WP-slider-title {
            color: #ffffff;
            text-shadow: 0 4px 0 rgba(0, 0, 0, 0.44);
        }

        .WP-day-label {
            color: #0f369b;
        }
    }

    .WP-slider-mobile {
        ${({ theme }) => theme.breakpoints.down('sm')} {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .WP-slider-title {
        text-shadow: 0 4px 0 rgba(255, 255, 255, 0.14);

        ${({ theme }) => theme.breakpoints.between(1780, 1800)} {
            font-size: 85px;
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            font-size: 85px;
        }
    }

    .WP-slider-discount {
        color: ${({ theme }) => theme.DailyOffer.modal.discountColor};
        text-shadow: ${({ theme }) => theme.DailyOffer.modal.discountShadow};
    }

    .WP-commodity-position {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .WP-commodity-row-2 {
        width: 80%;

        ${({ theme }) => theme.breakpoints.up('mac')} {
            width: 60%;
        }
    }

    .WP-commodity-row-3 {
        width: 90%;
    }

    .WP-commodity-row-4 {
        width: 60%;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            width: 80%;
        }

        ${({ theme }) => theme.breakpoints.up('mac')} {
            width: 60%;
        }
    }

    .WP-commodity-row-5,
    .WP-commodity-row-6 {
        width: 80%;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            width: 90%;
        }
    }

    .WP-commodity-row-7,
    .WP-commodity-row-8,
    .WP-commodity-row-9,
    .WP-commodity-row-10 {
        width: 90%;
    }
`;
