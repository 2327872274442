import React from 'react';
import PropTypes from 'prop-types';

import { Box, Link, useMediaQuery } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';
import { useTheme } from '@material-ui/core/styles';

import { SupportStyled } from '../Support.styled';
import DateFormat from '../../../components/DateFormat/DateFormat';
import { useTranslation } from '../../../../hooks/useTranslation';

const Item = ({ item, isTranslated }) => {
    const theme = useTheme();
    const t = useTranslation();

    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const breakpointsMac = useMediaQuery(theme.breakpoints.between(1780, 1800), {
        noSsr: true,
    });

    return (
        <Box
            component={SupportStyled}
            className="WP-support-item"
            borderRadius={[14, 20, 20, 24, 30]}
            overflow="hidden"
            height={1}
            bgcolor={theme.palette.orange.A50}
            boxShadow={theme.elevation[100]}
        >
            <Box
                component="img"
                className="object-cover"
                width={1}
                height={[126, 138, 133, breakpointsMac ? 206 : 163, 250]}
                src={item.image}
                alt="gift"
            />

            <Box
                component="figcaption"
                className="WP-support-figcaption"
                height={[110, 126, 126, 200, 220]}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                p={[2, 3, 3, 5]}
            >
                <Box>
                    <Box
                        display="flex"
                        alignItems="start"
                        justifyContent="space-between"
                        mb={[1, 2]}
                    >
                        <Box
                            component={Typography}
                            className="WP-support-date"
                            variant={['p12', 'p12', 'p12', 'p16']}
                            fWeight="semiBold"
                            color={theme.palette.brown.A600}
                            mb={2}
                        >
                            <DateFormat date={item.createdAt} format="MMMM D, YYYY" />
                        </Box>
                    </Box>

                    <Box
                        component={Typography}
                        className="WP-support-title"
                        variant={['p16', 'p16', 'p16', 'h5']}
                        fWeight="bold"
                        color={theme.palette.brown.main}
                    >
                        {item.title}
                    </Box>
                </Box>

                <Link
                    href={item.sectionUrl}
                    className={`${
                        breakpointsXL
                            ? 'large'
                            : breakpointsLG
                            ? 'medium'
                            : breakpointsSM
                            ? 'small'
                            : 'extra-small'
                    } WP-support-button primary`}
                    target="_blank"
                >
                    {isTranslated ? t(`${item.buttonText}`) : item.buttonText}
                </Link>
            </Box>
        </Box>
    );
};

Item.propTypes = {
    item: PropTypes.object,
    isTranslated: PropTypes.bool,
};

export default Item;
