import { LANGUAGES } from '../../../constants/languages.constantants';
export class WebMessageBannerGetter {
    constructor(webMessageBannerRepository) {
        this.webMessageBannerRepository = webMessageBannerRepository;
    }

    async execute(locale = LANGUAGES[0].locale) {
        const messageBanners = await this.webMessageBannerRepository.getWebMessageBanners(locale);

        return messageBanners[0] || null;
    }
}
