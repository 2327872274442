import React from 'react';
import PropTypes from 'prop-types';

import { Box, useMediaQuery } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';

import DateFormat from '../../components/DateFormat/DateFormat';
import { NewsPageStyled } from './NewsPage.styled';
import { Label } from '../../components/ui-components/Label/Label';
import { LABELTYPE } from '../../../enums/enums';
import NotFoundPage from '../NotFound/NotFound';

const NewsSelectedItem = ({ newItem, theme }) => {
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });

    return (
        <Box component={NewsPageStyled} mt={[4, 15]} mb={[6, 40]}>
            {!newItem ? (
                <NotFoundPage />
            ) : (
                <Box
                    className="WP-news-page-wrapper"
                    width={['100%', '80%']}
                    borderRadius={[14, 20, 20, 24, 30]}
                    display="flex"
                    flexDirection="column"
                >
                    <Box
                        component="img"
                        className="object-cover"
                        src={newItem.image}
                        alt={newItem.title}
                        width={1}
                        maxHeight={[146, 340]}
                    />
                    <Box bgcolor={theme.palette.orange.A50} px={7} py={7}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={2}
                        >
                            <Box
                                component={Typography}
                                variant={['p12', 'p12', 'p12', 'p14', 'p16']}
                                fWeight="semiBold"
                                color={theme.palette.brown.A600}
                                className="WP-NewsCard-Date"
                            >
                                <DateFormat date={newItem.createdAt} format="MMMM D, YYYY" />
                            </Box>

                            <Label
                                size={breakpointsXL ? 'large' : breakpointsLG ? 'medium' : 'small'}
                                variant="contained"
                                color={LABELTYPE[newItem.label]}
                            >
                                {newItem.labelCategory}
                            </Label>
                        </Box>

                        <Box
                            component={Typography}
                            variant={['h6', 'h6', 'h6', 'h5', 'h4']}
                            fWeight="bold"
                            color={theme.palette.brown.main}
                            className="WP-NewsCard-Title text-truncate-second-line"
                            mb={[4, 7]}
                        >
                            {newItem.title}
                        </Box>

                        <Box
                            component={Typography}
                            variant={['p14', 'p16']}
                            fWeight="medium"
                            color="#424242"
                            mt={2}
                        >
                            <p
                                className="WP-NewsCard-description"
                                dangerouslySetInnerHTML={{ __html: newItem.content }}
                            />
                        </Box>
                    </Box>
                </Box>
            )}

            {/*---------------- Next, Prev buttons start ----------------*/}

            {/*<Box*/}
            {/*    width={['100%', '80%']}*/}
            {/*    className="WP-news-buttons"*/}
            {/*    display="flex"*/}
            {/*    justifyContent="space-between"*/}
            {/*    mt={7}*/}
            {/*>*/}
            {/*<Box*/}
            {/*    component={Button}*/}
            {/*    variant="primary"*/}
            {/*    size={*/}
            {/*        breakpointsXL*/}
            {/*            ? 'large'*/}
            {/*            : breakpointsLG*/}
            {/*            ? 'medium'*/}
            {/*            : breakpointsSM*/}
            {/*            ? 'small'*/}
            {/*            : 'extra-small'*/}
            {/*    }*/}
            {/*    preventLoading={true}*/}
            {/*    fullWidth={false}*/}
            {/*    mr={3}*/}
            {/*>*/}
            {/*    <Icon className="icon-play-left" size={24} />*/}
            {/*    {t('previous')}*/}
            {/*</Box>*/}

            {/*<Box*/}
            {/*    component={Button}*/}
            {/*    variant="primary"*/}
            {/*    size={*/}
            {/*        breakpointsXL*/}
            {/*            ? 'large'*/}
            {/*            : breakpointsLG*/}
            {/*            ? 'medium'*/}
            {/*            : breakpointsSM*/}
            {/*            ? 'small'*/}
            {/*            : 'extra-small'*/}
            {/*    }*/}
            {/*    preventLoading={true}*/}
            {/*    fullWidth={false}*/}
            {/*>*/}
            {/*    {t('next')}*/}

            {/*    <Icon className="icon-play-right" size={24} />*/}
            {/*</Box>*/}
            {/*</Box>*/}

            {/*---------------- Next, Prev buttons end ----------------*/}
        </Box>
    );
};

NewsSelectedItem.propTypes = {
    theme: PropTypes.object,
    newItem: PropTypes.object,
};

export default NewsSelectedItem;
