import React from 'react';
import { useSelector } from 'react-redux';

import { Box, useMediaQuery } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { globalSel } from '../../../store/global';

const OfferCardSkeleton = () => {
    const theme = useSelector(globalSel.themeSelector);
    const breakpointsMac = useMediaQuery(theme.breakpoints.between(1780, 1800), {
        noSsr: true,
    });

    return (
        <Box
            className="WP-card-skeleton"
            borderRadius={[14, 20, 20, 24, 30]}
            bgcolor={theme.palette.orange.A50}
            boxShadow={theme.elevation[100]}
            overflow="hidden"
        >
            <Box
                className="WP-card-bgImg-skeleton"
                height={[126, 138, 133, breakpointsMac ? 206 : 163, 250]}
                flexShrink={0}
            >
                <Skeleton variant="rect" animation="wave" height="100%" width="100%" />
            </Box>

            <Box
                className="WP-card-figcaption-skeleton"
                height={[160, 210, 200, 240, 270]}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                pt={2}
                px={[2, 3, 3, 4, 5]}
                pb={[2, 3, 3, 4, 5]}
            >
                <Box>
                    <Box height={[16, 24, 24, 28, 32]} width="100%">
                        <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                    </Box>

                    <Box height={[24, 28, 28, 32, 40]} width="100%">
                        <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                    </Box>
                </Box>

                <Box height={[32, 38, 38, 48, 56]}>
                    <Skeleton variant="rect" animation="wave" height="100%" width="100%" />
                </Box>
            </Box>
        </Box>
    );
};

export default OfferCardSkeleton;
