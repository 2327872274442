import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';

import { BadgeStyled, ButtonBadgeStyled } from './Badge.styled';
import { useSelector } from 'react-redux';
import { globalSel } from '../../../store/global';

const Badge = ({ text, buttonBadge, className }) => {
    const theme = useSelector(globalSel.themeSelector);

    return buttonBadge ? (
        <Box component={ButtonBadgeStyled} px={[3, 6]}>
            <Typography variant={['p12', 'p14']} fWeight="bold" color={theme.palette.common.white}>
                {text}
            </Typography>
        </Box>
    ) : (
        <Box
            component={BadgeStyled}
            display="flex"
            alignItems="center"
            bgcolor={theme.palette.red.A700}
            height={[24, 40, 40, 44, 48]}
            px={[3, 5, 5, 6]}
            className={className && className}
        >
            <Typography
                className="WP-badge-text"
                variant={['p12', 'p16']}
                fWeight="bold"
                color={theme.palette.common.white}
            >
                {text}
            </Typography>
        </Box>
    );
};

Badge.defaultProps = {
    buttonBadge: false,
};

Badge.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    buttonBadge: PropTypes.bool,
};

export default Badge;
