import styled from 'styled-components';

export const LoadAnimateButtonStyled = styled.div`
    .processing-text {
        padding-left: 4px;
        ${({ theme }) => theme.breakpoints.down(600)} {
            display: none;
        }
    }

    .animation {
        &-load-button {
            width: 4px !important;
            height: 4px !important;
            background-color: white;
            border-radius: 50%;
            margin-right: 3px;
            display: inline-block;

            &:last-child {
                margin-right: 5px;
            }

            &-single {
                animation: single 0.6s linear infinite;
            }

            &-double {
                animation: double 0.6s linear infinite;
            }

            @keyframes single {
                0% {
                    transform: translateY(2px);
                }
                50% {
                    transform: translateY(-2px);
                }
                100% {
                    transform: translateY(2px);
                }
            }
            @keyframes double {
                0% {
                    transform: translateY(-2px);
                }
                50% {
                    transform: translateY(2px);
                }
                100% {
                    transform: translateY(-2px);
                }
            }
        }
    }
`;
