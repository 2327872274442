import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';
import { HighlightedTextStyled } from './HighlightedText.styled';

const HighlightedText = ({ text, fontSize, textCenter, highlightedText, ...props }) => {
    return (
        <Box
            component={HighlightedTextStyled}
            position="relative"
            display="flex"
            justifyContent={textCenter ? 'center' : null}
            {...props}
        >
            <Box
                component={Typography}
                className={highlightedText ? 'text-highlighted' : 'text-highlighted-black'}
                fontFamily="rubik"
                variant={fontSize}
                fWeight="bold"
            >
                {highlightedText ? text : `x${text}`}
            </Box>

            <Box
                component={Typography}
                className={`${
                    highlightedText ? 'WP-highlighted-color' : 'WP-highlighted-gradient'
                }`}
                fontFamily="rubik"
                variant={fontSize}
                fWeight="bold"
                position="absolute"
                top={0}
            >
                {highlightedText ? text : `x${text}`}
            </Box>
        </Box>
    );
};

HighlightedText.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    textCenter: PropTypes.bool,
    highlightedText: PropTypes.bool,
};

export default HighlightedText;
