import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Box } from '@material-ui/core';

function UserProfileSkeleton() {
    return (
        <Box mt={25} display="flex" alignItems="center" flexDirection="column">
            <Skeleton
                variant="circle"
                animation="wave"
                style={{ borderRadius: '50%' }}
                width="100px"
                height="100px"
            />
            <Skeleton variant="text" animation="wave" width="90%" height="25px" />
            <Skeleton variant="text" animation="wave" width="25%" height="20px" />
            <Skeleton
                variant="circle"
                animation="wave"
                style={{ borderRadius: '50%' }}
                width="40px"
                height="40px"
            />
            <Skeleton variant="text" animation="wave" width="100%" height="100px" />
            <Skeleton variant="text" animation="wave" width="100%" height="100px" />
        </Box>
    );
}

export default UserProfileSkeleton;
