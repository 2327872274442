import styled from 'styled-components';

export const PageLoaderStyled = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);

    .WP-page-loader {
        border: 4px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(180deg, #ff6827 -110%, #ffb932 100%);

        animation: loader 1.5s infinite ease;
    }

    .WP-page-loader-inner {
        vertical-align: top;
        background: linear-gradient(180deg, #ff6827 -110%, #ffb932 100%);
        animation: loader-inner 1.5s infinite ease-in;
    }

    .WP-page-loader-circle {
        width: 5px;
        height: 5px;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        background: linear-gradient(180deg, #ff6827 -110%, #ffb932 100%);

        &:nth-child(1) {
            top: 5px;
            left: 5px;
        }

        &:nth-child(2) {
            top: 5px;
            right: 5px;
        }

        &:nth-child(3) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:nth-child(4) {
            bottom: 5px;
            left: 5px;
        }
        &:nth-child(5) {
            bottom: 5px;
            right: 5px;
        }
    }

    @keyframes loader {
        0% {
            transform: rotate(0deg);
        }

        25% {
            transform: rotate(180deg);
        }

        50% {
            transform: rotate(180deg);
        }

        75% {
            transform: rotate(360deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes loader-inner {
        0% {
            height: 0;
        }

        25% {
            height: 0;
        }

        50% {
            height: 100%;
        }

        75% {
            height: 100%;
        }

        100% {
            height: 0;
        }
    }
`;
