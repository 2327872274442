import styled from 'styled-components';

export const ModalStoreLinksStyled = styled.div`
    display: flex;
    justify-content: center;
    gap: 40px;
    padding: 0 40px;

    .WP-modal-store-link {
        width: 100%;

        img {
            width: 100%;
        }
    }
`;
