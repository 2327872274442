import { getStorageItem } from './localStorage';

export const gtagEvent = (ACTION, category, label, value) => {
    const userID = getStorageItem('userID');
    if (!window.gtag) return;

    return window.gtag('event', ACTION, {
        event_category: category || '',
        event_label: label || '',
        value: value || userID,
    });
};

export const setGAEvent = (eventName, ...rest) => {
    if (!window.gtag) return;

    return window.gtag('event', eventName, ...rest);
};

/**
 * GA4 Analytics Event for Login with Fb or ScopelyId
 * @param userId
 * @param connectionType
 */
export const loginEvent = (connectionType, userId) => {
    if (!window.gtag) return;

    return window.gtag('event', 'login', {
        connection_type: connectionType || '',
        user_id: userId || null,
    });
};
