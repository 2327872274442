import { fetch } from '../FetchService';
import { postParser, postsParser } from './PostsParser';

export class PostsRepository {
    async getNews({ locale }) {
        const {
            data: {
                Data: { Items },
                IsSuccess,
            },
        } = await fetch({
            endPoint: `me/news`,
            method: 'GET',
            params: {
                c_locale: locale,
            },
        });

        if (!IsSuccess || !Items.length) {
            return [];
        }

        return postsParser(
            this.filterAndSortData(Items, [
                'Tips & Tricks',
                'Support',
                'Web Message Banner', //TODO: In future, remove this when no longer old Web Message Banners
                'Web Floating Banner Offer',
                'Web Floating Banner Generic',
                'Web Floating Banner Announcement',
                'Web Floating Banner Error/Change',
            ]),
        );
    }

    async getUserGuides({ locale }) {
        const {
            data: {
                Data: { Items },
                IsSuccess,
            },
        } = await fetch({
            endPoint: `me/news/category/Tips & Tricks`,
            method: 'GET',
            params: {
                c_locale: locale,
                BypassSignature: true,
            },
        });

        if (!IsSuccess || !Items.length) {
            return [];
        }

        return postsParser(this.filterAndSortData(Items));
    }

    async getSupports({ locale }) {
        const {
            data: {
                Data: { Items },
                IsSuccess,
            },
        } = await fetch({
            endPoint: `me/news/category/Support`,
            method: 'GET',
            params: {
                c_locale: locale,
                BypassSignature: true,
            },
        });

        if (!IsSuccess || !Items.length) {
            return [];
        }

        return postsParser(this.filterAndSortData(Items));
    }

    async getWebMessageBanners({ locale }) {
        const {
            data: {
                Data: { Items },
                IsSuccess,
            },
        } = await fetch({
            endPoint: `me/news`,
            method: 'GET',
            params: {
                c_locale: locale,
            },
        });

        if (!IsSuccess || !Items.length) {
            return [];
        }

        return postsParser(
            this.filterAndSortData(
                Items,
                [],
                [
                    'Web Floating Banner Offer',
                    'Web Floating Banner Generic',
                    'Web Floating Banner Announcement',
                    'Web Floating Banner Error/Change',
                ],
            ),
        );
    }

    async getOne({ slug }) {
        const {
            data: {
                Data: { Items },
                IsSuccess,
            },
        } = await fetch({
            endPoint: `me/news`,
            method: 'GET',
        });

        if (!IsSuccess || !Items.length) {
            return [];
        }

        return postParser(Items.find((item) => item.Config.Id === slug));
    }

    filterAndSortData(data, removes = [], filter = []) {
        return data.reduce((acc, item) => {
            if (
                (removes.length > 0 && !removes.includes(item.Config.Category.Name)) ||
                (filter.length > 0 && filter.includes(item.Config.Category.Name)) ||
                (removes.length === 0 && filter.length === 0)
            ) {
                const index = acc.findIndex((existingItem) => {
                    const exItemOrder = existingItem.Config.Order;
                    const newItemOrder = item.Config.Order;
                    const exItemDate = new Date(existingItem.Config.StartDate);
                    const newItemDate = new Date(item.Config.StartDate);

                    return (
                        exItemOrder > newItemOrder ||
                        (exItemOrder === newItemOrder && exItemDate < newItemDate)
                    );
                });

                if (index === -1) {
                    acc.push(item);
                } else {
                    acc.splice(index, 0, item);
                }
            }
            return acc;
        }, []);
    }
}
