import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Box, Typography } from 'portal-modules/core-ui';

import { NewsCardStyled } from './NewsCard.styled';
import DateFormat from '../DateFormat/DateFormat';
import { setGAEvent } from '../../../helpers/GA4Helper';
import { useTranslation } from '../../../hooks/useTranslation';
import { Button } from '../ui-components/Button/Button';
import { Label } from '../ui-components/Label/Label';
import { LABELTYPE } from '../../../enums/enums';

const NewsCard = ({ item }) => {
    const t = useTranslation();
    const theme = useTheme();
    const history = useHistory();

    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const breakpointsMac = useMediaQuery(theme.breakpoints.between(1780, 1800), {
        noSsr: true,
    });

    const handleNewsOpen = () => {
        history.push({
            pathname: `news/${item.url}`,
        });
        setGAEvent('clickEvent', {
            screen_name: 'home',
            screen_type: 'screen',
            screen_section: 'news_and_events',
            interaction_object: 'read_more',
            interaction_object_category: 'display',
            offer_id: item.id,
        });

        setGAEvent('impression', {
            screen_name: 'home_news',
            screen_type: 'pop-up',
            offer_id: item.id,
        });
    };

    return (
        <Box component={NewsCardStyled} borderRadius={[14, 20, 20, 24, 30]}>
            <Box>
                <Box className="WP-NewsCard-Image">
                    <Box
                        component="img"
                        src={item.image}
                        alt={item?.title}
                        width={1}
                        maxHeight={[126, 138, 133, breakpointsMac ? 206 : 163, 250]}
                        borderRadius={[14, 20, 20, 24, 30]}
                    />
                </Box>

                <Box
                    height={[200, 200, 220, 260, 260]}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    py={[3, 3, 4]}
                    px={[5, 5, 5, 5, 6]}
                >
                    <Box>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItem="center"
                            mb={2}
                        >
                            <Box
                                component={Typography}
                                variant={['p12', 'p12', 'p12', 'p14', 'p16']}
                                fWeight="semiBold"
                                color={theme.palette.brown.A600}
                                mb={[0, 0, 0, 0, 2]}
                                className="WP-NewsCard-Date"
                            >
                                <DateFormat date={item.createdAt} format="MMMM D, YYYY" />
                            </Box>

                            <Label
                                size={breakpointsXL ? 'large' : breakpointsLG ? 'medium' : 'small'}
                                variant="contained"
                                color={LABELTYPE[item.label]}
                            >
                                {item.labelCategory}
                            </Label>
                        </Box>

                        <Box
                            component={Typography}
                            variant={['h6', 'h6', 'h6', 'h5']}
                            fWeight="bold"
                            color={theme.palette.brown.main}
                            className="WP-NewsCard-Title text-truncate"
                        >
                            {item.title}
                        </Box>
                        <Box
                            className="WP-NewsCard-Desc text-truncate-second-line"
                            mt={2}
                            dangerouslySetInnerHTML={{
                                __html: item.description,
                            }}
                        />
                    </Box>

                    <Button
                        onClick={handleNewsOpen}
                        variant="primary"
                        size={
                            breakpointsXL
                                ? 'large'
                                : breakpointsLG
                                ? 'medium'
                                : breakpointsSM
                                ? 'small'
                                : 'extra-small'
                        }
                        preventLoading={true}
                    >
                        {t('read.more')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

NewsCard.propTypes = {
    item: PropTypes.object,
};

export default NewsCard;
