import styled from 'styled-components';

export const AddBalanceStyled = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 8px;
    background-image: linear-gradient(
        180deg,
        rgba(172, 199, 44, 0.8) 0%,
        rgba(172, 199, 44, 0.4) 100%
    );
    box-shadow: 0 1px 0 rgba(172, 199, 44, 1);

    &:active {
        box-shadow: none;
    }

    .WP-ActionButton-button {
        background-image: linear-gradient(rgba(172, 199, 44, 0.4), rgba(172, 199, 44, 0.8));
        color: ${({ theme }) => theme.palette.common.white};
        border-radius: 8px;
        border: none;
    }
`;
