import React from 'react';

import { Box } from '@material-ui/core';
import { PageLoaderStyled } from './PageLoader.styled';

const PageLoader = () => {
    return (
        <Box
            component={PageLoaderStyled}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Box className="WP-page-loader" width={40} height={40} position="relative">
                <span className="WP-page-loader-circle" />
                <span className="WP-page-loader-circle" />
                <span className="WP-page-loader-circle" />
                <span className="WP-page-loader-circle" />
                <span className="WP-page-loader-circle" />

                <Box className="WP-page-loader-inner" width={1} position="relative" zIndex={1} />
            </Box>
        </Box>
    );
};

export default PageLoader;
