import React from 'react';
//import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';
import { Box } from '@material-ui/core';

import { TopLeadersStyled } from './TopLeaders.styled';
//import { getStats } from '../../../../store/leaderboard/operations';

const TopLeaderItem = ({ item, index }) => {
    // type
    const theme = useTheme();
    //const dispatch = useDispatch();
    const breakpointsXS = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
    //const html = document.getElementsByTagName('html')[0].classList;

    // const handlePopupOpen = (type, id) => {
    //     dispatch(getStats(type, id));
    //     html.add('disable-scroll');
    // };

    return (
        <Box
            component={TopLeadersStyled}
            className="WP-leader-item"
            bgcolor={theme.palette.common.white}
            display="flex"
            justifyContent="space-between"
            minHeight={52}
            px={3}
            py={2}
            mb={2}
        >
            <Box display="flex">
                <>
                    {item.AvatarUrl.startsWith('https:') || item.AvatarUrl.endsWith('.png') ? (
                        <img
                            className="WP-leader-item-image"
                            src={item.AvatarUrl}
                            width="32px"
                            height="32px"
                            alt="User Image"
                        />
                    ) : null}
                </>
                <Box
                    width="100%"
                    display="flex"
                    alignItems={breakpointsXS ? 'baseline' : 'center'}
                    flexDirection={breakpointsXS ? 'column' : 'row'}
                >
                    <Box px={2}>
                        <Box
                            data-testid="players_modal"
                            // onClick={() => handlePopupOpen(type, item.Id)}
                        >
                            <Typography
                                variant="p14"
                                fWeight="bold"
                                color={theme.palette.brown.main}
                                // className="cursor-pointer"
                            >
                                {item.Name}
                            </Typography>
                        </Box>

                        {item.FamilyName && (
                            <Box>
                                {/*onClick={() => handlePopupOpen(2, item.FamilyId)}*/}
                                <Typography
                                    variant="p14"
                                    fWeight="normal"
                                    color={theme.palette.brown.A600}
                                    // className="cursor-pointer"
                                >
                                    {item.FamilyName}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    {breakpointsXS && (
                        <Box
                            component={Typography}
                            px={2}
                            variant="p14"
                            fWeight="normal"
                            color={theme.palette.common.black}
                            mt={1}
                        >
                            {item.Score}
                        </Box>
                    )}
                </Box>
            </Box>
            <Box className="flex-center">
                {!breakpointsXS && (
                    <Box
                        component={Typography}
                        px={2}
                        variant="p14"
                        fWeight="normal"
                        color={theme.palette.common.black}
                    >
                        {item.Score}
                    </Box>
                )}

                <Box width={30} height={30} display="flex" position="relative">
                    <Box
                        className={`hexagon-shape flex-center level-${
                            index < 4 ? index : 'standard'
                        }`}
                    >
                        {index}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

TopLeaderItem.propTypes = {
    item: PropTypes.object,
    type: PropTypes.number,
    index: PropTypes.number,
};

export default TopLeaderItem;
