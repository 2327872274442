import React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { globalSel } from '../../../../store/global';

const RefreshButtonsSkeleton = () => {
    const theme = useSelector(globalSel.themeSelector);

    return (
        <Box bgcolor={theme.palette.orange.A50} py={4} px={5} borderRadius={16} mt={[4, 5]}>
            <Box mb={2}>
                <Skeleton variant="rect" animation="wave" height={38} width="100%" />
            </Box>
            <Skeleton variant="text" animation="wave" height="100%" width="100%" />
        </Box>
    );
};

export default RefreshButtonsSkeleton;
