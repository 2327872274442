import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Icon, Box } from 'portal-modules/core-ui';
import { useMediaQuery, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { Label } from '../../../components/ui-components/Label/Label';
import { useTranslation } from '../../../../hooks/useTranslation';
import { NavStyled } from './Nav.styled';
import { deviceType } from '../../../../helpers/deviceType';
import { getSessionToken } from '../../../../bridge';
import globalSlice, { globalSel } from '../../../../store/global';
import { dailyRewardsSel } from '../../../../store/dailyReward';
import { getStorageItem } from '../../../../helpers/localStorage';
import NotificationPipContext from '../../../../context/notification-pip/NotificationPipContext';

export default function Nav({ navigationList }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const t = useTranslation();
    const md = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
    const checkMobileOrTablet = deviceType();
    const isLoggedIn = getSessionToken();

    const flagedPages = useSelector(globalSel.pageFlags);

    const dailyRewards = useSelector(dailyRewardsSel.dailyRewardsSelector);
    const dailyGiftClaimed = dailyRewards?.DailyGiftClaimed;

    const [notificationsState, setNotificationsState] = useState({
        6: false,
        7: false,
    });
    const { pips, isActive, hasNotification } = useContext(NotificationPipContext);

    const closeLeftSidebar = () => {
        dispatch(globalSlice.actions.setSidebar({ side: 'leftSidebar', open: false }));
    };

    var uri = undefined;
    switch (getStorageItem('fromSource')) {
        case 'dicefree':
            uri = 'dux://game/home/open';
            break;
        case 'yux':
            uri = 'yux://game/open';
            break;
        default:
            break;
    }

    useEffect(() => {
        navigationList.forEach((navItem) => {
            if (navItem.pip) {
                const hasNotif = hasNotification(navItem.href, navItem.pip);
                setNotificationsState((prevState) => ({
                    ...prevState,
                    [navItem.id]: hasNotif,
                }));
            }
        });
    }, [pips, dailyGiftClaimed]);

    return (
        <NavStyled>
            <Box p={0} m={0} pb={15} onClick={md ? closeLeftSidebar : null}>
                {navigationList.map((navItem) => {
                    const isHidden = navItem.name && flagedPages?.[navItem.name]?.Enabled === false;
                    return (
                        !isHidden && (
                            <NavLink
                                exact
                                activeClassName="active-link"
                                isActive={() => isActive(navItem)}
                                to={navItem.href}
                                key={navItem.id}
                                className="nav-link"
                                data-testid={navItem.className}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    py={3}
                                    px={3}
                                >
                                    <Icon
                                        className={`${navItem.iconClassname} ${
                                            notificationsState[navItem.id] && 'icon-notif'
                                        }`}
                                    />
                                    <div style={{ backgroundColor: 'green', height: '8px' }}></div>
                                    <Box className="text-truncate">
                                        <Box className="WP-Nav-title" mb={1}>
                                            {t(navItem.title)}
                                        </Box>
                                        <Box className="WP-Nav-subTitle">{t(navItem.subTitle)}</Box>
                                    </Box>
                                    {navItem.hasBadge ? (
                                        <Box ml={3} display="flex">
                                            <Label
                                                className="nav-label"
                                                size="extra-small"
                                                variant="contained"
                                                color="green"
                                            >
                                                New
                                            </Label>
                                        </Box>
                                    ) : null}
                                </Box>
                            </NavLink>
                        )
                    );
                })}
                {checkMobileOrTablet && uri && isLoggedIn && (
                    <Box className="back-to-game-wrapper" activeClassName="back-to-game-active">
                        <Link className="back-to-game" href={uri} underline="none">
                            <div className="back-to-game-title">{t('back.to.game')}</div>
                        </Link>
                    </Box>
                )}
            </Box>
        </NavStyled>
    );
}

Nav.propTypes = {
    navigationList: PropTypes.array.isRequired,
};
