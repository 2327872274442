import styled from 'styled-components';

export const DangerouslyHighlightedTextStyled = styled.div`
    .WP-highlighted-stroke,
    .WP-highlighted-color {
        font-family: 'Rubik';
        font-size: 16px;
        font-weight: bold;
        text-align: center;
    }

    .WP-highlighted-stroke {
        -webkit-text-stroke: 4px rgba(50, 50, 50, 1);
    }

    .WP-highlighted-color {
        color: rgba(255, 252, 225, 1);
    }
`;
