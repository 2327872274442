import styled from 'styled-components';
import LoyaltyPointsTransitionBg from '../../../../../assets/images/Modal/loyalty_points_new_look.png';

export const LoyaltyPointsTransitionStyled = styled.div`
    .WP-welcome-modal-header {
        background-size: cover;
        background-position: center;
        background-image: url(${LoyaltyPointsTransitionBg});
        z-index: 1;
    }
`;
