import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Box, useMediaQuery } from '@material-ui/core';

import RefreshButtons from './RefreshButtons/RefreshButtons';
import LogOutState from '../LogOutState/LogOutState';
import OfferCardLogOutSkeleton from '../OfferCardSkeleton/OfferCardLogOutSkeleton';
import SeeMoreItems from '../SeeMoreItems/SeeMoreItems';
import WrapperSkeleton from '../OfferCardSkeleton/WrapperSkeleton';
import SectionTitle from '../SectionTitle/SectionTitle';

import { dailyDealSel, dailyDealsOp } from '../../../store/dailyDeals';
import { shopSel } from '../../../store/shop';
import { useTranslation } from '../../../hooks/useTranslation';
import { getStorageItem } from '../../../helpers/localStorage';

import bgImageLogOut from '../../../assets/images/LogOut/daily_background.png';
import mainImageLogOut from '../../../assets/images/LogOut/daily_main.png';
import { globalSel } from '../../../store/global';

const DailyDeals = ({
    isTimingLabelExist,
    isRefreshButtonExist,
    redirectMoreItems,
    limit,
    limitSkeleton,
    cardGridSizeXl,
    skeletonGridSize,
    mt = [0, 0, 0],
}) => {
    const theme = useSelector(globalSel.themeSelector);
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });

    const t = useTranslation();
    const dispatch = useDispatch();

    const dealsLoading = useSelector(dailyDealSel.isLoadingSelector);
    const offersLoading = useSelector(shopSel.isLoading);

    const accessToken = getStorageItem('accessToken');
    const dailyDealsData = useSelector(dailyDealSel.dailyDealsSelector);
    const dailyDealsItems = dailyDealsData ? dailyDealsData?.Items : [];
    const forceRefreshCount = dailyDealsData?.ForceRefreshCount ?? 0;
    let isLoading = offersLoading || dealsLoading;

    return (
        <Box
            component="section"
            className="WP-daily-deals"
            id="WP-daily-deals-ftue"
            pb={[10, 12, 12, 16, 20]}
            mt={mt}
        >
            {!accessToken ? (
                <>
                    {isLoading ? (
                        <OfferCardLogOutSkeleton />
                    ) : (
                        <>
                            <SectionTitle
                                title={t('daily.deals')}
                                subtitle={t('daily.deals.subtitle')}
                                customIcon="daily-offers"
                            />
                            <LogOutState
                                bgImage={bgImageLogOut}
                                mainImage={mainImageLogOut}
                                section="daily_deals"
                            />
                        </>
                    )}
                </>
            ) : (
                <>
                    {isLoading ? (
                        <WrapperSkeleton
                            limitSkeleton={limitSkeleton}
                            skeletonGridSize={skeletonGridSize}
                            cardGridSizeXl={cardGridSizeXl}
                        />
                    ) : !dailyDealsData?.Items?.length ? null : (
                        <>
                            <Box className="WP-daily-deals-section-title">
                                <SectionTitle
                                    title={t('daily.deals')}
                                    subtitle={t('daily.deals.subtitle')}
                                    customIcon="daily-offers"
                                    isTimingLabelExist={
                                        isTimingLabelExist ? isTimingLabelExist : null
                                    }
                                    time={dailyDealsData?.NextRefreshDate}
                                    callback={() => dispatch(dailyDealsOp.getDailyDeals())}
                                />
                            </Box>

                            <SeeMoreItems
                                section="daily_deals"
                                data={dailyDealsItems}
                                limit={limit}
                                handleModalVariant="exclusiveVanityCard"
                                redirectTo={
                                    redirectMoreItems
                                        ? {
                                              page: '/loyalty-point-shop',
                                              section: 'WP-daily-deals',
                                          }
                                        : null
                                }
                                commodity="loyalty_point"
                                spacing={breakpointsLG ? 5 : 4}
                                cardGridSizeXl={cardGridSizeXl}
                            />
                            {isRefreshButtonExist && (
                                <Box mt={[5, 5, 5, 4, 8]}>
                                    <RefreshButtons
                                        forceRefreshCount={forceRefreshCount}
                                        isLoading={isLoading}
                                    />
                                </Box>
                            )}
                        </>
                    )}
                </>
            )}
        </Box>
    );
};

DailyDeals.defaultProps = {
    isTimingLabelExist: false,
    isRefreshButtonExist: false,
};

DailyDeals.propTypes = {
    isTimingLabelExist: PropTypes.bool,
    isRefreshButtonExist: PropTypes.bool,
    redirectMoreItems: PropTypes.bool,
    limit: PropTypes.number,
    limitSkeleton: PropTypes.array,
    cardGridSizeXl: PropTypes.number,
    skeletonGridSize: PropTypes.bool,
    mt: PropTypes.array,
};

export default DailyDeals;
