const xsollaTokenSelector = ({ shop }) => shop.xsollaToken;
const WebBonusRolls = ({ shop }) => shop?.offers?.WebBonusRolls?.StoreFront;
const WebExclusiveVanity = ({ shop }) => shop?.offers?.WebExclusiveVanity?.StoreFront;
const WebLoyaltyPoint = ({ shop }) => shop?.offers?.WebLoyaltyPoint?.StoreFront;
const WebFeatured = ({ shop }) => shop?.offers?.WebFeatured;
const WebFreeDailyGift = ({ shop }) => shop?.offers?.WebFreeDailyGift?.StoreFront;
const WebSticker = ({ shop }) => shop?.offers?.WebSticker?.StoreFront;
const WebLoyaltySticker = ({ shop }) => shop?.offers?.WebLoyaltySticker?.StoreFront;
const WebLoyaltyBonusRoll = ({ shop }) => shop?.offers?.WebLoyaltyBonusRoll?.StoreFront;
const WebLoyaltyPointsStoreEnergy = ({ shop }) =>
    shop?.offers?.WebLoyaltyPointsStoreEnergy?.StoreFront;
const WebEnergySKUs = ({ shop }) => shop?.offers?.WebEnergySKUs?.StoreFront;

const WebTopPlayers = ({ shop }) => shop?.offers?.WebTopPlayers?.StoreFront;
const WebTopFamilies = ({ shop }) => shop?.offers?.WebTopFamilies?.StoreFront;
const WebGameModes = ({ shop }) => shop?.offers?.WebGameModes?.StoreFront;
const WebLeaderboards = ({ shop }) => shop?.offers?.WebLeaderboards?.StoreFront;
const commoditiesSelector = ({ shop }) => shop?.commodities;
const limitsSelector = ({ shop }) => shop?.limits;
const purchasesSelector = ({ shop }) => shop?.purchases;
const offerDataSelector = ({ shop }) => shop?.offerData;
const processingOrder = ({ shop }) => shop?.processingOrder;
const isLoading = ({ shop }) => shop?.isLoading;
const sectionsSelector = ({ shop }) => shop?.sections;
const versionSelector = ({ shop }) => shop.version;

export const shopSel = {
    xsollaTokenSelector,
    WebBonusRolls,
    WebExclusiveVanity,
    WebLoyaltyPoint,
    WebFeatured,
    WebFreeDailyGift,
    WebSticker,
    WebLoyaltySticker,
    WebLoyaltyBonusRoll,
    WebLoyaltyPointsStoreEnergy,
    WebEnergySKUs,
    WebTopPlayers,
    WebTopFamilies,
    WebGameModes,
    WebLeaderboards,
    commoditiesSelector,
    processingOrder,
    isLoading,
    limitsSelector,
    sectionsSelector,
    purchasesSelector,
    offerDataSelector,
    versionSelector,
};
