import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Typography } from 'portal-modules/core-ui';
import { Box, Hidden } from '@material-ui/core';

import { FeaturedOffersStyled } from './FeaturedOffers.styled';
import { globalSel } from '../../../../store/global';
import { shopSel } from '../../../../store/shop';

function FeaturedOffers({ modalOffers, data }) {
    const theme = useSelector(globalSel.themeSelector);

    const offersCount = data.length;
    const commodities = useSelector(shopSel.commoditiesSelector);

    return (
        <Box
            component={FeaturedOffersStyled}
            display="flex"
            alignItems="center"
            justifyContent={['center', 'right']}
            width={1}
            height={1}
            zIndex={1}
        >
            <Box
                className={clsx(
                    'WP-featured-offers WP-featured-offers-' + offersCount,
                    modalOffers ? 'WP-featured-modal-offers' : 'WP-featured-banner-offers',
                )}
                pr={[0, 6]}
                justifyContent={['center', 'end']}
            >
                {data.map((item, idx) => {
                    return (
                        commodities?.[item.CommodityKey]?.image && (
                            <Box
                                key={`${item.CommodityKey}_${idx}`}
                                textAlign="center"
                                px={5}
                                className={`WP-featured-offer WP-featured-offer-${offersCount}`}
                            >
                                <Box className="WP-featured-offer-shine">
                                    <Box
                                        component="img"
                                        src={commodities[item.CommodityKey]?.image}
                                        alt={commodities[item.CommodityKey]?.name}
                                        className="WP-featured-img"
                                        width={[50, 50, 50, 60, 84]}
                                        height={[50, 50, 50, 60, 84]}
                                    />
                                </Box>

                                <Box className="WP-featured-points">
                                    <Box
                                        component={Typography}
                                        className="WP-featured-offer-amount"
                                        variant={['h6', 'h6', 'h6', 'h5', 'h3']}
                                        fWeight="bold"
                                        fontFamily="rubik"
                                        color={theme.palette.yellow.A500}
                                        mt={0}
                                    >
                                        x{item.Quantity}
                                    </Box>
                                </Box>

                                {modalOffers ? (
                                    <Box
                                        component={Typography}
                                        variant={['p12', 'p12', 'p12', 'p14', 'p14']}
                                        fWeight="medium"
                                        color={theme.palette.common.white}
                                        mt={[0, 2, 2, 2, 3]}
                                        className="WP-featured-offer-text"
                                    >
                                        {item.text}
                                    </Box>
                                ) : (
                                    <Hidden xsDown>
                                        <Box
                                            component={Typography}
                                            variant={['p12', 'p12', 'p12', 'p14', 'h6']}
                                            fWeight="bold"
                                            color={theme.palette.common.white}
                                            className="WP-featured-offer-text"
                                        >
                                            {item.text}
                                        </Box>
                                    </Hidden>
                                )}
                            </Box>
                        )
                    );
                })}
            </Box>
        </Box>
    );
}

FeaturedOffers.defaultProps = {
    modalOffers: false,
};

FeaturedOffers.propTypes = {
    modalOffers: PropTypes.bool,
    data: PropTypes.array,
};

export default FeaturedOffers;
