import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RightSidebarStyled } from './RightSidebar.styled';
import UserWrapper from './UserWrapper/UserWrapper';
import MailChimp from '../../components/MailChimp/MailChimp';
import { globalSel } from '../../../store/global';

const RightSidebar = () => {
    const history = useHistory();
    const rightSidebarRef = useRef(null);
    const openRightSidebar = useSelector(globalSel.rightSidebar);

    useEffect(() => {
        return history.listen(() => {
            rightSidebarRef.current?.scrollTo({ top: 0, behavior: 'auto' });
        });
    }, [history]);

    return (
        <RightSidebarStyled className={openRightSidebar ? 'show' : ''} ref={rightSidebarRef}>
            <UserWrapper />
            <MailChimp />
        </RightSidebarStyled>
    );
};

export default RightSidebar;
