import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../../../hooks/useTranslation';
import PropTypes from 'prop-types';

import { Box, Tooltip, useMediaQuery } from '@material-ui/core';
import { Icon, Typography } from 'portal-modules/core-ui';

import { withPurchase } from '../../../../../hoc/withPurchase';
import { calculateDiscount, USNumberFormat } from '../../../../../helpers/utils';

import OfferCardBody from './OfferCardBody/OfferCardBody';
import { Button } from '../../../ui-components/Button/Button';

import { shopSel } from '../../../../../store/shop';
import { OfferCardBodyStyled } from './OfferCardBody/OfferCardBody.styled';

import LoyaltyPointIcon from '../../../../../assets/images/Global/loyaltyPoints.png';
import { removeStorageItem } from '../../../../../helpers/localStorage';

const OfferCardModal = ({
    theme,
    data,
    handlePurchase,
    section,
    isEarnExist,
    isBonusRollsDetails,
    isDiceAndMoreDetails,
}) => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const commodities = useSelector(shopSel.commoditiesSelector);
    const Credits = data?.Credit?.Adjustments;

    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

    const loyaltyPoints = data?.Credit?.Adjustments.find((item) => {
        return item.CommodityKey === 'LoyaltyPoint';
    });
    const discountedCommodity = data?.Debit?.Adjustments[0];
    const originalCommodity = data?.OriginalListing?.Debit?.Adjustments[0];
    const divider = discountedCommodity.CommodityKey === 'USP' ? 100 : 1;
    const originalQuantity = originalCommodity ? originalCommodity?.Quantity / divider : null;
    const discountedQuantity = discountedCommodity ? discountedCommodity?.Quantity / divider : null;

    const discount = calculateDiscount(originalQuantity, discountedQuantity);
    const completed =
        (data?.availableCount !== null && data?.availableCount <= 0) ||
        (data?.DisplayDetails.IsFtue === 'true' && data?.DisplayDetails.IsCompleted === 'true');

    const html = document.getElementsByTagName('html')[0].classList;

    useEffect(() => {
        html.add('disable-scroll');
        return () => {
            if (!localStorage.getItem('processingOrder')) {
                html.remove('disable-scroll');
            }

            removeStorageItem('orderInProgress');
        };
    }, [dispatch]);

    return (
        <Box
            component={OfferCardBodyStyled}
            bgImageUrl={data?.DisplayDetails?.BackgroundUrl}
            width="100%"
            height="100%"
            className="WP-modal-small"
        >
            <OfferCardBody data={data} />

            <Box className="WP-card-footer-container" px={1} py={1}>
                <Box
                    component="footer"
                    className="WP-card-footer"
                    minHeight={[170, 217, 217, 266, 300]}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    pt={[3, 3, 3, 4, 4]}
                    px={[2, 3, 3, 4, 6]}
                    pb={[3, 3, 3, 4, 6]}
                >
                    <Box pb={1}>
                        <Typography
                            className="WP-card-modal-title"
                            variant={['p12', 'p16']}
                            fWeight="semiBold"
                            color={theme.palette.brown.A600}
                        >
                            {isBonusRollsDetails || isDiceAndMoreDetails
                                ? data?.DisplayDetails?.DisplayName
                                : commodities?.[data?.Credit?.Adjustments[0]?.CommodityKey]?.name}
                        </Typography>

                        <Typography
                            className="WP-card-modal-subtitle"
                            variant={['h6', 'h6', 'h6', 'h5', 'h4']}
                            fWeight="bold"
                            color={theme.palette.brown.main}
                        >
                            {isBonusRollsDetails
                                ? `${Credits[0]?.Quantity}
                                  ${
                                      commodities?.[data?.Credit?.Adjustments[0]?.CommodityKey]
                                          ?.name
                                  }`
                                : isDiceAndMoreDetails
                                ? commodities?.[data?.Credit?.Adjustments[0]?.CommodityKey]?.name
                                : data?.DisplayDetails?.DisplayName}
                        </Typography>
                    </Box>

                    <Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={[2, 2, 2, 3, 3]}
                        >
                            {originalQuantity && discount && (
                                <Box display="flex" alignItems="center">
                                    <Box
                                        component={Typography}
                                        className="WP-card-modal-discount"
                                        display="flex"
                                        alignItems="center"
                                        variant={['p12', 'p16']}
                                        fWeight="bold"
                                        color={theme.palette.red.A700}
                                        mr={1}
                                    >
                                        {t('retail.price')}
                                    </Box>

                                    <Box
                                        component={Typography}
                                        className="WP-card-modal-discount text-decoration-line-through"
                                        display="flex"
                                        alignItems="center"
                                        variant={['p12', 'p16']}
                                        fWeight="bold"
                                        color={theme.palette.red.A700}
                                    >
                                        {originalCommodity?.CommodityKey === 'USP' ? (
                                            <span>$</span>
                                        ) : (
                                            <Box
                                                component="img"
                                                src={LoyaltyPointIcon}
                                                alt="loyalty points"
                                                width={[16, 20, 20, 24, 30]}
                                                height={[16, 20, 20, 24, 30]}
                                                mr={1}
                                            />
                                        )}

                                        {USNumberFormat(originalQuantity)}
                                    </Box>
                                </Box>
                            )}

                            {loyaltyPoints && !isEarnExist && (
                                <Box
                                    className="WP-card-modal-tooltip"
                                    display="flex"
                                    alignItems="center"
                                    ml="auto"
                                >
                                    <Tooltip
                                        enterTouchDelay={0}
                                        title={t('loyalty.point.tooltip')}
                                        arrow
                                        placement="top"
                                        PopperProps={{ disablePortal: true }}
                                    >
                                        <Box
                                            className="flex-center cursor-pointer"
                                            width={[12, 16]}
                                            height={[12, 16]}
                                            borderRadius="50%"
                                            bgcolor={theme.palette.brown.A800}
                                        >
                                            <Icon
                                                className="icon-info"
                                                size={7}
                                                color={theme.palette.orange.A50}
                                            />
                                        </Box>
                                    </Tooltip>

                                    <Box
                                        component={Typography}
                                        className="WP-card-modal-earn"
                                        variant={['p12', 'p16']}
                                        fWeight="bold"
                                        color={theme.palette.brown.A600}
                                        px={1}
                                    >
                                        {`${t('earn')} ${loyaltyPoints?.Quantity}`}
                                    </Box>

                                    <Box
                                        component="img"
                                        src={LoyaltyPointIcon}
                                        alt="loyalty points"
                                        width={[16, 20, 20, 20, 25]}
                                        height={[16, 20, 20, 20, 25]}
                                    />
                                </Box>
                            )}
                        </Box>

                        <Box position="relative">
                            <Button
                                completed={completed}
                                variant="secondary"
                                size={breakpointsSM ? 'large' : 'medium'}
                                onClick={handlePurchase({
                                    commodity: data?.Debit.Adjustments[0].CommodityKey,
                                    iapSku: data?.IapSku,
                                    storeListingName: data?.Name,
                                    item: {
                                        openType: 'pop-up',
                                        name: data?.DisplayDetails?.DisplayName,
                                        price: discountedQuantity,
                                        section,
                                    },
                                })}
                                preventLoading
                            >
                                {completed ? (
                                    t('already.owned')
                                ) : (
                                    <>
                                        {discountedCommodity.CommodityKey === 'USP' ? (
                                            <span>$</span>
                                        ) : (
                                            <Icon
                                                className="icon-loyality-point"
                                                size={breakpointsSM ? 26 : 24}
                                            />
                                        )}

                                        {discountedQuantity && USNumberFormat(discountedQuantity)}
                                    </>
                                )}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

OfferCardModal.propTypes = {
    theme: PropTypes.object,
    data: PropTypes.object,
    variant: PropTypes.string,
    commodity: PropTypes.string,
    handlePurchase: PropTypes.func,
    section: PropTypes.string,
    isEarnExist: PropTypes.bool,
    isBonusRollsDetails: PropTypes.bool,
    isDiceAndMoreDetails: PropTypes.bool,
};

export default withPurchase(OfferCardModal);
