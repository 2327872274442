import { createSlice } from '@reduxjs/toolkit';
import { initialTranslationsState } from './initialState';

export const translationsSlice = createSlice({
    name: 'translations',
    initialState: initialTranslationsState(),
    reducers: {
        setTranslations(state, action) {
            state.data = action.payload;
        },
        setLanguage(state, action) {
            state.language = action.payload;
        },
    },
});
