import { PaymentService, PaymentSettings } from '@scopely/web-sdk-payments';
import { tracker } from './titan.config';
import { fetch } from '../core/infra/dice-admin/FetchService';
import { getSessionToken } from '../bridge';
import { TITAN_API_KEY } from '../constants/api.constants';

const GAME_API_KEY = TITAN_API_KEY;
const PENDING_PAYMENTS_INTERVAL_MS = 180_000;

const settings = new PaymentSettings(GAME_API_KEY, 'Yahtzee with Buddies Online', 'en');

const COMMON_PARAMS = {
    BypassSignature: false,
    c_bundle: 'yahtzeenux',
    c_platform: 'web',
    c_store: 'Playgami',
};

class GameMakerApiClient {
    async sendCreateCheckoutRequest(userId, sessionToken, checkoutRequest) {
        try {
            const responseBody = await this.sendRequest({
                endPoint: 'playgami-payments/payment',
                body: checkoutRequest,
                method: 'POST',
                params: {
                    ...COMMON_PARAMS,
                    SessionToken: sessionToken,
                },
            });

            return {
                paymentId: responseBody.PaymentId,
                provider: responseBody.Provider,
            };
        } catch (e) {
            throw new Error('Failed to create a checkout', {
                cause: e,
            });
        }
    }

    async sendClaimPaymentRequest(paymentId, sessionToken) {
        try {
            return await this.sendRequest({
                endPoint: 'playgami-payments/payment/claim',
                method: 'POST',
                params: {
                    ...COMMON_PARAMS,
                    SessionToken: sessionToken,
                },
                body: {
                    paymentId: paymentId,
                },
            });
        } catch (e) {
            throw new Error('Failed to claim payment', {
                cause: e,
            });
        }
    }

    async sendGetUnclaimedPaymentsRequest(currentUserId, sessionToken) {
        try {
            return await this.sendRequest({
                endPoint: 'playgami-payments/payment/unclaimed',
                method: 'GET',
                params: {
                    ...COMMON_PARAMS,
                    SessionToken: sessionToken,
                },
            });
        } catch (e) {
            throw new Error('Failed to list unclaimed payments', {
                cause: e,
            });
        }
    }

    async sendRequest(fetchParams) {
        const response = await fetch(fetchParams);

        if (!response?.data) {
            throw new Error('Invalid backend response: ' + response);
        }
        if (!response.data.IsSuccess) {
            throw new Error('Request to the server was not successful: ' + response.Error);
        }
        return response.data.Data;
    }
}

class GameMakerGameDelegate {
    constructor() {
        this.client = new GameMakerApiClient();
    }
    setCurrentUserId = (userId) => {
        if (this.currentUserId === userId) {
            return;
        }
        if (this.currentUserId && this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
        this.currentUserId = userId;
        if (this.currentUserId != null) {
            setTimeout(this.claimPending, 1_000);
        }
    };
    createCheckout = async (checkoutRequest) => {
        if (!this.currentUserId) {
            throw new Error('No user logged in. Cannot start a payment');
        }
        if (!getSessionToken()) {
            throw new Error('No session token available. Cannot start a payment');
        }

        if (!this.interval) {
            this.interval = setInterval(this.claimPending, PENDING_PAYMENTS_INTERVAL_MS);
        }

        return await this.client.sendCreateCheckoutRequest(this.currentUserId, getSessionToken(), {
            ...checkoutRequest,
            userId: this.currentUserId,
        });
    };
    claimPayment = async (paymentId) => {
        if (!this.currentUserId) {
            throw new Error('No user logged in. Cannot claim a payment');
        }
        if (!getSessionToken()) {
            throw new Error('No session token available. Cannot claim a payment');
        }
        try {
            return await this.client.sendClaimPaymentRequest(paymentId, getSessionToken());
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Could not contact backend to claim payment. Will try again later');
        }
    };
    claimPending = async () => {
        if (!this.currentUserId || !getSessionToken()) {
            return;
        }
        const pending = await this.client.sendGetUnclaimedPaymentsRequest(
            this.currentUserId,
            getSessionToken(),
        );
        pending.PendingPayments.forEach((paymentId) => this.claimPayment(paymentId));
    };
}

const paymentsBackendDelegate = new GameMakerGameDelegate();
const playgamiPayments = new PaymentService(settings, paymentsBackendDelegate, tracker);

export { paymentsBackendDelegate, playgamiPayments };
