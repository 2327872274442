import styled from 'styled-components';
import GlowImage from '../../../../assets/images/Home/Featured/glow.png';

export const FeaturedOffersStyled = styled.div`
    position: relative;

    .WP-featured-offers {
        display: flex;
        flex-wrap: wrap;

        ${({ theme }) => theme.breakpoints.down('xs')} {
            padding-bottom: 10px;
        }

        .WP-featured-offer-4 {
            width: 50%;
            max-width: 110px;
            padding-right: 8px;
            padding-left: 8px;

            ${({ theme }) => theme.breakpoints.up('sm')} {
                max-width: 170px;
                padding-right: 10px;
                padding-left: 10px;
            }

            ${({ theme }) => theme.breakpoints.up('md')} {
                max-width: 135px;
            }

            ${({ theme }) => theme.breakpoints.up('lg')} {
                max-width: 166px;
            }

            &:nth-child(1),
            &:nth-child(2) {
                padding-bottom: 0;

                ${({ theme }) => theme.breakpoints.up('sm')} {
                    padding-bottom: 30px;
                }
            }
        }

        .WP-featured-offer-6 {
            width: 33.33%;

            ${({ theme }) => theme.breakpoints.down('xs')} {
                max-width: 130px;
            }

            &:first-child {
                padding-bottom: 30px;

                ${({ theme }) => theme.breakpoints.down('lg')} {
                    padding-bottom: 20px;
                }
            }
        }

        &.WP-featured-banner-offers-4 {
            ${({ theme }) => theme.breakpoints.down('xs')} {
                display: flex;
            }
        }
    }

    .WP-featured-offer {
        position: relative;
    }

    .WP-featured-offers-3,
    .WP-featured-offers-2 {
        flex-wrap: nowrap;
    }

    .WP-featured-offers-4 {
        flex-wrap: initial;
        align-items: center;
        padding: 12px 24px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            flex-wrap: wrap;
            padding-left: 0;
        }
    }

    .WP-featured-offers-5 {
        display: grid;
        grid-template-rows: auto auto auto;
        align-items: baseline;
        grid-template-columns: repeat(6, 1fr);

        ${({ theme }) => theme.breakpoints.down('xs')} {
            max-width: 270px;
        }

        .WP-featured-offer {
            &:first-child {
                padding-bottom: 30px;

                ${({ theme }) => theme.breakpoints.down('lg')} {
                    padding-bottom: 20px;
                }
            }
        }
    }

    .WP-featured-offer-5 {
        grid-column-end: span 2;

        &:nth-child(1) {
            grid-column-start: 2;
        }

        &:nth-last-child(-n + 3) {
            grid-column-start: 1;
        }

        &:nth-last-child(-n + 2) {
            grid-column-start: 3;
        }

        &:nth-last-child(-n + 1) {
            grid-column-start: 5;
        }
    }

    .WP-featured-offer-shine {
        position: relative;

        &:before {
            width: 140px;
            height: 140px;

            position: absolute;
            top: -30px;
            left: 50%;
            transform: translateX(-50%);
            content: '';
            z-index: -1;

            background-image: url(${GlowImage});
            background-size: cover;
            display: inline-block;

            ${({ theme }) => theme.breakpoints.down('lg')} {
                width: 80px;
                height: 80px;
                top: -16px;
            }

            ${({ theme }) => theme.breakpoints.down('xs')} {
                width: 50px;
                height: 50px;
                top: -10px;
            }
        }
    }

    .WP-featured-offer-amount {
        ${({ theme }) => theme.breakpoints.down('xs')} {
            font-size: ${({ theme }) => theme.typography.size.p14.fontSize};
        }
    }

    .WP-featured-offer-text {
        max-width: 150px;
        margin: 0 auto;
        line-height: 1.3;
    }

    .WP-featured-img {
        object-fit: contain;
    }
`;
