import React from 'react';

import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';

import SectionTitle from '../SectionTitle/SectionTitle';
import TopLeaders from './TopLeaders/TopLeaders';
import TopLeadersItemSkeleton from './TopLeaders/TopLeadersItemSkeleton';
import { useSelector } from 'react-redux';
import { leadersSel } from '../../../store/leaderboard';
import { TopLeadersStyled } from './TopLeaders/TopLeaders.styled';
import { globalSel } from '../../../store/global';

const TopPlayers = () => {
    const theme = useSelector(globalSel.themeSelector);
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });

    const t = useTranslation();
    const playersBoards = useSelector(leadersSel.playersBoardsSelector);

    return (
        <Box component="section" pb={[10, 12, 12, 16, 20]}>
            {!playersBoards ? (
                <TopLeadersItemSkeleton />
            ) : !playersBoards?.length ? null : (
                <>
                    <SectionTitle title={t('top.players')} icon="icon-crown" />

                    <Grid container spacing={breakpointsXL ? 10 : breakpointsLG ? 5 : 4}>
                        {playersBoards.map((item, index) => {
                            return (
                                <Grid key={index} item xs={12} sm={6}>
                                    <Box className="WP-top-players" component={TopLeadersStyled}>
                                        <TopLeaders type={item.Category} item={item} />
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                </>
            )}
        </Box>
    );
};

export default TopPlayers;
