import styled from 'styled-components';

export const BackdropStyled = styled.div`
    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: ${({ zIndex }) => zIndex || 1};
        opacity: 0.75;
        background-color: ${({ theme }) => theme.hexToRgba('#332B21', 0.6)};
    }

    .backdrop-content {
        position: relative;
        z-index: ${({ zIndex }) => zIndex + 1 || 2};
    }
`;
