import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useMediaQuery, Box, Grid } from '@material-ui/core';
import { Button } from '../ui-components/Button/Button';
import OfferCard from '../OfferCard/OfferCard';

import { globalSel } from '../../../store/global';
import { getSlicedArray } from '../../../helpers/utils';
import { setGAEvent } from '../../../helpers/GA4Helper';
import { useTranslation } from '../../../hooks/useTranslation';

const SeeMoreItems = ({
    data,
    limit,
    newsCard,
    redirectTo,
    bonusRollsImage,
    spacing,
    cardGridSizeSm,
    cardGridSizeXl,
    imageSizeHeight,
    section,
    isEarnExist,
    isBonusRollsDetails,
    isDiceAndMoreDetails,
}) => {
    const t = useTranslation();
    const history = useHistory();
    const pathname = window.location.pathname;

    const theme = useSelector(globalSel.themeSelector);
    const ftueInformation = useSelector(globalSel.ftueSelector);

    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
    const customXS = useMediaQuery(theme.breakpoints.down(375), { noSsr: true });
    const [page, setPage] = useState(1);
    const [list, setList] = useState(() => {
        return getSlicedArray(data, 0, 1 * limit);
    });

    useEffect(() => {
        if (data && page > 1) {
            setList(getSlicedArray(data, 0, page * limit));
        }
    }, [data, limit, page, ftueInformation]);

    const handleSeeMore = (callback) => {
        setTimeout(() => {
            callback(false);
            redirectTo ? history.push(redirectTo.page, redirectTo.section) : setPage(page + 1);
        }, 200);

        setGAEvent('clickEvent', {
            screen_name: `${pathname === '/' ? '/home' : pathname}`,
            screen_type: 'screen',
            screen_section: newsCard ? 'news' : section,
            interaction_object: 'see_more_items',
            interaction_object_category: 'display',
        });
    };

    if (!list || !list.length) return false;
    const total = data.length;

    return (
        <Box>
            <Grid container spacing={spacing} className="daily-deals">
                {list?.map((dataItem, index) => {
                    return (
                        <Grid
                            item
                            xs={customXS ? 12 : 6}
                            sm={cardGridSizeSm}
                            xl={cardGridSizeXl}
                            key={index}
                            id={`${section}_${index}`}
                        >
                            <OfferCard
                                data={dataItem?.StoreListing}
                                bonusRollsImage={bonusRollsImage}
                                section={section}
                                isEarnExist={isEarnExist}
                                imageSizeHeight={imageSizeHeight}
                                isBonusRollsDetails={isBonusRollsDetails}
                                isDiceAndMoreDetails={isDiceAndMoreDetails}
                                commodity={
                                    dataItem?.StoreListing?.Debit?.Adjustments[0].CommodityKey
                                }
                            />
                        </Grid>
                    );
                })}
            </Grid>

            {redirectTo ? (
                <Box boxShadow={theme.elevation[100]} borderRadius={10} mt={[5, 5, 6, 8, 8]}>
                    <Button
                        variant="primary"
                        size={breakpointsXL ? 'large' : 'small'}
                        onClick={handleSeeMore}
                    >
                        {t('see.more.items')}
                    </Button>
                </Box>
            ) : page < total / limit ? (
                <Box boxShadow={theme.elevation[100]} borderRadius={10} mt={[5, 5, 6, 8, 8]}>
                    <Button
                        variant="primary"
                        size={breakpointsXL ? 'large' : 'small'}
                        onClick={handleSeeMore}
                    >
                        {t('see.more')}
                    </Button>
                </Box>
            ) : null}
        </Box>
    );
};

SeeMoreItems.defaultProps = {
    newsCard: false,
    bonusRollsImage: false,
    cardGridSizeSm: 4,
    cardGridSizeXl: 4,
};

SeeMoreItems.propTypes = {
    data: PropTypes.array,
    limit: PropTypes.number,
    spacing: PropTypes.number,
    component: PropTypes.string,
    redirectTo: PropTypes.object,
    commodity: PropTypes.string,
    bonusRollsImage: PropTypes.bool,
    cardGridSizeSm: PropTypes.number,
    cardGridSizeXl: PropTypes.number,
    newsCard: PropTypes.bool,
    section: PropTypes.string,
    isEarnExist: PropTypes.bool,
    imageSizeHeight: PropTypes.array,
    isBonusRollsDetails: PropTypes.bool,
    isDiceAndMoreDetails: PropTypes.bool,
};

export default SeeMoreItems;
