import styled from 'styled-components';

export const BackToGameStyled = styled.div`
    width: 48px;
    height: 48px;
    position: fixed;
    z-index: 9999991;
    right: 1.375rem;
    bottom: 5.5rem;

    img {
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.6) 0px 2px 14px 0px;
        border-radius: 50%;
        border: 1px solid #0000005e;
    }
`;
