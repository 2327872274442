import styled from 'styled-components';

export const CustomCheckboxStyled = styled.div`
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.orange.A800};

    .WP-custom-checkbox {
        position: absolute; // take it out of document flow
        opacity: 0; // hide it
        z-index: 1;
        cursor: pointer;

        & + label {
            position: relative;
            padding: 0;
            font-size: 12px;
            font-weight: 400;
            color: ${({ theme }) => theme.palette.orange.A500};
        }

        & + label:before {
            width: 16px;
            height: 16px;
            content: '';
            display: inline-block;
            vertical-align: text-top;
            background: transparent;
            border: 2px solid ${({ theme }) => theme.palette.orange.A500};
            border-radius: 2px;
        }

        &:checked + label:before {
            background: #0dd39cff;
            border: 2px solid #0dd39cff;
        }

        &:checked + label:after {
            width: 3px;
            height: 3px;
            position: absolute;
            left: 4px;
            top: 9px;
            content: '';

            background: white;
            box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
                4px -6px 0 white, 4px -8px 0 white;
            transform: rotate(45deg);
        }
    }

    .WP-custom-checkbox-text {
        margin-left: 10px;

        a {
            color: ${({ theme }) => theme.palette.orange.A800};
            padding: 0;
        }
    }
`;
