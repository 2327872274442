import styled from 'styled-components';

export const LabelStyled = styled.span`
    display: flex;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    font-size: ${({ theme }) => theme.typography.size.p14.fontSize};
    font-weight: ${({ theme }) => theme.typography.weight.semiBold};
    border-radius: ${({ rounded }) => (rounded ? '60px' : '8px')};
    transition: background-color 200ms ease-out;

    white-space: nowrap;

    &.large {
        height: 32px;
    }

    &.medium {
        height: 26px;
    }

    &.small {
        height: 24px;
    }

    &.contained {
        &-purple {
            background-color: ${({ theme }) => theme.palette.purple.A50};
            color: ${({ theme }) => theme.palette.purple.A400};
        }

        &-red {
            background-color: ${({ theme }) => theme.palette.red.A100};
            color: ${({ theme }) => theme.palette.red.A600};
        }

        &-blue {
            background-color: ${({ theme }) => theme.palette.blue.A50};
            color: ${({ theme }) => theme.palette.blue.A800};
        }

        &-green {
            background-color: ${({ theme }) => theme.palette.green.A50};
            color: ${({ theme }) => theme.palette.green.A800};
        }

        &-orange {
            background-color: ${({ theme }) => theme.palette.orange.A100};
            color: ${({ theme }) => theme.palette.orange.A800};
        }

        &-brown {
            background-color: ${({ theme }) => theme.palette.brown.A50};
            color: ${({ theme }) => theme.palette.brown.A700};
        }

        &-yellow {
            background-color: ${({ theme }) => theme.palette.yellow.A200};
            color: ${({ theme }) => theme.palette.brown.A700};
        }
    }

    &.outlined {
        border: 1px solid;
        &-purple {
            color: ${({ theme }) => theme.palette.purple.A400};
        }

        &-red {
            color: ${({ theme }) => theme.palette.red.A700};
            border-color: ${({ theme }) => theme.palette.red.A700};
        }

        &-blue {
            color: ${({ theme }) => theme.palette.blue.main};
            border-color: ${({ theme }) => theme.palette.blue.main};
        }

        &-green {
            color: ${({ theme }) => theme.palette.green.main};
            border-color: ${({ theme }) => theme.palette.green.main};
        }

        &-orange {
            color: ${({ theme }) => theme.palette.orange.main};
            border-color: ${({ theme }) => theme.palette.orange.main};
        }

        &-brown {
            color: ${({ theme }) => theme.palette.brown.main};
            border-color: ${({ theme }) => theme.palette.brown.main};
        }

        &-yellow {
            color: ${({ theme }) => theme.palette.brown.main};
            border-color: ${({ theme }) => theme.palette.yellow.main};
        }
    }
`;
