import styled from 'styled-components';

export const ModalStyled = styled.div`
    .WP-Modal-close-container {
        border-radius: 50%;
    }

    .WP-Modal-close-button {
        width: 32px;
        height: 32px;
        color: ${({ theme }) => theme.palette.orange.A50};

        position: relative;
        z-index: 10;

        background-color: #de3535;
        padding: 10px;
        border: 3px solid ${({ theme }) => theme.palette.orange.A50};

        ${({ theme }) => theme.breakpoints.down('xs')} {
            padding: 7px;
        }

        ${({ theme }) => theme.breakpoints.up('sm')} {
            width: 40px;
            height: 40px;

            > i {
                font-size: 24px;
            }
        }
    }

    .WP-Modal-loading {
        circle {
            stroke: url(#linearColors);
        }
    }

    .button-text {
        .icon-loyality-point {
            margin-left: 4px;
        }
    }
`;
