import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/ui-components/Button/Button';
import { useTranslation } from '../../../hooks/useTranslation';
import { Typography } from 'portal-modules/core-ui';
import { NotFoundStyled } from './NotFound.styled';
import { globalOp, globalSel } from '../../../store/global';
import { Box, useMediaQuery } from '@material-ui/core';
import Logo from '../../../assets/images/NotFound/notFoundLogo.png';
import cont from '../../../assets/images/NotFound/404.png';

export default function NotFoundPage() {
    const theme = useSelector(globalSel.themeSelector);
    const t = useTranslation();
    const dispatch = useDispatch();

    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });

    const onHandleClick = () => {
        window.location.href = '/';
    };

    useEffect(() => {
        window?.DD_RUM?.addError('404-visit-count', window.history?.state?.as);
        dispatch(globalOp.setNotFound(true));
    }, [dispatch]);

    return (
        <Box component={NotFoundStyled} textAlign="center">
            <div className="image-wrapper">
                <Box pt={8}>
                    <Box
                        component="img"
                        className="WP-NotFound-logo"
                        src={Logo}
                        alt="not-found"
                        mb={[8, 12, 10]}
                    />
                </Box>
            </div>
            <Box width="100%">
                <Box
                    component="img"
                    className="WP-NotFound-image"
                    src={cont}
                    alt="not-found"
                    height={[170, 370, 370, 443, 550]}
                />
            </Box>
            <Box>
                <Typography
                    className="WP-NotFound-title"
                    fWeight="medium"
                    color={theme.palette.brown.main}
                >
                    {t('not.found.title')}
                </Typography>
                <Typography
                    className="WP-NotFound-subTitle"
                    fWeight="medium"
                    color={theme.palette.brown.main}
                >
                    {t('not.found.subtitle')}
                </Typography>
                <Box className="flex-center" width="100%">
                    <Box display="flex" mt={[6, 8, 7, 8, 10]} width={[310, 275, 275, 340, 384]}>
                        <Button
                            variant="primary"
                            size={
                                breakpointsXL
                                    ? 'large'
                                    : breakpointsLG
                                    ? 'medium'
                                    : breakpointsSM
                                    ? 'small'
                                    : 'extra-small'
                            }
                            onClick={onHandleClick}
                            preventLoading={true}
                        >
                            {t('back.to.homepage')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
