import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { MainStyled } from './Main.styled';
import { Box } from 'portal-modules/core-ui';
import { setGAEvent } from '../../../helpers/GA4Helper';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import globalSlice, { globalOp, globalSel } from '../../../store/global';
import { getStorageItem, removeStorageItem, setStorageItem } from '../../../helpers/localStorage';
import { useMediaQuery } from '@material-ui/core';
import { scopelyId } from '../../../configs/scopelyId.config';
import { loginAsync } from '../../../store/global/operations';
import { getUrlParam } from '../../../helpers/utils';
import {
    TRACKING_ERROR_CODES,
    TRACKING_ACTIONS,
    TRACKING_TYPES,
    loginAnalyticsTracking,
} from '../../../helpers/loginAnalyticsTracking';

export default function Main({ children }) {
    const history = useHistory();
    const location = useLocation();
    const logoutParam = getUrlParam('state');
    const scopelyLoginStatus = globalSlice.actions.setScopelyLogin;

    const dispatch = useDispatch();
    const authenticated = useSelector(globalSel.isAuthSelector);
    const app_version = useSelector(globalSel.versionSelector);

    const theme = useSelector(globalSel.themeSelector);
    const pathname = window.location.pathname;
    const breakpointsXS = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
    const breakpointsMD = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

    const scopelyLogin = useCallback(async () => {
        let currentLocation = window.location.href;
        dispatch(scopelyLoginStatus(true));
        setStorageItem('loggingIn', true);
        const previousLocation = scopelyId.previousLocation();
        if (previousLocation) history.replace(previousLocation.pathname);
        let loginResult = null;
        try {
            loginResult = await scopelyId.authorizationResult(currentLocation);
        } catch (e) {
            loginAnalyticsTracking({
                type: TRACKING_TYPES.SCOPELY_ID,
                action: TRACKING_ACTIONS.LOGIN_FAILED,
                errorCode: `${TRACKING_ERROR_CODES.SCOPELY_AUTH_RESULT_CATCH}${
                    e?.error?.code || TRACKING_ERROR_CODES.UNKNOWN_ERROR
                }`,
            });
            removeStorageItem('loggingIn');
            removeStorageItem('authorizing');
            dispatch(scopelyLoginStatus(false));
        }

        if (loginResult) {
            if (loginResult.isSuccessful()) {
                const scopelyToken = loginResult.getResponse().getAccessToken();
                setStorageItem('accessToken', scopelyToken);
                dispatch(loginAsync()).then(() => {
                    removeStorageItem('loggingIn');
                    dispatch(scopelyLoginStatus(false));
                    removeStorageItem('authorizing');
                });
            } else {
                loginAnalyticsTracking({
                    type: TRACKING_TYPES.SCOPELY_ID,
                    action: TRACKING_ACTIONS.LOGIN_FAILED,
                    errorCode: `${TRACKING_ERROR_CODES.SCOPELY_IS_NOT_SUCCESSFUL}${TRACKING_ERROR_CODES.UNKNOWN_ERROR}`,
                });
                removeStorageItem('loggingIn');
                removeStorageItem('authorizing');
                dispatch(scopelyLoginStatus(false));
            }
        }
    }, [dispatch, history, scopelyLoginStatus]);

    const scopelyLogout = useCallback(async () => {
        let currentLocation = window.location.href;
        if (scopelyId.isLogoutUri(currentLocation)) {
            const previousLocation = scopelyId.previousLocation();
            if (previousLocation) history.replace(previousLocation.pathname);
            dispatch(globalOp.logout());
            let logoutResult = await scopelyId.logoutResult(currentLocation);

            if (logoutResult.isSuccessful()) {
                // eslint-disable-next-line no-console
                console.log('logout success');
            } else {
                // eslint-disable-next-line no-console
                console.log(logoutResult.getError());
            }
            removeStorageItem('authorizing');
        }
    }, [history, dispatch]);

    useEffect(() => {
        window.history.replaceState({}, document.title);
    }, [history]);

    useLayoutEffect(() => {
        if (location?.state) {
            setTimeout(() => {
                let section = document.getElementsByClassName(location.state)[0];

                if (section) {
                    const getSectionPosition = breakpointsMD
                        ? section.offsetTop - 60
                        : section.offsetTop;

                    getSectionPosition &&
                        window.scrollTo({
                            top: getSectionPosition,
                            behavior: 'smooth',
                        });
                }
            });
        } else if (window.scrollY && window.scrollY !== 0) {
            window.scrollTo({
                top: 0,
            });
        }
    }, [history, breakpointsXS, location]);

    useEffect(() => {
        const pagePathname = `${pathname === '/' ? '/home' : pathname}`;

        setGAEvent('clickEvent', {
            screen_name: pagePathname,
            screen_type: 'screen',
            screen_section: 'left_menu',
            interaction_object: pagePathname,
            interaction_object_category: 'redirect',
        });

        setGAEvent('impression', { screen_name: pagePathname, screen_type: 'screen' });
    }, [pathname]);

    useEffect(() => {
        const loginInProgress = getStorageItem('loggingIn');

        if (app_version && !loginInProgress) {
            let currentLocation = window.location.href;
            if (!authenticated) {
                if (scopelyId.isAuthorizationUri(currentLocation)) scopelyLogin();
                else {
                    if (getStorageItem('authorizing')) {
                        loginAnalyticsTracking({
                            type:
                                getStorageItem('loginProcessing') === 'withFacebook'
                                    ? TRACKING_TYPES.FACEBOOK
                                    : TRACKING_TYPES.SCOPELY_ID,
                            action: TRACKING_ACTIONS.LOGIN_ABANDONED,
                        });
                    }

                    removeStorageItem('authorizing');
                }
            } else {
                if (scopelyId.isLogoutUri(currentLocation) && logoutParam === 'scopely-logout')
                    scopelyLogout();
                else dispatch(globalOp.getUserData());
            }
        }
    }, [authenticated, dispatch, app_version, scopelyLogin, scopelyLogout, logoutParam]);
    useEffect(() => {
        const claimed = getStorageItem('claimed_clicked');
        if (authenticated && claimed) {
            removeStorageItem('claimed_clicked');
            history.push('/loyalty-point-shop');
        }
    }, [authenticated]);
    return (
        <Box
            component={MainStyled}
            position="relative"
            flexGrow={1}
            width={[1, 1, 'calc(100% - 520px)']}
            pt={[14, 14, 0]}
            pl={[0, 0, 60]}
            pr={[0, 0, 70]}
            minHeight="100vh"
        >
            <Box
                px={[5, 8]}
                pb={[5, 5, 5, 7]}
                height="100%"
                position="relative"
                zIndex={['initial', 1, 8]}
            >
                {children}
            </Box>
        </Box>
    );
}

Main.propTypes = {
    children: PropTypes.node,
};
