import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CommodityCounterStyled } from './CommodityCounter.styled';
import { Box } from '@material-ui/core';

import { globalSel } from '../../../store/global';
import { thousandsFormatterWithDots } from '../../../helpers/utils';
import { energyEnabledGetter } from '../../../core/application/config';

import bonusRoll from '../../../assets/images/Global/bonusRolls.png';
import loyaltyPoints from '../../../assets/images/Global/loyaltyPoints.png';
import energies from '../../../assets/images/Global/Energy.png';

const CommodityCounter = () => {
    const currentUser = useSelector(globalSel.currentUserSelector);

    const [energyEnabled, setEnergyEnabled] = useState(false);

    const fetchEnergyEnabled = async () => {
        const res = await energyEnabledGetter.execute();
        setEnergyEnabled(res);
    };

    useEffect(() => {
        fetchEnergyEnabled();
    }, []);

    return (
        currentUser && (
            <Box
                component={CommodityCounterStyled}
                id="commodity-counter"
                className="WP-commodity-counter"
            >
                <Box className="WP-commodity-counter-card">
                    <Box
                        component="img"
                        src={bonusRoll}
                        alt="bonusRoll"
                        width={40}
                        className="WP-commodity-counter-card-img"
                    />
                    <Box className="WP-commodity-counter-card-text">
                        {thousandsFormatterWithDots(currentUser.BonusRollCount)}
                    </Box>
                </Box>
                {energyEnabled && (
                    <Box className="WP-commodity-counter-card">
                        <Box
                            component="img"
                            src={energies}
                            alt="EntrySystemEnergy"
                            width={40}
                            className="WP-commodity-counter-card-img"
                        />
                        <Box className="WP-commodity-counter-card-text">
                            {thousandsFormatterWithDots(currentUser.EntrySystemEnergy)}
                        </Box>
                    </Box>
                )}
                <Box className="WP-commodity-counter-card">
                    <Box
                        component="img"
                        src={loyaltyPoints}
                        alt="loyaltyPoints"
                        width={40}
                        className="WP-commodity-counter-card-img"
                    />
                    <Box className="WP-commodity-counter-card-text">
                        {thousandsFormatterWithDots(currentUser.LoyaltyPoints)}
                    </Box>
                </Box>
            </Box>
        )
    );
};

CommodityCounter.propTypes = {};

export default CommodityCounter;
