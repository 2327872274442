import { isEmptyObject } from './utils';

const setStorageItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

const addStorageItem = (key, value) => {
    const isset = localStorage.getItem(key);
    if (isset) {
        let data = JSON.parse(isset);
        if (typeof data === 'string') data = value;
        else if (Array.isArray(data)) {
            data = [...data, ...value];
        } else {
            data = { ...data, ...value };
        }
        localStorage.setItem(key, JSON.stringify(data));
    } else {
        localStorage.setItem(key, JSON.stringify(value));
    }
};
const removeInStorageItem = (key, value) => {
    const isset = localStorage.getItem(key);
    if (isset) {
        let data = JSON.parse(isset);
        if (typeof data === 'string') localStorage.removeItem(key);
        else if (Array.isArray(data)) {
            data = data.filter((element) => {
                return element !== value;
            });
        } else {
            delete data[value];
        }

        if ((Array.isArray(data) && data.length === 0) || isEmptyObject(data))
            localStorage.removeItem(key);
        else localStorage.setItem(key, JSON.stringify(data));
    } else {
        return false;
    }
};

const getStorageItem = (key) => {
    try {
        return JSON.parse(localStorage.getItem(key));
    } catch (e) {
        localStorage.removeItem(key);
    }
};

const isStorageItem = (key, value) => {
    const isset = localStorage.getItem(key);
    if (isset) {
        let data = JSON.parse(isset);
        if (Array.isArray(data)) {
            return data.includes(value);
        }
    }
    return false;
};

const removeStorageItem = (key) => {
    localStorage.removeItem(key);
};

export {
    setStorageItem,
    getStorageItem,
    removeStorageItem,
    addStorageItem,
    removeInStorageItem,
    isStorageItem,
};
