import React from 'react';
import PropTypes from 'prop-types';

import bonusRollOffers from '../../../assets/icons/custom/bonus-roll-offers.svg';
import dailyOffers from '../../../assets/icons/custom/daily-offers.svg';
import energyOffers from '../../../assets/icons/custom/energy-offers.svg';
import featureOffers from '../../../assets/icons/custom/feature-offers.svg';
import loyaltyPointOffers from '../../../assets/icons/custom/loyalty-point-offers.svg';
import stickerOffers from '../../../assets/icons/custom/sticker-offers.svg';
import vanityOffers from '../../../assets/icons/custom/vanity-offers.svg';
import xTwitter from '../../../assets/icons/custom/x-twitter.svg';

const icons = {
    'bonus-roll-offers': bonusRollOffers,
    'daily-offers': dailyOffers,
    'energy-offers': energyOffers,
    'feature-offers': featureOffers,
    'loyalty-point-offers': loyaltyPointOffers,
    'sticker-offers': stickerOffers,
    'vanity-offers': vanityOffers,
    'x-twitter': xTwitter,
};

const IconCustom = ({ icon, color, size = 26, className }) => {
    const iconStyle = {
        fill: color,
        width: size,
        height: size,
    };

    return <img src={icons[icon]} alt="Circle" style={iconStyle} className={className} />;
};

IconCustom.propTypes = {
    icon: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.number,
    className: PropTypes.string,
};

export default IconCustom;
