const dailyRewardsSelector = ({ dailyRewards }) => dailyRewards?.dailyRewardData;
const isLoading = ({ dailyRewards }) => dailyRewards?.isLoading;
const modalSelector = ({ dailyRewards }) => dailyRewards?.modal;
const closeCalendarBannerSelector = ({ dailyRewards }) => dailyRewards?.calendarBanner;

export const dailyRewardsSel = {
    dailyRewardsSelector,
    isLoading,
    closeCalendarBannerSelector,
    modalSelector,
};
