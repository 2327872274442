import React from 'react';
import LoginContext from './LoginContext';
import PropTypes from 'prop-types';
import useFastLogin from './hooks/useFastLogin';

const LoginProvider = ({ children }) => {
    useFastLogin();

    return <LoginContext.Provider value={{}}>{children}</LoginContext.Provider>;
};

export default LoginProvider;

LoginProvider.propTypes = {
    children: PropTypes.node,
};
