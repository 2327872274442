import styled from 'styled-components';

import bgImage from '../../../../assets/images/Home/Logout/rewardBg.png';
import rightCoinPile from '../../../../assets/images/Home/Logout/rightCoinPile.png';
import leftCoinPile from '../../../../assets/images/Home/Logout/leftCoinPile.png';

export const RewardsStyled = styled.section`
    min-height: 380px;

    flex-direction: column-reverse;

    background-image: url(${bgImage});
    z-index: 5;

    ${({ theme }) => theme.breakpoints.up('lg')} {
        flex-direction: row;
        justify-content: center;
    }

    &:before,
    &:after {
        width: 150px;
        height: 150px;
        background-size: cover;

        ${({ theme }) => theme.breakpoints.up('lg')} {
            width: 300px;
            height: 300px;
            background-size: contain;
        }
    }

    &:before {
        background-image: url(${leftCoinPile});
    }

    &:after {
        background-image: url(${rightCoinPile});
    }

    .WP-reward-art {
        width: 300px;
        margin-top: -35px;

        ${({ theme }) => theme.breakpoints.up('lg')} {
            width: initial;
            margin-top: 0;
        }
    }
`;
