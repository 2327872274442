import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Box, Grid, useMediaQuery } from '@material-ui/core';

import NewsWrapperSkeleton from './NewsWrapperSkeleton/NewsWrapperSkeleton';
import { useTranslation } from '../../../hooks/useTranslation';
import NewsCard from '../NewsCard/NewsCard';
import { globalSel } from '../../../store/global';
import PageHeader from '../PageHeader/PageHeader';
import SectionTitle from '../SectionTitle/SectionTitle';
import { getSlicedArray } from '../../../helpers/utils';
import { getStorageItem } from '../../../helpers/localStorage';
import { LANGUAGES } from '../../../constants/languages.constantants';

import { newsGetter } from '../../../core/application/news';

const NewsWrapper = ({ isHomePage, newsCountToShow }) => {
    const [news, setNews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const t = useTranslation();
    const theme = useSelector(globalSel.themeSelector);

    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), {
        noSsr: true,
    });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), {
        noSsr: true,
    });

    const fetchNews = async () => {
        setIsLoading(true);
        newsGetter
            .execute(getStorageItem('language')?.locale || LANGUAGES[0].locale)
            .then((res) => {
                setNews(getSlicedArray(res, 0, newsCountToShow));
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchNews();
    }, []);

    return (
        <Box component="section">
            {isLoading ? (
                <NewsWrapperSkeleton />
            ) : (
                news.length && (
                    <>
                        {!isHomePage ? (
                            <PageHeader
                                title={t('news')}
                                subtitle={t('follow.the.latest.updates')}
                                icon="icon-news"
                                isLoading={isLoading}
                            />
                        ) : (
                            <SectionTitle
                                title={t('news')}
                                subtitle={t('follow.the.latest.updates')}
                                icon="icon-news"
                            />
                        )}
                        <Box pt={[5, 7, 7, 0, 0, 0]}>
                            <Grid container spacing={breakpointsXL ? 10 : breakpointsLG ? 5 : 4}>
                                {news?.map((item) => (
                                    <Grid item key={item.id} xs={12} sm={6}>
                                        <NewsCard item={item} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </>
                )
            )}
        </Box>
    );
};

NewsWrapper.propTypes = {
    newsCountToShow: PropTypes.number,
    isHomePage: PropTypes.bool,
};

export default NewsWrapper;
