import { createSelector } from 'reselect';
const globalSelector = (state) => state.global;

const currentUserSelector = ({ global }) => global.currentUser;
const isLoggedIn = ({ global }) => global.loggedIn;
const currentLevelSelector = createSelector([globalSelector], (global) => global.currentLevel);
const snackbarSelector = createSelector([globalSelector], (global) => global.snackbar);
const themeSelector = createSelector([globalSelector], (global) => global.theme);
const modalSelector = createSelector([globalSelector], (global) => global.modal);
const isLoadingSelector = createSelector([globalSelector], (global) => global.isLoading);

const globalConfigSelector = createSelector([globalSelector], (global) => global.globalConfig);
const fbDataSelector = createSelector([globalSelector], (global) => global.fbData);

const popUpsSelector = ({ global }) => global?.popUps;
const ftueSelector = ({ global }) => global?.ftue;
const showTourSelector = ({ global }) => global?.showTour;
const rightSidebar = ({ global }) => global?.rightSidebar;
const leftSidebar = ({ global }) => global?.leftSidebar;

const notFoundSelector = createSelector([globalSelector], (global) => global.notFound);

const languageSelector = ({ global }) => global?.language;
const isProfileLoading = ({ global }) => global?.isProfileLoading;
const maintenanceModeSelector = ({ global }) => global?.configs?.IsUnderMaintenance;
const versionSelector = ({ global }) => global?.configs?.MinVersion;
const appLoaded = ({ global }) => global?.configs?.appLoaded;
const isAuthSelector = ({ global }) => global?.authenticated;
const scopelyLoginStatus = ({ global }) => global?.scopelyLogin;
const FBLoginStatus = ({ global }) => global?.fbLogin;
const tabsLoading = ({ global }) => global?.tabsLoading;
const interstitials = ({ global }) => global?.interstitials;
const pageFlags = ({ global }) => global?.features;

export const globalSel = {
    globalSelector,
    isAuthSelector,
    currentUserSelector,
    currentLevelSelector,
    themeSelector,
    snackbarSelector,
    modalSelector,
    isLoadingSelector,
    maintenanceModeSelector,
    globalConfigSelector,
    fbDataSelector,
    languageSelector,
    isProfileLoading,
    popUpsSelector,
    notFoundSelector,
    versionSelector,
    appLoaded,
    scopelyLoginStatus,
    FBLoginStatus,
    tabsLoading,
    interstitials,
    isLoggedIn,
    ftueSelector,
    showTourSelector,
    rightSidebar,
    leftSidebar,
    pageFlags,
};
