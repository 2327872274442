import styled from 'styled-components';

import backgroundImageXS from '../../../../../assets/images/DailyOffer/backgroundImage-xs.png';
import backgroundImageXl from '../../../../../assets/images/DailyOffer/backgroundImageModal-xl.png';

export const DailyOfferStyled = styled.div`
    &.WP-day-1 {
        .WP-day-label {
            color: #005fa6;
        }
    }

    &.WP-day-2 {
        .WP-day-label {
            color: #4e19bc;
        }
    }

    &.WP-day-3 {
        .WP-day-label {
            color: #0f369b;
        }
    }

    .WP-modal-title {
        color: ${({ theme }) => theme.DailyOffer.modal.titleColor};
        text-shadow: ${({ theme }) => theme.DailyOffer.modal.titleShadow};
    }

    .WP-commodity-position {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 16px;
    }

    .WP-modal-discount {
        color: ${({ theme }) => theme.DailyOffer.modal.discountColor};
        text-shadow: ${({ theme }) => theme.DailyOffer.modal.discountShadow};
    }
`;

export const DailyOfferBodyStyled = styled.div`
    background-image: url(${({ backgroundImage }) => backgroundImage});
    background-color: #18a0aa;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .WP-commodity-row-2 {
        width: 80%;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            width: 60%;
        }

        ${({ theme }) => theme.breakpoints.up('lg')} {
            width: 45%;
        }
    }

    .WP-commodity-row-3 {
        width: 90%;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            width: 70%;
        }
    }

    .WP-commodity-row-4 {
        width: 60%;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            width: 50%;
        }

        ${({ theme }) => theme.breakpoints.up('lg')} {
            width: 40%;
        }
    }

    .WP-commodity-row-5,
    .WP-commodity-row-6 {
        width: 80%;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            width: 55%;
        }
    }

    .WP-commodity-row-7,
    .WP-commodity-row-8 {
        width: 90%;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            width: 70%;
        }
    }

    .WP-commodity-row-9,
    .WP-commodity-row-10 {
        width: 90%;

        ${({ theme }) => theme.breakpoints.up('lg')} {
            width: 75%;
        }
    }
`;

export const DailyOfferFooterStyled = styled.footer`
    .icon-calendar {
        ${({ theme }) => theme.breakpoints.up('1780')} {
            font-size: 30px;
        }
    }

    .WP-modal-footer-title {
        ${({ theme }) => theme.breakpoints.up('1780')} {
            font-size: 22px;
        }
    }

    .WP-modal-footer-text {
        ${({ theme }) => theme.breakpoints.up('1780')} {
            font-size: ${({ theme }) => theme.typography.size.h5.fontSize};
        }

        color: white;
        text-shadow: 2px 2px 0 black, -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black,
            0px 3px 3px black, 0 -3px 2px black, 3px 0 2px black, -3px 0 2px black;
        letter-spacing: 1px;
    }

    button {
        z-index: 2;
    }
`;

export const DailyOfferItemStyled = styled.li`
    background-image: url(${({ bgImageUrl }) => bgImageUrl});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 12px;
    position: relative;
    overflow: hidden;

    ${({ theme }) => theme.breakpoints.up('1780')} {
        border-radius: 30px;
    }

    ~ li {
        margin-left: 12px;

        ${({ theme }) => theme.breakpoints.up('1780')} {
            margin-left: 30px;
        }
    }

    &.active {
        &:first-child {
            border: 1px solid ${({ theme }) => theme.palette.green.A800};
        }

        &:nth-child(2) {
            border: 1px solid ${({ theme }) => theme.palette.brown.A400};
        }

        &:last-child {
            border: 1px solid ${({ theme }) => theme.palette.brown.A400};
        }
    }

    &:first-child {
        .WP-list-item-day {
            background-color: rgba(67, 199, 0, 0.8);
        }
    }

    &:nth-child(2) {
        .WP-list-item-day {
            text-transform: uppercase;
            background-color: rgba(214, 166, 150, 0.6);
        }
    }

    &:last-child {
        .WP-list-item-day {
            text-transform: uppercase;
            background-color: rgba(214, 166, 150, 0.8);
        }
    }

    .WP-list-item-purchased {
        background-color: rgba(102, 41, 20, 0.75);
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.58);
        font-size: ${({ theme }) => theme.typography.size.p12.fontSize};
        font-weight: ${({ theme }) => theme.typography.weight.bold};

        ${({ theme }) => theme.breakpoints.up('lg')} {
            top: 36px;
            font-size: ${({ theme }) => theme.typography.size.p14.fontSize};
        }
    }

    .WP-list-item-day {
        text-shadow: 0 1px 5px rgba(0, 0, 0, 0.58);
        font-size: ${({ theme }) => theme.typography.size.p14.fontSize};

        ${({ theme }) => theme.breakpoints.up('1780')} {
            font-size: ${({ theme }) => theme.typography.size.h5.fontSize};
        }
    }
`;

export const DailyUpcomingOfferBodyStyled = styled.div`
    background-image: url(${backgroundImageXS});
    background-color: #2e20ac;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    z-index: 2;

    ${({ theme }) => theme.breakpoints.up('1780')} {
        background-image: url(${backgroundImageXl});
    }

    .WP-daily-upcoming-text {
        line-height: 28px;

        ${({ theme }) => theme.breakpoints.up('lg')} {
            line-height: 44px;
        }
    }
`;
