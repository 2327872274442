import styled from 'styled-components';

import bgImage from '../../../../assets/images/Home/Logout/landingBg.png';

import landingArt from '../../../../assets/images/Home/Logout/landingArt.png';

export const LandingStyled = styled.section`
    min-height: 380px;
    background-image: url(${bgImage});
    z-index: 6;

    ${({ theme }) => theme.breakpoints.up('md')} {
        margin-top: -61px;
    }

    &:after {
        content: '';
        display: block;
        width: 350px;
        height: 350px;
        position: absolute;
        right: 0;
        bottom: 6px;
        z-index: -1;
        background: url(${landingArt}) no-repeat right bottom;
        border-bottom-right-radius: 20px;
    }

    .WP-logo-wrapper {
        width: 50%;
        text-align: center;

        .WP-landing-logo {
            width: 400px;
        }
    }
`;
