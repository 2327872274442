import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box, useMediaQuery } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';

import { useTranslation } from '../../../../hooks/useTranslation';
import DailyTimer from './DailyTimer';

import { globalSel } from '../../../../store/global';
import { UpcomingSliderItemStyled } from './UpcommingSliderItemStyled';

const UpcomingSliderItem = ({ offer, openModal }) => {
    const { StartDate: start, EndDate: end } = offer;
    const t = useTranslation();
    const theme = useSelector(globalSel.themeSelector);
    const breakpointsMac = useMediaQuery(theme.breakpoints.between(1780, 1800), {
        noSsr: true,
    });

    return (
        <Box
            component={UpcomingSliderItemStyled}
            className="WP-daily-upcoming flex-center"
            height={[400, 244, 244, breakpointsMac ? 385 : 288, 450]}
            width={1}
            overflow="hidden"
            onClick={openModal}
        >
            <Box
                className="flex-center"
                flexDirection="column"
                maxWidth={['100%', '42%', '42%', breakpointsMac ? '58%' : '40%', '50%']}
            >
                <Box
                    component={Typography}
                    className="WP-daily-upcoming-text text-uppercase"
                    variant={['h6', 'h6', 'h6', breakpointsMac ? 'h1' : 'h5', 'h1']}
                    textAlign="center"
                    fWeight="bold"
                    color={theme.palette.common.white}
                >
                    {offer.offerIndex === 2
                        ? t('daily.upcoming.offer.text')
                        : t('daily.upcoming.after.tomorrow.text')}
                </Box>

                <Box width="fit-content" mx="auto" mt={[5, 5, 5, 5, 7]}>
                    <DailyTimer start={start} end={end} />
                </Box>
            </Box>
        </Box>
    );
};
UpcomingSliderItem.propTypes = {
    offer: PropTypes.object,
    openModal: PropTypes.func,
};
export default UpcomingSliderItem;
