import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';

import { useTranslation } from '../../../hooks/useTranslation';
import PageHeader from '../../components/PageHeader/PageHeader';
import TopPlayers from '../../components/Leaderboard/TopPlayers';
import TopFamilies from '../../components/Leaderboard/TopFamilies';

import { leadersOp } from '../../../store/leaderboard';
import { leadersSel } from '../../../store/leaderboard';

const Leaderboard = () => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const isLoadingLeaderboards = useSelector(leadersSel.isLoading);

    useEffect(() => {
        dispatch(leadersOp.getBoards(10));
        return () => {
            dispatch(leadersOp.resetBoards());
        };
    }, []);

    return (
        <Box className="WP-leaderboard" pt={[5, 5, 5, 7]}>
            <PageHeader
                title={t('leaderboards')}
                subtitle={t('leaderboard.page.sub.title')}
                icon="icon-leaderboard"
                isLoading={isLoadingLeaderboards}
            />

            <TopPlayers selector="WebTopPlayers" />

            <TopFamilies selector="WebTopFamilies" />
        </Box>
    );
};

export default Leaderboard;
