import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import NotificationPipContext from './NotificationPipContext';
import { pipGetter } from '../../core/application/config';
import { setGAEvent } from '../../helpers/GA4Helper';
import { getStorageItem, removeStorageItem, setStorageItem } from '../../helpers/localStorage';

import { globalSel } from '../../store/global';
import { dailyRewardsSel } from '../../store/dailyReward';
import { dailyDealSel } from '../../store/dailyDeals';

const NotificationPipProvider = ({ children }) => {
    const location = useLocation();
    const [pips, setPips] = useState({});
    const [hamburguerNotification, setHamburguerNotification] = useState(false);
    const authenticated = useSelector(globalSel.isAuthSelector);

    const dailyRewards = useSelector(dailyRewardsSel.dailyRewardsSelector);
    const dailyGiftClaimed = dailyRewards?.DailyGiftClaimed;
    const dailyDealsDataNextRefreshDate = useSelector(
        dailyDealSel.dailyDealsSelector,
    )?.NextRefreshDate;

    const isActive = (href) => {
        return href === location.pathname || (location.pathname.includes(href) && href !== '/');
    };

    const hasNotification = (href, pip) => {
        if (
            pips &&
            authenticated &&
            pips[pip] &&
            pips[pip]?.IsEnabled &&
            dailyGiftClaimed !== undefined
        ) {
            const active = isActive(href);

            switch (pip) {
                case 'storePip':
                    if (dailyGiftClaimed === true) {
                        if (active) {
                            setGAEvent('clickEvent', {
                                screen_id: 'web_portal',
                                screen_name: 'web_portal',
                                screen_type: 'screen',
                                interaction_type: 'tap',
                                interaction_object_category: 'display',
                                screen_content: 'lp_store',
                            });
                        }
                    } else {
                        setGAEvent('impression', {
                            screen_id: 'web_portal',
                            screen_name: 'web_portal',
                            screen_type: 'screen',
                            interaction_type: 'load',
                            interaction_object_category: 'display',
                            screen_content: 'lp_store_red_dot_active',
                        });
                        if (active) {
                            setGAEvent('clickEvent', {
                                screen_id: 'web_portal',
                                screen_name: 'web_portal',
                                screen_type: 'screen',
                                interaction_type: 'tap',
                                interaction_object_category: 'display',
                                screen_content: 'lp_store_red_dot_active',
                            });
                        }

                        handleHamburguerNotification(pips);
                        return true;
                    }

                    break;
                case 'lpPip':
                    var storagePip = getStorageItem(pip);

                    if (storagePip) {
                        if (new Date() > new Date(storagePip)) {
                            removeStorageItem(pip);
                            storagePip = null;
                        } else if (
                            dailyDealsDataNextRefreshDate &&
                            new Date(storagePip) !== new Date(dailyDealsDataNextRefreshDate)
                        ) {
                            setStorageItem(pip, new Date(dailyDealsDataNextRefreshDate));
                            storagePip = new Date(dailyDealsDataNextRefreshDate);
                        }
                    }

                    if (active) {
                        if (!storagePip) {
                            setStorageItem(pip, new Date(dailyDealsDataNextRefreshDate));

                            setGAEvent('clickEvent', {
                                screen_id: 'web_portal',
                                screen_name: 'web_portal',
                                screen_type: 'screen',
                                interaction_type: 'tap',
                                interaction_object_category: 'display',
                                screen_content: 'lp_store_red_dot_active',
                            });
                        } else {
                            setGAEvent('clickEvent', {
                                screen_id: 'web_portal',
                                screen_name: 'web_portal',
                                screen_type: 'screen',
                                interaction_type: 'tap',
                                interaction_object_category: 'display',
                                screen_content: 'lp_store',
                            });
                        }
                    } else {
                        if (!storagePip) {
                            handleHamburguerNotification(pips);
                            setGAEvent('impression', {
                                screen_id: 'web_portal',
                                screen_name: 'web_portal',
                                screen_type: 'screen',
                                interaction_type: 'load',
                                interaction_object_category: 'display',
                                screen_content: 'lp_store_red_dot_active',
                            });
                            return true;
                        }
                    }

                    break;

                default:
                    return false;
            }
        }

        handleHamburguerNotification(pips);
        return false;
    };

    const handleHamburguerNotification = (pipsRes) => {
        for (const key in pipsRes) {
            const storagePip = getStorageItem(key);

            if (pipsRes[key].IsEnabled && authenticated) {
                switch (key) {
                    case 'storePip':
                        if (dailyGiftClaimed !== undefined && dailyGiftClaimed === false) {
                            setHamburguerNotification(true);
                            return;
                        }
                        break;
                    case 'lpPip':
                        if (!storagePip) {
                            setHamburguerNotification(true);
                            return;
                        }
                        break;

                    default:
                        break;
                }
            }
        }
        setHamburguerNotification(false);
    };

    const fetchPip = async () => {
        const res = await pipGetter.execute();
        setPips(res);
        handleHamburguerNotification(res);
    };

    useEffect(() => {
        fetchPip();
    }, [location, authenticated]);

    const value = {
        pips,
        hamburguerNotification,
        isActive,
        hasNotification,
    };

    return (
        <NotificationPipContext.Provider value={value}>{children}</NotificationPipContext.Provider>
    );
};

export default NotificationPipProvider;

NotificationPipProvider.propTypes = {
    children: PropTypes.node,
};
