import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';

import SectionTitle from '../SectionTitle/SectionTitle';
import TopLeadersSkeleton from './TopLeaders/TopLeadersSkeleton';
import TopLeadersSlider from './TopLeaders/TopLeadersSlider';
import { useTranslation } from '../../../hooks/useTranslation';
import { leadersOp, leadersSel } from '../../../store/leaderboard';

const HomeLeaderboard = () => {
    const t = useTranslation();
    const dispatch = useDispatch();

    const isLoading = false;
    const data = [true];
    const boards = useSelector(leadersSel.boardsSelector);

    useEffect(() => {
        dispatch(leadersOp.getBoards(3));
        return () => {
            dispatch(leadersOp.resetBoards());
        };
    }, []);

    const list = useMemo(() => {
        let data = [];
        if (boards.Players) data = boards.Players;
        if (boards.Families) data = [...data, ...boards.Families];
        return data;
    }, [boards]);

    if (!boards) return false;

    return (
        <Box component="section" pb={[10, 12, 12, 16, 20]}>
            {isLoading ? (
                <TopLeadersSkeleton />
            ) : !data?.length ? null : (
                <>
                    <SectionTitle
                        title={t('leaderboards')}
                        subtitle={t('leaderboards.subtitle')}
                        icon="icon-leaderboard"
                    />

                    <TopLeadersSlider isHomePage data={list} />
                </>
            )}
        </Box>
    );
};

export default HomeLeaderboard;
