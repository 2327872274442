import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { Box, Icon } from 'portal-modules/core-ui';
import { Dialog } from '@material-ui/core';
import { ModalStyled } from '../Modal/Modal.styled';
import { globalOp, globalSel } from '../../../store/global';
import DefaultModal from '../Modal/components/InterstitialModal/DefaultModal/DefaultModal';
import { setGAEvent } from '../../../helpers/GA4Helper';

const Interstitials = () => {
    const dispatch = useDispatch();
    const interstitials = useSelector(globalSel.interstitials);
    //const interstitialPopups = useSelector(globalSel.popUpsSelector);
    const maintenanceMode = useSelector(globalSel.maintenanceModeSelector);

    //if (!interstitialPopups) return false;

    /*    const handleClose = () => {
        if (interstitialPopups.length - 1 > index) {
            setIndex(index + 1);
        } else {
            setIndex(0);
            dispatch(globalOp.closeInterstitials());
        }
    };*/

    const handleClose = () => {
        dispatch(globalOp.closeInterstitials());
        setGAEvent('clickEvent', {
            screen_name: 'interstitial',
            screen_type: 'pop-up',
            interaction_object: 'close_button',
            interaction_object_category: 'close',
            interstitial_id: interstitials?.data?.Id,
        });
    };

    return (
        interstitials.open &&
        !maintenanceMode && (
            <Dialog
                data-testid="interstitial_modal"
                fullWidth={false}
                maxWidth="sm"
                open={interstitials.open}
                className="WP-Modal-dialog"
                onClose={handleClose}
            >
                <Box
                    component={ModalStyled}
                    className="WP-Modal-root flex-center22"
                    width="100%"
                    position="relative"
                    flexDirection="column"
                >
                    <Box position="absolute" top={12} right={12} onClick={handleClose}>
                        <Box
                            component={Icon}
                            size={18}
                            className={clsx(
                                'icon-close',
                                'cursor-pointer',
                                'WP-Modal-close-button flex-center',
                            )}
                            borderRadius="50%"
                        />
                    </Box>
                    <DefaultModal data={interstitials.data} />
                </Box>
            </Dialog>
        )
    );
};

export default Interstitials;
