import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from '../../../hooks/useTranslation';

import { Icon, Typography } from 'portal-modules/core-ui';
import { Box, Hidden, Tooltip, useMediaQuery } from '@material-ui/core';

import { Button } from '../ui-components/Button/Button';

import FeaturedOffers from './FeaturedOffers/FeaturedOffers';
import CountdownUTC from '../CountdownUTC/CountdownUTC';
import Badge from '../Badge/Badge';
import { FeaturedItemStyled } from './FeaturedSliderItem.styled';

import { withPurchase } from '../../../hoc/withPurchase';
import { calculateDiscount, USNumberFormat } from '../../../helpers/utils';
import { globalSel } from '../../../store/global';
import { setStorageItem } from '../../../helpers/localStorage';
import LoyaltyPointsImage from '../../../assets/images/Global/loyaltyPoints.png';

function FeaturedSliderItem({ item, handlePurchase, endDate }) {
    const t = useTranslation();
    const theme = useSelector(globalSel.themeSelector);
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
    const breakpointsMac = useMediaQuery(theme.breakpoints.between(1780, 1800), {
        noSsr: true,
    });

    if (!item) return false;

    const loyaltyPoints = item?.Credit?.Adjustments.find((elem) => {
        return elem.CommodityKey === 'LoyaltyPoint';
    });
    const discountedCommodity = item?.Debit?.Adjustments[0];
    const originalCommodity = item?.OriginalListing?.Debit?.Adjustments[0];
    const divider = discountedCommodity.CommodityKey === 'USP' ? 100 : 1;
    const originalQuantity = originalCommodity ? originalCommodity?.Quantity / divider : null;
    const discountedQuantity = discountedCommodity ? discountedCommodity?.Quantity / divider : null;

    const discount = calculateDiscount(originalQuantity, discountedQuantity);
    /* if (limitReset) {
        const splitTime = limitReset?.split(':');
        const daySplit = splitTime?.[0].split('.');
        if (daySplit && daySplit[1]) {
            splitTime[0] = daySplit[0] * 24 + +daySplit[1];
        }
        endDate.setHours(splitTime?.[0], splitTime?.[1], splitTime?.[2]);
    }*/

    return (
        <Box
            key={item.IapSku}
            component={FeaturedItemStyled}
            width={1}
            height={[400, 244, 244, breakpointsMac ? 385 : 288, 450]}
            display="flex"
            alignItems="center"
            backgroundImage={item?.DisplayDetails?.BackgroundUrl}
            boxShadow={theme.elevation[800]}
            overflow="hidden"
        >
            {discount ? (
                <Badge text={`${discount}% ${t('off')}`} className="large-mobile" />
            ) : item?.DisplayDetails?.BannerText ? (
                <Badge text={item?.DisplayDetails?.BannerText} className="large-mobile" />
            ) : null}

            <Box
                width={['100%', '60%']}
                height={1}
                className="flex-center"
                justifyContent="space-between"
                flexDirection="column"
                zIndex={0}
            >
                <Box flexGrow={1} className="flex-center" flexDirection="column" py={2}>
                    <Box className="WP-featured-shine-effect">
                        <Box
                            component="img"
                            src={item?.DisplayDetails?.FeaturedLogoUrl}
                            alt="Featured Image"
                            // width={[160, 175, 175, 175, 236]}
                            mb={2}
                            mt={[5, 0]}
                            maxHeight={[80, 70, 60, 80, 160]}
                        />
                    </Box>
                </Box>
                {endDate && (
                    <Box mb={[2, 4, 4, 4, 6]}>
                        <CountdownUTC
                            text={t('offers.refresh.timer')}
                            start={endDate}
                            interval={60 * 1000}
                            d
                            h
                            m
                            callback={() => setStorageItem('getFeaturedItems', true)}
                        />
                    </Box>
                )}
                <Hidden smUp>
                    <Box width={['100%', '40%']}>
                        <FeaturedOffers data={item?.Credit?.Adjustments} />
                    </Box>
                </Hidden>

                <Box
                    width={1}
                    className="WP-featured-shadow flex-center"
                    flexDirection="column"
                    pb={[2, 2, 2, 2, 4]}
                >
                    {originalQuantity && originalQuantity !== discountedQuantity && (
                        <Box display="flex" alignItems="center" pb={[1, 1, 1, 1, 2]}>
                            <Box
                                component={Typography}
                                className="WP-card-discount"
                                display="flex"
                                alignItems="center"
                                variant={['p16', 'p16', 'p16', 'h6']}
                                fWeight="semiBold"
                                color={theme.palette.red.A700}
                                mr={1}
                            >
                                {t('retail.price')}
                            </Box>

                            <Box
                                variant={['p16', 'p16', 'p16', 'h6']}
                                fWeight="semiBold"
                                color={theme.palette.red.A700}
                                component={Typography}
                                textAlign="center"
                                className="text-decoration-line-through WP-featured-discount"
                            >
                                {originalCommodity.CommodityKey === 'USP' ? (
                                    <span>$</span>
                                ) : (
                                    <Box
                                        component="img"
                                        src={LoyaltyPointsImage}
                                        alt="loyalty points"
                                        width={[16, 20, 20, 24, 30]}
                                        height={[16, 20, 20, 24, 30]}
                                    />
                                )}
                                {USNumberFormat(originalQuantity)}
                            </Box>
                        </Box>
                    )}

                    <Box
                        mb={[2, 2, 2, 2, 3]}
                        minWidth={['100%', 300, 300, breakpointsMac ? 550 : 300, 550]}
                        px={3}
                    >
                        <Button
                            completed={item?.availableCount != null && item?.availableCount <= 0}
                            onClick={handlePurchase({
                                commodity: discountedCommodity?.CommodityKey,
                                iapSku: item.IapSku,
                                storeListingName: item.Name,
                                item: {
                                    ga: true,
                                    name: item?.DisplayDetails?.DisplayName,
                                    price: discountedQuantity,
                                    section: 'featured_offers',
                                },
                            })}
                            className="featured-purchase"
                            preventLoading
                            size={breakpointsXL ? 'large' : breakpointsLG ? 'medium' : 'small'}
                            variant="secondary"
                        >
                            {item?.availableCount != null && item?.availableCount <= 0 ? (
                                t('already.owned')
                            ) : (
                                <>
                                    {discountedCommodity.CommodityKey === 'USP' ? (
                                        <span>$</span>
                                    ) : (
                                        <Box
                                            component="img"
                                            src={LoyaltyPointsImage}
                                            alt="loyalty points"
                                            width={[16, 20, 20, 24, 30]}
                                            height={[16, 20, 20, 24, 30]}
                                        />
                                    )}
                                    {discountedQuantity && USNumberFormat(discountedQuantity)}
                                </>
                            )}
                        </Button>
                    </Box>
                    {loyaltyPoints && (
                        <Box width="100%" className="flex-center">
                            <Tooltip
                                enterTouchDelay={0}
                                title={t('loyalty.point.tooltip')}
                                arrow
                                placement="top-start"
                                PopperProps={{ disablePortal: true }}
                            >
                                <Box
                                    width={16}
                                    height={16}
                                    className="flex-center"
                                    borderRadius="50%"
                                    bgcolor={theme.palette.common.white}
                                >
                                    <Icon
                                        className="icon-info"
                                        size={8}
                                        color={theme.palette.brown.A700}
                                    />
                                </Box>
                            </Tooltip>

                            <Box
                                component={Typography}
                                variant={['p12', 'p12', 'p12', 'p14']}
                                fWeight="medium"
                                color={theme.palette.common.white}
                                mx={1}
                            >
                                {`${t('purchase.and.earn')} ${loyaltyPoints?.Quantity}`}
                            </Box>

                            <Box
                                component="img"
                                src={LoyaltyPointsImage}
                                alt="Timer Image"
                                width={16}
                                height={16}
                            />
                        </Box>
                    )}
                </Box>
            </Box>

            <Hidden xsDown>
                <Box width={['100%', '40%']}>
                    <FeaturedOffers data={item?.Credit?.Adjustments} />
                </Box>
            </Hidden>
        </Box>
    );
}

FeaturedSliderItem.propTypes = {
    item: PropTypes.object,
    handlePurchase: PropTypes.func,
    commodity: PropTypes.string,
    endDate: PropTypes.string,
};

export default withPurchase(FeaturedSliderItem);
