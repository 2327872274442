import { LANGUAGES } from '../../../constants/languages.constantants';

export class UserGuidesGetter {
    constructor(userGuidesRepository) {
        this.userGuidesRepository = userGuidesRepository;
    }

    async execute(locale = LANGUAGES[0].locale) {
        return await this.userGuidesRepository.getUserGuides({ locale });
    }
}
