import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '../../../../../hooks/useTranslation';
import PropTypes from 'prop-types';

import { Box, Icon, Typography } from 'portal-modules/core-ui';
import { Button } from '../../../ui-components/Button/Button';
import { useMediaQuery } from '@material-ui/core';

import { AccountModalStyled } from '../AccountModal.styled';
import mainImage from '../../../../../assets/images/Modal/insufficient.png';
import { globalOp } from '../../../../../store/global';

const InsufficientModal = ({ theme }) => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const breakpointsMD = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

    const redirectToStore = () => {
        dispatch(globalOp.handleCloseModal({}));
        history.push('/store', 'store-loyalty');
    };

    return (
        <Box
            component={AccountModalStyled}
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            overflow="hidden"
            className="WP-daily-gift WP-modal-small"
        >
            <Box
                height={[160, 250]}
                position="relative"
                bgcolor={theme.palette.orange.main}
                className="flex-center WP-modal-header"
            >
                <Box className="WP-image-container">
                    <Box className="WP-image-bg flex-center" width={[82, 108]} height={[82, 108]}>
                        <Box component="img" src={mainImage} alt="Insufficient image" />
                    </Box>
                </Box>
            </Box>

            <Box
                minHeight={250}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                px={[4, 6]}
                pb={7}
                pt={2}
            >
                <Box
                    component={Typography}
                    variant={['h6', 'h5', 'h4']}
                    fWeight="bold"
                    color={theme.palette.brown.main}
                    mb={3}
                >
                    {t('insufficient.description')}
                </Box>

                <Box
                    component={Button}
                    variant="secondary"
                    size={breakpointsMD ? 'large' : breakpointsSM ? 'medium' : 'small'}
                    onClick={redirectToStore}
                >
                    {t('buy.more.loyalty.points')}

                    <Icon
                        className="icon-loyality-point"
                        size={breakpointsMD ? 26 : breakpointsSM ? 24 : 20}
                    />
                </Box>
            </Box>
        </Box>
    );
};

InsufficientModal.propTypes = {
    theme: PropTypes.object,
    data: PropTypes.object,
    dailyGift: PropTypes.bool,
};

export default InsufficientModal;
