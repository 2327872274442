import styled from 'styled-components';

export const UserImageStyled = styled.div`
    user-select: none;

    .WP-user-image {
        > img {
            object-fit: cover;
        }
    }

    .WP-user-default-image {
        .WP-user-icon {
            font-size: 70px;
            color: ${({ theme }) => theme.palette.orange.A200};

            margin: 0;
        }
    }
`;
